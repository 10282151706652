import * as React from 'react';
import { Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, Switch, TextField, Typography } from '@mui/material';
import { batometroCatalog, batteryCatalog, discount, inclination, installStructureCatalog, inversorCatalog, InversorsCatalog, montaje, panelCatalog, sellerCatalog, solarPanelsCatalog, squareCatalog, structure, typeInversor } from '../utils/products.catalog';
import { getConsumInSolary, getConsumInNight, getCPanelesPerAnualConsum, getExcedent, getSaveMoney, getSaveTree, getSaveCO, getSaveTV } from '../utils/process.quote';
// import { PDFViewer } from '@react-pdf/renderer';
// import ViewPdfQuote from '../viewPdf/viewPdfQuote';
import { format } from 'date-fns';

export default function GeneralData({
    valueForm, 
    onValueForm,
    errorForm,
    onErrorForm,
    showErrorForm,
    dateContrat
}) {
    const handlePpto = (e) => {
        if (e.target.value === '') {
            onErrorForm({
                ...errorForm,
                noPpto: 'Campo incompleto',
            });
        } else if (parseInt(e.target.value) < 111 || parseInt(e.target.value) > 999) {
            onErrorForm({
                ...errorForm,
                noPpto: 'Valor fuera de rango',
            });
        } else {
            onErrorForm({
                ...errorForm,
                noPpto: null,
            });
        }

        onValueForm({
            ...valueForm,
            noPpto: e.target.value,
        });
    };
    
    const handleSeller = (e) => {
        if (e.target.value !== '') {
            onErrorForm({
                ...errorForm,
                seller: null,
            });
        }

        onValueForm({
            ...valueForm,
            seller: e.target.value,
            nameSeller: e.target.value === 'colaborador' ? '' : 'NEWE SOLUTIONS',
        });
    };
    
    const handleNameSeller = (e) => {
        if (e.target.value === '') {
            onErrorForm({
                ...errorForm,
                nameSeller: 'Campo obligatorio',
            });
        } else {
            onErrorForm({
                ...errorForm,
                nameSeller: null,
            });
        }

        onValueForm({
            ...valueForm,
            nameSeller: e.target.value.toUpperCase(),
        });
    };

    return (
        <React.Fragment>
            <Typography variant='h5'>Datos Generales</Typography>
            <Typography variant='body1'>Fecha: {format(dateContrat, 'd-M-yyyy')}</Typography>
            <TextField
                label='No. Presupuesto'
                required
                value={valueForm.noPpto}
                onChange={handlePpto}
                type='number'
                InputProps={{
                    startAdornment: format(dateContrat, 'yyMM') + '13-',
                    type: 'number',
                    autoComplete: 'off',
                    min: 111,
                    max: 999,
                }}
                error={showErrorForm && errorForm.noPpto !== null}
                helperText={showErrorForm && errorForm.noPpto}
            />
            <FormControl required error={showErrorForm && errorForm.seller !== null}>
                <InputLabel id="seller-select-required-label">Vendedor</InputLabel>
                <Select
                    labelId="seller-select-required-label"
                    id="demo-simple-select-required"
                    value={valueForm.seller}
                    label="Vendedor *"
                    onChange={handleSeller}
                >
                    {sellerCatalog.map(item => {
                        return (<MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>);
                    })}
                </Select>
                {showErrorForm && errorForm.seller !== null && (
                    <FormHelperText>{errorForm.seller}</FormHelperText>
                )}
            </FormControl>
            {valueForm.seller === 'colaborador' && (
                <TextField
                    label='Nombre Colaborador'
                    required
                    fullWidth
                    inputProps={{
                        autoComplete: 'off',
                    }}
                    value={valueForm.nameSeller}
                    onChange={handleNameSeller}
                    error={showErrorForm && errorForm.nameSeller !== null}
                    helperText={showErrorForm && errorForm.nameSeller}
                />
            )}
        </React.Fragment>
    );
}