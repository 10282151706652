import * as React from 'react';
import { Autocomplete, Box, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import { batteryCatalog, inversorCatalog, panelCatalog, typeInversor } from '../utils/products.catalog';

export default function InstallData({
    valueForm, 
    onValueForm,
    errorForm,
    onErrorForm,
    showErrorForm
}) {
    const handleCountInversor = e => {
        if (parseInt(e.target.value) >= 1) {
            onValueForm({
                ...valueForm,
                countInversors: e.target.value,
            });
        }
    };

    const handleTypeInversor = e => {
        onValueForm({
            ...valueForm,
            typeInversor: e.target.value,
        });
    };

    const handleInversor = (e, value) => {
        onValueForm({
            ...valueForm,
            inversor: value,
        });
    };

    const handleCountPanels = e => {
        if (parseInt(e.target.value) >= 1) {
            onValueForm({
                ...valueForm,
                countPanels: e.target.value,
            });
        }
    };

    const handlePanel = (e, value) => {
        onValueForm({
            ...valueForm,
            panel: value,
        });
    };

    const handleIncludeBattery = e => {
        onValueForm({
            ...valueForm,
            includeBattery: e.target.checked,
            countBatteries: 1,
            battery: null,
        });
    };
    
    const handleCountBatteries = e => {
        if (parseInt(e.target.value) >= 1) {
            onValueForm({
                ...valueForm,
                countBatteries: e.target.value,
            });
        }
    };

    const handleBattery = (e, value) => {
        onValueForm({
            ...valueForm,
            battery: value,
        });
    };

    return (
        <React.Fragment>
            <Typography variant='h5'>Datos de la Instalaci&oacute;n</Typography>
                <Grid container justifyContent='space-between'>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            required
                            label='No. Inversores'
                            type='number'
                            inputProps={{
                                autoComplete: 'off',
                                min: 1,
                            }}
                            value={valueForm.countInversors}
                            onChange={handleCountInversor}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl required fullWidth>
                            <InputLabel id="typeinversor-select-required-label">Tipo Inversor</InputLabel>
                            <Select
                                labelId="typeinversor-select-required-label"
                                id="demo-simple-select-required"
                                value={valueForm.typeInversor}
                                label="Tipo Inversor *"
                                onChange={handleTypeInversor}
                            >
                                {typeInversor.map(item => {
                                    return (<MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>);
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            id="select-inversor"
                            value={valueForm.inversor}
                            onChange={handleInversor}
                            options={inversorCatalog}
                            autoHighlight
                            clearText='Limpiar selecci&oacute;n'
                            closeText='Cerrar'
                            getOptionLabel={(option) => `${option.name}`}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.name}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Modelo de Inversor"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off',
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent='space-between'>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            required
                            label='No. Placas'
                            type='number'
                            inputProps={{
                                autoComplete: 'off',
                                min: 1,
                            }}
                            value={valueForm.countPanels}
                            onChange={handleCountPanels}
                        />
                    </Grid>
                    <Grid item xs={9.5}>
                        <Autocomplete
                            id="select-panel"
                            value={valueForm.panel}
                            onChange={handlePanel}
                            options={panelCatalog}
                            autoHighlight
                            clearText='Limpiar selecci&oacute;n'
                            closeText='Cerrar'
                            getOptionLabel={(option) => `${option.name}`}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.name}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Modelo de Placas"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off',
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <FormGroup>
                    <FormControlLabel control={<Switch
                        checked={valueForm.includeBattery}
                        onChange={handleIncludeBattery}
                    />} label="Baterías" />
                </FormGroup>
                {valueForm.includeBattery && (
                    <Grid container justifyContent='space-between'>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                required
                                label='No. Baterías'
                                type='number'
                                inputProps={{
                                    autoComplete: 'off',
                                    min: 1,
                                }}
                                value={valueForm.countBatteries}
                                onChange={handleCountBatteries}
                            />
                        </Grid>
                        <Grid item xs={9.5}>
                            <Autocomplete
                                id="select-battery-install"
                                value={valueForm.battery}
                                onChange={handleBattery}
                                options={batteryCatalog}
                                autoHighlight
                                clearText='Limpiar selecci&oacute;n'
                                closeText='Cerrar'
                                getOptionLabel={(option) => `${option.name}`}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.name}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Moledo de Batería"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off',
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                )}
        </React.Fragment>
    );
}