import * as React from 'react';
import { FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Switch, Typography } from '@mui/material';
import { montaje } from '../utils/products.catalog';

export default function MountData({
    valueForm,
    onValueForm,
    errorForm,
    onErrorForm,
    showErrorForm
}) {
    const handleIncludeMount = e => {
        onValueForm({
            ...valueForm,
            includeMount: e.target.checked,
            mount: '',
        });
    };

    const handleMount = (e) => {
        onValueForm({
            ...valueForm,
            mount: e.target.value,
        });
    };

    return (
        <React.Fragment>
            <Typography variant='h5'>Montaje</Typography>
            <FormGroup>
                <FormControlLabel control={<Switch
                    checked={valueForm.includeMount}
                    onChange={handleIncludeMount}
                />} label="Andamio/Grúa" />
            </FormGroup>
            {valueForm.includeMount && (
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="montaje-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={valueForm.mount}
                        onChange={handleMount}
                    >
                        {montaje.map(item => {
                            return (
                                <FormControlLabel
                                    key={item.value}
                                    checked={valueForm.mount === item.value}
                                    value={item.value}
                                    control={<Radio />}
                                    label={item.name}
                                />
                            );
                        })}
                    </RadioGroup>
                </FormControl>
            )}
        </React.Fragment>
    );
}