import * as React from 'react';
import { FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Switch, Typography } from '@mui/material';
import { maintenanceTypes } from '../utils/products.catalog';

export default function MaintenanceData({
    valueForm,
    onValueForm,
    errorForm,
    onErrorForm,
    showErrorForm
}) {
    const handleIncludeMaintenance = e => {
        onValueForm({
            ...valueForm,
            includeMaintenance: e.target.checked
        });
    };

    const handleTypeMaintenance = (e) => {
        onValueForm({
            ...valueForm,
            typeMaintenance: e.target.value,
        });
    };

    return (
        <React.Fragment>
            <Typography variant='h5'>Mantenimiento</Typography>
            <FormGroup>
                <FormControlLabel control={<Switch
                    checked={valueForm.includeMaintenance}
                    onChange={handleIncludeMaintenance}
                />} label="Mantenimiento Anual Incluido" />
            </FormGroup>
            {valueForm.includeMaintenance && (
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="typemaintenance-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={valueForm.typeMaintenance}
                        onChange={handleTypeMaintenance}
                    >
                        {maintenanceTypes.map(item => {
                            return (
                                <FormControlLabel
                                    key={item.value}
                                    value={item.value}
                                    // checked={valueForm.structure === item.value}
                                    control={<Radio />}
                                    label={item.name}
                                />
                            );
                        })}
                    </RadioGroup>
                </FormControl>
            )}
        </React.Fragment>
    );
}