import React from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import logoNewe from '../media/logo-newe.png';

import { es } from 'date-fns/locale';
import { format } from "date-fns";

const PageNinePdfQuote = ({ styles, valueForm, dateContrat }) => {
    return (
        <React.Fragment>
            <Page
                size="A4"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    backgroundColor: "white",
                    marginTop: '5vh',
                }}
            >
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    position: 'absolute',
                    width: '100%',
                    textAlign: 'right',
                    marginTop: '6.5vh',
                    marginRight: '32.5%',
                }}>
                    1/2 p&aacute;ginas
                </Text>
                <Image
                    src={logoNewe}
                    style={{
                        width: '26%',
                        height: 'auto',
                        marginHorizontal: '37%',
                        marginBottom: '1.75vh'
                    }}
                />
                <View
                    style={{
                        display: "flex",
                        alignItems: 'center',
                        backgroundColor: '#bfe35a',
                        paddingTop: '0.5vh',
                        paddingBottom: '0.5vh',
                    }}
                >
                    <Text style={{ ...styles.fontSubtitle, color: 'white' }}>CONTRATO DE INSTALACION DE PLACAS SOLARES FOTOVOLTAICAS</Text>
                </View>
                <View
                    style={{
                        marginHorizontal: '10%',
                    }}
                >
                    <Text style={{
                        ...styles.fontBody,
                        ...styles.primaryColor,
                        marginTop: '1.5vh',
                    }}>En Castelló de la Plana, a {format(dateContrat, "d 'de' MMMM 'de' yyyy", { locale: es })}</Text>
                    <Text style={{
                        ...styles.fontBody,
                        ...styles.primaryColor,
                        textAlign: 'center',
                        marginTop: '2vh',
                    }}>REUNIDOS</Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        De una parte Don/Doña {`${valueForm.nameClient} ${valueForm.surname} ${valueForm.secondname}`}, mayor de edad, con domicilio en {`${valueForm.zipCode} - ${valueForm.place} - ${valueForm.address}`} y número de DNI/NIF {valueForm.indentity}; en adelante “el Cliente”.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        Y de la otra JOSE MIGUEL BORT MONDRAGON, en representación de la Sociedad CONSTRUCCIONES FALUPE, S.L., con domicilio social en Castelló de la Plana y CIF B01919562, y con denominación comercial NewE Solutions, en adelante “el Instalador”.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        Ambas partes intervienen en su propio nombre y derecho y se reconocen mutuamente suficiente capacidad jurídica y de obrar para el otorgamiento del presente contrato, a cuyo efecto,
                    </Text>
                    <Text style={{
                        ...styles.fontBody,
                        ...styles.primaryColor,
                        textAlign: 'center',
                        marginTop: '2vh',
                    }}>EXPONEN</Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        PRIMERO.- El Instalador se dedica profesionalmente a la instalación y mantenimiento de placas solares fotovoltaicas.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        SEGUNDO.- El Cliente desea instalar placas solares fotovoltaicas en su vivienda cuya descripción consta en el Anexo I.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        Las partes convienen en formalizar el presente CONTRATO DE INSTALACION DE PLACAS SOLARES FOTOVOLTAICAS, en base a las siguientes
                    </Text>
                    <Text style={{
                        ...styles.fontBody,
                        ...styles.primaryColor,
                        textAlign: 'center',
                        marginTop: '2vh',
                    }}>ESTIPULACIONES</Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        PRIMERA.- OBJETO DEL CONTRATO. Mediante el presente contrato de instalación, las partes acuerdan que el Instalador instale las placas solares fotovoltaicas y los aparatos accesorios necesarios en la vivienda del Cliente a cambio de que éste abone el precio convenido en el Anexo I.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        SEGUNDA.- OBLIGACIONES DE LAS PARTES. El Cliente se obliga a satisfacer el precio pactado en el Anexo I. Además, el Cliente anticipará un diez por ciento del total de la cantidad contratada a la firma del presente contrato, un cuarenta por ciento el día de la entrega de los materiales objeto de instalación, y quedará un cincuenta por ciento restante pagadero dentro de los cinco días siguientes a la finalización de los trabajos de instalación. Y el Instalador se compromete a realizar la instalación, de acuerdo, a la descripción establecida en el Anexo I y conforme a los requisitos legales.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        El Cliente declara que su vivienda ostenta una cédula de habitabilidad y que se lo otorgó en su momento la oportuna licencia de obras.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        El Cliente se obliga a facilitar toda la información tanto personal como de la vivienda necesaria para la tramitación de la licencia necesaria.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        TERCERA.- RESPONSABILIDADES. Si el Cliente contrata al Instalador para que tramite en su nombre las ayudas “Next Generation” destinadas al autoconsumo de acuerdo con el Real Decreto 477/2021, de 29 de junio, el Instalador no se hace responsable del no otorgamiento de dichas ayudas.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        En el caso de que el Cliente desee obtener la deducción por obras de mejora de la eficiencia energética de la vivienda en su declaración del Impuesto sobre la Renta deberá comunicarlo al Instalador de forma expresa y por escrito al objeto de que éste emita el oportuno certificado de clasificación energética de la vivienda o de reducción del consumo energético procedente de fuentes de energía no renovables.
                    </Text>
                </View>
            </Page>
            <Page
                size="A4"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    backgroundColor: "white",
                    marginTop: '5vh',
                }}
            >
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    position: 'absolute',
                    width: '100%',
                    textAlign: 'right',
                    marginTop: '6.5vh',
                    marginRight: '32.5%',
                }}>
                    2/2 p&aacute;ginas
                </Text>
                <Image
                    src={logoNewe}
                    style={{
                        width: '26%',
                        height: 'auto',
                        marginHorizontal: '37%',
                        marginBottom: '1.75vh'
                    }}
                />
                <View
                    style={{
                        display: "flex",
                        alignItems: 'center',
                        backgroundColor: '#bfe35a',
                        paddingTop: '0.5vh',
                        paddingBottom: '0.5vh',
                    }}
                >
                    <Text style={{ ...styles.fontSubtitle, color: 'white' }}>CONTRATO DE INSTALACION DE PLACAS SOLARES FOTOVOLTAICAS</Text>
                </View>
                <View
                    style={{
                        marginHorizontal: '10%',
                    }}
                >
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        CUARTA.- GARANTÍAS. El Instalador se compromete a instalar placas solares fotovoltaicas con una garantía mínima del fabricante de 20 años y un inversor con una garantía mínima de 5 años.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        QUINTA.- PLAZO DE EJECUCION. El Instalador se compromete a realizar la instalación en un plazo máximo de tres meses desde la firma del presente contrato.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        SEXTA.- PROTECCION DE DATOS DE CARÁCTER PERSONAL. El Cliente consiente el tratamiento de los datos personales necesarios únicamente para los fines del presente contrato a cargo del Instalador. Y el Instalador reconoce el derecho del Cliente de podrá ejercitar sus derechos de acceso, rectificación, oposición, supresión remitiendo un correo electrónico al email administracion@grupofalupe.com
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        SÉPTIMA.- SUMISIÓN A TRIBUNALES. Las partes acuerdan que para las discrepancias que pudieran surgir en la interpretación, ejecución o aplicación de este contrato se someten expresamente a los Juzgados de Castelló de la Plana y renuncian de forma expresa a cualquier otro fuero o jurisdicción que pudiera serles de aplicación.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        Y, en prueba de conformidad, los comparecientes firman, en el lugar y fecha que figuran en el encabezamiento.
                    </Text>
                </View>
                <View
                    style={{
                        marginHorizontal: '10%',
                        marginTop: '15vh',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                    }}
                >
                    <Text
                        style={{
                            ...styles.fontSubtitle,
                            ...styles.primaryColor,
                            width: '35%',
                            textAlign: 'center',
                            borderTop: 1,
                            borderTopColor: '#005da4',
                            paddingTop: '1vh',
                        }}
                    >EL INSTALADOR</Text>
                    <Text
                        style={{
                            ...styles.fontSubtitle,
                            ...styles.primaryColor,
                            width: '35%',
                            textAlign: 'center',
                            borderTop: 1,
                            borderTopColor: '#005da4',
                            paddingTop: '1vh',
                        }}
                    >EL CLIENTE</Text>
                </View>
            </Page>
            {/* CONTRATO CONTRATO CONTRATO CONTRATO */}
            <Page
                size="A4"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    backgroundColor: "white",
                    marginTop: '5vh',
                }}
            >
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    position: 'absolute',
                    width: '100%',
                    textAlign: 'right',
                    marginTop: '6.5vh',
                    marginRight: '32.5%',
                }}>
                    1/2 p&aacute;ginas
                </Text>
                <Image
                    src={logoNewe}
                    style={{
                        width: '26%',
                        height: 'auto',
                        marginHorizontal: '37%',
                        marginBottom: '1.75vh'
                    }}
                />
                <View
                    style={{
                        display: "flex",
                        alignItems: 'center',
                        backgroundColor: '#bfe35a',
                        paddingTop: '0.5vh',
                        paddingBottom: '0.5vh',
                    }}
                >
                    <Text style={{ ...styles.fontSubtitle, color: 'white' }}>CONTRATO DE MANTENIMIENTO DE PLACAS SOLARES FOTOVOLTAICAS</Text>
                </View>
                <View
                    style={{
                        marginHorizontal: '10%',
                    }}
                >
                    <Text style={{
                        ...styles.fontBody,
                        ...styles.primaryColor,
                        marginTop: '1.5vh',
                    }}>En Castelló de la Plana, a {format(dateContrat, "d 'de' MMMM 'de' yyyy", { locale: es })}</Text>
                    <Text style={{
                        ...styles.fontBody,
                        ...styles.primaryColor,
                        textAlign: 'center',
                        marginTop: '2vh',
                    }}>REUNIDOS</Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        De una parte Don/Doña {`${valueForm.nameClient} ${valueForm.surname} ${valueForm.secondname}`}, mayor de edad, con domicilio en {`${valueForm.zipCode} - ${valueForm.place} - ${valueForm.address}`} y número de DNI/NIF {valueForm.indentity}; en adelante “el Cliente”.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        Y de la otra JOSE MIGUEL BORT MONDRAGON, en representación de la Sociedad CONSTRUCCIONES FALUPE, S.L., con domicilio social en Castelló de la Plana y CIF B01919562, y con denominación comercial NewE Solutions, en adelante “el Mantenedor”.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        Ambas partes intervienen en su propio nombre y derecho y se reconocen mutuamente suficiente capacidad jurídica y de obrar para el otorgamiento del presente contrato, a cuyo efecto,
                    </Text>
                    <Text style={{
                        ...styles.fontBody,
                        ...styles.primaryColor,
                        textAlign: 'center',
                        marginTop: '2vh',
                    }}>EXPONEN</Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        PRIMERO.- El Mantenedor se dedica profesionalmente a la instalación y mantenimiento de placas solares fotovoltaicas.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        SEGUNDO.- El Cliente ha adquirido del Mantenedor una instalación de placas fotovoltaicas cuya descripción consta en el Anexo I, y ahora le interesa su mantenimiento para su perfecto funcionamiento.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        Las partes convienen en formalizar el presente CONTRATO DE MANTENIMIENTO DE PLACAS SOLARES FOTOVOLTAICAS, en base a las siguientes
                    </Text>
                    <Text style={{
                        ...styles.fontBody,
                        ...styles.primaryColor,
                        textAlign: 'center',
                        marginTop: '2vh',
                    }}>ESTIPULACIONES</Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        PRIMERA.- OBJETO DEL CONTRATO. Mediante el presente contrato de mantenimiento, las partes acuerdan que el Mantenedor se ocupará del mantenimiento preventivo de la instalación adquirida por el Cliente a cambio de que éste abone el precio convenido en el Anexo I.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        Dicho mantenimiento preventivo comprende la limpieza de las placas fotovoltaicas, la revisión del cableado y del inversor y, en su caso, de las baterías instaladas por el Mantenedor. Por tanto, se establece un servicio que consistirá en una única asistencia presencial anual a las instalaciones del Cliente para realizar las tareas contratadas.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        Se excluye del presente contrato el mantenimiento correctivo, es decir, la resolución de incidencias, averías del material instalado, o sustitución del material tras el período de garantía legalmente establecido.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        Si se producen modificaciones de equipos o instalaciones periféricas, más allá de la simple variación del número de equipos, el Mantenedor tendrá la facultad de someter a la aprobación del Cliente una nueva propuesta, así como de rescindir el Contrato en caso de no alcanzar un acuerdo. La actualización de los precios del presente Contrato, por las mencionadas variaciones, se regularizará de acuerdo a lo establecido en este Contrato.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        SEGUNDA.- DURACIÓN DEL CONTRATO Y PRÓRROGAS. El presente contrato tendrá una duración de UN AÑO, prorrogable automáticamente por igual período si ninguna de las partes lo denuncia con un mes de antelación a su finalización. No cabe la finalización anticipada de este contrato, excepto si alguna de las partes incumple con cualesquiera de sus obligaciones.
                    </Text>
                </View>
            </Page>
            <Page
                size="A4"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    backgroundColor: "white",
                    marginTop: '5vh',
                }}
            >
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    position: 'absolute',
                    width: '100%',
                    textAlign: 'right',
                    marginTop: '6.5vh',
                    marginRight: '32.5%',
                }}>
                    2/2 p&aacute;ginas
                </Text>
                <Image
                    src={logoNewe}
                    style={{
                        width: '26%',
                        height: 'auto',
                        marginHorizontal: '37%',
                        marginBottom: '1.75vh'
                    }}
                />
                <View
                    style={{
                        display: "flex",
                        alignItems: 'center',
                        backgroundColor: '#bfe35a',
                        paddingTop: '0.5vh',
                        paddingBottom: '0.5vh',
                    }}
                >
                    <Text style={{ ...styles.fontSubtitle, color: 'white' }}>CONTRATO DE MANTENIMIENTO DE PLACAS SOLARES FOTOVOLTAICAS</Text>
                </View>
                <View
                    style={{
                        marginHorizontal: '10%',
                    }}
                >
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        TERCERA.- OBLIGACIONES DE MANTENIMIENTO. El Mantenedor se compromete a realizar las siguientes tareas de mantenimiento:
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1vh',
                        marginLeft: '5%',
                    }}>
                        - Limpieza de las placas solares instaladas por el Mantenedor.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1vh',
                        marginLeft: '5%',
                    }}>
                        - Revisión del cableado.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1vh',
                        marginLeft: '5%',
                    }}>
                        - Revisión del inversor.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        En el caso de que durante el mantenimiento preventivo se detecten incidencias que puedan afectar al normal funcionamiento de la instalación, el Mantenedor propondrá al Cliente las tareas o el material a sustituir. En cualquier caso, la sustitución del material o el mantenimiento correctivo no se encuentra incluido dentro de los servicios contratados y tendrán que ser prestados y abonados aparte.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        CUARTA.- OBLIGACIONES DEL CLIENTE. El Cliente se obliga a satisfacer el precio pactado en el Anexo I.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        QUINTA.- SUMISIÓN A TRIBUNALES. Las partes acuerdan que para las discrepancias que pudieran surgir en la interpretación, ejecución o aplicación de este contrato se someten expresamente a los Juzgados de Castelló de la Plana y renuncian de forma expresa a cualquier otro fuero o jurisdicción que pudiera serles de aplicación.
                    </Text>
                    <Text style={{
                        ...styles.fontMiniBody,
                        ...styles.primaryColor,
                        textAlign: 'justify',
                        marginTop: '1.5vh',
                    }}>
                        Y, en prueba de conformidad, los comparecientes firman, en el lugar y fecha que figuran en el encabezamiento.
                    </Text>
                </View>
                <View
                    style={{
                        marginHorizontal: '10%',
                        marginTop: '15vh',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                    }}
                >
                    <Text
                        style={{
                            ...styles.fontSubtitle,
                            ...styles.primaryColor,
                            width: '35%',
                            textAlign: 'center',
                            borderTop: 1,
                            borderTopColor: '#005da4',
                            paddingTop: '1vh',
                        }}
                    >EL MANTENEDOR</Text>
                    <Text
                        style={{
                            ...styles.fontSubtitle,
                            ...styles.primaryColor,
                            width: '35%',
                            textAlign: 'center',
                            borderTop: 1,
                            borderTopColor: '#005da4',
                            paddingTop: '1vh',
                        }}
                    >EL CLIENTE</Text>
                </View>
            </Page>
        </React.Fragment>
    );
}

export default PageNinePdfQuote;