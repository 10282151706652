import React from "react";
import { Document, Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import pFourFull from '../media/pag4-full.PNG';

const PageFourPdfQuote = ({ styles, valueForm }) => {
  return (
    <Page
      size="A4"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        backgroundColor: "white"
      }}
    >
      <Image
        src={pFourFull}
      />
    </Page>
  );
};

export default PageFourPdfQuote;