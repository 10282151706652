import React from "react";
import { Document, Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import pTenFull from '../media/pag10-full.PNG';

const PageTenPdfQuote = ({ styles, valueForm }) => {
  return (
    <Page
      size="A4"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        backgroundColor: "white"
      }}
    >
      <Image
        src={pTenFull}
      />
    </Page>
  );
};

export default PageTenPdfQuote;