import React from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import imgRight from '../media/pag5-right.png';
import { format } from "date-fns";
import { getCostWIVA, getAhorroMoney, roundTwoDecimal } from '../utils/process.quote';

const PageSixPdfQuote = ({ styles, valueForm, dateContrat }) => {
    return (
        <Page
            size="A4"
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                backgroundColor: "white",
            }}
        >
            <View>
                <Text
                    style={{
                        ...styles.fontSubtitle,
                        width: '100%',
                        paddingVertical: '1.5vh',
                        marginBottom: '3vh',
                        fontSize: '20pt',
                        color: 'white',
                        textAlign: 'center',
                        backgroundColor: styles.primaryColor.color
                    }}
                >PRESUPUESTO COMERCIAL</Text>
            </View>
            <View
                style={{
                    ...styles.fontMiniBody,
                    width: '96%',
                    marginHorizontal: '2%',
                }}
            >
                <View
                    style={{
                        ...styles.fontBody,
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '0.5vh',
                        width: '35%',
                        marginLeft: '65%'
                    }}
                >
                    <Text style={{ textAlign: 'left' }}>Nº PRESUPUESTO:</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspSecondary,
                            paddingHorizontal: 5,
                        }}
                    >{`${format(dateContrat, 'yyMM')}13${valueForm.noPpto}`}</Text>
                </View>
                <View
                    style={{
                        ...styles.fontBody,
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginTop: '-1.5vh',
                        paddingLeft: 4,
                        width: '100%',
                        borderBottom: 4,
                        borderBottomColor: styles.secondaryColor.color,
                    }}
                >
                    <Text style={{ textAlign: 'left' }}>DATOS</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ width: '33%', textAlign: 'left' }}>NOMBRE COMPLETO / RAZ&Oacute;N SOCIAL:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '65%',
                        }}
                    >{`${valueForm.nameClient} ${valueForm.surname} ${valueForm.secondname}`}</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ width: '12%', textAlign: 'left' }}>M&Oacute;VIL / FIJO:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '36%',
                        }}
                    >{`${valueForm.phone} - ${valueForm.fixPhone}`}</Text>
                    <Text style={{ width: '3%', textAlign: 'left', marginLeft: '4%' }}>EMAIL:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '39%',
                        }}
                    >{valueForm.email}</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ textAlign: 'left', marginRight: '1%' }}>DIRECCI&Oacute;N:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '86.8%',
                        }}
                    >{valueForm.address}</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ width: '15%', textAlign: 'left' }}>C&Oacute;DIGO POSTAL:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '33%',
                        }}
                    >{valueForm.zipCode}</Text>
                    <Text style={{ width: '5%', textAlign: 'left', marginLeft: '4%' }}>CIUDAD:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '37%',
                        }}
                    >{valueForm.place}</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ width: '7%', textAlign: 'left' }}>FECHA:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '41%',
                        }}
                    >{format(dateContrat, 'd/M/yyyy')}</Text>
                    <Text style={{ width: '7%', textAlign: 'left', marginLeft: '4%' }}>COMERCIAL:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '35%',
                        }}
                    >{valueForm.nameSeller}</Text>
                </View>
            </View>
            <View
                style={{
                    ...styles.fontMiniBody,
                    width: '96%',
                    marginHorizontal: '2%',
                }}
            >
                <View
                    style={{
                        ...styles.fontBody,
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginTop: '2vh',
                        paddingLeft: 4,
                        width: '100%',
                        borderBottom: 4,
                        borderBottomColor: styles.secondaryColor.color,
                    }}
                >
                    <Text style={{ textAlign: 'left' }}>DATOS DEL SUMINISTRO</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ width: '7%', textAlign: 'left' }}>CUPS:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '43%',
                        }}
                    >{valueForm.cups}</Text>
                    <Text style={{ width: '20%', textAlign: 'left', marginLeft: '2%' }}>CONSUMO TOTAL ANUAL:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '26%',
                        }}
                    >{valueForm.consum} Kw</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ width: '23%', textAlign: 'left' }}>POTENCIA CONTRATADA: P1:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '8%',
                        }}
                    > </Text>
                    <Text style={{ textAlign: 'left', marginLeft: '2%' }}>P2:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '8%',
                        }}
                    > </Text>
                    <Text style={{ textAlign: 'left', marginLeft: '2%' }}>P3:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '8%',
                        }}
                    > </Text>
                    <Text style={{ textAlign: 'left', marginLeft: '2%' }}>P4:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '8%',
                        }}
                    > </Text>
                    <Text style={{ textAlign: 'left', marginLeft: '2%' }}>P5:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '8%',
                        }}
                    > </Text>
                    <Text style={{ textAlign: 'left', marginLeft: '2%' }}>P6:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '8%',
                        }}
                    > </Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ width: '23%', textAlign: 'left' }}>PRECIO KwH CONSUMO: P1:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '8%',
                        }}
                    > </Text>
                    <Text style={{ textAlign: 'left', marginLeft: '2%' }}>P2:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '8%',
                        }}
                    > </Text>
                    <Text style={{ textAlign: 'left', marginLeft: '2%' }}>P3:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '8%',
                        }}
                    > </Text>
                    <Text style={{ textAlign: 'left', marginLeft: '2%' }}>P4:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '8%',
                        }}
                    > </Text>
                    <Text style={{ textAlign: 'left', marginLeft: '2%' }}>P5:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '8%',
                        }}
                    > </Text>
                    <Text style={{ textAlign: 'left', marginLeft: '2%' }}>P6:</Text>
                    <Text
                        style={{
                            ...styles.boxValueQuote,
                            width: '8%',
                        }}
                    > </Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <View
                        style={{
                            backgroundColor: styles.secondaryColor.color,
                            width: '100%',
                            height: '6vh',
                            paddingTop: 3,
                            paddingLeft: 3,
                        }}
                    >
                        <Text
                            style={{
                                width: '100%',
                                textAlign: 'left',
                                backgroundColor: styles.secondaryColor.color,
                            }}
                        >OBSERVACIONES:</Text>
                    </View>
                </View>
            </View>
            <View
                style={{
                    ...styles.fontMiniBody,
                    width: '96%',
                    marginHorizontal: '2%',
                }}
            >
                <View
                    style={{
                        ...styles.fontBody,
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginTop: '2vh',
                        paddingLeft: 4,
                        width: '100%',
                        borderBottom: 4,
                        borderBottomColor: styles.secondaryColor.color,
                    }}
                >
                    <Text style={{ textAlign: 'left' }}>INSTALACI&Oacute;N</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ width: '18%', textAlign: 'left' }}>Nº PLACAS:</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '10%',
                        }}
                    >{valueForm.countPanels}</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ width: '18%', textAlign: 'left' }}>POTENCIA INVERSOR:</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '15%',
                        }}
                    >{valueForm.inversor.watts}</Text>
                    <Text style={{ width: '10%', textAlign: 'left' }}>Kw</Text>
                    <Text style={{ width: '6%', textAlign: 'left' }}>TIPO</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '1%',
                        }}
                    >{valueForm.inversor.type === 'red' ? 'X' : ''}</Text>
                    <Text style={{ textAlign: 'left' }}>RED</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '1%',
                        }}
                    >{valueForm.inversor.type === 'red' ? '' : 'X'}</Text>
                    <Text style={{ textAlign: 'left' }}>H&Iacute;BRIDO</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ width: '18%', textAlign: 'left' }}>Nº BATER&Iacute;AS:</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '10%',
                        }}
                    >{valueForm.includeBattery ? valueForm.countBatteries : '-'}</Text>
                    <Text style={{ fontSize: '7pt', textAlign: 'left', marginTop: 3 }}>x</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '15%',
                        }}
                    >{valueForm.includeBattery ? valueForm.battery.power : '-'}</Text>
                    <Text style={{ fontSize: '7pt', textAlign: 'left', marginTop: 3 }}>Kw =</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspSecondary,
                            width: '15%',
                        }}
                    >{valueForm.includeBattery ? valueForm.battery.power * valueForm.countBatteries : '-'}</Text>
                    <Text style={{ fontSize: '7pt', textAlign: 'left', marginTop: 3 }}>Kw Almacenamiento</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ width: '18%', textAlign: 'left' }}>ESTRUCTURA:</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '1%',
                        }}
                    >{valueForm.structure === 'coplanar' ? 'X' : ''}</Text>
                    <Text style={{ textAlign: 'left' }}>COPLANAR</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '1%',
                        }}
                    >{valueForm.structure === 'coplanar' ? '' : 'X'}</Text>
                    <Text style={{ textAlign: 'left' }}>ELEVADA</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '5%',
                        }}
                    >{valueForm.structure !== 'coplanar' ? valueForm.inclination + '%' : '-'}</Text>
                    <Text style={{ fontSize: '7pt', textAlign: 'left', marginTop: 3 }}>% Inclinaci&oacute;n</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspSecondary,
                            width: '5%',
                        }}
                    >-</Text>
                    <Text style={{ fontSize: '7pt', textAlign: 'left', marginTop: 3 }}>metros superficie</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ width: '18%', textAlign: 'left' }}>OTROS:</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '1%',
                        }}
                    >{valueForm.mount === 'andamio' ? 'X' : ''}</Text>
                    <Text style={{ textAlign: 'left' }}>Andamio</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '1%',
                        }}
                    >{valueForm.mount === 'andamio' ? '' : 'X'}</Text>
                    <Text style={{ textAlign: 'left' }}>Gr&uacute;a</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '1%',
                        }}
                    >{valueForm.includePergola ? 'X' : ''}</Text>
                    <Text style={{ textAlign: 'left' }}>P&eacute;rgola</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '1%',
                        }}
                    >-</Text>
                    <Text style={{ textAlign: 'left' }}>Cargador coche el&eacute;ctrico</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '1%',
                        }}
                    >X</Text>
                    <Text style={{ textAlign: 'left' }}>Desplazamiento (+ 10Km)</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '1%',
                        }}
                    >{valueForm.processAllow ? 'X' : ''}</Text>
                    <Text style={{ textAlign: 'left' }}>Legalizaci&oacute;n</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '1%',
                        }}
                    >{valueForm.processSubvencion ? 'X' : ''}</Text>
                    <Text style={{ textAlign: 'left' }}>Subvenci&oacute;n</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ width: '18%', textAlign: 'left' }}>PRECIO ESTIMADO:</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspSecondary,
                            width: '15%',
                        }}
                    >{roundTwoDecimal(getCostWIVA(valueForm))}</Text>
                    <Text style={{ textAlign: 'left' }}>&euro; I.V.A. INCLU&Iacute;DO</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '1%',
                        }}
                    >{valueForm.includeMaintenance ? 'X' : ''}</Text>
                    <Text style={{ textAlign: 'left' }}>Deseo contratar mantenimiento anual</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '1%',
                        }}
                    >{valueForm.includeMaintenance && valueForm.typeMaintenance === 'one-year' ? 'X' : ''}</Text>
                    <Text style={{ textAlign: 'left' }}>Por 1 a&ntilde;o (+250 &euro;)</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '1%',
                        }}
                    >{valueForm.includeMaintenance && valueForm.typeMaintenance !== 'one-year' ? 'X' : ''}</Text>
                    <Text style={{ textAlign: 'left' }}>Por 2 a&ntilde;os (+400 &euro;)</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ width: '18%', textAlign: 'left' }}>AHORRO ANUAL:</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '8%',
                        }}
                    >{getAhorroMoney(valueForm)}</Text>
                    <Text style={{ fontSize: '7pt', textAlign: 'left', marginTop: 3 }}>x 20 a&ntilde;os =</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '8%',
                        }}
                    >{getAhorroMoney(valueForm) * 20}</Text>
                    <Text style={{ textAlign: 'left' }}>&euro;</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{width: '27%',  fontSize: '7pt', textAlign: 'left', marginTop: 3 }}>Subvenci&oacute;n IVACE/Next Generation =</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '8%',
                        }}
                    >{}</Text>
                    <Text style={{ fontSize: '7pt', textAlign: 'left', marginTop: 3 }}>Kw x 600 &euro; =</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '8%',
                        }}
                    >{}</Text>
                    <Text style={{ textAlign: 'left' }}>&euro;</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ fontSize: '7pt', textAlign: 'center', marginTop: 3, marginLeft: '25%' }}>Precio sin I.V.A</Text>
                    <Text style={{ fontSize: '7pt', textAlign: 'center', marginTop: 3, marginLeft: '18%' }}>IVACE</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{width: '27%',  fontSize: '7pt', textAlign: 'left', marginTop: 3 }}>Bonificaci&oacute;n IRPF hasta 40% =</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '8%',
                        }}
                    >{}</Text>
                    <Text style={{ fontSize: '7pt', textAlign: 'left', marginTop: 3 }}>-</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '8%',
                        }}
                    >{}</Text>
                    <Text style={{ fontSize: '7pt', textAlign: 'left', marginTop: 3 }}>=</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '8%',
                        }}
                    >{}</Text>
                    <Text style={{ textAlign: 'left' }}>&euro;</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ fontSize: '7pt', textAlign: 'center', marginTop: 3, marginLeft: '25%' }}>IBI</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ width: '27%', fontSize: '7pt', textAlign: 'left', marginTop: 3 }}>25% Bonificaci&oacute;n sobre IBI durante 5 a&ntilde;os =</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '8%',
                        }}
                    >{}</Text>
                    <Text style={{ fontSize: '7pt', textAlign: 'left', marginTop: 3 }}>x 25% =</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '8%',
                        }}
                    >{}</Text>
                    <Text style={{ fontSize: '7pt', textAlign: 'left', marginTop: 3 }}>x 5 a&ntilde;os =</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '8%',
                        }}
                    >{}</Text>
                    <Text style={{ textAlign: 'left' }}>&euro;</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ fontSize: '7pt', textAlign: 'center', marginTop: 3, marginLeft: '20.5%' }}>PRECIO CON I.V.A</Text>
                    <Text style={{ fontSize: '7pt', textAlign: 'center', marginTop: 3, marginLeft: '8%' }}>AYUDAS</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ width: '18%', textAlign: 'left' }}>COSTE ESTIMADO:</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '8%',
                        }}
                    >{roundTwoDecimal(getCostWIVA(valueForm))}</Text>
                    <Text style={{ fontSize: '7pt', textAlign: 'left', marginTop: 3 }}>-</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '8%',
                        }}
                    >{}</Text>
                    <Text style={{ fontSize: '7pt', textAlign: 'left', marginTop: 3 }}>=</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '8%',
                        }}
                    >{}</Text>
                    <Text style={{ textAlign: 'left' }}>&euro;</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1vh',
                        marginHorizontal: '1%',
                    }}
                >
                    <Text style={{ width: '22%', textAlign: 'left' }}>SU AHORRO EN 20 A&Ntilde;OS =</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspSecondary,
                            width: '15%',
                        }}
                    >{}</Text>
                    <Text style={{ textAlign: 'left' }}>&euro;</Text>
                </View>
            </View>
        </Page>
    );
}

export default PageSixPdfQuote;