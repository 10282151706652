import * as React from 'react';
import { FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, MenuItem, Select, Switch, Typography } from '@mui/material';
import { displacementTypes } from '../utils/products.catalog';

export default function DisplacementData({
    valueForm,
    onValueForm,
    errorForm,
    onErrorForm,
    showErrorForm
}) {
    const handleDisplacement = e => {
        onValueForm({
            ...valueForm,
            displacement: e.target.value
        });
    };

    return (
        <React.Fragment>
            <Typography variant='h5'>Desplazamiento</Typography>
            <FormControl required fullWidth>
                <InputLabel id="displacement-select-required-label">Renting de Furgoneta</InputLabel>
                <Select
                    labelId="displacement-select-required-label"
                    id="displacement-simple-select"
                    value={valueForm.displacement}
                    label="Renting de Furgoneta"
                    onChange={handleDisplacement}
                >
                    {displacementTypes.map(item => {
                        return (<MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>);
                    })}
                </Select>
            </FormControl>
        </React.Fragment>
    );
}