export const sellerCatalog = [
    {
        name: "NEWE SOLUTIONS",
        value: "newe-solutions",
        percent: 1.40, // 40%
    },
    {
        name: "Colaborador",
        value: "colaborador",
        percent: 1.55, // 55%
    },
];

export const discount = [
    {
        name: "Sin Descuento - 0%",
        value: "half-discount",
        percent: 1, // 0%
    },
    {
        name: "Instalar en menos de 7 días - 10%",
        value: "install-7days-10",
        percent: 0.90, // 10%
    },
    {
        name: "Instalar en menos de 15 días - 5%",
        value: "install-15days-5",
        percent: 0.95, // 5%
    },
    {
        name: "Cliente - 5%",
        value: "client-5",
        percent: 0.95, // 5%
    },
    {
        name: "Cliente - 10%",
        value: "client-10",
        percent: 0.9, // 10%
    },
    {
        name: "Referido - 15%",
        value: "referr-15",
        percent: 0.85, // 15%
    },
    {
        name: "Referido - 20%",
        value: "referr-20",
        percent: 0.8, // 20%
    },
    {
        name: "Familiar - 25%",
        value: "family-25",
        percent: 0.75, // 25%
    },
    {
        name: "Familiar - 30%",
        value: "family-30",
        percent: 0.7, // 30%
    },
];

export const typeInversor = [
    {
        name: "Red",
        value: "red",
    },
    {
        name: "Híbrido",
        value: "hibrido",
    },
];

export const inversorCatalog = [
    {
        name: "KOSTAL Monofásico 5kW",
        model: "KOSTAL",
        type: "red",
        watts: 5000,
        price: 600,
    },
    {
        name: "KOSTAL Monofásico 8kW",
        model: "KOSTAL",
        type: "red",
        watts: 8000,
        price: 1800,
    },
    {
        name: "JA SOLAR Trifásico 6kW",
        model: "JA SOLAR",
        type: "hibrido",
        watts: 6000,
        price: 1500,
    },
];

export const panelCatalog = [
    {
        name: "KASSEL Monocristalino 450kWH",
        model: "KASSEL",
        type: "Monocristalino",
        power: 450,
        price: 450 * 0.33,
    },
    {
        name: "KASSEL Monocristalino 455kWH",
        model: "KASSEL",
        type: "Monocristalino",
        power: 455,
        price: 455 * 0.33,
    },
    {
        name: "KASSEL Monocristalino 480kWH",
        model: "KASSEL",
        type: "Monocristalino",
        power: 480,
        price: 480 * 0.33,
    },
    {
        name: "JA SOLAR Monocristalino 450kWH",
        model: "JA SOLAR",
        type: "Monocristalino",
        power: 450,
        price: 450 * 0.33,
    },
    {
        name: "JA SOLAR Monocristalino 480kWH",
        model: "JA SOLAR",
        type: "Monocristalino",
        power: 480,
        price: 480 * 0.33,
    },
];

export const batteryCatalog = [
    {
        name: "PYLONTECH US3000 Litio 3.5KwH",
        model: "PYLONTECH",
        type: "Litio",
        power: 3500,
        price: 3500 * 0.29,
    },
    {
        name: "PYLONTECH US2000 Litio 3KwH",
        model: "PYLONTECH",
        type: "Litio",
        power: 3000,
        price: 3000 * 0.29,
    },
    {
        name: "HUAWEI Litio 4KwH",
        model: "HUAWEI",
        type: "Litio",
        power: 4000,
        price: 4000 * 0.33,
    },
    {
        name: "HUAWEI Litio PLUS 5KwH",
        model: "HUAWEI",
        type: "Litio",
        power: 5000,
        price: 5000 * 0.335,
    },
];

export const meterCatalog = {
    price: 500,
};

export const cableadoCatalog = {
    price: 4,
};

export const squareCatalog = [
    {
        name: "Tipo 1",
        value: "type-1",
        price: 150,
    },
    {
        name: "Tipo 2",
        value: "type-2",
        price: 220,
    },
];

export const batometroCatalog = [
    {
        name: "N 1",
        value: "n-1",
        price: 90,
    },
    {
        name: "N 2",
        value: "n-2",
        price: 120,
    },
];

export const structure = [
    {
        name: "Coplanar",
        value: "coplanar",
        price: 28, // per panel
    },
    {
        name: "Elevada",
        value: "elevada",
        price: 38, // per panel
    },
];

export const inclination = [
    {
        name: "15%",
        value: 15,
        percent: 1.15, // +15%
    },
    {
        name: "30%",
        value: 30,
        percent: 1.2, // +20%
    },
];

export const montaje = [
    {
        name: "Andamio",
        value: "andamio",
        price: 22, // per day
    },
    {
        name: "Grúa",
        value: "grua",
        price: 150, // per day
    },
];

export const pergolaCatalog = {
    price: 35,
    unit: "metros",
};

export const workforceDay = {
    priceWorker: 140,
    countWorker: 3,
    countPanels: 4,
    amortizarTools: 25, // +25
    adminShopLogist: 50 // per day
};

export const displacement = {
    rentingFurgo: 55, // per day
    
};

export const displacementTypes = [
    {
        name: "meno de 10 Km",
        value: "to-10",
        price: 55 // per day
    },
    {
        name: "de 10 Km a 30 Km",
        value: "to-30",
        price: 110 // per day
    },
    {
        name: "mas de 30 Km",
        value: "to-more",
        price: 165 // per day
    },
];

export const maintenance = {
    price: 350, // per year
    priceOneYear: 250,
    priceTwoYear: 400,
};

export const maintenanceTypes = [
    {
        name: "1 Año",
        value: "one-year",
        price: 250
    },
    {
        name: "2 Año",
        value: "two-year",
        price: 400
    },
];

export const costAllows = {
    percent: 0.07
    // price: 70,
    // limitScaleInstall: 10000,
};

export const costSubvencion = {
    limitInWatts: 1000,
    priceDownEqual: 250,
    priceUp: 400,
};

// export const solarPanelsCatalog = [
//     {
//         name: "Placa Tipo A",
//         model: "Audi",
//         watts: 300,
//         price: 300,
//         unit: "Unidad",
//     },
//     {
//         name: "Placa Tipo B",
//         model: "Ford",
//         watts: 355,
//         price: 355,
//         unit: "Unidad",
//     },
//     {
//         name: "Placa Tipo C",
//         model: "Honda",
//         watts: 450,
//         price: 450,
//         unit: "Unidad",
//     },
//     {
//         name: "Placa Tipo D",
//         model: "Peugeot",
//         watts: 480,
//         price: 480,
//         unit: "Unidad",
//     },
//     {
//         name: "Placa Tipo E",
//         model: "Lada",
//         watts: 510,
//         price: 510,
//         unit: "Unidad",
//     },
// ];

// export const InversorsCatalog = [
//     {
//         name: "Inversor Tipo A",
//         model: "Audi",
//         watts: 4500,
//         price: 450,
//         unit: "Unidad",
//     },
//     {
//         name: "Inversor Tipo B",
//         model: "Ford",
//         watts: 5000,
//         price: 500,
//         unit: "Unidad",
//     },
//     {
//         name: "Inversor Tipo C",
//         model: "Honda",
//         watts: 5500,
//         price: 520,
//         unit: "Unidad",
//     },
//     {
//         name: "Inversor Tipo D",
//         model: "Peugeot",
//         watts: 6000,
//         price: 600,
//         unit: "Unidad",
//     },
//     {
//         name: "Inversor Tipo E",
//         model: "Lada",
//         watts: 6500,
//         price: 650,
//         unit: "Unidad",
//     },
// ];

// export const carChargerCatalog = {
//     name: "Cargador de automóvil eléctrico",
//     price: 10,
//     unit: "Unidad",
// };

// export const conexionsCatalog = {
//     name: "Cableado eléctrico",
//     price: 5,
//     unit: "Metros",
// };

// export const assemblyCatalog = {
//     name: "Montaje",
//     price: 10,
//     unit: "Servicio",
// };

// export const scaffolCatalog = {
//     name: "Montaje",
//     price: 10,
//     unit: "Servicio",
// };

// export const allowPermitCatalog = {
//     name: "Solicitud de Permiso",
//     price: 50,
//     unit: "Servicio",
// };

// export const allowConstructCatalog = {
//     name: "Solicitud de Obra",
//     price: 100,
//     unit: "Servicio",
// };

// export const movingCatalog = {
//     name: "Desplazamiento",
//     price: 200,
//     unit: "Servicio",
// };

// export const handsWorkerCatalog = {
//     name: "Mano de Obra",
//     price: 700,
//     unit: "Servicio",
// };

// export const supportCatalog = {
//     name: "Revisión",
//     price: 800,
//     unit: "Servicio",
// };

// export const installStructureCatalog = [
//     {
//         name: "Plan A",
//         price: 20,
//     },
//     {
//         name: "Plan B",
//         price: 10,
//     },
//     {
//         name: "Plan C",
//         price: 15,
//     },
// ];

// export const inRedCatalog = {
//     name: "Inyección a Red",
//     price: 100,
//     unit: "Servicio",
// };

// export const batteriesCatalog = {
//     name: "Batería",
//     price: 250,
//     watts: 1000,
//     unit: "Unidad",
// };

// export const optimiCatalog = {
//     name: "Optimizador",
//     price: 500,
//     unit: "Unidad",
// };

// export const allowTramitCatalog = {
//     name: "Optimizador",
//     price: 500,
//     unit: "Unidad",
// };