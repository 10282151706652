import * as React from 'react';
import { Autocomplete, Box, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { discount } from '../utils/products.catalog';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import { characterControlCups } from '../utils/crm.cups';
import { distributionCode } from '../utils/distributionCode';
import { zipCodeES } from '../utils/zipCodeES';

export default function ClientData({
    valueForm,
    onValueForm,
    errorForm,
    onErrorForm,
    showErrorForm
}) {
    const handleNameClient = e => {
        if (e.target.value === '') {
            onErrorForm({
                ...errorForm,
                nameClient: 'Campo obligatorio',
            });
        } else {
            onErrorForm({
                ...errorForm,
                nameClient: null,
            });
        }

        onValueForm({
            ...valueForm,
            nameClient: e.target.value.toUpperCase(),
        });
    }

    const handleSurname = e => {
        if (e.target.value === '') {
            onErrorForm({
                ...errorForm,
                surname: 'Campo obligatorio',
            });
        } else {
            onErrorForm({
                ...errorForm,
                surname: null,
            });
        }

        onValueForm({
            ...valueForm,
            surname: e.target.value.toUpperCase(),
        });
    }

    const handleSecondname = e => {
        if (e.target.value === '') {
            onErrorForm({
                ...errorForm,
                secondname: 'Campo obligatorio',
            });
        } else {
            onErrorForm({
                ...errorForm,
                secondname: null,
            });
        }

        onValueForm({
            ...valueForm,
            secondname: e.target.value.toUpperCase(),
        });
    }
    
    const handleIndentity = e => {
        if (e.target.value === '') {
            onErrorForm({
                ...errorForm,
                indentity: 'Campo obligatorio',
            });
        } else {
            onErrorForm({
                ...errorForm,
                indentity: null,
            });
        }

        onValueForm({
            ...valueForm,
            indentity: e.target.value.toUpperCase(),
        });
    }

    const handleFixPhone = e => {
        if (e.target.value.length <= 9) {
            const reValid = new RegExp('^[89]{1}[0-9]{8}$');
            if (e.target.value === '') {
                onErrorForm({
                    ...errorForm,
                    fixPhone: 'Campo obligatorio'
                });
            } else if (!reValid.test(e.target.value)) {
                onErrorForm({
                    ...errorForm,
                    fixPhone: 'Teléfono fijo incorrecto (9 dígitos comenzando por 8-9)'
                });
            } else {
                onErrorForm({
                    ...errorForm,
                    fixPhone: null
                });
            }

            onValueForm({
                ...valueForm,
                fixPhone: e.target.value
            });
        }
    }

    const handlePhone = e => {
        if (e.target.value.length <= 9) {
            const reValid = new RegExp('^[67]{1}[0-9]{8}$');
            if (e.target.value === '') {
                onErrorForm({
                    ...errorForm,
                    phone: 'Campo obligatorio'
                });
            } else if (!reValid.test(e.target.value)) {
                onErrorForm({
                    ...errorForm,
                    phone: 'Teléfono incorrecto (9 dígitos comenzando por 6-7)'
                });
            } else {
                onErrorForm({
                    ...errorForm,
                    phone: null
                });
            }

            onValueForm({
                ...valueForm,
                phone: e.target.value
            });
        }
    }

    const handleEmail = e => {
        const reValid = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$");
        if (!reValid.test(e.target.value)) {
            onErrorForm({
                ...errorForm,
                email: 'Correo electrónico incorrecto'
            });
        } else {
            onErrorForm({
                ...errorForm,
                email: null
            });
        }

        onValueForm({
            ...valueForm,
            email: e.target.value
        });
    }

    const handleZipCode = (e) => {
        if (e.target.value.length <= 5) {
            if (e.target.value === '') {
                onErrorForm({
                    ...errorForm,
                    zipCode: 'Campo obligatorio'
                });
            } else if (e.target.value.length < 5) {
                onErrorForm({
                    ...errorForm,
                    zipCode: 'Código postal inválido (5 dígitos)'
                });
            } else {
                onErrorForm({
                    ...errorForm,
                    zipCode: null
                });
            }

            if (e.target.value.length === 5) {
                const zipCode = zipCodeES.find(element => {
                    return e.target.value === (element.cp > 10000 ? '' + element.cp : '0' + element.cp);
                });
                if (!zipCode) {
                    onErrorForm({
                        ...errorForm,
                        zipCode: 'Código postal inválido'
                    });
                    onValueForm({
                        ...valueForm,
                        zipCode: e.target.value,
                        place: '',
                    });
                } else {
                    onValueForm({
                        ...valueForm,
                        zipCode: e.target.value,
                        place: `${zipCode.prov} - ${zipCode.pob}`,
                    });
                }
            } else {
                onValueForm({
                    ...valueForm,
                    zipCode: e.target.value,
                    place: '',
                });
            }
        }
    };

    const handleAddress = e => {
        if (e.target.value === '') {
            onErrorForm({
                ...errorForm,
                address: 'Campo obligatorio',
            });
        } else {
            onErrorForm({
                ...errorForm,
                address: null,
            });
        }

        onValueForm({
            ...valueForm,
            address: e.target.value.toUpperCase(),
        });
    }

    const validar = (value) => {
        if (!value) {
            return null;
        }
        if (value.length >= 20 && value.length <= 22) {
            return validarControlCups(value);
        } else {
            return null;
        }
    };

    const validarControlCups = (cups) => {
        if (
            (cups.slice(0, 2) !== 'ES')
            || !distributionCode.some(element => element.code === cups.slice(2, 6))
            || characterControlCups.find(elem => (Math.trunc((parseInt(cups.slice(3, 18)) % 529) / 23)) === elem.value).letter !== cups[18]
            || characterControlCups.find(elem => (Math.trunc((parseInt(cups.slice(3, 18)) % 529) % 23)) === elem.value).letter !== cups[19]
        ) {
            return false;
        }
        return true;
    };

    const showControlCups = (control) => {
        return control ? (
            <InputAdornment position="start">
                <DoneSharpIcon color='success' />
            </InputAdornment>
        ) : (
            <InputAdornment position="start">
                <ErrorOutlineSharpIcon color='error' />
            </InputAdornment>
        );
    };

    const handleCups = (e) => {
        if (e.target.value.length <= 22) {
            if (e.target.value.length < 20) {
                onErrorForm({
                    ...errorForm,
                    cups: 'Código CUPS incompleto',
                });
            } else {
                onErrorForm({
                    ...errorForm,
                    cups: validar(e.target.value.toUpperCase()) ? null : 'Código CUPS inválido',
                });
            }
            onValueForm({
                ...valueForm,
                cups: e.target.value.toUpperCase(),
                validCups: validar(e.target.value.toUpperCase()),
            });
        }
    };

    const handlePower = e => {
        if (e.target.value === '') {
            onErrorForm({
                ...errorForm,
                power: 'Campo obligatorio',
            });
        } else {
            onErrorForm({
                ...errorForm,
                power: null,
            });
        }

        onValueForm({
            ...valueForm,
            power: e.target.value,
        });
    }

    const consumNight = (consum, consumDayTime) => {
        if (consum === '' && consumDayTime === '') {
            return 0;
        } else if (consumDayTime === '') {
            return Math.round((parseFloat(consum) + Number.EPSILON) * 1000) / 1000;
        } else if (consum === '') {
            return Math.round(((0 - parseFloat(consumDayTime)) + Number.EPSILON) * 1000) / 1000;
        } else {
            return Math.round(((parseFloat(consum) - parseFloat(consumDayTime)) + Number.EPSILON) * 1000) / 1000;
        }
    };

    const handleConsum = e => {
        if (e.target.value === '') {
            onErrorForm({
                ...errorForm,
                consum: 'Campo obligatorio',
            });
        } else {
            onErrorForm({
                ...errorForm,
                consum: null,
            });
        }

        onValueForm({
            ...valueForm,
            consum: e.target.value,
            consumNightTime: consumNight(e.target.value, valueForm.consumDayTime)
        });
    }
    
    const handleCurrentPriceKw = e => {
        if (e.target.value === '') {
            onErrorForm({
                ...errorForm,
                currentPriceKw: 'Campo obligatorio',
            });
        } else {
            onErrorForm({
                ...errorForm,
                currentPriceKw: null,
            });
        }

        onValueForm({
            ...valueForm,
            currentPriceKw: e.target.value
        });
    }

    const handleConsumDayTime = e => {
        if (e.target.value === '') {
            onErrorForm({
                ...errorForm,
                consumDayTime: 'Campo obligatorio',
            });
        } else {
            onErrorForm({
                ...errorForm,
                consumDayTime: null,
            });
        }

        onValueForm({
            ...valueForm,
            consumDayTime: e.target.value,
            consumNightTime: consumNight(valueForm.consum, e.target.value)
        });
    }

    const handlePriceDayTime = e => {
        if (e.target.value === '') {
            onErrorForm({
                ...errorForm,
                priceDayTime: 'Campo obligatorio',
            });
        } else {
            onErrorForm({
                ...errorForm,
                priceDayTime: null,
            });
        }

        onValueForm({
            ...valueForm,
            priceDayTime: e.target.value,
        });
    }

    const handlePriceNightTime = e => {
        if (e.target.value === '') {
            onErrorForm({
                ...errorForm,
                priceNightTime: 'Campo obligatorio',
            });
        } else {
            onErrorForm({
                ...errorForm,
                priceNightTime: null,
            });
        }

        onValueForm({
            ...valueForm,
            priceNightTime: e.target.value,
        });
    }

    const handleDiscount = (e, value) => {
        onValueForm({
            ...valueForm,
            discount: value,
        });
    }

    return (
        <React.Fragment>
            <Typography variant='h5'>Datos del Cliente</Typography>
            <TextField
                label='Nombre'
                required
                type='text'
                fullWidth
                inputProps={{
                    autoComplete: 'off',
                }}
                value={valueForm.nameClient}
                onChange={handleNameClient}
                error={showErrorForm && errorForm.nameClient !== null}
                helperText={showErrorForm && errorForm.nameClient}
            />
            <TextField
                label='Primer Apellido'
                required
                type='text'
                fullWidth
                inputProps={{
                    autoComplete: 'off',
                }}
                value={valueForm.surname}
                onChange={handleSurname}
                error={showErrorForm && errorForm.surname !== null}
                helperText={showErrorForm && errorForm.surname}
            />
            <TextField
                label='Segundo Apellido'
                required
                type='text'
                fullWidth
                inputProps={{
                    autoComplete: 'off',
                }}
                value={valueForm.secondname}
                onChange={handleSecondname}
                error={showErrorForm && errorForm.secondname !== null}
                helperText={showErrorForm && errorForm.secondname}
            />
            <TextField
                label='DNI/NIF'
                required
                type='text'
                fullWidth
                inputProps={{
                    autoComplete: 'off',
                }}
                value={valueForm.indentity}
                onChange={handleIndentity}
                error={showErrorForm && errorForm.indentity !== null}
                helperText={showErrorForm && errorForm.indentity}
            />
            <TextField
                fullWidth
                required
                label={`Teléfono Fijo (${9 - valueForm.fixPhone.length}/9)`}
                type='tel'
                value={valueForm.fixPhone}
                onChange={handleFixPhone}
                inputProps={{
                    inputMode: 'tel',
                    pattern: '^[89]{1}[0-9]{8}$',
                    autoComplete: 'off',
                }}
                error={showErrorForm && errorForm.fixPhone !== null}
                helperText={showErrorForm && errorForm.fixPhone}
            />
            <TextField
                fullWidth
                required
                label={`Teléfono Móvil (${9 - valueForm.phone.length}/9)`}
                type='tel'
                value={valueForm.phone}
                onChange={handlePhone}
                inputProps={{
                    inputMode: 'tel',
                    pattern: '^[67]{1}[0-9]{8}$',
                    autoComplete: 'off',
                }}
                error={showErrorForm && errorForm.phone !== null}
                helperText={showErrorForm && errorForm.phone}
            />
            <TextField
                fullWidth
                label='Correo Electr&oacute;nico'
                type='email'
                value={valueForm.email}
                onChange={handleEmail}
                inputProps={{
                    pattern: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$",
                    autoComplete: 'off',
                }}
                error={showErrorForm && errorForm.email !== null}
                helperText={showErrorForm && errorForm.email}
            />
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        required
                        label={`Código Postal (${5 - valueForm.zipCode.length}/5)`}
                        type='tel'
                        value={valueForm.zipCode}
                        onChange={handleZipCode}
                        helperText={showErrorForm && errorForm.zipCode}
                        error={showErrorForm && errorForm.zipCode !== null}
                    />
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        disabled
                        label='Provincia - Poblaci&oacute;n'
                        type='text'
                        value={valueForm.place}
                    />
                </Grid>
            </Grid>
            <TextField
                label='Dirección'
                required
                type='text'
                fullWidth
                inputProps={{
                    autoComplete: 'off',
                }}
                value={valueForm.address}
                onChange={handleAddress}
                error={showErrorForm && errorForm.address !== null}
                helperText={showErrorForm && errorForm.address}
            />
            <TextField
                fullWidth
                required
                label={`CUPS (${22 - valueForm.cups.length}/22)`}
                type='text'
                value={valueForm.cups}
                onChange={handleCups}
                InputProps={{
                    startAdornment: valueForm.validCups !== null ? showControlCups(valueForm.validCups) : null,
                }}
                helperText={showErrorForm && errorForm.cups}
                error={showErrorForm && errorForm.cups !== null}
            />
            <TextField
                fullWidth
                required
                label='Potencia Actual'
                type='number'
                inputProps={{
                    autoComplete: 'off',
                    min: 0,
                }}
                value={valueForm.power}
                onChange={handlePower}
                error={showErrorForm && errorForm.power !== null}
                helperText={showErrorForm && errorForm.power}
            />
            <TextField
                fullWidth
                required
                label='Consumo Anual (12 meses anteriores)'
                type='number'
                inputProps={{
                    autoComplete: 'off',
                    min: 0,
                }}
                value={valueForm.consum}
                onChange={handleConsum}
                error={showErrorForm && errorForm.consum !== null}
                helperText={showErrorForm && errorForm.consum}
            />
            <TextField
                fullWidth
                required
                label='Precio Actual'
                type='number'
                inputProps={{
                    autoComplete: 'off',
                    min: 0,
                }}
                value={valueForm.currentPriceKw}
                onChange={handleCurrentPriceKw}
                error={showErrorForm && errorForm.currentPriceKw !== null}
                helperText={showErrorForm && errorForm.currentPriceKw}
            />
            <TextField
                fullWidth
                required
                label='Consumo Anual (Diurno)'
                type='number'
                inputProps={{
                    autoComplete: 'off',
                    min: 0,
                }}
                value={valueForm.consumDayTime}
                onChange={handleConsumDayTime}
                error={showErrorForm && errorForm.consumDayTime !== null}
                helperText={showErrorForm && errorForm.consumDayTime}
            />
            <TextField
                fullWidth
                required
                label='Consumo Anual (Nocturno)'
                disabled
                type='number'
                value={valueForm.consumNightTime}
            />
            <TextField
                fullWidth
                required
                label='Precio kWH (Diurno)'
                type='number'
                inputProps={{
                    autoComplete: 'off',
                    min: 0,
                }}
                value={valueForm.priceDayTime}
                onChange={handlePriceDayTime}
                error={showErrorForm && errorForm.priceDayTime !== null}
                helperText={showErrorForm && errorForm.priceDayTime}
            />
            <TextField
                fullWidth
                required
                label='Precio kWH (Nocturno)'
                type='number'
                inputProps={{
                    autoComplete: 'off',
                    min: 0,
                }}
                value={valueForm.priceNightTime}
                onChange={handlePriceNightTime}
                error={showErrorForm && errorForm.priceNightTime !== null}
                helperText={showErrorForm && errorForm.priceNightTime}
            />
            <Autocomplete
                id="select-discount"
                onChange={handleDiscount}
                value={valueForm.discount}
                options={discount}
                autoHighlight
                clearText='Limpiar selecci&oacute;n'
                closeText='Cerrar'
                getOptionLabel={(option) => `${option.name}`}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.name}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Descuento a aplicar"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off',
                        }}
                    />
                )}
            />
        </React.Fragment>
    );
}