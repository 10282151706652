import React from "react";
import { Document, Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import { getAhorro, getCostWIVA, getExcedenteProduccion, getProduccionTotalInstallAuto, getProducctionPico } from '../utils/process.quote';
import logoNewe from '../logo.svg';

import { format } from "date-fns";

const PageEightEPdfQuote = ({ styles, valueForm }) => {
    return (
        <Page
            size="A4"
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                backgroundColor: "white"
            }}
        >
            {/* <Image
                src={logoNewe}
                style={{
                    width: 'auto',
                    height: 'auto',
                }}
            /> */}
            <View
                style={{
                    display: "flex",
                    alignItems: 'center',
                    backgroundColor: '#bfe35a',
                    paddingTop: '0.5vh',
                    paddingBottom: '0.5vh',
                    marginTop: '5vh',
                }}
            >
                <Text style={{ ...styles.fontTitle, color: 'white' }}>CLIENTE</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderBottom: 2,
                    borderBottomColor: '#005da4',
                    marginTop: '1vh'
                }}
            >
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '3%',
                    width: '27%',
                }}>NOMBRE/RAZON SOCIAL:</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '67%',
                }}>{`${valueForm.nameClient} ${valueForm.surname} ${valueForm.secondname}`}</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderBottom: 2,
                    borderBottomColor: '#005da4',
                }}
            >
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '3%',
                    width: '27%',
                }}>DNI-NIF:</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '67%',
                }}>{valueForm.indentity}</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderBottom: 2,
                    borderBottomColor: '#005da4',
                }}
            >
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '3%',
                    width: '27%',
                }}>DIRECCION:</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '67%',
                }}>{`${valueForm.zipCode} - ${valueForm.place} - ${valueForm.address}`}</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderBottom: 2,
                    borderBottomColor: '#005da4',
                }}
            >
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '3%',
                    width: '27%',
                }}>CUPS:</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '67%',
                }}>{valueForm.cups}</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderBottom: 2,
                    borderBottomColor: '#005da4',
                }}
            >
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '3%',
                    width: '27%',
                }}>TELEFONO:</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '67%',
                }}>{`${valueForm.fixPhone} - ${valueForm.phone}`}</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderBottom: 2,
                    borderBottomColor: '#005da4',
                }}
            >
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '3%',
                    width: '27%',
                }}>EMAIL:</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '67%',
                }}>{valueForm.email}</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderBottom: 2,
                    borderBottomColor: '#005da4',
                }}
            >
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '3%',
                    width: '27%',
                }}>VENDEDOR:</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '67%',
                }}>{valueForm.nameSeller}</Text>
            </View>
            <View
                style={{
                    display: "flex",
                    alignItems: 'center',
                    backgroundColor: '#bfe35a',
                    paddingTop: '0.5vh',
                    paddingBottom: '0.5vh',
                    marginTop: '2vh',
                }}
            >
                <Text style={{ ...styles.fontTitle, color: 'white' }}>ALCANCE DEL SERVICIO</Text>
            </View>
            <Text style={{
                ...styles.fontMiniBody,
                ...styles.primaryColor,
                marginHorizontal: '3%',
                marginTop: '0.5vh',
            }}>El alcance del servicio incluye:- Diseño de la instalación.- Suministro de los equipos y montaje de la instalación.- Tramitación administrativa de los permisos necesarios para la legalización de la instalación ante las administraciones que correspondan, siendo a cargo del CLIENTE el coste de permisos municipales, tasas, impuestos y avales que le sean de aplicación.</Text>
            <View
                style={{
                    display: "flex",
                    alignItems: 'center',
                    backgroundColor: '#bfe35a',
                    paddingTop: '0.5vh',
                    paddingBottom: '0.5vh',
                    marginTop: '2vh',
                }}
            >
                <Text style={{ ...styles.fontTitle, color: 'white' }}>COMPONENTES DE LA INSTALACION FOTOVOLTAICA</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderBottom: 2,
                    borderBottomColor: '#005da4',
                    marginTop: '1vh'
                }}
            >
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '3%',
                    width: '27%',
                }}>UNIDADES</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    width: '50%',
                }}>Equipos y modelos</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '17%',
                }}>GARANTIA</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderBottom: 2,
                    borderBottomColor: '#005da4',
                }}
            >
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '3%',
                    width: '27%',
                }}>{valueForm.countInversors}</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    width: '50%',
                }}>{`${valueForm.inversor.name} - ${valueForm.inversor.type.toUpperCase()}`}</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '17%',
                }}>Fabricante</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderBottom: 2,
                    borderBottomColor: '#005da4',
                }}
            >
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '3%',
                    width: '27%',
                }}>{valueForm.countPanels}</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    width: '50%',
                }}>{`${valueForm.panel.name}`}</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '17%',
                }}>Fabricante</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderBottom: 2,
                    borderBottomColor: '#005da4',
                }}
            >
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '3%',
                    width: '27%',
                }}> </Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    width: '50%',
                }}> </Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '17%',
                }}> </Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderBottom: 2,
                    borderBottomColor: '#005da4',
                }}
            >
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '3%',
                    width: '27%',
                }}> </Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    width: '50%',
                }}> </Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '17%',
                }}> </Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderBottom: 2,
                    borderBottomColor: '#005da4',
                }}
            >
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '3%',
                    width: '27%',
                }}> </Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    width: '50%',
                }}>El precio total de sus equipos es de {Math.round((getCostWIVA(valueForm) + Number.EPSILON)*100) / 100 } &euro;</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '17%',
                }}>I.V.A. INCLU&Iacute;DO</Text>
            </View>
            <View
                style={{
                    display: "flex",
                    alignItems: 'center',
                    backgroundColor: '#bfe35a',
                    paddingTop: '0.5vh',
                    paddingBottom: '0.5vh',
                    marginTop: '2vh',
                }}
            >
                <Text style={{ ...styles.fontTitle, color: 'white' }}>M&Eacute;TODOS DE PAGO</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: '2vh',
                }}
            >
                <View style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '25%',
                    width: '5%',
                    border: 1.5,
                    borderColor: '#005da4',
                }}></View>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    width: '50%',
                    borderBottom: 1,
                    borderBottomColor: '#005da4',
                }}>PAGO FRACCIONADO</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '17%',
                    borderBottom: 1,
                    borderBottomColor: '#005da4',
                }}> </Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <View style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '25%',
                    width: '5%',
                }}></View>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    width: '50%',
                    borderBottom: 1,
                    borderBottomColor: '#005da4',
                }}>1º Transferencia bancaria para iniciar la instalación</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '17%',
                    borderBottom: 1,
                    borderBottomColor: '#005da4',
                }}>{Math.round((getCostWIVA(valueForm) + Number.EPSILON)*100) / 100 * 0.25 } &euro;</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <View style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '25%',
                    width: '5%',
                }}></View>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    width: '50%',
                    borderBottom: 1,
                    borderBottomColor: '#005da4',
                }}>2º Transferencia bancaria cuando se inicie la instalación</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '17%',
                    borderBottom: 1,
                    borderBottomColor: '#005da4',
                }}>{Math.round((getCostWIVA(valueForm) + Number.EPSILON)*100) / 100 * 0.5 } &euro;</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <View style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '25%',
                    width: '5%',
                }}></View>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    width: '50%',
                    borderBottom: 1,
                    borderBottomColor: '#005da4',
                }}>3º Transferencia al finalizar la instalación</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '17%',
                    borderBottom: 1,
                    borderBottomColor: '#005da4',
                }}>{Math.round((getCostWIVA(valueForm) + Number.EPSILON)*100) / 100 * 0.25 } &euro;</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <View style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '25%',
                    width: '5%',
                    border: 1.5,
                    borderColor: '#005da4',
                }}></View>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    width: '50%',
                    borderBottom: 1,
                    borderBottomColor: '#005da4',
                }}>PAGO ÚNICO</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '17%',
                    borderBottom: 1,
                    borderBottomColor: '#005da4',
                }}>{Math.round((getCostWIVA(valueForm) + Number.EPSILON)*100) / 100 } &euro;</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: '1vh',
                }}
            >
                <View style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginLeft: '25%',
                    width: '5%',
                    border: 1.5,
                    borderColor: '#005da4',
                }}></View>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    width: '50%',
                    borderBottom: 1,
                    borderBottomColor: '#005da4',
                }}>PAGO ÚNICO FINANCIADO</Text>
                <Text style={{
                    ...styles.fontMiniBody,
                    ...styles.primaryColor,
                    marginRight: '3%',
                    width: '17%',
                    borderBottom: 1,
                    borderBottomColor: '#005da4',
                }}>{Math.round((getCostWIVA(valueForm) + Number.EPSILON)*100) / 100 } &euro;</Text>
            </View>
            <Text style={{
                ...styles.fontMiniBody,
                ...styles.primaryColor,
                marginHorizontal: '3%',
                marginTop: '0.5vh',
            }}>PAGO MEDIANTE TRANSFERENCIA BANCARIA EN LA CUENTA BBVA - ES94 0182 1900 3102 0179 3310</Text>
            <Text style={{
                ...styles.fontMiniBody,
                ...styles.primaryColor,
                marginHorizontal: '3%',
                marginTop: '0.5vh',
            }}>Las presentes condiciones económicas están condicionadas al análisis de solvencia del CLIENTE. La empresa se reserva el derecho a modificar las condiciones económicas, que comunicará al CLIENTE para su aceptación o rechazo en un plazo de 15 días naturales sin penalización alguna. Los precios incluyen: materiales, mano de obra, transporte, montaje, y cualquier otro gasto directo o indirecto necesario para la perfecta entrega de la instalación. Se excluyen condicionantes imprevistos derivados de la aprobación de la empresa distribuidora eléctrica o de propietarios particulares que condicionen los trabajos necesarios para llevar a cabo la instalación, que serán asumidos por el CLIENTE. Las subvenciones o desgravaciones del importe quedan sujetas a la legislación vigente no siendo responsable la empresa de su pago en el caso de que por cualquier motivo no se concedieran. Así mismo es la empresa la que se encargará de esos trámites para la aplicación de las mismas.</Text>
            <Text style={{
                ...styles.fontMiniBody,
                ...styles.primaryColor,
                marginHorizontal: '3%',
                marginTop: '0.5vh',
            }}>Las características del modelo seleccionado podrán variar en función de la solución técnica aplicable a cada instalación, sin perjuicio de la potencia total especificada. Los equipos dependen del stock y podrán ser sustituidos por otros con similares características. El plazo de Garantía se refiere a los Años de garantía que tienen los equipos dados por el fabricante.</Text>
            <View
                style={{
                    display: "flex",
                    alignItems: 'center',
                    backgroundColor: '#bfe35a',
                    paddingTop: '0.5vh',
                    paddingBottom: '0.5vh',
                    marginTop: '2vh',
                }}
            >
                <Text style={{ ...styles.fontTitle, color: 'white' }}>OTRAS CONDICIONES</Text>
            </View>
            <Text style={{
                ...styles.fontMiniBody,
                ...styles.primaryColor,
                marginHorizontal: '3%',
                marginTop: '0.5vh',
            }}>En caso de pago al contado, el cliente realizara los ingresos correspondientes en el número de cuenta indicado, además si el cliente solicitara el desistimiento la empresa dispondrá de un plazo de 90 días para realizar la devolución de los importes realizados. En caso de pago financiado, el CLIENTE abonará directamente a la entidad financiera las cuotas del préstamo correspondiente. Si durante la ejecución de la instalación se detectaran condicionantes técnicos que requieran modificar o proponer una instalación distinta a la ofertada, la empresa se reserva el derecho a modificar las condiciones técnicas y económicas del presente documento, quedando éste sin efecto. En ese caso la empresa propondrá al CLIENTE un nuevo contrato para su firma. La empresa no será responsable de las incidencias que se puedan producir por la alteración de las condiciones técnicas del inmueble del CLIENTE, si éstas se produjeran con posterioridad a la visita de la emoresa por la actuación de terceros o del CLIENTE.</Text>
            {/* <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginHorizontal: '10%'
                }}
            >
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%'
                }}>{getProducctionPico(valueForm)} Kw</Text>
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%',
                    marginLeft: '1%',
                }}>{valueForm.countPanels}</Text>
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%',
                    marginLeft: '1%',
                }}>{valueForm.countInversors}</Text>
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%'
                }}>{valueForm.includeBattery ? valueForm.countBatteries : '-'}</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginHorizontal: '10%'
                }}
            >
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%'
                }}>{getAhorro(valueForm)} &euro;</Text>
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%',
                    marginLeft: '1.5%',
                }}>{getAhorro(valueForm) * 25} &euro;</Text>
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%',
                    marginLeft: '3%',
                }}>{getProduccionTotalInstallAuto(valueForm)} Kw</Text>
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%'
                }}>{getExcedenteProduccion(valueForm)} Kw</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginHorizontal: '10%'
                }}
            >
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%'
                }}> </Text>
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%',
                    marginLeft: '1%',
                }}>{valueForm.includeMount && valueForm.mount === 'grua' ? 'Si' : 'No'}</Text>
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%',
                    marginLeft: '3%',
                }}> </Text>
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%'
                }}>{valueForm.processAllow ? 'Si' : 'No'}</Text>
            </View> */}
        </Page>
    );
}

export default PageEightEPdfQuote;