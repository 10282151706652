export const zipCodeES = [
  {
    "cp": 1193,
    "pob": "Aberasturi",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1013,
    "pob": "Abetxuko",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1449,
    "pob": "Abezia",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1427,
    "pob": "Acebedo",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1138,
    "pob": "Acosta",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1207,
    "pob": "Adana",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1479,
    "pob": "AgiÑaga",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1200,
    "pob": "Agurain",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1476,
    "pob": "Aiara",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1208,
    "pob": "Albeiz",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1423,
    "pob": "Alcedo",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1117,
    "pob": "Alda",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1240,
    "pob": "Alegria-dulantzi",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1129,
    "pob": "Aletxa",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1010,
    "pob": "Ali",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1468,
    "pob": "Aloria",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1139,
    "pob": "Altube",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1520,
    "pob": "Amarita",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1470,
    "pob": "Amurrio",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1439,
    "pob": "Anda",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1196,
    "pob": "Andetxa",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1260,
    "pob": "Andoin",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1118,
    "pob": "Angostina",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1220,
    "pob": "Antezana De La Ribera",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1128,
    "pob": "AntoÑana",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1428,
    "pob": "Anucita",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1250,
    "pob": "Araia",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1169,
    "pob": "Aramaio",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1420,
    "pob": "Arbigano",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1192,
    "pob": "Arbulu",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1194,
    "pob": "Aretxabaleta",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1195,
    "pob": "AriÑez",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1216,
    "pob": "Arluzea",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1007,
    "pob": "Armentia",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1120,
    "pob": "Arraia-maeztu",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1426,
    "pob": "Arreo",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1206,
    "pob": "Arrieta",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1474,
    "pob": "Artziniega",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1308,
    "pob": "Asa",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1191,
    "pob": "Astegieta",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1477,
    "pob": "AÑes",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1450,
    "pob": "Baranbio",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1211,
    "pob": "Baroja",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1322,
    "pob": "Barriobusto",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1330,
    "pob": "Bastida",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1307,
    "pob": "BaÑos De Ebro",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1212,
    "pob": "Berganzo",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1510,
    "pob": "Betolaza",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1309,
    "pob": "Bilar",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1409,
    "pob": "Billatxika",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1213,
    "pob": "Caicedo De Yuso",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1110,
    "pob": "Campezo",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1478,
    "pob": "Campijo",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1100,
    "pob": "Centro Militar Araka",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1340,
    "pob": "Elciego",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1170,
    "pob": "Elosu",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1160,
    "pob": "Ibarra",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1230,
    "pob": "IruÑa De Oca",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1440,
    "pob": "Izarra",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1430,
    "pob": "Kuartango",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1300,
    "pob": "Laguardia",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1306,
    "pob": "Lapuebla De Labarca",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1321,
    "pob": "Laserna",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1400,
    "pob": "Laudio/llodio",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1408,
    "pob": "Luiaondo",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1130,
    "pob": "Murgia",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1320,
    "pob": "Oion",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1165,
    "pob": "Oleta",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1000,
    "pob": "Vitoria-gasteiz (ver Callejero)",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 2439,
    "pob": "Abejuela",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2250,
    "pob": "Abengibre",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2511,
    "pob": "Abuzaderas, Las",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2409,
    "pob": "Agra",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2490,
    "pob": "Agramon",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2049,
    "pob": "Aguas Nuevas",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2152,
    "pob": "Alatoz",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2000,
    "pob": "Albacete (ver Callejero)",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2653,
    "pob": "Albatana",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2215,
    "pob": "Alborea",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2124,
    "pob": "Alcadozo",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2210,
    "pob": "Alcala Del Jucar",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2489,
    "pob": "Alcantarilla",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2300,
    "pob": "Alcaraz",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2249,
    "pob": "Alcozarejos",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2448,
    "pob": "Alejos, Los",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2512,
    "pob": "Aljube",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2640,
    "pob": "Almansa",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2690,
    "pob": "Alpera",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2140,
    "pob": "Anguijes, Los",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2327,
    "pob": "Argamason",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2484,
    "pob": "Arguellite",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2462,
    "pob": "Arroyofrio",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2137,
    "pob": "Arteaga",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2125,
    "pob": "Ayna",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2320,
    "pob": "Balazote",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2614,
    "pob": "Ballestero, El",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2214,
    "pob": "Balsa De Ves",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2639,
    "pob": "Barrax",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2536,
    "pob": "Beg",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2460,
    "pob": "Bellotar",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2127,
    "pob": "Berro",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2360,
    "pob": "Bienservida",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2130,
    "pob": "Bogarra",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2691,
    "pob": "Bonete",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2610,
    "pob": "Bonillo, El",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2129,
    "pob": "Campillo De La Virgen",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2251,
    "pob": "Campoalbillo",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2312,
    "pob": "Canaleja",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2499,
    "pob": "Cancarix",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2437,
    "pob": "CaÑada De Buendia",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2449,
    "pob": "CaÑada De Morote",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2326,
    "pob": "CaÑada Juncosa",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2534,
    "pob": "CaÑadas",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2139,
    "pob": "CaÑadas De Haches Abajo",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2153,
    "pob": "Carcelen",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2459,
    "pob": "Casa De La Noguera",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2151,
    "pob": "Casas De Juan NuÑez",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2329,
    "pob": "Casas De Lazaro",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2212,
    "pob": "Casas De Ves",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2211,
    "pob": "Casas Del Cerro",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2200,
    "pob": "Casas-ibaÑez",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2660,
    "pob": "Caudete",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2247,
    "pob": "Cenizate",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2520,
    "pob": "Chinchilla De Monte Aragon",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2340,
    "pob": "Chospes, Los",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2314,
    "pob": "Cilleruelo",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2513,
    "pob": "Cordovilla",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2693,
    "pob": "Corral Rubio",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2461,
    "pob": "Cotillas",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2162,
    "pob": "Cuartico, El",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2430,
    "pob": "Elche De La Sierra",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2156,
    "pob": "Felipa, La",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2436,
    "pob": "Ferez",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2637,
    "pob": "Fuensanta",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2651,
    "pob": "Fuente-alamo",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2260,
    "pob": "Fuentealbilla",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2110,
    "pob": "Gineta, La",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2253,
    "pob": "Golosalvo",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2400,
    "pob": "Hellin",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2694,
    "pob": "Higueruela",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2486,
    "pob": "Horno Ciego",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2696,
    "pob": "Hoya Gonzalo",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2420,
    "pob": "Isso",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2248,
    "pob": "Jorquera",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2487,
    "pob": "Juan Quilez",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2434,
    "pob": "Letur",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2160,
    "pob": "Lezuza",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2410,
    "pob": "Lietor",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2230,
    "pob": "Madrigueras",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2240,
    "pob": "Mahora",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2252,
    "pob": "Mariminguez",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2620,
    "pob": "Minaya",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2440,
    "pob": "Molinicos",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2638,
    "pob": "Montalvos",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2650,
    "pob": "Montealegre Del Castillo",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2220,
    "pob": "Motilleja",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2142,
    "pob": "Mullidar",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2612,
    "pob": "Munera",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2246,
    "pob": "Navas De Jorquera",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2530,
    "pob": "Nerpio",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2652,
    "pob": "Ontur",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2611,
    "pob": "Ossa De Montiel",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2213,
    "pob": "Pared, La",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2136,
    "pob": "Paterna De Madera",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2120,
    "pob": "PeÑas De San Pedro",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2313,
    "pob": "PeÑascosa",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2692,
    "pob": "Petrola",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2311,
    "pob": "Povedilla",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2510,
    "pob": "Pozo Bueno",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2141,
    "pob": "Pozohondo",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2154,
    "pob": "Pozo-lorente",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2316,
    "pob": "Reolid",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2450,
    "pob": "Riopar",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2630,
    "pob": "Roda, La",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2328,
    "pob": "Santa Ana",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2435,
    "pob": "Socovos",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2100,
    "pob": "Tarazona De La Mancha",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2155,
    "pob": "Tinajeros",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2161,
    "pob": "Tiriez",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2500,
    "pob": "Tobarra",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2485,
    "pob": "Tus",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2150,
    "pob": "Valdeganga",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2315,
    "pob": "Vianos",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2636,
    "pob": "Villalgordo Del Jucar",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2270,
    "pob": "Villamalea",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2350,
    "pob": "Villapalacios",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2695,
    "pob": "Villar De Chinchilla",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2600,
    "pob": "Villarrobledo",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2310,
    "pob": "Viveros",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2480,
    "pob": "Yeste",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 3517,
    "pob": "Abdet",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3726,
    "pob": "Abiar, L', Partida",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3100,
    "pob": "Abio",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3710,
    "pob": "Adelfas, Las, Urbanizacion",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3786,
    "pob": "Adsubia (ajuntament Adsubia)",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3739,
    "pob": "Adsubia (ajuntament Xabia/javea)",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3730,
    "pob": "Aduanas De Mar",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3698,
    "pob": "Agost",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3837,
    "pob": "Agres",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3600,
    "pob": "Agualejas",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3610,
    "pob": "Aguarrios, Zona",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3569,
    "pob": "AigÜes",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3570,
    "pob": "Aixihuili, Partida",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3000,
    "pob": "Alacant/alicante (ver Callejero)",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3839,
    "pob": "Albaida, De, Puerto",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3340,
    "pob": "Albatera",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3349,
    "pob": "Albatera-catral, De, Estacion",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3520,
    "pob": "Alberca, La, Urbanizacion",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3581,
    "pob": "Albir, L', Nucleo",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3313,
    "pob": "Alcachofar",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3788,
    "pob": "Alcala De La Jovada",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3728,
    "pob": "Alcalali",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3669,
    "pob": "Alcana",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3724,
    "pob": "Alcasar",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3841,
    "pob": "Alcocer De Planes",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3814,
    "pob": "Alcoleja",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3699,
    "pob": "Alcoraya, La",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3108,
    "pob": "Alcoyes",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3829,
    "pob": "Alcudia",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3759,
    "pob": "Aldea De Las Cuevas, Urbanizacion",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3838,
    "pob": "Alfafara",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3580,
    "pob": "AlfÀs Del Pi, L'",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3518,
    "pob": "Algar, El, Partida",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3820,
    "pob": "Algars",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3296,
    "pob": "Algoda",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3169,
    "pob": "Algorfa",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3668,
    "pob": "AlgueÑa",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3599,
    "pob": "Alhama Springs, Urbanizacion",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3109,
    "pob": "Aljibe, El, Urbanizacion",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3560,
    "pob": "Alkabir, Urbanizacion",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3779,
    "pob": "Almadrava, Playa",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3503,
    "pob": "Almafra, Partida",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3110,
    "pob": "Almajada, La, Urbanizacion",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3360,
    "pob": "Almajal, Cami",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3149,
    "pob": "Almarjal",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3191,
    "pob": "Almazara, La, Caserio",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3510,
    "pob": "Almedia",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3738,
    "pob": "Almendros (javea/xabia), Urbanitzacio",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3189,
    "pob": "Almendros, Los (orihuela-costa), Urbanizacion",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3160,
    "pob": "Almoradi",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3649,
    "pob": "Almorqui, Casa",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3827,
    "pob": "Almudaina",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3640,
    "pob": "Alquebla, Partida",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3749,
    "pob": "Alqueria Gasens",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3590,
    "pob": "Altea",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3195,
    "pob": "Altet, El",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3700,
    "pob": "Altomira",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3339,
    "pob": "Amoros, Partida",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3311,
    "pob": "Aparecida, La",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3530,
    "pob": "Arcos Este, Urbanizacion",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3312,
    "pob": "Arneva",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3315,
    "pob": "Asensios, Los, Caserio",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3680,
    "pob": "Aspe",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3795,
    "pob": "Aspre, Partida",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3114,
    "pob": "Bacarot",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3369,
    "pob": "Baden, El, Partida",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3720,
    "pob": "Baladrar, Partida",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3812,
    "pob": "Balones",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3469,
    "pob": "Balsa, La, Partida",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3450,
    "pob": "Banyeres De Mariola",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3679,
    "pob": "BaÑos Nuestra SeÑora De Orito",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3689,
    "pob": "Barbarroja",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3750,
    "pob": "Barranco Parra",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3139,
    "pob": "Barrio Alto",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3325,
    "pob": "Barrio Carretera (campaneta)",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3292,
    "pob": "Bayas, Las",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3460,
    "pob": "Beneixama",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3390,
    "pob": "Benejuzar",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3316,
    "pob": "Benferri",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3828,
    "pob": "Benialfaqui",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3778,
    "pob": "Beniarbeig",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3850,
    "pob": "Beniarres",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3816,
    "pob": "Benifallim",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3794,
    "pob": "Benigembla",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3178,
    "pob": "Benijofar",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3810,
    "pob": "Benilloba",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3550,
    "pob": "Benimagrell",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3516,
    "pob": "Benimantell",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3709,
    "pob": "Benimaquia, Partida",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3725,
    "pob": "Benimarco, Partida",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3791,
    "pob": "Benimaurell",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3769,
    "pob": "Benimeli",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3370,
    "pob": "Benitos, Los",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3410,
    "pob": "Biar",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3380,
    "pob": "Bigastro",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3187,
    "pob": "Blanque, Lo",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3159,
    "pob": "Bodega, La (dolores)",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3317,
    "pob": "Bodega, La (orihuela)",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3740,
    "pob": "Bolerias",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3690,
    "pob": "Boqueres",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3130,
    "pob": "Bras Del Port",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3322,
    "pob": "Brunete, Vereda",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3111,
    "pob": "Busot",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3659,
    "pob": "Caballusa, La",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3540,
    "pob": "Cabo De Las Huertas",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3658,
    "pob": "Camarillas",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3660,
    "pob": "Campet",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3819,
    "pob": "Canaleta, La",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3688,
    "pob": "Canalosa, La",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3409,
    "pob": "CaÑada",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3657,
    "pob": "Capellania",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3811,
    "pob": "Capellans",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3158,
    "pob": "Casicas, Las / Casicas",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3420,
    "pob": "Castalla",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3793,
    "pob": "Castell De Castells",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3737,
    "pob": "Catarroja, Partida",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3140,
    "pob": "Chapaprietas, Los, Partida",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3321,
    "pob": "Chapitel, Vereda",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3112,
    "pob": "Chareus",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3170,
    "pob": "Ciudad Quesada",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3792,
    "pob": "Coll De Rates",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3350,
    "pob": "Collereros, Los",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3639,
    "pob": "Colonia De Santa Eulalia, La",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3729,
    "pob": "Cometa (senija)",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3310,
    "pob": "Correntias Bajas (jacarilla)",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3330,
    "pob": "Crevillent",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3176,
    "pob": "Crispin, Lo",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3294,
    "pob": "Daimes",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3177,
    "pob": "Daya Vieja",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3150,
    "pob": "Dolores",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3408,
    "pob": "Encina, La, Nucleo",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3319,
    "pob": "Entrenaranjos / Urb. Entre Naranjos, Urbanizacion",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3579,
    "pob": "EspaÑa La Vieja, Urbanizacion",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3813,
    "pob": "Facheca",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3291,
    "pob": "Ferriol",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3509,
    "pob": "Figueretes, Partida",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3113,
    "pob": "Foncalent / Font- Calent",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3179,
    "pob": "Formentera Del Segura",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3840,
    "pob": "Gaianes",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3348,
    "pob": "Granja De Rocamora",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3300,
    "pob": "Huertos, Los",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3440,
    "pob": "Ibi",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3295,
    "pob": "Jubalcoy",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3723,
    "pob": "Llosa De Camacho",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3194,
    "pob": "Marina Del Pinet,  La",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3670,
    "pob": "Monforte Del Cid",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3115,
    "pob": "Monnegre",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3789,
    "pob": "Monte Pego, Urbanizacion",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3780,
    "pob": "Montepego",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3830,
    "pob": "Muro De Alcoy",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3760,
    "pob": "Ondara",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3430,
    "pob": "Onil",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3790,
    "pob": "Orba (casco Antiguo)",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3860,
    "pob": "Orxa, L'/lorcha",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3203,
    "pob": "Parque Industrial De Torrellano",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3815,
    "pob": "PenÀguila / Penaguila",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3870,
    "pob": "Penella",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3190,
    "pob": "Pilar De La Horadada",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3650,
    "pob": "Pinoso",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3193,
    "pob": "Presidente, El / Urbanizacion El Presidente, Urbanizacion",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3578,
    "pob": "Relleu",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3318,
    "pob": "Rocamora, Lo",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3638,
    "pob": "Salinas",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3314,
    "pob": "San Bartolome",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3559,
    "pob": "Santa Faz (alicante)",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3630,
    "pob": "Sax",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3138,
    "pob": "Tabarca",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3502,
    "pob": "Tolls, Partida",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3320,
    "pob": "Torrellano",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3770,
    "pob": "Verger, El",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3400,
    "pob": "Villena",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3727,
    "pob": "Xalo/jalon",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 4691,
    "pob": "Abejuela",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4510,
    "pob": "Abla",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4260,
    "pob": "Abriojal",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4520,
    "pob": "Abrucena",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4692,
    "pob": "Aceituno, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4770,
    "pob": "Adra",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4811,
    "pob": "Adrianes, Los",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4638,
    "pob": "Agua Del Medio, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4720,
    "pob": "Aguadulce",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4149,
    "pob": "Aguamarga",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4879,
    "pob": "Aguamarga (urracal)",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4271,
    "pob": "Alameda, La",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4828,
    "pob": "Alamicos, Los - Dehesa, La",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4830,
    "pob": "Alara",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4857,
    "pob": "Albanchez",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4288,
    "pob": "Albarico, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4116,
    "pob": "Albaricoques",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4279,
    "pob": "Albarracin",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4531,
    "pob": "Alboloduy",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4800,
    "pob": "Albox",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4769,
    "pob": "Alcaudique",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4778,
    "pob": "Alcazaba, La",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4480,
    "pob": "Alcolea",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4897,
    "pob": "Alcontar",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4558,
    "pob": "Alcubillas Altas, Las",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4276,
    "pob": "Alcudia De Monteagud",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4661,
    "pob": "Alfoquia, La",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4610,
    "pob": "Algarrobina, La",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4618,
    "pob": "Algarrobos, Los (palomares)",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4567,
    "pob": "Alhabia",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4400,
    "pob": "Alhama De Almeria",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4619,
    "pob": "Alhanchete, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4278,
    "pob": "Alias, Los",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4409,
    "pob": "Alicun",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4814,
    "pob": "Aljambra, La",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4629,
    "pob": "Aljariz",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4650,
    "pob": "Almajalejo (zurgena)",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4815,
    "pob": "Almanzora",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4559,
    "pob": "Almendral, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4000,
    "pob": "Almeria (ver Callejero)",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4711,
    "pob": "Almerimar",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4289,
    "pob": "Almocaizar",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4458,
    "pob": "Almocita",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4829,
    "pob": "Alqueria, La (velez Rubio)",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4779,
    "pob": "Alqueria, La (venta Nueva)",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4130,
    "pob": "Alquian, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4568,
    "pob": "Alsodux",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4628,
    "pob": "Antas",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4826,
    "pob": "Aranegas, Los",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4660,
    "pob": "Arboleas",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4888,
    "pob": "ArmuÑa De Almanzora",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4850,
    "pob": "Arroyo Aceituno, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4858,
    "pob": "Arroyo Albanchez, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4550,
    "pob": "Arroyo De Verdelecho",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4813,
    "pob": "Arroyo Del Saliente, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4867,
    "pob": "Arroyo Franco Y Estella, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4275,
    "pob": "Arroyos, Los",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4820,
    "pob": "Asensio, Los",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4825,
    "pob": "Aspilla",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4113,
    "pob": "Atochares",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4549,
    "pob": "Aulago",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4600,
    "pob": "Aznares, Los",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4889,
    "pob": "Bacares",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4713,
    "pob": "Balanegra",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4712,
    "pob": "Balerma",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4715,
    "pob": "BaÑos De Guardias Viejas, Los",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4810,
    "pob": "Barranco De Quiles, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4117,
    "pob": "Barranquete, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4450,
    "pob": "Barriada De Alcora, La",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4738,
    "pob": "Barrio De Archilla",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4648,
    "pob": "Barrio Mortero",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4479,
    "pob": "Bayarcal",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4410,
    "pob": "Benahadux",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4569,
    "pob": "Bentarique",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4647,
    "pob": "Benzal",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4760,
    "pob": "Berja",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4120,
    "pob": "Bobar, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4150,
    "pob": "Cabo De Gata, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4827,
    "pob": "Cabreras, Los",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4420,
    "pob": "Calderona, La",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4240,
    "pob": "Campamento",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4277,
    "pob": "Campico",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4110,
    "pob": "Campohermoso",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4274,
    "pob": "Canal, La",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4746,
    "pob": "Cantinas, Las",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4839,
    "pob": "CaÑada De CaÑepla, La",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4140,
    "pob": "CaÑada De Don Rodrigo, La",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4869,
    "pob": "CaÑada De Las Cruces, La",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4640,
    "pob": "Caparroses, Los (pulpi)",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4230,
    "pob": "Carmen, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4200,
    "pob": "Cartero",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4212,
    "pob": "Castro De Filabres",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4887,
    "pob": "Cela",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4750,
    "pob": "Celin",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4859,
    "pob": "Chercos",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4270,
    "pob": "Cinta Blanca",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4890,
    "pob": "Claveros, Los",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4662,
    "pob": "Concepcion, La",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4114,
    "pob": "Cortijillos, Los",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4639,
    "pob": "Cortijo Grande, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4532,
    "pob": "Cortijo Real",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4741,
    "pob": "Cortijos De Marin",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4160,
    "pob": "Cuevas De Los Medinas",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4768,
    "pob": "Darrical",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4530,
    "pob": "DoÑa Maria De OcaÑa",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4700,
    "pob": "Ejido, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4211,
    "pob": "Encalmados, Los",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4729,
    "pob": "Enix",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4727,
    "pob": "Envia, La",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4118,
    "pob": "Escullos, Los",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4649,
    "pob": "Estacion, La (san Miguel De Pulpi)",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4151,
    "pob": "Fabriquilla, La",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4728,
    "pob": "Felix",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4500,
    "pob": "FiÑana",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4460,
    "pob": "Fondon",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4693,
    "pob": "Fuente Amarga",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4812,
    "pob": "Fuente Del Mojon",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4694,
    "pob": "Gacia",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4560,
    "pob": "Gador",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4280,
    "pob": "Gallardos, Los",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4630,
    "pob": "Garrucha",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4690,
    "pob": "GoÑar",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4838,
    "pob": "Graj",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4899,
    "pob": "Hernandez, Los (los Zoilos)",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4898,
    "pob": "Hijate, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4119,
    "pob": "Huebro",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4860,
    "pob": "Huitar Mayor",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4431,
    "pob": "Illar",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4430,
    "pob": "Instincion",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4868,
    "pob": "Laroya",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4470,
    "pob": "Laujar De Andarax",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4210,
    "pob": "Lucainena De Las Torres",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4621,
    "pob": "Marinas, Las",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4740,
    "pob": "Marinas, Las (roquetas De Mar)",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4745,
    "pob": "Mojonera, La",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4533,
    "pob": "Monjos, Los",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4213,
    "pob": "Moraton",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4540,
    "pob": "Nacimiento",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4100,
    "pob": "Nijar",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4716,
    "pob": "Norias De Daza, Las",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4459,
    "pob": "Ohanes",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4617,
    "pob": "Palomares",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4721,
    "pob": "Parador, El (vicar)",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4250,
    "pob": "Pechina",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4880,
    "pob": "Porteros, Los (tijola)",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4870,
    "pob": "Purchena",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4440,
    "pob": "Ragol",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4710,
    "pob": "Redonda, La",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4131,
    "pob": "Retamar",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4115,
    "pob": "Rodalquilar",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4717,
    "pob": "San Agustin",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4259,
    "pob": "Sierra Alhamilla",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4878,
    "pob": "Sierro",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4743,
    "pob": "Solanillo, El",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4877,
    "pob": "Somontin",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4620,
    "pob": "Vera",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4616,
    "pob": "Villaricos",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 5296,
    "pob": "Adanero",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5430,
    "pob": "Adrada, La",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5370,
    "pob": "Ajo, El",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5516,
    "pob": "Alameda, La",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5160,
    "pob": "Alamedilla Del Berrocal",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5358,
    "pob": "Albornos",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5197,
    "pob": "Aldea Del Rey NiÑo",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5520,
    "pob": "Aldealabad",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5153,
    "pob": "Aldealabad Del Miron",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5580,
    "pob": "Aldeanueva Santa Cruz",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5212,
    "pob": "Aldeaseca",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5193,
    "pob": "Aldeavieja",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5593,
    "pob": "Aldehuela, La",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5630,
    "pob": "Aliseda De Tormes, La",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5515,
    "pob": "Almohalla, La",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5141,
    "pob": "Altamiros",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5560,
    "pob": "Amavida",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5631,
    "pob": "Angostura, La",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5416,
    "pob": "Arenal, El",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5400,
    "pob": "Arenas De San Pedro",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5200,
    "pob": "Arevalo",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5279,
    "pob": "Atalaya, La",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5357,
    "pob": "Aveinte",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5000,
    "pob": "Avila (ver Callejero)",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5198,
    "pob": "Bandadas",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5635,
    "pob": "Barajas",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5600,
    "pob": "Barco De Avila, El",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5692,
    "pob": "Barquillo, El",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5110,
    "pob": "Barraco, El",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5514,
    "pob": "Barrio, El",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5229,
    "pob": "Barroman",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5130,
    "pob": "Baterna",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5610,
    "pob": "Becedas",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5162,
    "pob": "Berlanas, Las",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5195,
    "pob": "Bernuy Salinero",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5211,
    "pob": "Bernuy- Zapardiel",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5194,
    "pob": "Berrocalejo De Aragona",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5540,
    "pob": "Blacha",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5147,
    "pob": "Blascojimeno",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5146,
    "pob": "Blascomillan",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5299,
    "pob": "BlasconuÑo De Matacabras",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5290,
    "pob": "Blascosancho",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5165,
    "pob": "Bohodon, El",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5690,
    "pob": "Bohoyo",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5140,
    "pob": "Bularros",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5113,
    "pob": "Burgohondo",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5278,
    "pob": "Burguillo, El",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5190,
    "pob": "CabaÑas",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5697,
    "pob": "Cabezas Altas",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5217,
    "pob": "Cabezas De Alambre",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5148,
    "pob": "Cabezas Del Villar",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5112,
    "pob": "Calas Del Burguillo",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5693,
    "pob": "Canaleja, La",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5480,
    "pob": "Candeleda",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5294,
    "pob": "CaÑada, La",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5320,
    "pob": "CardeÑosa",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5151,
    "pob": "Carpio Medianero",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5620,
    "pob": "Carrera, La",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5592,
    "pob": "Casas De Navancuerda",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5571,
    "pob": "Casas Del Puerto De Villatoro",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5621,
    "pob": "Casas Del Rey",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5450,
    "pob": "Casavieja",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5428,
    "pob": "Casillas",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5260,
    "pob": "Cebreros",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5132,
    "pob": "Cepeda La Mora",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5309,
    "pob": "Chaherreros",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5149,
    "pob": "Cillan",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5239,
    "pob": "Ciudad Ducal",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5192,
    "pob": "Colilla, La",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5289,
    "pob": "Cortos",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5291,
    "pob": "Coto De Puenteviejo",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5300,
    "pob": "Crespos",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5414,
    "pob": "Cuevas Del Valle",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5215,
    "pob": "Donvidas",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5696,
    "pob": "Encinares",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5310,
    "pob": "Fontiveros",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5427,
    "pob": "Fresnedilla",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5420,
    "pob": "Fuente De La Salud",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5134,
    "pob": "Garganta Del Villar",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5460,
    "pob": "Gavilanes",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5619,
    "pob": "Gilbuena",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5380,
    "pob": "Gimialcon",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5163,
    "pob": "Gotarrendura",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5417,
    "pob": "Guisando",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5164,
    "pob": "Hernansancho",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5268,
    "pob": "Herradon De Pinares",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5491,
    "pob": "Higuera, La",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5131,
    "pob": "Hija De Dios, La",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5418,
    "pob": "Hontanares",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5695,
    "pob": "Horcajada, La",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5210,
    "pob": "Horcajo De Las Torres",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5415,
    "pob": "Hornillo, El",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5250,
    "pob": "Hoyo De Pinares, El",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5123,
    "pob": "Hoyocasero",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5634,
    "pob": "Hoyos Del Collado",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5694,
    "pob": "Junciana",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5213,
    "pob": "Langa",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5490,
    "pob": "Lanzahita",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5691,
    "pob": "Loros, Los",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5220,
    "pob": "Madrigal De Las Altas Torres",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5216,
    "pob": "Magazos",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5298,
    "pob": "Mamblas",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5145,
    "pob": "Manjabalago",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5154,
    "pob": "Mercadillo",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5461,
    "pob": "Mijares",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5280,
    "pob": "Mingorria",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5191,
    "pob": "Mironcillo",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5410,
    "pob": "Mombeltran",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5152,
    "pob": "Montalbo",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5350,
    "pob": "MoraÑuela",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5122,
    "pob": "Morisco",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5530,
    "pob": "MuÑogalindo",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5301,
    "pob": "MuÑosancho",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5369,
    "pob": "Narros Del Castillo",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5633,
    "pob": "Navacepeda De Tormes",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5429,
    "pob": "Navahondilla",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5196,
    "pob": "Navalgrande",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5120,
    "pob": "Navalmoral",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5240,
    "pob": "Navalperal De Pinares",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5100,
    "pob": "Navaluenga",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5115,
    "pob": "Navarrevisca",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5230,
    "pob": "Navas Del Marques, Las",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5214,
    "pob": "Pajares De Adaja",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5143,
    "pob": "Pasarilla Del Rebollar",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5150,
    "pob": "Pascualcobo",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5470,
    "pob": "Pedro Bernardo",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5500,
    "pob": "Piedrahita",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5440,
    "pob": "Piedralaves",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5492,
    "pob": "Poyales Del Hoyo",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5292,
    "pob": "Pozanco",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5489,
    "pob": "Raso, El",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5267,
    "pob": "San Bartolome De Pinares",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5412,
    "pob": "San Esteban Del Valle",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5111,
    "pob": "San Juan De La Nava",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5133,
    "pob": "San Martin De La Vega Alberche",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5166,
    "pob": "San Pascual",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5413,
    "pob": "Santa Cruz Del Valle",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5510,
    "pob": "Santa Maria Del Berrocal",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5270,
    "pob": "Tiemblo, El",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5114,
    "pob": "Villanueva De Avila",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5591,
    "pob": "Villanueva Del Campillo",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 6730,
    "pob": "Acedera",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6207,
    "pob": "Aceuchal",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6940,
    "pob": "Ahillones",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6840,
    "pob": "Alange",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6170,
    "pob": "Albuera, La",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6510,
    "pob": "Alburquerque",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6182,
    "pob": "Alcazaba",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6131,
    "pob": "Alconchel",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6393,
    "pob": "Alconera",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6196,
    "pob": "Aldea De Retamal",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6894,
    "pob": "Aljucen",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6171,
    "pob": "Almendral",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6200,
    "pob": "Almendralejo",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6614,
    "pob": "Almorchon",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6850,
    "pob": "Arroyo De San Servan",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6329,
    "pob": "Atalaya",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6920,
    "pob": "Azuaga",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6519,
    "pob": "Bacoco",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6000,
    "pob": "Badajoz (ver Callejero)",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6195,
    "pob": "Balboa",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6499,
    "pob": "BarbaÑo",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6160,
    "pob": "Barcarrota",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6050,
    "pob": "Base Aerea Talavera Real",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6659,
    "pob": "Baterno",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6389,
    "pob": "Bazana, La",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6429,
    "pob": "Benquerencia De La Serena",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6930,
    "pob": "Berlanga",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6250,
    "pob": "Bienvenida",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6394,
    "pob": "Bodonal De La Sierra",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6193,
    "pob": "Botoa",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6370,
    "pob": "Burguillos Del Cerro",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6600,
    "pob": "Cabeza Del Buey",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6293,
    "pob": "Cabeza La Vaca",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6810,
    "pob": "Calamonte",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6292,
    "pob": "Calera De Leon",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6249,
    "pob": "Calzadilla De Los Barros",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6460,
    "pob": "Campanario",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6443,
    "pob": "Campillo De Llerena",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6612,
    "pob": "Capilla",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6929,
    "pob": "Cardenchosa, La",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6488,
    "pob": "Carmonita",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6770,
    "pob": "Casas De Don Pedro",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6960,
    "pob": "Casas De Reina",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6680,
    "pob": "Castilblanco",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6718,
    "pob": "Castillo De La Encomienda",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6420,
    "pob": "Castuera",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6105,
    "pob": "Cheles",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6518,
    "pob": "Codosera, La",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6410,
    "pob": "Conquista",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6487,
    "pob": "Cordobilla De Lacara",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6469,
    "pob": "Coronada, La",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6479,
    "pob": "Cristina",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6820,
    "pob": "Don Alvaro",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6400,
    "pob": "Don Benito",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6710,
    "pob": "Entrerrios",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6197,
    "pob": "Entrin Alto",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6860,
    "pob": "Esparragalejo",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6439,
    "pob": "Esparragosa De La Serena",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6620,
    "pob": "Esparragosa De Lares",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6390,
    "pob": "Feria",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6340,
    "pob": "Fregenal De La Sierra",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6660,
    "pob": "Fuenlabrada De Los Montes",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6240,
    "pob": "Fuente De Cantos",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6980,
    "pob": "Fuente Del Arco",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6360,
    "pob": "Fuente Del Maestre",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6280,
    "pob": "Fuentes De Leon",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6690,
    "pob": "Garbayuela",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6711,
    "pob": "Gargaligas",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6656,
    "pob": "Garlitos",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6870,
    "pob": "Garrovilla, La",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6180,
    "pob": "Gevora Del Caudillo",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6910,
    "pob": "Granja De Torrehermosa",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6187,
    "pob": "Guadajira",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6713,
    "pob": "Guadalperales, Los",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6186,
    "pob": "Guadiana Del Caudillo",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6459,
    "pob": "Guarda, La",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6470,
    "pob": "GuareÑa",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6714,
    "pob": "Haba, La",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6613,
    "pob": "Helechal",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6692,
    "pob": "Helechosa De Los Montes",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6412,
    "pob": "Hernan Cortes",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6670,
    "pob": "Herrera Del Duque",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6441,
    "pob": "Higuera De La Serena",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6445,
    "pob": "Higuera De Llerena",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6132,
    "pob": "Higuera De Vargas",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6350,
    "pob": "Higuera La Real",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6226,
    "pob": "Hinojosa Del Valle",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6228,
    "pob": "Hornachos",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6380,
    "pob": "Jerez De Los Caballeros",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6489,
    "pob": "Lacara, De, Poblado",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6391,
    "pob": "Lapa, La",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6227,
    "pob": "Llera",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6900,
    "pob": "Llerena",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6498,
    "pob": "Lobon",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6468,
    "pob": "Magacela",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6939,
    "pob": "Maguilla",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6928,
    "pob": "Malcocinado",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6440,
    "pob": "Malpartida De La Serena",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6478,
    "pob": "Manchita",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6411,
    "pob": "Medellin",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6320,
    "pob": "Medina De Las Torres",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6413,
    "pob": "Mengabril",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6800,
    "pob": "Merida",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6891,
    "pob": "Mirandilla",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6260,
    "pob": "Monesterio",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6291,
    "pob": "Montemolin",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6427,
    "pob": "Monterrubio De La Serena",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6480,
    "pob": "Montijo",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6176,
    "pob": "Morera, La",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6486,
    "pob": "Nava De Santiago, La",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6615,
    "pob": "Nava, La (de Almorchon)",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6760,
    "pob": "Navalvillar De Pela",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6173,
    "pob": "Nogales",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6183,
    "pob": "Novelda Del Guadiana",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6120,
    "pob": "Oliva De La Frontera",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6475,
    "pob": "Oliva De Merida",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6100,
    "pob": "Olivenza",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6750,
    "pob": "Orellana De La Sierra",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6740,
    "pob": "Orellana La Vieja",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6717,
    "pob": "Palazuelo",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6907,
    "pob": "Pallares",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6476,
    "pob": "Palomas",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6691,
    "pob": "Pantano De Cijara",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6679,
    "pob": "Peloche",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6610,
    "pob": "PeÑalsordo",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6919,
    "pob": "Peraleda Del Zaucejo",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6630,
    "pob": "Puebla De Alcocer",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6490,
    "pob": "Puebla De La Calzada",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6477,
    "pob": "Puebla De La Reina",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6191,
    "pob": "Puebla De Obando",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6310,
    "pob": "Puebla De Sancho Perez",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6906,
    "pob": "Puebla Del Maestre",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6229,
    "pob": "Puebla Del Prior",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6184,
    "pob": "Pueblonuevo Del Guadiana",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6428,
    "pob": "Puerto Hurraco",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6450,
    "pob": "Quintana De La Serena",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6392,
    "pob": "Raposo, El",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6970,
    "pob": "Reina",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6715,
    "pob": "Rena",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6442,
    "pob": "Retamal De Llerena",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6225,
    "pob": "Ribera Del Fresno",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6657,
    "pob": "Risco",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6190,
    "pob": "Roca De La Sierra, La",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6181,
    "pob": "Sagrajas",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6174,
    "pob": "Salvaleon",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6175,
    "pob": "Salvatierra De Los Barros",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6106,
    "pob": "San Benito De La Contienda",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6446,
    "pob": "San Cristobal De Zalamea",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6109,
    "pob": "San Francisco De Olivenza",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6108,
    "pob": "San Jorge De Alor",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6893,
    "pob": "San Pedro De Merida",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6500,
    "pob": "San Vicente De Alcantara",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6655,
    "pob": "Sancti-spiritus",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6908,
    "pob": "Santa Maria La Nava",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6150,
    "pob": "Santa Marta De Los Barros",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6230,
    "pob": "Santos De Maimona, Los",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6270,
    "pob": "Segura De Leon",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6650,
    "pob": "Siruela",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6209,
    "pob": "Solana De Los Barros",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6640,
    "pob": "Talarrubias",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6140,
    "pob": "Talavera La Real",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6133,
    "pob": "Taliga",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6658,
    "pob": "Tamurejo",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6172,
    "pob": "Torre De Miguel Sesmero",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6880,
    "pob": "Torremayor",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6210,
    "pob": "Torremegia",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6719,
    "pob": "Torviscal, El",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6909,
    "pob": "Trasierra",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6892,
    "pob": "Trujillanos",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6290,
    "pob": "Usagre",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6194,
    "pob": "Valdebotoa",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6689,
    "pob": "Valdecaballeros",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6185,
    "pob": "Valdelacalzada",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6474,
    "pob": "Valdetorres",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6720,
    "pob": "Valdivia",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6444,
    "pob": "Valencia De Las Torres",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6134,
    "pob": "Valencia Del Mombuey",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6330,
    "pob": "Valencia Del Ventoso",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6458,
    "pob": "Valle De La Serena",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6177,
    "pob": "Valle De Matamoros",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6178,
    "pob": "Valle De Santa Ana",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6378,
    "pob": "Valverde De Burguillos",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6130,
    "pob": "Valverde De Leganes",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6927,
    "pob": "Valverde De Llerena",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6890,
    "pob": "Valverde De Merida",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6731,
    "pob": "Vegas Altas",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6220,
    "pob": "Villafranca De Los Barros",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6950,
    "pob": "Villagarcia De La Torre",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6473,
    "pob": "Villagonzalo",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6208,
    "pob": "Villalba De Los Barros",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6700,
    "pob": "Villanueva De La Serena",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6110,
    "pob": "Villanueva Del Fresno",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6716,
    "pob": "Villar De Rena",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6192,
    "pob": "Villar Del Rey",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6107,
    "pob": "Villarreal",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6678,
    "pob": "Villarta De Los Montes",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6415,
    "pob": "Yelbes",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6300,
    "pob": "Zafra",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6129,
    "pob": "Zahinos",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6430,
    "pob": "Zalamea De La Serena",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6611,
    "pob": "Zarza Capilla",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6830,
    "pob": "Zarza, La",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6712,
    "pob": "Zurbaran",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 7142,
    "pob": "Coves",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7817,
    "pob": "Aeroport D'eivissa",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7730,
    "pob": "Alaior",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7340,
    "pob": "Alaro",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7713,
    "pob": "Alcaufar",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7179,
    "pob": "Alconasser",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7400,
    "pob": "Alcudia",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7210,
    "pob": "Algaida",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7150,
    "pob": "Andratx",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7199,
    "pob": "Aranjassa (s')",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7600,
    "pob": "Arenal (s')",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7529,
    "pob": "Ariany",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7159,
    "pob": "Arraco (s')",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7570,
    "pob": "Arta",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7560,
    "pob": "Auto Safari",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7609,
    "pob": "Badia Blava",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7191,
    "pob": "Banyalbufar",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7691,
    "pob": "Barca Trencada",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7470,
    "pob": "Base Aeria De PollenÇa",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7181,
    "pob": "Bendinat",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7141,
    "pob": "Benestar",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7579,
    "pob": "Betlem",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7350,
    "pob": "Biniagual",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7143,
    "pob": "Biniali",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7711,
    "pob": "Biniali (menorca)",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7369,
    "pob": "Biniamar",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7101,
    "pob": "Biniaraix",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7712,
    "pob": "Binicalaf",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7710,
    "pob": "Binifadet",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7800,
    "pob": "Blanca Dona (eivissa)",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7520,
    "pob": "Bon Any",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7311,
    "pob": "Buger",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7110,
    "pob": "Bunyola",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7314,
    "pob": "Caimari",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7590,
    "pob": "Cala Agulla",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7680,
    "pob": "Cala Anguila - Cala Mendia",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7769,
    "pob": "Cala Blanca (ciutadella De Menorca), Urbanitzacio",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7559,
    "pob": "Cala Bona",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7469,
    "pob": "Cala Carbo",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7811,
    "pob": "Cala De San Vicente Ibiza",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7660,
    "pob": "Cala D'or",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7669,
    "pob": "Cala Ferrera",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7659,
    "pob": "Cala Figuera (mallorca)",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7160,
    "pob": "Cala Fornells",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7750,
    "pob": "Cala Galdana",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7820,
    "pob": "Cala Gracio",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7850,
    "pob": "Cala Llenya, Platja",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7690,
    "pob": "Cala Llombards",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7849,
    "pob": "Cala Llonga, Platja",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7670,
    "pob": "Cala Marsal",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7589,
    "pob": "Cala Mesquida",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7688,
    "pob": "Cala Murada",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7639,
    "pob": "Cala Pi",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7810,
    "pob": "Cala Portinax, Urbanitzacio",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7720,
    "pob": "Cala San Esteban",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7689,
    "pob": "Cala Tropicana, Platja",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7315,
    "pob": "Cala Tuent",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7184,
    "pob": "CalviÀ",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7108,
    "pob": "Camp De La Mar",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7310,
    "pob": "Campanet",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7630,
    "pob": "Campos",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7813,
    "pob": "Can Cabrit (eivissa)",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7819,
    "pob": "Ca'n Cirer",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7815,
    "pob": "Ca'n Clavo",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7610,
    "pob": "Can Pastilla",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7458,
    "pob": "Ca'n Picafort",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7209,
    "pob": "Canteras Las",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7860,
    "pob": "Cap De Barbaria",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7196,
    "pob": "Capdella, Es",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7580,
    "pob": "Capdepera",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7208,
    "pob": "Carritxo (es)",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7140,
    "pob": "Ca's Cana",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7193,
    "pob": "Caubet",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7760,
    "pob": "Ciutadella De Menorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7638,
    "pob": "Colonia De Sant Jordi",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7330,
    "pob": "Consell",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7183,
    "pob": "Costa De La Calma",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7650,
    "pob": "Costa, La (alqueria Blanca)",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7144,
    "pob": "Costitx",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7740,
    "pob": "Covas Novas (mercadal)",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7872,
    "pob": "Es Calo",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7749,
    "pob": "Es Migjorn Gran/el Migjorn Gran",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7190,
    "pob": "Esporles",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7192,
    "pob": "Estellencs",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7200,
    "pob": "Felanitx",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7109,
    "pob": "Fornalutx",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7748,
    "pob": "Fornells",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7700,
    "pob": "Fortaleza Isabel Ii",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7180,
    "pob": "Galatzo (santa PonÇa), Urbanitzacio",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7195,
    "pob": "Galilea",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7120,
    "pob": "Garriga, La",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7460,
    "pob": "Horta, L' (pollenÇa)",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7300,
    "pob": "Inca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7518,
    "pob": "Lloret De Vistalegre",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7360,
    "pob": "Lloseta",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7430,
    "pob": "Llubi",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7620,
    "pob": "Llucmajor",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7500,
    "pob": "Manacor",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7312,
    "pob": "Mancor De La Vall",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7000,
    "pob": "Mao (ver Callejero)",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7519,
    "pob": "Maria De La Salut",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7170,
    "pob": "Miramar",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7871,
    "pob": "Mitjorn, Platja",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7230,
    "pob": "Montuiri",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7316,
    "pob": "Moscari",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7440,
    "pob": "Muro",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7349,
    "pob": "Orient",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7121,
    "pob": "Parc Bit, Urbanizacion",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7220,
    "pob": "Pina",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7420,
    "pob": "Pobla (sa)",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7009,
    "pob": "Poligon Industrial Son Rossinyol",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7260,
    "pob": "Porreres",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7157,
    "pob": "Port D'andratx",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7194,
    "pob": "Puigpunyent",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7629,
    "pob": "Randa",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7511,
    "pob": "Ruberts",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7198,
    "pob": "Sa Creu Vermella/la Creu Vermella",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7640,
    "pob": "Salines (ses)",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7240,
    "pob": "Sant Joan",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7839,
    "pob": "Sant Josep De Sa Talaia (benimussa)",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7829,
    "pob": "Sant Josep De Sa Talaia (cala Bassa)",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7830,
    "pob": "Sant Josep De Sa Talaia (cala Vedella)",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7818,
    "pob": "Sant Josep De Sa Talaia (la Caleta)",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7812,
    "pob": "Sant LlorenÇ De BalÀfia",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7530,
    "pob": "Sant LlorenÇ Des Cardassar",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7816,
    "pob": "Sant Mateu D'albarca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7828,
    "pob": "Santa Agnes De Corona",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7840,
    "pob": "Santa Eularia Des Riu",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7814,
    "pob": "Santa Gertrudis De Fruitera",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7450,
    "pob": "Santa Margalida",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7320,
    "pob": "Santa Maria Del Cami (isla De Mallorca)",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7870,
    "pob": "Savina, La",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7313,
    "pob": "Selva",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7459,
    "pob": "Serra Nova",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7100,
    "pob": "S'horta (soller)",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7687,
    "pob": "S'illot-cala Morlanda",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7510,
    "pob": "Sineu",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7540,
    "pob": "Son Carrio (mallorca)",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7509,
    "pob": "Son Fangos",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7550,
    "pob": "Son Servera",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7122,
    "pob": "Universitat Illes Balears",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7250,
    "pob": "Vilafranca De Bonany",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 8630,
    "pob": "Abrera",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8820,
    "pob": "Aeroport Del Prat, El",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8256,
    "pob": "Aguilar De Segarra",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8591,
    "pob": "Aiguafreda",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8328,
    "pob": "Alella",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8587,
    "pob": "Alpens",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8680,
    "pob": "Ametlla De Casserres, L'",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8672,
    "pob": "Ametlla De Merola, L'",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8480,
    "pob": "Ametlla Del Valles, L'",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8734,
    "pob": "ArboÇar, L' (avinyonet Del Penedes)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8350,
    "pob": "Arenys De Mar",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8358,
    "pob": "Arenys De Munt",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8717,
    "pob": "ArgenÇola",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8260,
    "pob": "Argensola (castellnou De Bages)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8310,
    "pob": "Argentona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8271,
    "pob": "Artes",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8610,
    "pob": "Avia",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8279,
    "pob": "Avinyo",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8793,
    "pob": "Avinyonet Del Penedes",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8000,
    "pob": "Badalona (ver Callejero)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8214,
    "pob": "Badia Del Valles",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8784,
    "pob": "Badorc, El",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8695,
    "pob": "Baga",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8660,
    "pob": "Balsareny",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8210,
    "pob": "Barbera Del Valles",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8269,
    "pob": "Barri La Rata(navas)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8420,
    "pob": "Barriada Nova",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8476,
    "pob": "Batlloria, La (sant Celoni)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8782,
    "pob": "Beguda Alta, La (masquefa)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8859,
    "pob": "Begues",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8193,
    "pob": "Bellaterra",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8521,
    "pob": "Bellavista",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 43421,
    "pob": "Bellprat",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 8600,
    "pob": "Berga",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8415,
    "pob": "Bigues",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8619,
    "pob": "Borreda",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8860,
    "pob": "Botigues De Sitges, Les (sitges)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8650,
    "pob": "Botjosa, La",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8294,
    "pob": "Bruc, El",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8559,
    "pob": "Brull, El",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8733,
    "pob": "Ca L'avi (subirats)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8794,
    "pob": "Cabanyes, Les",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8718,
    "pob": "Cabrera D'anoia",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8349,
    "pob": "Cabrera De Mar",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8348,
    "pob": "Cabrils",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8611,
    "pob": "Cal Rosal (olvan)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8280,
    "pob": "Calaf",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8275,
    "pob": "Calders",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8140,
    "pob": "Caldes De Montbui",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8393,
    "pob": "Caldes D'estrac",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8370,
    "pob": "Calella",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8506,
    "pob": "Calldetenes",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8262,
    "pob": "Callus",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8281,
    "pob": "Calonge De Segarra",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8472,
    "pob": "Campins",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8770,
    "pob": "Can Benet De La Prua",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8787,
    "pob": "Can Bou (orpi)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8211,
    "pob": "Can Carner, Residencial",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8396,
    "pob": "Can Domenec (tordera)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8292,
    "pob": "Can Fosalba (hostalets De Pierola)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8757,
    "pob": "Can Roig (cervello)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8360,
    "pob": "Canet De Mar",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8445,
    "pob": "Canoves",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8569,
    "pob": "Cantonigros",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8318,
    "pob": "Canyamars (dosrius)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8811,
    "pob": "Canyelles",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8786,
    "pob": "Capellades",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8617,
    "pob": "Capolat",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8440,
    "pob": "Cardedeu",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8261,
    "pob": "Cardona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8729,
    "pob": "Casetes, Les (castellet I La Gornal)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8693,
    "pob": "Casserres",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8604,
    "pob": "Castell De L'areny",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8671,
    "pob": "Castelladral",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8696,
    "pob": "Castellar De N'hug",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8618,
    "pob": "Castellar Del Riu",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8296,
    "pob": "Castellbell I El Vilar",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8755,
    "pob": "Castellbisbal",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8183,
    "pob": "Castellcir",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8283,
    "pob": "Castellfollit De Riubregos",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8255,
    "pob": "Castellfollit Del Boix",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8297,
    "pob": "Castellgali",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8251,
    "pob": "Castellnou De Bages",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8719,
    "pob": "Castelloli",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8732,
    "pob": "Castellvi De La Marca",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8769,
    "pob": "Castellvi De Rosanes",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8540,
    "pob": "Centelles",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8698,
    "pob": "Cercs",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8290,
    "pob": "Cerdanyola Del Valles",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8758,
    "pob": "Cervello",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8293,
    "pob": "Collbato",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8450,
    "pob": "Collsabadell",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8178,
    "pob": "Collsuspina",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8199,
    "pob": "Colonia Puig, La (marganell)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8105,
    "pob": "Conreria, La (sant Fost Campcentelles)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8289,
    "pob": "Copons",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8940,
    "pob": "Cornella De Llobregat",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8520,
    "pob": "Corro D'amunt",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8479,
    "pob": "Costa Del Montseny, La",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8880,
    "pob": "Cubelles",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8319,
    "pob": "Dosrius",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8150,
    "pob": "Eixample, L'",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8670,
    "pob": "El Mujal",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8950,
    "pob": "Esplugues De Llobregat",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8614,
    "pob": "Espunyola, L'",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8148,
    "pob": "Estany, L'",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8259,
    "pob": "Fals",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8590,
    "pob": "Figaro (montmany)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8130,
    "pob": "Florida, La",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8495,
    "pob": "Fogars De La Selva",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8519,
    "pob": "Folgueroles",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8504,
    "pob": "Font De'n Titus (calldetenes)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8753,
    "pob": "Fontpineda Urbanizacion (pallejÀ), Urbanitzacio",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8736,
    "pob": "Font-rubi",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8194,
    "pob": "Fonts, Les (sant Quirze Del Valles)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8228,
    "pob": "Fonts, Les (terrassa)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8104,
    "pob": "Gallecs",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8146,
    "pob": "Gallifa",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8871,
    "pob": "Garraf (sitges)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8530,
    "pob": "Garriga, La",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8792,
    "pob": "Garrofa, La (avinyonet Del Penedes)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8850,
    "pob": "Gava",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8790,
    "pob": "Gelida",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8508,
    "pob": "Gleva, La (masies De Voltrega)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8474,
    "pob": "Gualba",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8694,
    "pob": "Guardiola De Bergueda",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8503,
    "pob": "Gurb",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8550,
    "pob": "Hostalets De Balenya, Els (balenyÀ)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8781,
    "pob": "Hostalets De Pierola, Els",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8700,
    "pob": "Igualada",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8779,
    "pob": "Llacuna, La",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8120,
    "pob": "Llagosta, La",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8186,
    "pob": "LliÇa D'amunt",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8185,
    "pob": "LliÇa De Vall",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8514,
    "pob": "LluÇa",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8380,
    "pob": "Malgrat De Mar",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8522,
    "pob": "Malla",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8560,
    "pob": "Manlleu",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8298,
    "pob": "Marganell",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8760,
    "pob": "Martorell",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8107,
    "pob": "Martorelles",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8812,
    "pob": "Mas D'en Serra, El",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8797,
    "pob": "Mas More (el Pla Del Penedes)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8916,
    "pob": "Mas Ram Urb. (tiana)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8510,
    "pob": "Masies De Roda, Les",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8509,
    "pob": "Masies De Voltrega, Les (vinyoles D'oris)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8320,
    "pob": "Masnou, El",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8783,
    "pob": "Masquefa",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8230,
    "pob": "Matadepera",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8773,
    "pob": "Mediona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8195,
    "pob": "Mira-sol (ver Callejero Sant Cugat)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8180,
    "pob": "Moia",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8470,
    "pob": "Moixerigues (santa Maria De Palautordera)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8720,
    "pob": "Moli D'en Rovira, El",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8750,
    "pob": "Molins De Rei",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8100,
    "pob": "Mollet Del Valles",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8691,
    "pob": "Monistrol De Montserrat",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8110,
    "pob": "Montcada I Reixac",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8605,
    "pob": "Montclar",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8585,
    "pob": "Montesquiu",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8390,
    "pob": "Montgat",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8612,
    "pob": "Montmajor",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8160,
    "pob": "Montmelo",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8170,
    "pob": "Montornes Del Valles",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8460,
    "pob": "Montseny",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8529,
    "pob": "Muntanyola",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8278,
    "pob": "Mura",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8270,
    "pob": "Navarcles",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8711,
    "pob": "Odena",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8795,
    "pob": "Olesa De Bonesvalls",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8640,
    "pob": "Olesa De Montserrat",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8818,
    "pob": "Olivella",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8516,
    "pob": "Olost",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8573,
    "pob": "Oris",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8518,
    "pob": "Orista",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8317,
    "pob": "Orrius",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8796,
    "pob": "Pacs Del Penedes",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8389,
    "pob": "Palafolls",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8184,
    "pob": "Palau-solita I Plegamans",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8780,
    "pob": "Palleja",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8756,
    "pob": "Palma De Cervello, La",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8754,
    "pob": "Papiol, El",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8589,
    "pob": "Perafita",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8397,
    "pob": "Pineda De Mar",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8789,
    "pob": "Pinedes De L'ermengol, Les",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8196,
    "pob": "Planes, Les (ver Callejero Sant Cugat)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8213,
    "pob": "Polinya",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8272,
    "pob": "Pont De Cabrianes, El",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8254,
    "pob": "Pont De Vilomara I Rocafort, El",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8738,
    "pob": "Pontons",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8513,
    "pob": "Prats De LluÇanes",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8338,
    "pob": "Premia De Dalt",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8330,
    "pob": "Premia De Mar",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8810,
    "pob": "Puigmolto",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8692,
    "pob": "Puig-reig",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8282,
    "pob": "Pujalt",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8606,
    "pob": "Quar, La",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8299,
    "pob": "Rellinars",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8416,
    "pob": "Riells Del Fai (bigues I Riells)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8469,
    "pob": "Rieral, El (fogars De Montclus)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8187,
    "pob": "Rieral, El (stª Eulalia De RonÇana)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8291,
    "pob": "Ripollet",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8430,
    "pob": "Roca Del Valles, La",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8870,
    "pob": "Rocamar (sant Pere De Ribes)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8191,
    "pob": "Rubi",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8517,
    "pob": "Sagas",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8697,
    "pob": "Saldes",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8930,
    "pob": "Sant Adria De Besos",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8586,
    "pob": "Sant Agusti De LluÇanes",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8740,
    "pob": "Sant Andreu De La Barca",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8392,
    "pob": "Sant Andreu De Llavaneres",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8459,
    "pob": "Sant Antoni De Vilamajor",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8830,
    "pob": "Sant Boi De Llobregat",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8849,
    "pob": "Sant Climent De Llobregat",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8798,
    "pob": "Sant Cugat Sesgarrigues",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8461,
    "pob": "Sant Esteve De Palautordera",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8635,
    "pob": "Sant Esteve Sesrovires",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8182,
    "pob": "Sant Feliu De Codines",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8980,
    "pob": "Sant Feliu De Llobregat",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8274,
    "pob": "Sant Feliu Sasserra",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8512,
    "pob": "Sant Hipolit De Voltrega",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8359,
    "pob": "Sant Iscle De Vallalta",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8607,
    "pob": "Sant Jaume De Frontanya",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8712,
    "pob": "Sant Jaume Sesoliveres Urb. (igualada)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8673,
    "pob": "Sant Joan De Montdarn",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8250,
    "pob": "Sant Joan De Vilatorrada",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8970,
    "pob": "Sant Joan Despi",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8960,
    "pob": "Sant Just Desvern",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8791,
    "pob": "Sant LlorenÇ D'hortons",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8212,
    "pob": "Sant LlorenÇ Savall",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8515,
    "pob": "Sant Marti D'albars",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8592,
    "pob": "Sant Marti De Centelles",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8731,
    "pob": "Sant Marti Sarroca",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8263,
    "pob": "Sant Mateu De Bages",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8554,
    "pob": "Sant Miquel De Balenya-seva",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8776,
    "pob": "Sant Pere De Riudebitlles",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8572,
    "pob": "Sant Pere De Torello",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8458,
    "pob": "Sant Pere De Vilamajor",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8799,
    "pob": "Sant Pere Molanta (olerdola)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8395,
    "pob": "Sant Pol De Mar",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8777,
    "pob": "Sant Quinti De Mediona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8580,
    "pob": "Sant Quirze De Besora",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8192,
    "pob": "Sant Quirze Del Valles",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8189,
    "pob": "Sant Quirze Safaja",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8253,
    "pob": "Sant Salvador De Guardiola",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8295,
    "pob": "Sant VicenÇ De Castellet",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8394,
    "pob": "Sant VicenÇ De Montalt",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8571,
    "pob": "Sant VicenÇ De Torello",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8620,
    "pob": "Sant VicenÇ Dels Horts",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8690,
    "pob": "Santa Coloma De Cervello",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8507,
    "pob": "Santa Eugenia De Berga",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8505,
    "pob": "Santa Eulalia De Riuprimer",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8710,
    "pob": "Santa Margarida De Montbui",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8730,
    "pob": "Santa Margarida I Els Monjos",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8584,
    "pob": "Santa Maria De Besora",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8511,
    "pob": "Santa Maria De Corco",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8106,
    "pob": "Santa Maria De Martorelles",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8273,
    "pob": "Santa Maria D'olo",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8398,
    "pob": "Santa Susanna",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8181,
    "pob": "Sentmenat",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8553,
    "pob": "Seva",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8588,
    "pob": "Sora",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8739,
    "pob": "Subirats",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8593,
    "pob": "Tagamanent",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8552,
    "pob": "Taradell",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8329,
    "pob": "Teia",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8391,
    "pob": "Tiana",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8551,
    "pob": "Tona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8490,
    "pob": "Tordera",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8570,
    "pob": "Torello",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8227,
    "pob": "Torrebonica",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8775,
    "pob": "Torrelavit",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8737,
    "pob": "Torrelles De Foix",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8629,
    "pob": "Torrelles De Llobregat",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8231,
    "pob": "Ullastrell",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8233,
    "pob": "Vacarisses",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8785,
    "pob": "Vallbona D'anoia",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8872,
    "pob": "Vallcarca (sitges)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8699,
    "pob": "Vallcebre",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8197,
    "pob": "Valldoreix (ver Callejero Sant Cugat)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8410,
    "pob": "Valldoriolf (vilanova Del VallÈs)",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8471,
    "pob": "Vallgorguina",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8759,
    "pob": "Vallirana",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8188,
    "pob": "Vallromanes",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8500,
    "pob": "Vic",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8613,
    "pob": "Vilada",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8840,
    "pob": "Viladecans",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8232,
    "pob": "Viladecavalls",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8241,
    "pob": "Viladordis",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8455,
    "pob": "Vilalba Sasserra",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8788,
    "pob": "Vilanova Del Cami",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8800,
    "pob": "Vilanova I La Geltru",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8339,
    "pob": "Vilassar De Dalt",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8340,
    "pob": "Vilassar De Mar",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8735,
    "pob": "Vilobi Del Penedes",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 9586,
    "pob": "Abadia La Mena",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9141,
    "pob": "Abajas De Bureba",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9129,
    "pob": "Acedillo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9640,
    "pob": "AceÑa De Lara, La",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9462,
    "pob": "Adrada De Haza",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9515,
    "pob": "Ael",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9199,
    "pob": "Ages",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9593,
    "pob": "Aguas Candidas",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9569,
    "pob": "Aguera De Montija",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9249,
    "pob": "Aguilar De Bureba",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9370,
    "pob": "Aguilera, La",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9216,
    "pob": "Aguillo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9146,
    "pob": "Ailanes De Zamanzas",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 34492,
    "pob": "Albacastro",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 9239,
    "pob": "Albillos",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9258,
    "pob": "Alcocero De Mola",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9660,
    "pob": "Aldea Del Pinar",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9211,
    "pob": "Aldea Del Portillo De Busto, La",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9554,
    "pob": "Aldea, La",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9572,
    "pob": "Alfoz De Bricia",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9571,
    "pob": "Alfoz De Santa Gadea",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9559,
    "pob": "AlmiÑe, El",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9219,
    "pob": "Altable",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9136,
    "pob": "Amaya",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9588,
    "pob": "Angostina",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9514,
    "pob": "Angosto",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9313,
    "pob": "Anguix",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9585,
    "pob": "AntuÑano",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9589,
    "pob": "Anzo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9215,
    "pob": "AÑastro",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9511,
    "pob": "Aostri",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9217,
    "pob": "Araico",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9400,
    "pob": "Aranda De Duero",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9410,
    "pob": "Arandilla",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9451,
    "pob": "Arauzo De Miel",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9218,
    "pob": "Arce",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9127,
    "pob": "Arcellares Del Tozo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9587,
    "pob": "Arceo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9592,
    "pob": "Arconada",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9195,
    "pob": "Arcos De La Llana",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9107,
    "pob": "Arenillas De Riopisuerga",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9133,
    "pob": "Arenillas De Villadiego",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9558,
    "pob": "Arges",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9570,
    "pob": "Arija",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9292,
    "pob": "Arraya De Oca",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9131,
    "pob": "Arroyal De Vivar",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9615,
    "pob": "Arroyo De Salas",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9549,
    "pob": "Arroyuelo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9194,
    "pob": "Ausines, Los",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9345,
    "pob": "Avellanosa De MuÑo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9267,
    "pob": "Avellanosa De Rioja",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9145,
    "pob": "Ayoluengo De La Lora",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9200,
    "pob": "Azucarera Leopoldo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9350,
    "pob": "Bahabon De Esgueva",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9119,
    "pob": "Balbases, Los",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9450,
    "pob": "BaÑos De Valdearados",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9248,
    "pob": "BaÑuelos De Bureba",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9143,
    "pob": "BaÑuelos De Rudron",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9613,
    "pob": "Barbadillo Del Mercado",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9614,
    "pob": "Barbadillo Del Pez",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9566,
    "pob": "Barcenas De Espinosa",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9567,
    "pob": "Barcenillas De Cerezo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9212,
    "pob": "Barcina Del Barco",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9510,
    "pob": "Baro",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9226,
    "pob": "Barrio De MuÑo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9135,
    "pob": "Barrio De San Feciles",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 34815,
    "pob": "Barrio De San Vicente",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 9126,
    "pob": "Barrio Panizares",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9246,
    "pob": "Barrios De Bureba",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9124,
    "pob": "Barrios De Villadiego",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9553,
    "pob": "Barriosuso",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9618,
    "pob": "Barriosuso Del Val",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9347,
    "pob": "Bascones Del Agua",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9259,
    "pob": "BascuÑana",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9213,
    "pob": "BascuÑuelos",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9568,
    "pob": "Bedon",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9250,
    "pob": "Belorado",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9316,
    "pob": "Berlangas De Roa",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9245,
    "pob": "Berzosa De Bureba",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9314,
    "pob": "Boada De Roa",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9125,
    "pob": "Boada De Villadiego",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9490,
    "pob": "Brazacorta",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9198,
    "pob": "Brieva De Juarros",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9610,
    "pob": "Briongos De Cervera",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9240,
    "pob": "Briviesca",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9557,
    "pob": "Brizuela",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9247,
    "pob": "Buezo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9293,
    "pob": "Bugedo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9230,
    "pob": "Buniel",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9000,
    "pob": "Burgos (ver Callejero)",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9294,
    "pob": "Burgueta",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9573,
    "pob": "Busnela",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9244,
    "pob": "Busto De Bureba",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9612,
    "pob": "Cabezon De La Sierra",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9493,
    "pob": "Campillo De Aranda",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9555,
    "pob": "Campo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9650,
    "pob": "Campolara",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9692,
    "pob": "Canicosa De La Sierra",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9611,
    "pob": "Carazo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9193,
    "pob": "Carcedo De Burgos",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9192,
    "pob": "CardeÑuela Riopico",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9253,
    "pob": "Cascajares De Bureba",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9556,
    "pob": "Casillas",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9227,
    "pob": "Castellanos De Castro",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9512,
    "pob": "Castricciones",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9691,
    "pob": "Castrillo De La Reina",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9391,
    "pob": "Castrillo De La Vega",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9109,
    "pob": "Castrillo De Murcia",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9108,
    "pob": "Castrillo De Rio Pisuerga",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9348,
    "pob": "Castrillo De Solarana",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9110,
    "pob": "Castrojeriz",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9591,
    "pob": "Celada De La Torre",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9150,
    "pob": "Celadas, Las",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9140,
    "pob": "Celadilla Sotobrin",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9270,
    "pob": "Cerezo De Rio Tiron",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9228,
    "pob": "Ciadoncha",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9574,
    "pob": "Cidad De Valdeporres",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9349,
    "pob": "Cilleruelo De Abajo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9123,
    "pob": "Citores Del Paramo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9320,
    "pob": "Cogollos",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9620,
    "pob": "Cojobar",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9210,
    "pob": "Cormenzana",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9346,
    "pob": "Covarrubias",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9339,
    "pob": "Cristo De Villahizan",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9642,
    "pob": "Cubillejo De Lara",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9551,
    "pob": "Cubillo De Butron",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9352,
    "pob": "Cubillo Del Campo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9251,
    "pob": "Cubo De Bureba",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9319,
    "pob": "Cueva De Roa, La",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9641,
    "pob": "Cuevas De San Clemente",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9560,
    "pob": "Espinosa De Los Monteros",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9268,
    "pob": "Espinosa Del Monte",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9491,
    "pob": "Fresnillo De Las DueÑas",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9272,
    "pob": "Fresno De Rio Tiron",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9290,
    "pob": "Fresno De Rodilla",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9315,
    "pob": "Fuentecen",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9471,
    "pob": "Fuentelcesped",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9318,
    "pob": "Fuentelisendo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9461,
    "pob": "Fuentenebro",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9142,
    "pob": "Gredilla De Sedano",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9128,
    "pob": "Grijalba",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9443,
    "pob": "Gumiel Del Mercado",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9463,
    "pob": "Haza",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9221,
    "pob": "Herrera",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9454,
    "pob": "Hinojar Del Rey",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9351,
    "pob": "Hontoria De La Cantera",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9342,
    "pob": "Hontoria De Rio Franco",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9311,
    "pob": "Horra, La",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9430,
    "pob": "Huerta De Rey",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9191,
    "pob": "Hurones",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9653,
    "pob": "Isar",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9651,
    "pob": "Lara De Los Infantes",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9340,
    "pob": "Lerma",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 39232,
    "pob": "Linares De Bricia",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 9144,
    "pob": "Lorilla",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9390,
    "pob": "Madrigalejo Del Monte",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9317,
    "pob": "Mambrilla De Castrejon",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9646,
    "pob": "Mazueco De Lara",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9513,
    "pob": "Medianabella",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9500,
    "pob": "Medina De Pomar",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9100,
    "pob": "Melgar De Fernamental",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9460,
    "pob": "Milagros",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9252,
    "pob": "Miraveche",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9619,
    "pob": "Navas De Pinar",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9679,
    "pob": "Neila",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9159,
    "pob": "Nuez De Abajo, La",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9280,
    "pob": "Obarenes",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9530,
    "pob": "OÑa",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9654,
    "pob": "Palacios De Benaver",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9680,
    "pob": "Palacios De La Sierra",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9649,
    "pob": "Palazuelos De La Sierra",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9220,
    "pob": "Pampliega",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9354,
    "pob": "Pinilla Trasmonte",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9440,
    "pob": "Pinillos De Esgueva",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9214,
    "pob": "Portilla",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9260,
    "pob": "Pradoluengo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9233,
    "pob": "Presillas",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9197,
    "pob": "QuintanadueÑas",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9647,
    "pob": "Quintanalara",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9670,
    "pob": "Quintanar De La Sierra",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9271,
    "pob": "Quintanilla San Garcia",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9196,
    "pob": "Quintanilleja",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9130,
    "pob": "Rabe De Las Calzadas",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9693,
    "pob": "Regumiel De La Sierra",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9117,
    "pob": "Revilla Vallejera",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9300,
    "pob": "Roa",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9344,
    "pob": "Royuela De Riofranco",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9341,
    "pob": "Ruyales Del Agua",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9600,
    "pob": "Salas De Los Infantes",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9121,
    "pob": "San Llorente De La Vega",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9353,
    "pob": "Santa Maria Mercadillo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9617,
    "pob": "SantibaÑez Del Val",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9441,
    "pob": "Sotillo De La Ribera",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9540,
    "pob": "Tartales De Cilla",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9616,
    "pob": "Tejada",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 26212,
    "pob": "Ternero, El",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 9442,
    "pob": "Terradillos De Esgueva",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9645,
    "pob": "Torrelara",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9310,
    "pob": "Torresandino",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9312,
    "pob": "Tortoles De Esgueva",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9453,
    "pob": "Tubilla Del Lago",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9338,
    "pob": "Valles De Palenzuela",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9594,
    "pob": "Valmayor De Cuesta Urria",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9120,
    "pob": "Villadiego",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9257,
    "pob": "Villafranca Montes De Oca",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9343,
    "pob": "Villahoz",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9269,
    "pob": "Villamudria",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9652,
    "pob": "Villanueva De ArgaÑo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9118,
    "pob": "Villaquiran De Los Infantes",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9550,
    "pob": "Villarcayo",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9580,
    "pob": "Villasana De Mena",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9690,
    "pob": "Vilviestre Del Pinar",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 10748,
    "pob": "Abadia",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10262,
    "pob": "Abertura",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10857,
    "pob": "Acebo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10879,
    "pob": "Acehuche",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10666,
    "pob": "Aceituna",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10515,
    "pob": "AceÑa La Borrega",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10650,
    "pob": "Ahigal",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10690,
    "pob": "Alagon",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10187,
    "pob": "Albala",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10980,
    "pob": "Alcantara",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10135,
    "pob": "Alcollarin",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10160,
    "pob": "Alcuescar",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10163,
    "pob": "Aldea Del Cano",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10291,
    "pob": "Aldea Del Obispo, La",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10195,
    "pob": "Aldea Moret",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10251,
    "pob": "Aldeacentenera",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10440,
    "pob": "Aldeanueva De La Vera",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10740,
    "pob": "Aldeanueva Del Camino",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10638,
    "pob": "Aldehuela",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10671,
    "pob": "Aldehuela De Jerte",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10137,
    "pob": "Alia",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10550,
    "pob": "Aliseda",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10350,
    "pob": "Almaraz",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10132,
    "pob": "Almoharin",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10109,
    "pob": "Alonso De Ojeda",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10623,
    "pob": "Arrolobos",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10900,
    "pob": "Arroyo De La Luz",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10161,
    "pob": "Arroyomolinos",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10410,
    "pob": "Arroyomolinos De La Vera",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10628,
    "pob": "Asegur",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10810,
    "pob": "Atalaya",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10649,
    "pob": "Azabal",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10750,
    "pob": "BaÑos De Montemayor",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10318,
    "pob": "Barquilla De Pinares",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10696,
    "pob": "Barrado",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10692,
    "pob": "Batan, El",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10591,
    "pob": "Bazagona, La",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10292,
    "pob": "Belen",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10394,
    "pob": "Belvis De Monroy",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10185,
    "pob": "Benquerencia",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10392,
    "pob": "Berrocalejo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10129,
    "pob": "Berzocana",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10320,
    "pob": "Bohonal De Ibor",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10188,
    "pob": "Botija",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10661,
    "pob": "Bronco, El",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10950,
    "pob": "Brozas",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10373,
    "pob": "CabaÑas Del Castillo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10729,
    "pob": "Cabezabellosa",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10625,
    "pob": "Cabezo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10610,
    "pob": "Cabezuela Del Valle",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10616,
    "pob": "Cabrero",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10000,
    "pob": "Caceres (ver Callejero)",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10881,
    "pob": "Cachorrilla",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10865,
    "pob": "Cadalso",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10817,
    "pob": "Calzadilla",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10629,
    "pob": "Cambron",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10620,
    "pob": "Caminomorisco",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10329,
    "pob": "Campillo De Deleitosa",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10134,
    "pob": "Campo Lugar",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10136,
    "pob": "CaÑamero",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10820,
    "pob": "CaÑaveral",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10511,
    "pob": "Carbajo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10670,
    "pob": "Carcaboso",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10331,
    "pob": "Carrascalejo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10190,
    "pob": "Casar De Caceres",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10640,
    "pob": "Casar De Palomero",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10162,
    "pob": "Casas De Don Antonio",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10818,
    "pob": "Casas De Don Gomez",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10592,
    "pob": "Casas De Millan",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10360,
    "pob": "Casas De Miravete",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10730,
    "pob": "Casas Del Monte",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10520,
    "pob": "Casatejada",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10340,
    "pob": "CastaÑar De Ibor",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10870,
    "pob": "Ceclavin",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10513,
    "pob": "Cedillo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10319,
    "pob": "Centenillo, El",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10627,
    "pob": "Cerezal",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10663,
    "pob": "Cerezo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10895,
    "pob": "Cilleros",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10414,
    "pob": "Collado",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10240,
    "pob": "Conquista De La Sierra",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10800,
    "pob": "Coria",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10430,
    "pob": "Cuacos De Yuste",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10270,
    "pob": "Cumbre, La",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10370,
    "pob": "Deleitosa",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10866,
    "pob": "Descargamaria",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10891,
    "pob": "Eljas",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10133,
    "pob": "Escurial",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10990,
    "pob": "Estorninos",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10516,
    "pob": "FontaÑera, La",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10328,
    "pob": "Fresnedoso De Ibor",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10691,
    "pob": "Galisteo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10250,
    "pob": "Garciaz",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10412,
    "pob": "Garganta La Olla",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10759,
    "pob": "Garganta, La",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10749,
    "pob": "Gargantilla",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10940,
    "pob": "Garrovillas De Alconetar",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10333,
    "pob": "Garvin",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10860,
    "pob": "Gata",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10711,
    "pob": "Granja, La",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10829,
    "pob": "Grimaldo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10140,
    "pob": "Guadalupe",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10815,
    "pob": "Guijo De Coria",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10816,
    "pob": "Guijo De Galisteo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10665,
    "pob": "Guijo De Granadilla",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10459,
    "pob": "Guijo De Santa Barbara",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10697,
    "pob": "Haza De La Concepcion",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10230,
    "pob": "Herguijuela",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10868,
    "pob": "Hernan-perez",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10512,
    "pob": "Herrera De Alcantara",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10560,
    "pob": "Herreruela",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10700,
    "pob": "Hervas",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10359,
    "pob": "Higuera",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10192,
    "pob": "Hinojal",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10850,
    "pob": "Hoyos",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10849,
    "pob": "Huelaga",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10290,
    "pob": "Huertas De Animas",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10514,
    "pob": "Huertas De Cansa, Las",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10280,
    "pob": "Ibahernando",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10380,
    "pob": "Jaraicejo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10400,
    "pob": "Jaraiz De La Vera",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10450,
    "pob": "Jarandilla",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10728,
    "pob": "Jarilla",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10612,
    "pob": "Jerte",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10519,
    "pob": "Lanchuelas, Las",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10120,
    "pob": "Logrosan",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10460,
    "pob": "Losar De La Vera",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10480,
    "pob": "Madrigal De La Vera",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10110,
    "pob": "Madrigalejo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10210,
    "pob": "MadroÑera",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10529,
    "pob": "Majadas",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10910,
    "pob": "Malpartida De Caceres",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10680,
    "pob": "Malpartida De Plasencia",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10662,
    "pob": "Marchagaz",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10970,
    "pob": "Mata De Alcantara",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10580,
    "pob": "Membrio",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10624,
    "pob": "Mestas, Las",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10100,
    "pob": "Miajadas",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10540,
    "pob": "Mirabel",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10664,
    "pob": "Mohedas De Granadilla",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10590,
    "pob": "Monfrague",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10194,
    "pob": "Monroy",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10170,
    "pob": "Montanchez",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10840,
    "pob": "Moraleja",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10811,
    "pob": "Morcillo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10613,
    "pob": "Navaconcejo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10300,
    "pob": "Navalmoral De La Mata",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10341,
    "pob": "Navalvillar De Ibor",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10930,
    "pob": "Navas Del MadroÑo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10374,
    "pob": "Navezuelas",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10626,
    "pob": "NuÑomoral",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10667,
    "pob": "Oliva De Plasencia",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10639,
    "pob": "Ovejuela",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10220,
    "pob": "Pago De San Clemente",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10660,
    "pob": "Palomero",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10712,
    "pob": "Pantano De Gabriel Y Galan",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10411,
    "pob": "Pasaron De La Vera",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10630,
    "pob": "Pedro MuÑoz",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10335,
    "pob": "Peraleda De La Mata",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10334,
    "pob": "Peraleda De San Roman",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10896,
    "pob": "Perales Del Puerto",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10882,
    "pob": "Pescueza",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10991,
    "pob": "Piedras Albas",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10615,
    "pob": "Piornal",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10600,
    "pob": "Plasencia",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10271,
    "pob": "Plasenzuela",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10883,
    "pob": "Portaje",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10828,
    "pob": "Portezuelo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10813,
    "pob": "Pozuelo De Zarzon",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10261,
    "pob": "Puerto De Santa Cruz",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10617,
    "pob": "Rebollar",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10372,
    "pob": "Retamosa",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10199,
    "pob": "Rincon De Ballesteros",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10693,
    "pob": "Riolobos",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10867,
    "pob": "Robledillo De Gata",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10493,
    "pob": "Robledillo De La Vera",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10269,
    "pob": "Robledillo De Trujillo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10371,
    "pob": "Robledollano",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10391,
    "pob": "Rosalejo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10272,
    "pob": "Ruanes",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10570,
    "pob": "Salorino",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10189,
    "pob": "Salvatierra De Santiago",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10892,
    "pob": "San Martin De Trevejo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10260,
    "pob": "Santa Cruz De La Sierra",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10198,
    "pob": "Santa Marta De Magasca",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10510,
    "pob": "Santiago De Alcantara",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10191,
    "pob": "Santiago Del Campo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10859,
    "pob": "SantibaÑez El Alto",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10390,
    "pob": "Saucedilla",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10739,
    "pob": "Segura De Toro",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10530,
    "pob": "Serradilla",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10528,
    "pob": "Serrejon",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10181,
    "pob": "Sierra De Fuentes",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10126,
    "pob": "Solana De CabaÑas",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10193,
    "pob": "Talavan",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10491,
    "pob": "Talaveruela",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10310,
    "pob": "Talayuela",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10420,
    "pob": "Tejeda",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10521,
    "pob": "Toril",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10611,
    "pob": "Tornavacas",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10864,
    "pob": "Torre De Don Miguel",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10186,
    "pob": "Torre De Santa Maria",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10252,
    "pob": "Torrecilla De La Tiesa",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10869,
    "pob": "Torrecilla De Los Angeles",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10694,
    "pob": "Torrejon El Rubio",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10830,
    "pob": "Torrejoncillo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10413,
    "pob": "Torremenga",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10184,
    "pob": "Torremocha",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10182,
    "pob": "Torreorgaz",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10183,
    "pob": "Torrequemada",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10894,
    "pob": "Trevejo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10200,
    "pob": "Trujillo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10614,
    "pob": "Valdastillas",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10180,
    "pob": "Valdefuentes",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10393,
    "pob": "Valdehuncar",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10332,
    "pob": "Valdelacasa De Tajo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10131,
    "pob": "Valdemorales",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10839,
    "pob": "Valdencin",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10672,
    "pob": "Valdeobispo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10164,
    "pob": "Valdesalor",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10500,
    "pob": "Valencia De Alcantara",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10490,
    "pob": "Valverde De La Vera",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10890,
    "pob": "Valverde Del Fresno",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10317,
    "pob": "Vegas De Mesillas",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10848,
    "pob": "Vegaviana",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10492,
    "pob": "Viandar De La Vera",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10814,
    "pob": "Villa Del Campo",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10960,
    "pob": "Villa Del Rey",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10263,
    "pob": "Villamesias",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10893,
    "pob": "Villamiel",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10812,
    "pob": "Villanueva De La Sierra",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10470,
    "pob": "Villanueva De La Vera",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10720,
    "pob": "Villar De Plasencia",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10330,
    "pob": "Villar Del Pedroso",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10695,
    "pob": "Villarreal De San Carlos",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10858,
    "pob": "Villasbuenas De Gata",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10710,
    "pob": "Zarza De Granadilla",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10880,
    "pob": "Zarza La Mayor",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10130,
    "pob": "Zorita",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 11630,
    "pob": "Abiertas, Las",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11500,
    "pob": "Aguila, Del, Camino",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11391,
    "pob": "Alamillo, El",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11379,
    "pob": "Alamos, Los, Urbanizacion",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11170,
    "pob": "Alburejos, Los",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11316,
    "pob": "Alcaidesa, La",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11180,
    "pob": "Alcala De Los Gazules",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11693,
    "pob": "Alcala Del Valle",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11339,
    "pob": "Aldefilla",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11549,
    "pob": "Algaida, La",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11639,
    "pob": "Algar",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11000,
    "pob": "Algeciras (ver Callejero)",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11680,
    "pob": "Algodonales",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11393,
    "pob": "Almarchal, El",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11351,
    "pob": "Almoraima",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11400,
    "pob": "AÑina",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11688,
    "pob": "Arroyomolinos",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11179,
    "pob": "Badalejos, Los",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11160,
    "pob": "Barbate",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11570,
    "pob": "Barca De La Florida, La",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11158,
    "pob": "Barca De Vejer, La",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11510,
    "pob": "Barriada De Matagorda",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11518,
    "pob": "Barrio De Jarana",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11149,
    "pob": "Barrio Nuevo",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11370,
    "pob": "Barrios, Los",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11139,
    "pob": "Barrosa, La",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11138,
    "pob": "Bartivas",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11190,
    "pob": "Benalup-casas Viejas",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11679,
    "pob": "Benamahoma",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11612,
    "pob": "Benaocaz",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11520,
    "pob": "Bercial, El",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11540,
    "pob": "Bonanza",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11640,
    "pob": "Bornos",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11670,
    "pob": "Bosque, El",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11390,
    "pob": "Bugeo, El",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11405,
    "pob": "Camino De Espera",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11314,
    "pob": "Campamento",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11130,
    "pob": "Campano",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11120,
    "pob": "Campo Soto",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11610,
    "pob": "Campobuche",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11380,
    "pob": "CaÑada De La Jara",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11150,
    "pob": "CaÑal, El",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11159,
    "pob": "CaÑos De Meca, Los",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11369,
    "pob": "Carteya-carteya",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11350,
    "pob": "Castellar De La Frontera",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11550,
    "pob": "Chipiona",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11650,
    "pob": "Cierva, La",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11140,
    "pob": "Conil De La Frontera",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11519,
    "pob": "Consorcio Bahia De Cadiz",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11649,
    "pob": "Coto De Bornos",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11593,
    "pob": "Cuartillo De La Paz",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11620,
    "pob": "Drago, El",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11687,
    "pob": "Era De La ViÑa",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11648,
    "pob": "Espera",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11591,
    "pob": "Guadalcacin",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11311,
    "pob": "Guadiaro",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11595,
    "pob": "Ina, La",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11638,
    "pob": "Industria Quimicas Urumea",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11330,
    "pob": "Jimena De La Frontera",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11579,
    "pob": "Jose Antonio",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11300,
    "pob": "Linea De La Concepcion, La",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11590,
    "pob": "Mesas De Asta",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11592,
    "pob": "Mesas De Santiago",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11349,
    "pob": "Montenegral Alto",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11689,
    "pob": "Muela De Algodonales, La",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11690,
    "pob": "Olvera",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11178,
    "pob": "Paterna De Rivera",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11100,
    "pob": "Poligono Tres Caminos",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11408,
    "pob": "Portal, El",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11596,
    "pob": "Portalillo",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11659,
    "pob": "Pozo Amargo",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11660,
    "pob": "Prado Del Rey",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11313,
    "pob": "Puente Mayorga",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11530,
    "pob": "Rota Naval",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11340,
    "pob": "Sambana",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11312,
    "pob": "San Enrique De Guadiaro",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11110,
    "pob": "San Fernando Naval",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11594,
    "pob": "San Isidro De Guadalete",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11580,
    "pob": "San Jose Del Valle",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11320,
    "pob": "San Pablo De Buceite",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11360,
    "pob": "San Roque",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11368,
    "pob": "San Roque, Ferrea De, Estacion",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11315,
    "pob": "Santa Margarita",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11692,
    "pob": "Setenil De Las Bodegas",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11310,
    "pob": "Sotogrande",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11392,
    "pob": "Tahivilla",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11691,
    "pob": "Torre Alhaquime",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11560,
    "pob": "Trebujena",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11600,
    "pob": "Ubrique",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11611,
    "pob": "Villaluenga Del Rosario",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 12222,
    "pob": "Ain",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12140,
    "pob": "Albocasser",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12570,
    "pob": "Alcala De Xivert",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12110,
    "pob": "Alcora, L'",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12579,
    "pob": "Alcossebre",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12609,
    "pob": "Alfondeguilla",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12414,
    "pob": "Algimia De Almonacid",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12550,
    "pob": "Almassora/almazora",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12413,
    "pob": "Almedijar",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12590,
    "pob": "Almenara",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12448,
    "pob": "Alqueria, La",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12530,
    "pob": "Alquerias De Santa Barbara",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12539,
    "pob": "Alquerias Del NiÑo Perdido",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12410,
    "pob": "Altura",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12370,
    "pob": "Anroig",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12232,
    "pob": "AraÑuel",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12119,
    "pob": "Araya",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12165,
    "pob": "Ares Del Maestre",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12230,
    "pob": "Argelita",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12527,
    "pob": "Artana",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12220,
    "pob": "Artesa",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12430,
    "pob": "Artesa De Abajo",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12132,
    "pob": "Atzeneta Del Maestrat",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12513,
    "pob": "Avella, La (cati)",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12224,
    "pob": "Ayodar",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12490,
    "pob": "Azuebar",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12599,
    "pob": "Ballestar",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12193,
    "pob": "Barona, La",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12420,
    "pob": "Barracas",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12591,
    "pob": "Barrio Del Mar",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12512,
    "pob": "Bel",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12449,
    "pob": "Benafer",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12134,
    "pob": "Benafigos",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12160,
    "pob": "Benasal",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12580,
    "pob": "Benicarlo",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12560,
    "pob": "Benicasim",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12181,
    "pob": "Benlloch",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12549,
    "pob": "Betxi",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12124,
    "pob": "Bibioj",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12190,
    "pob": "Borriol",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12180,
    "pob": "Cabanes",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12589,
    "pob": "Calig",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12428,
    "pob": "Calpes, Los",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12469,
    "pob": "Canales, Los",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12350,
    "pob": "Canet Lo Roig",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12319,
    "pob": "Castell De Cabres",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12159,
    "pob": "Castellfort",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12000,
    "pob": "Castello/castellon De La Plana (ver Callejero)",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12123,
    "pob": "Castillo De Villamalefa",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12440,
    "pob": "Caudiel",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12578,
    "pob": "Cervera Del Maestre",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12592,
    "pob": "Chilches",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12499,
    "pob": "Chovar",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12318,
    "pob": "Cinctorres",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12231,
    "pob": "Cirat",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12182,
    "pob": "Collet",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12130,
    "pob": "Correntilla, De, Masia",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12127,
    "pob": "Cortes De Arenoso",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12185,
    "pob": "Coves De VinromÀ, Les",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12131,
    "pob": "Crevadas, Las",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12163,
    "pob": "Culla",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12528,
    "pob": "Eslida",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12122,
    "pob": "Figueroles",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12310,
    "pob": "Forcall",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12121,
    "pob": "Foya, La",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12225,
    "pob": "Fuentes De Ayodar",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12415,
    "pob": "Gaibiel",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12412,
    "pob": "Geldo",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12593,
    "pob": "Grao De Moncofar",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12100,
    "pob": "Grau De Castello, El/grao, El",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12317,
    "pob": "Herbes",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12316,
    "pob": "Hostal Nou",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12166,
    "pob": "Ibarzos, Los",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12340,
    "pob": "Jana, La",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12450,
    "pob": "Jerica",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12120,
    "pob": "Lucena Del Cid",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12529,
    "pob": "Mascarell",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12312,
    "pob": "Mata De Morella, La",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12133,
    "pob": "Meanes",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12194,
    "pob": "Montalva",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12447,
    "pob": "Montan",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12300,
    "pob": "Morella",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12470,
    "pob": "Navajas",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12520,
    "pob": "Nules",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12200,
    "pob": "Onda",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12594,
    "pob": "Oropesa/oropesa Del Mar",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12311,
    "pob": "Ortells",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12598,
    "pob": "PeÑiscola",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12429,
    "pob": "Pina De Montalgrao",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12313,
    "pob": "Pobla D'alcolea, La",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12191,
    "pob": "Pobla Tornesa, La",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12595,
    "pob": "Ribera De Cabanes, La",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12210,
    "pob": "Ribesalbes",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12164,
    "pob": "Rosildos, Los",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12511,
    "pob": "Rossell",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12186,
    "pob": "Salzadella, La",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12320,
    "pob": "San Jorge",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12510,
    "pob": "San Rafael Del Rio",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12126,
    "pob": "San Vicente De Cortes",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12170,
    "pob": "Sant Mateu",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12597,
    "pob": "Santa Magdalena De Pulpis",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12184,
    "pob": "Sarratella",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12400,
    "pob": "Segorbe",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12480,
    "pob": "Soneja",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12489,
    "pob": "Sot De Ferrer",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12223,
    "pob": "Suera/sueras",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12221,
    "pob": "Tales",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12179,
    "pob": "Tirig",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12431,
    "pob": "Toras",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12161,
    "pob": "Torre D'en Besora, La",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12596,
    "pob": "Torreblanca",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12330,
    "pob": "Traiguera",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12118,
    "pob": "Useres, Les/ Useras",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12600,
    "pob": "Vall D'uixo, La",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12315,
    "pob": "Vallibona",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12192,
    "pob": "Vilafames",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12183,
    "pob": "Vilanova D'alcolea",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12162,
    "pob": "Vilar De Canes",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12540,
    "pob": "Vila-real/villarreal",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12526,
    "pob": "Vilavella, La",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12150,
    "pob": "Villafranca Del Cid",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12500,
    "pob": "VinarÒs",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12135,
    "pob": "Vistabella Del Maestrazgo",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12460,
    "pob": "Viver",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12360,
    "pob": "Xert/chert",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12314,
    "pob": "Xiva De Morella",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12125,
    "pob": "Zucaina",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 13180,
    "pob": "Abenojar",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13410,
    "pob": "Agudo",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13379,
    "pob": "Alameda",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13690,
    "pob": "Alameda De Cervera",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13413,
    "pob": "Alamillo",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13340,
    "pob": "Albaladejo",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13600,
    "pob": "Alcazar De San Juan",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13116,
    "pob": "Alcoba De Los Montes",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13107,
    "pob": "Alcolea De Calatrava",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13113,
    "pob": "Alcornocal, El",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13391,
    "pob": "Alcubillas",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13380,
    "pob": "Aldea Del Rey",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13248,
    "pob": "Alhambra",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13400,
    "pob": "Almaden",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13480,
    "pob": "Almadenejos",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13270,
    "pob": "Almagro",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13328,
    "pob": "Almedina",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13580,
    "pob": "Almodovar Del Campo",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13760,
    "pob": "Almuradiel",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13117,
    "pob": "Anchuras",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13619,
    "pob": "Arenales De San Gregorio",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13679,
    "pob": "Arenas De San Juan",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13710,
    "pob": "Argamasilla De Alba",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13440,
    "pob": "Argamasilla De Calatrava",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13193,
    "pob": "Arroba De Los Montes",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13432,
    "pob": "Ballesteros De Calatrava",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13428,
    "pob": "Ballesteros, Los",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13597,
    "pob": "Barriada Rio Ojailen",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13739,
    "pob": "Bazan",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13129,
    "pob": "Betetas, Las",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13596,
    "pob": "Bienvenida",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13260,
    "pob": "BolaÑos De Calatrava",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13450,
    "pob": "Brazatortas",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13192,
    "pob": "Cabezarados",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13591,
    "pob": "Cabezarrubias Del Puerto",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13370,
    "pob": "Calzada De Calatrava",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13610,
    "pob": "Campo De Criptana",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13430,
    "pob": "CaÑada De Calatrava, La",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13331,
    "pob": "CaÑamares",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13433,
    "pob": "Caracuel De Calatrava",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13150,
    "pob": "Carrion De Calatrava",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13329,
    "pob": "Carrizosa",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13128,
    "pob": "Casas Del Rio",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13196,
    "pob": "Casas, Las",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13750,
    "pob": "Castellar De Santiago",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13412,
    "pob": "Chillon",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13720,
    "pob": "Cinco Casas, Estacion",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13310,
    "pob": "Consolacion, La",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13190,
    "pob": "Corral De Calatrava",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13427,
    "pob": "Cortijos De Abajo",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13345,
    "pob": "Cozar",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13429,
    "pob": "Cristo De Espiritu Santo",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13250,
    "pob": "Daimiel",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13140,
    "pob": "Fernan Caballero",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13473,
    "pob": "Fontanosas",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13130,
    "pob": "Fuencaliente",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13333,
    "pob": "Fuenllana",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13680,
    "pob": "Fuente El Fresno",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 14449,
    "pob": "Garganta, La",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 13414,
    "pob": "Gargantiel",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13360,
    "pob": "Granatula De Calatrava",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13490,
    "pob": "Guadalmez",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13640,
    "pob": "Herencia",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13200,
    "pob": "Herrera De La Mancha",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13590,
    "pob": "Hinojosas De Calatrava",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13110,
    "pob": "Horcajo De Los Montes",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13594,
    "pob": "Hoyo, El",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13118,
    "pob": "Huertas Del Sauceral, Las",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13779,
    "pob": "Huertezuelas",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13114,
    "pob": "Islas, Las",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13660,
    "pob": "Labores, Las",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13220,
    "pob": "Llanos Del Caudillo",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13108,
    "pob": "Luciana",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13420,
    "pob": "Malagon",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13230,
    "pob": "Membrilla",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13592,
    "pob": "Mestanza",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13170,
    "pob": "Miguelturra",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13194,
    "pob": "Molinillo, El",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13326,
    "pob": "Montiel",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13350,
    "pob": "Moral De Calatrava",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13189,
    "pob": "Navacerrada",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13620,
    "pob": "Pedro MuÑoz",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13100,
    "pob": "Piedrabuena",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13195,
    "pob": "Poblete",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13120,
    "pob": "Porzuna",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13390,
    "pob": "Pozo De La Serna",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13179,
    "pob": "Pozuelo De Calatrava",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13191,
    "pob": "Pozuelos De Calatrava, Los",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13109,
    "pob": "Puebla De Don Rodrigo",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13342,
    "pob": "Puebla Del Principe",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13650,
    "pob": "Puerto Lapice",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13500,
    "pob": "Puertollano",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13598,
    "pob": "Retamar",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13249,
    "pob": "Ruidera",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13415,
    "pob": "San Benito",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13247,
    "pob": "San Carlos Del Valle",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13327,
    "pob": "Santa Cruz De Los CaÑamos",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13730,
    "pob": "Santa Cruz De Mudela",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13115,
    "pob": "Santa Quiteria",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13630,
    "pob": "Socuellamos",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13593,
    "pob": "Solana Del Pino",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13240,
    "pob": "Solana, La",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13341,
    "pob": "Terrinches",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13700,
    "pob": "Tomelloso",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13160,
    "pob": "Torralba De Calatrava",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13344,
    "pob": "Torre De Juan Abad",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13740,
    "pob": "Torrenueva",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13470,
    "pob": "Valdeazogues",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13411,
    "pob": "Valdemanco Del Esteras",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13300,
    "pob": "ValdepeÑas",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13279,
    "pob": "Valenzuela De Calatrava",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13768,
    "pob": "Venta De Cardenas",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13459,
    "pob": "Veredas",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13332,
    "pob": "Villahermosa",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13343,
    "pob": "Villamanrique",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13595,
    "pob": "Villamayor De Calatrava",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13330,
    "pob": "Villanueva De La Fuente",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13320,
    "pob": "Villanueva De Los Infantes",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13431,
    "pob": "Villar Del Pozo",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13670,
    "pob": "Villarrubia De Los Ojos",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13210,
    "pob": "Villarta De San Juan",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13460,
    "pob": "ViÑuela",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13770,
    "pob": "Viso Del Marques",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 14005,
    "pob": "Abejorreras (cordoba)",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14430,
    "pob": "Adamuz",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14978,
    "pob": "Adelantado, El",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14740,
    "pob": "Aguila, El",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14920,
    "pob": "Aguilar De La Frontera",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14850,
    "pob": "Aladid",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14859,
    "pob": "Albendin",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14940,
    "pob": "Alcaidia, La",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14480,
    "pob": "Alcaracejos",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14610,
    "pob": "Alcolea, Nucleo",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14249,
    "pob": "Alcornocal",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14439,
    "pob": "Algallarin",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14811,
    "pob": "Algar",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14100,
    "pob": "Algarbes",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14730,
    "pob": "Aljabaras, Las",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14812,
    "pob": "Almedinilla",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14720,
    "pob": "Almodovar Del Rio",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14815,
    "pob": "Angosturas, Las",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14900,
    "pob": "Anjaron",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14450,
    "pob": "AÑora",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14010,
    "pob": "Arenal, El (cordoba)",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14029,
    "pob": "Arenales, Los",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14298,
    "pob": "Argallon",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14191,
    "pob": "Arrecife, El",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14700,
    "pob": "Arriel",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14979,
    "pob": "Arroyo De Priego",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14950,
    "pob": "Arroyo Tijeras",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14820,
    "pob": "Atalayuela",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14447,
    "pob": "Azuel",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14220,
    "pob": "Ballesta, La",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14710,
    "pob": "Barquera, La (villarrubia)",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14709,
    "pob": "Barrio Parque",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14130,
    "pob": "Barrio San Vicente (guadalcazar)",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14280,
    "pob": "Belalcazar",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14240,
    "pob": "Belmez",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14910,
    "pob": "Benameji",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14810,
    "pob": "Bernabe",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14208,
    "pob": "Blazquez, Los",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14512,
    "pob": "Bocas De Riguelo",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14813,
    "pob": "Bracana",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14330,
    "pob": "Brimz (centro Militar)",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14650,
    "pob": "Bujalance",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14011,
    "pob": "Caballera, De La (santa Maria De Trassierra)",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14600,
    "pob": "CampiÑa",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14014,
    "pob": "CampiÑuela Baja (cordoba)",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14192,
    "pob": "Campo Alegre",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14814,
    "pob": "Campo Nubes",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14299,
    "pob": "CaÑada Del Gamo",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14113,
    "pob": "CaÑada Del Rabadan",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14660,
    "pob": "CaÑete De Las Torres",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14550,
    "pob": "Carchena",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14445,
    "pob": "CardeÑa",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14620,
    "pob": "Carpio, El",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14749,
    "pob": "Carrascal, El (palma Del Rio)",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14817,
    "pob": "Castellar, El",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14840,
    "pob": "Castro Del Rio",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14210,
    "pob": "Cerro Miguelito",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14350,
    "pob": "Cerro Muriano, Nucleo",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14640,
    "pob": "Charco Del Novillo",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14111,
    "pob": "Chica Carlota",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14970,
    "pob": "Cierzos Y Cabreras",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14511,
    "pob": "Colina De La Virgen",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14816,
    "pob": "Concepcion, La",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14448,
    "pob": "Conquista",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14000,
    "pob": "Cordoba (ver Callejero)",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14500,
    "pob": "Cordobilla",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14193,
    "pob": "Cortijo El Rubio",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14297,
    "pob": "Cuenca",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14860,
    "pob": "DoÑa Mencia",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14209,
    "pob": "DoÑa Rama",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14460,
    "pob": "Dos Torres",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14711,
    "pob": "Encinarejo De Cordoba, Nucleo",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14913,
    "pob": "Encinas Reales",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14830,
    "pob": "Espejo",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14880,
    "pob": "Estacion, La (luque)",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14520,
    "pob": "Fernan-nuÑez",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14547,
    "pob": "Fontanar, El",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14110,
    "pob": "Fuente Carreteros",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14960,
    "pob": "Fuente De Las CaÑas",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14260,
    "pob": "Fuente La Lancha",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14290,
    "pob": "Fuente Obejuna",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14120,
    "pob": "Fuente Palmera",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14207,
    "pob": "Granjuela, La",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14413,
    "pob": "Guijo, El",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14129,
    "pob": "Herreria, La",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14270,
    "pob": "Hinojosa Del Duque",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14012,
    "pob": "Hospital Los Morales (cordoba)",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14549,
    "pob": "Huertas Bocas Del Salado",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14911,
    "pob": "Huertas Duque",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14800,
    "pob": "Jaula",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14858,
    "pob": "Llano Del Espinar",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14729,
    "pob": "Llanos, Los (almodovar Del Rio)",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14548,
    "pob": "Montalban De Cordoba",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14530,
    "pob": "Montemayor",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14930,
    "pob": "Monturque",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14659,
    "pob": "Morente",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14510,
    "pob": "Moriles",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14857,
    "pob": "Nueva Carteya",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14310,
    "pob": "Obejo",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14320,
    "pob": "Obejo, De, Estacion",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14914,
    "pob": "Palenciana",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14630,
    "pob": "Pedro Abad",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14412,
    "pob": "Pedroche",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14200,
    "pob": "PeÑarroya-pueblonuevo",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14400,
    "pob": "Pozoblanco",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14190,
    "pob": "Puente Viejo",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14540,
    "pob": "Rambla, La",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14739,
    "pob": "Rivero De Posadas",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14150,
    "pob": "San Sebastian De Ballesteros",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14491,
    "pob": "Santa Eufemia",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14546,
    "pob": "Santaella",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14915,
    "pob": "Tejar, El",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14410,
    "pob": "Torrecampo",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14912,
    "pob": "Vadofresno",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14670,
    "pob": "Valenzuela",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14206,
    "pob": "Valsequillo",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14446,
    "pob": "Venta Del Charco",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14112,
    "pob": "Ventilla, La",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14719,
    "pob": "Veredon De Los Frailes",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14140,
    "pob": "Victoria, La",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14420,
    "pob": "Villafranca De Cordoba",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14440,
    "pob": "Villanueva De Cordoba",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14250,
    "pob": "Villanueva Del Duque",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14230,
    "pob": "Villanueva Del Rey",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14115,
    "pob": "Villar, El",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14490,
    "pob": "Villaralto",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14300,
    "pob": "Villaviciosa De Cordoba",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14470,
    "pob": "Viso, El",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14870,
    "pob": "Zuheros",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 15177,
    "pob": "Aba (santa Maria De Dexo-oleiros)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15563,
    "pob": "Abade (santiago)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15938,
    "pob": "Abanqueiro",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15809,
    "pob": "Abeancos (san Salvador)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15318,
    "pob": "Abegondo",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15178,
    "pob": "Abeleiras De Abaixo",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15685,
    "pob": "Abella (santo Estevo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15290,
    "pob": "Abelleira (san Estevo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15979,
    "pob": "Abuin (san Pedro De BaroÑa-porto Do  Son)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15928,
    "pob": "Abuin (santa Maria De Leiro-rianxo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15174,
    "pob": "Acea Da Ma (fonteculler)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15670,
    "pob": "Acea De Ama (o Burgo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15259,
    "pob": "Acea, A (serres)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15314,
    "pob": "Adragonte (santiago)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15186,
    "pob": "Adro (san MartiÑo De Rodis-cerceda)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15820,
    "pob": "Aeropuerto De A Lavacolla",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15180,
    "pob": "Aeropuerto De Alvedro",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15883,
    "pob": "Agoso (santa Baia De Oza-teo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15892,
    "pob": "Agra Dos Campos San MartiÑo De Arins-santiago)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15995,
    "pob": "Agra, A (santa MariÑa De XuÑo-porto Do Son)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15578,
    "pob": "Agrande (santa Maria De Castro-naron)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15572,
    "pob": "Agras (nosa SeÑora Dos Desamparados De Xubia-naron)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15292,
    "pob": "Agrobello (santa Maria De Lira-carnota)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15864,
    "pob": "Agron (san Lourenzo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15910,
    "pob": "Agronovo (santiago De Padron-padron)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15148,
    "pob": "Agualada (san Lourenzo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15965,
    "pob": "AguiÑo",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15181,
    "pob": "Aian",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15686,
    "pob": "Aiazo (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15258,
    "pob": "Albores (san Mamede)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15594,
    "pob": "Aldea (pª San MartiÑo De Covas)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15939,
    "pob": "Aldea De Arriba (santa Maria De Castro-boiro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15165,
    "pob": "Aldea De Arriba, A (santa Marta De Babio-bergondo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15220,
    "pob": "Aldea Nova",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15114,
    "pob": "Aldea, A (corme)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15108,
    "pob": "Aldemunde (santa Maria Madanela)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15893,
    "pob": "Aldrei (santa Maria De Marrozos-santiago)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15121,
    "pob": "Allo (san Pedro De Ponte Do Porto,a-camelle",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15851,
    "pob": "Allo, O (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15846,
    "pob": "Alon (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15280,
    "pob": "Alqueidon",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15315,
    "pob": "Alto De Xestoso, O (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15570,
    "pob": "Alto Del CastaÑo (san Xose Obreiro De Xubia-naron)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15838,
    "pob": "Alvite (san Tome)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15887,
    "pob": "Amarelle (santa Maria De Gonzar-o Pino)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15319,
    "pob": "Ambroa (san Tiso)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15870,
    "pob": "Ameixenda (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15298,
    "pob": "Ameixenda, A (santiago)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15966,
    "pob": "Ameixida, A",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15500,
    "pob": "Ameneiral, O (perlio)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15866,
    "pob": "Ameneiro (san Xoan De Calo-teo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15229,
    "pob": "Ames (san Tome)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15168,
    "pob": "Amexeiral (san Xian De Mondego-sada)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15218,
    "pob": "Amoreira, A( San MartiÑo De MiÑortos-porto Do Son)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15577,
    "pob": "Anca (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15816,
    "pob": "Andavao (san MartiÑo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15824,
    "pob": "Andeade (santiago)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15669,
    "pob": "Andeiro (san MartiÑo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15684,
    "pob": "Andoio (san Mamede)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15614,
    "pob": "Andrade (san MartiÑo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15405,
    "pob": "Aneiros (serantes, S. Salvador)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15980,
    "pob": "Angueira De Suso (santa Maria De Cruces-padron)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15948,
    "pob": "Angustia, A (posmarcos)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15110,
    "pob": "Anllons (san Fins)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15149,
    "pob": "Anos (santo Estevo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15151,
    "pob": "Ansean (san Pedro De Buxantes-dumbria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15688,
    "pob": "Anxeles, Os (san Mamede)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15327,
    "pob": "Aparral, O (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15118,
    "pob": "Aprazaduiro, O (san Simon De Nande-laxe)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15874,
    "pob": "Arabexo (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15317,
    "pob": "Aranga",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15845,
    "pob": "Aranton (san Vicente)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15984,
    "pob": "AraÑo, O (santa Baia)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15821,
    "pob": "Arca (santa Eulalia)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15818,
    "pob": "Arceo",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15109,
    "pob": "ArdaÑa (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15881,
    "pob": "Ardesende",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15949,
    "pob": "Areos (santa Maria De Xobre, O Ou MaÑo-pobra Do CaramiÑal, A)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15624,
    "pob": "Ares",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15213,
    "pob": "Argalo (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15520,
    "pob": "Armada, A (maniÑos)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15316,
    "pob": "Armea (san Vicente)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15807,
    "pob": "Armental (san MartiÑo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15144,
    "pob": "Armenton (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15166,
    "pob": "ArmuÑo (san Xoan De Lubre-bergondo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15107,
    "pob": "Arnados",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15142,
    "pob": "Arteixo (casco Urbano)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15969,
    "pob": "Artes",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15839,
    "pob": "Arzon (san Cristovo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15810,
    "pob": "Arzua",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15819,
    "pob": "Arzua (santa Maria )",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15113,
    "pob": "Asalo (santiago De Mens-malpica De BergantiÑos)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15238,
    "pob": "Asenso",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15115,
    "pob": "Aspera (santo Estevo De Cesullas-cabana De BergantiÑos)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15913,
    "pob": "Augasantas (san Vicente)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15885,
    "pob": "Avenida Do Mestre Manuel Gomez Lorenzo",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15127,
    "pob": "BaiÑas",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15150,
    "pob": "Baio (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15185,
    "pob": "Baixa, A (cerceda)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15689,
    "pob": "Balado (santa Maria De Ordes-ordes)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15640,
    "pob": "Baldomir (guisamo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15541,
    "pob": "Baltar (santa Maria A Maior De Val.o-naron)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15823,
    "pob": "Bama (san Vicenzo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15886,
    "pob": "Bamonde (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15890,
    "pob": "Bando (santa Eulalia)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15388,
    "pob": "Bandoxa (san MartiÑo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15237,
    "pob": "Banzas (santo Ourente De Santo Ourente De Entins-outes)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15863,
    "pob": "BaÑa, A",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15639,
    "pob": "BaÑobre",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15528,
    "pob": "Barallobre (santiago)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15808,
    "pob": "Barazon (santa Maria) Santiso",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15837,
    "pob": "Barbeira (san MartiÑo De Fontecada-santa Comba)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15332,
    "pob": "Barbos (san Xulian)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15660,
    "pob": "Barcala, A (cambre)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15897,
    "pob": "Barcia, A (san MartiÑo De LaraÑo-santiago)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15562,
    "pob": "Bardaos (santa Maria-san SadurniÑo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15124,
    "pob": "Bardullas (san Xoan)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15337,
    "pob": "Bares",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15569,
    "pob": "Barqueira, A ( Santo Antonio)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15210,
    "pob": "BarquiÑa, A (barro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15920,
    "pob": "Barral (santa Comba De Rianxo-rianxo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15145,
    "pob": "Barreira (santa Maria De Toras-laracha)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15805,
    "pob": "Barreiro, O (san Mamede) Melide",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15871,
    "pob": "Bascuas (san MartiÑo De Coucieiro-val Do Dubra)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15994,
    "pob": "BasoÑas (san Pedro De Muro-porto Do Son)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15176,
    "pob": "Batan, O (san Pedro De Nos-oleiros)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15847,
    "pob": "Bazar (san Mamede)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15138,
    "pob": "Bazarra (santo Adran De Toba-cee)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15990,
    "pob": "Bealo (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15683,
    "pob": "Bedrobe (san Xian De Cabaleiros-tordoia)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15183,
    "pob": "Beira (santa MariÑa)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15635,
    "pob": "Bemantes (santo Tome)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15873,
    "pob": "Bembibre",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15822,
    "pob": "BendaÑa (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15287,
    "pob": "Bendimon (san Xoan De Roo-outes)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15999,
    "pob": "Beneso (san SadurniÑo De Goians-porto Do Son)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15895,
    "pob": "Bentin (santa Maria De Biduido-ames)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15128,
    "pob": "Berdoias (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15329,
    "pob": "Bermui (santiago)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15687,
    "pob": "Berreo (san Mamede)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15300,
    "pob": "Betanzos",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15982,
    "pob": "Bexo",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15008,
    "pob": "Birloque, O",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15848,
    "pob": "BoaÑa De Arriba (san Pedro De Xallas De Castriz-santa Comba)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15608,
    "pob": "Boebre",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15607,
    "pob": "Boebre (santiago)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15199,
    "pob": "Boedo (san Silvestre De Veiga-culleredo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15230,
    "pob": "Boel (san Pedro De Outes-outes)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15826,
    "pob": "Boente (santiago)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15817,
    "pob": "Boimil (san Miguel)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15930,
    "pob": "Boiro",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15119,
    "pob": "Borneiro (san Xoan)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15390,
    "pob": "Borrifans (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15865,
    "pob": "Boullon (san Miguel)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15859,
    "pob": "Brandomil (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15147,
    "pob": "BraÑa (san Salvador De Erbecedo-coristanco)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15146,
    "pob": "BraÑa Grande (san Roman De CabovilaÑo-laracha)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15288,
    "pob": "BraÑo (sabardes)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15102,
    "pob": "Brea, A (carballo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15299,
    "pob": "Brens (santa Baia)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15659,
    "pob": "Brexo (san Paio)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15590,
    "pob": "Brion (ferrol)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15591,
    "pob": "Brion (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15551,
    "pob": "BroÑo (santiago De Lago-valdoviÑo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15656,
    "pob": "Budian (santa Maria De Gandara-zas)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15111,
    "pob": "BuÑo (buÑo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15985,
    "pob": "Burato, O",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15595,
    "pob": "Bustelo (mandia-ferrol)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15884,
    "pob": "Busto (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15860,
    "pob": "Busto, O (santa SabiÑa)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15912,
    "pob": "Buxan (san Xoan)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15996,
    "pob": "CaamaÑo",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15623,
    "pob": "Caamouco (san Vicente)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15613,
    "pob": "Caaveiro (san Boulo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15621,
    "pob": "Cabanas",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15125,
    "pob": "Caberta (san Fins)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15609,
    "pob": "Cabria Nova (pontedeume)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15105,
    "pob": "Caion (santa Maria Do Socorro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15627,
    "pob": "Cal, O (limodre)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15295,
    "pob": "Caldebarcos",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15281,
    "pob": "Calle, A",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15389,
    "pob": "Callobre (santa Maria De CuiÑa-oza Dos Rios)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15129,
    "pob": "Calo (san Xoan-vimianzo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15540,
    "pob": "Calvario (san Mateo De Trasancos-naron)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15828,
    "pob": "Calvos De SocamiÑo (san MartiÑo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15123,
    "pob": "CamariÑas",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15010,
    "pob": "CamiÑo De Penamoa",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15171,
    "pob": "Campamento, O",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15250,
    "pob": "Campo Das Cortes",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15690,
    "pob": "Campo De Golf (moras-arteixo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15978,
    "pob": "Campo Do Prado, O (santa Maria De Nebra-porto Do Son)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15882,
    "pob": "Camporrapado",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15286,
    "pob": "Cando (san Tirso)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15116,
    "pob": "Canduas (san MartiÑo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15550,
    "pob": "Cantodomuro, O (san Vicente De Meiras-valdoviÑo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15175,
    "pob": "CaÑas (santa Baia)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15806,
    "pob": "Capela, A (santa Maria) Toques",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15896,
    "pob": "Carballal, O (santa Maria De Villestro-santiago)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15358,
    "pob": "Carballeira (regoa)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15100,
    "pob": "Carballo",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15914,
    "pob": "Carcacia (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15814,
    "pob": "Carelle (san Lourenzo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15339,
    "pob": "Caridad, A (san Xulian De Celtigos-ortigueira)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15360,
    "pob": "CariÑo",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15169,
    "pob": "Carnoedo (santo Andre)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15293,
    "pob": "Carnota",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15967,
    "pob": "Carreira (carreira-ribeira)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15855,
    "pob": "Carreira (santiago)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15391,
    "pob": "Carres (san Vicenzo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15009,
    "pob": "Casanova De Eiris",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15680,
    "pob": "Casillas (santa Maria De Ordes-ordes)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15160,
    "pob": "Castelo (osedo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15139,
    "pob": "Castelo (santiago)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15898,
    "pob": "CastiÑeiriÑo, O (nosa SeÑora De Fatima)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15650,
    "pob": "Cecebre (san Salvador)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15350,
    "pob": "Cedeira",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15357,
    "pob": "Cedeira (santa Maria Do Mar)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15270,
    "pob": "Cee",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15198,
    "pob": "Celas (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15365,
    "pob": "Cerca, A Santa Maria De A Pedra-cariÑo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15530,
    "pob": "Cerdido",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15112,
    "pob": "Cerqueda (san Cristovo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15625,
    "pob": "Cervas (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15347,
    "pob": "Cervo (santalla)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15622,
    "pob": "Chao Da Aldea (san MartiÑo De San MartiÑo De  Porto-cabanas",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15214,
    "pob": "Chave (lousame)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15179,
    "pob": "Choupana (santaia De Lians-oleiros)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15313,
    "pob": "Churio (san Martin)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15815,
    "pob": "Ciadella, A (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15899,
    "pob": "Conxo (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15130,
    "pob": "Corcubion",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15285,
    "pob": "Cornada (brion)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15911,
    "pob": "Cornes (san Mamede De Rois-rois)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15560,
    "pob": "Cornide (santa Maria De San SadurniÑo-san SadurniÑo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15510,
    "pob": "Coto (santa Maria De Neda-neda)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15825,
    "pob": "Couto PequeÑo (santo Estevo De Medin-o Pino)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15173,
    "pob": "Cova, A (santa Maria De Oleiros-oleiros)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15240,
    "pob": "Creo",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15122,
    "pob": "Cruceiro, O (xaviÑa-camariÑas)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15968,
    "pob": "CubeliÑo (santa Uxia De Ribeira-ribeira)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15256,
    "pob": "CuiÑa (san Fins De San Fins De Eiron-mazaricos",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15189,
    "pob": "Culleredo",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15937,
    "pob": "Cures (santo Andre)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15310,
    "pob": "Curtis",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15326,
    "pob": "Deveso, O (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15981,
    "pob": "Dodro",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15593,
    "pob": "DoniÑos (san Roman)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15615,
    "pob": "DoroÑa (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15597,
    "pob": "Doso (san Lourenzo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15154,
    "pob": "Duio (san Vicenzo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15566,
    "pob": "Enchousas, As (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15187,
    "pob": "Encrobas, As (san Roman)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15236,
    "pob": "Entins (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15282,
    "pob": "Ermedelo (san MartiÑo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15862,
    "pob": "Ermida, A (san Salvador)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15338,
    "pob": "Ermo, O (san Xulian)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15992,
    "pob": "Escarabote",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15582,
    "pob": "EspiÑaredo (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15568,
    "pob": "Estacion Oroso (san MartiÑo De Oroso-oroso)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15555,
    "pob": "Esteiro (san Fiz)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15297,
    "pob": "Ezaro, O",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15325,
    "pob": "Faeira, A (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15190,
    "pob": "Feans (elviÑa-coruÑa, A)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15366,
    "pob": "Feas (san Pedro-cariÑo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15598,
    "pob": "Ferreira (san Paio)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15155,
    "pob": "Fisterra",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15379,
    "pob": "Fisteus (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15668,
    "pob": "Fontenla, A (pravio, Cambre), Lugar",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15626,
    "pob": "Franza",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15215,
    "pob": "Fruime (san MartiÑo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15880,
    "pob": "Galegos",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15167,
    "pob": "Gandario",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15106,
    "pob": "Goians (santo Estevo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15638,
    "pob": "Goimil (san Cristobal)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15182,
    "pob": "Gosende (san MartiÑo De Tabeaio-carral)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15616,
    "pob": "Grandal (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15679,
    "pob": "Graxal, O",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15915,
    "pob": "Herbon",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15565,
    "pob": "Iglesia, A  Santiago Sere De Somozas, As-somozas, As",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15580,
    "pob": "Igrexafeita (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15872,
    "pob": "Insua (santa MariÑa De San Roman-val Do Dubra)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15959,
    "pob": "Insuela (san Pedro De Palmeira-ribeira)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15917,
    "pob": "Iria Flavia",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15611,
    "pob": "Iris (santo Estevo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15983,
    "pob": "Isorna (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15564,
    "pob": "Labacengos (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15857,
    "pob": "Langueiron (san MartiÑo De Meanos-zas)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15612,
    "pob": "Laraxe (san Mamede)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15117,
    "pob": "Laxe",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15126,
    "pob": "Leis De Nemancos (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15212,
    "pob": "Lesende (san MartiÑo De Lesende-lousame)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15542,
    "pob": "Loira (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15856,
    "pob": "LoroÑo (santiago)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15291,
    "pob": "Louro",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15509,
    "pob": "Magalofes (san Xurxo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15841,
    "pob": "Mallon (san Cristovo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15960,
    "pob": "Martin (ribeira)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15239,
    "pob": "Matasueiro (san Lourenzo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15188,
    "pob": "Meirama",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15800,
    "pob": "Melide",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15340,
    "pob": "Mera De Abaixo (santiago)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15349,
    "pob": "Mera De Arriba (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15216,
    "pob": "Merelle (tallara-lousame)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15630,
    "pob": "MiÑo",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15619,
    "pob": "Monfero (san Felix)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15359,
    "pob": "Montoxo (san Xulian)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15620,
    "pob": "Mugardos",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15858,
    "pob": "MuiÑo (san Tirso)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15993,
    "pob": "MuiÑos (san MartiÑo De Oleiros-ribeira)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15576,
    "pob": "Naraio (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15830,
    "pob": "Negreira",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15991,
    "pob": "Nine",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15200,
    "pob": "Noia",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15330,
    "pob": "Ortigueira",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15141,
    "pob": "Oseiro (arteixo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15380,
    "pob": "Oza Dos Rios",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15900,
    "pob": "Padron",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15553,
    "pob": "Pantin (santiago)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15894,
    "pob": "Parque Montouto",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15140,
    "pob": "Pastoriza (arteixo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15172,
    "pob": "Pazo Do Rio (santaia De Lians-oleiros)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15296,
    "pob": "Pindo, O",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15596,
    "pob": "Placente (santo Estevo De Sedes-naron)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15940,
    "pob": "Pobra Do CaramiÑal",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15143,
    "pob": "Poligono Industrial Sabon",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15573,
    "pob": "Poligono Rio Do Pozo",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15211,
    "pob": "Ponte Nafonso, A (roo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15916,
    "pob": "Ponte Vilar",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15600,
    "pob": "Pontedeume",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15320,
    "pob": "Pontes De Garcia Rodriguez, As",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15813,
    "pob": "Porta, A (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15970,
    "pob": "Porto Do Son",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15617,
    "pob": "Prada (monfero)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15998,
    "pob": "Queiruga (porto Do Son)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15840,
    "pob": "Randufe (san Pedro De Santa Comba-santa Comba)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15480,
    "pob": "Recinto Ferial Ferrol (fimo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15137,
    "pob": "Redonda (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15387,
    "pob": "Regueira, A (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15386,
    "pob": "Rodeiro (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15579,
    "pob": "Roxal, O",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15328,
    "pob": "Saa (santa Maria De Vilavella-as Pontes De Garcia Rodriguez)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15152,
    "pob": "Salgueiros (san Mamede)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15368,
    "pob": "San Claudio (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15592,
    "pob": "San Xurxo Da Marina (san Xurxo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15561,
    "pob": "Santa MariÑa Do Monte (santa MariÑa)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15153,
    "pob": "SardiÑeiro (san Xoan)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15567,
    "pob": "Seixas (santa Maria)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15324,
    "pob": "Seoane (san Xoan)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15861,
    "pob": "Ser (san Pedro)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15888,
    "pob": "SigÜeiro (barciela))",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15369,
    "pob": "Sismundi (santo Estevo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15184,
    "pob": "Sumio (santiago)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15552,
    "pob": "ValdoviÑo",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15367,
    "pob": "Veiga (ortigueira)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15543,
    "pob": "Vilaboa (san Vicente)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15637,
    "pob": "Vilarmaior",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15554,
    "pob": "Vilarrube (san MartiÑo)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15850,
    "pob": "Zas (santo Andre)",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 16195,
    "pob": "Abia De Obispalia",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16412,
    "pob": "Acebron, El",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16214,
    "pob": "Alarcon",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16111,
    "pob": "Albaladejo Del Cuende",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16841,
    "pob": "Albalate De Las Nogueras",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16812,
    "pob": "Albendea",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16620,
    "pob": "Alberca De Zancara, La",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16290,
    "pob": "Alcahozo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16315,
    "pob": "Alcala De La Vega",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16464,
    "pob": "Alcazar Del Rey",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16537,
    "pob": "Alcohujate",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16433,
    "pob": "Alconchel De La Estrella",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16338,
    "pob": "Algarra",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16313,
    "pob": "Aliaguilla",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16740,
    "pob": "Almarcha, La",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16420,
    "pob": "Almendros",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16215,
    "pob": "Almodovar Del Pinar",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16431,
    "pob": "Almonacid Del Marquesado",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16780,
    "pob": "Altarejos",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16123,
    "pob": "Arcas",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16191,
    "pob": "Arcos De La Cantera",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16141,
    "pob": "Arcos De La Sierra",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16360,
    "pob": "Arguisuelas",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16855,
    "pob": "Arrancacepas",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16710,
    "pob": "Atalaya Del CaÑavate",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16460,
    "pob": "Barajas De Melo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16196,
    "pob": "Barbalimpia",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16118,
    "pob": "Barchin Del Hoyo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16152,
    "pob": "Beamud",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16470,
    "pob": "Belinchon",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16640,
    "pob": "Belmonte",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16779,
    "pob": "Belmontejo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16870,
    "pob": "Beteta",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16843,
    "pob": "Bolliga",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16311,
    "pob": "Boniches",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16540,
    "pob": "Bonilla",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16851,
    "pob": "Buciegas",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16114,
    "pob": "Buenache De Alarcon",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16192,
    "pob": "Buenache De La Sierra",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16512,
    "pob": "Buendia",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16210,
    "pob": "Campillo De Altobuey",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16316,
    "pob": "Campillos-sierra",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16857,
    "pob": "Canalejas Del Arroyo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16340,
    "pob": "CaÑada Del Hoyo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16720,
    "pob": "CaÑada Juncosa",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16890,
    "pob": "CaÑamares",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16738,
    "pob": "CaÑavate, El",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16850,
    "pob": "CaÑaveras",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16300,
    "pob": "CaÑete",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16891,
    "pob": "CaÑizares",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16350,
    "pob": "Carboneras De Guadazaon",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16373,
    "pob": "Cardenete",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16879,
    "pob": "Carrascosa",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16649,
    "pob": "Carrascosa De Haro",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16555,
    "pob": "Carrascosa Del Campo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16707,
    "pob": "Casas De Benitez",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16610,
    "pob": "Casas De Fernando Alonso",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16708,
    "pob": "Casas De Guijarro",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16611,
    "pob": "Casas De Haro",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16612,
    "pob": "Casas De Los Pinos",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16234,
    "pob": "Casas De Santa Cruz",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16238,
    "pob": "Casas Del Olmo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16318,
    "pob": "Casas Nuevas",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16239,
    "pob": "Casasimarro",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16321,
    "pob": "Casillas De Ranera",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16856,
    "pob": "Castejon",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16122,
    "pob": "Castellar, El",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16250,
    "pob": "Castillejo De Iniesta",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16541,
    "pob": "Castillejo Del Romeral",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16623,
    "pob": "Castillo De GarcimuÑoz",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16854,
    "pob": "Castillo-albaraÑez",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16444,
    "pob": "Cervera Del Llano",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16190,
    "pob": "Chillaron De Cuenca",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16216,
    "pob": "Chumillas",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16143,
    "pob": "Collados",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16194,
    "pob": "Colliga",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16771,
    "pob": "Congosto, El",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16000,
    "pob": "Cuenca (ver Callejero)",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16542,
    "pob": "Cuevas De Velasco",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16372,
    "pob": "Enguidanos",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16781,
    "pob": "Fresneda De Altarejos",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16144,
    "pob": "Frontera, La",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16411,
    "pob": "Fuente De Pedro Naharro",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16647,
    "pob": "Fuentelespino De Haro",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16193,
    "pob": "Fuentes",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16312,
    "pob": "Garaballa",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16510,
    "pob": "Garcinarro",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16532,
    "pob": "GascueÑa",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16339,
    "pob": "Graja De Campalbo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16251,
    "pob": "Graja De Iniesta",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16435,
    "pob": "Hinojosa, La",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16417,
    "pob": "Hinojosos, Los",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16441,
    "pob": "Hito, El",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16730,
    "pob": "Honrubia",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16421,
    "pob": "Hontanaya",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16162,
    "pob": "Horcajada De La Torre",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16410,
    "pob": "Horcajo De Santiago",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16465,
    "pob": "Huelves",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16500,
    "pob": "Huete",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16235,
    "pob": "Iniesta",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16878,
    "pob": "Lagunaseca",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16330,
    "pob": "Landete",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16550,
    "pob": "Langa, La",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16237,
    "pob": "LedaÑa",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16461,
    "pob": "Leganiel",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16142,
    "pob": "Majadas, Las",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16650,
    "pob": "Mesas, Las",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16260,
    "pob": "Minglanilla",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16393,
    "pob": "Mira",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16520,
    "pob": "Moncalvillo De Huete",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16434,
    "pob": "Montalbanejo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16440,
    "pob": "Montalbo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16630,
    "pob": "Mota Del Cuervo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16200,
    "pob": "Motilla Del Palancar",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16371,
    "pob": "Narboneta",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16760,
    "pob": "Olivares De Jucar",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16852,
    "pob": "Olmeda De La Cuesta",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16336,
    "pob": "Olmeda, La",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16853,
    "pob": "Olmedilla De Eliz",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16423,
    "pob": "Osa De La Vega",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16145,
    "pob": "Pajares",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16390,
    "pob": "Pajaron",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16160,
    "pob": "Palomares Del Campo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16113,
    "pob": "Parra De Las Vegas, La",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16638,
    "pob": "Pedernoso, El",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16337,
    "pob": "Pedro Izquierdo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16660,
    "pob": "PedroÑeras, Las",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16240,
    "pob": "Peral, El",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16269,
    "pob": "Pesquera, La",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16211,
    "pob": "Picazo, El",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16622,
    "pob": "Pinarejo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16522,
    "pob": "Portalrubio De Guadamejud",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16414,
    "pob": "Pozorrubio",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16212,
    "pob": "Pozoseco",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16800,
    "pob": "Priego",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16670,
    "pob": "Provencio, El",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16892,
    "pob": "Puente De Vadillos",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16220,
    "pob": "Quintanar Del Rey",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16452,
    "pob": "Rozalen Del Monte",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16463,
    "pob": "Saceda Trasierra",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16430,
    "pob": "Saelices",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16317,
    "pob": "Salinas Del Manzano",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16813,
    "pob": "Salmeroncillos De Abajo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16600,
    "pob": "San Clemente",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16770,
    "pob": "San Lorenzo De La Parrilla",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16370,
    "pob": "San Martin De Boniches",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16639,
    "pob": "Santa Maria De Los Llanos",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16621,
    "pob": "Santa Maria Del Campo Rus",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16709,
    "pob": "Simarro, El",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16700,
    "pob": "Sisante",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16893,
    "pob": "Solan De Cabras",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16320,
    "pob": "Talayuelas",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16400,
    "pob": "Tarancon",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16842,
    "pob": "Torralba",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16161,
    "pob": "Torrejoncillo Del Rey",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16413,
    "pob": "Torrubia Del Campo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16739,
    "pob": "Torrubia Del Castillo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16150,
    "pob": "Tragacete",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16422,
    "pob": "Tresjuncos",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16146,
    "pob": "Valdecabras",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16521,
    "pob": "Valdemoro Del Rey",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16120,
    "pob": "Valera De Abajo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16100,
    "pob": "Valverde De Jucar",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16860,
    "pob": "Villaconejos De Trabaque",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16236,
    "pob": "Villagarcia Del Llano",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16140,
    "pob": "Villalba De La Sierra",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16535,
    "pob": "Villalba Del Rey",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16646,
    "pob": "Villalgordo Del Marquesado",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16270,
    "pob": "Villalpardo",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16415,
    "pob": "Villamayor De Santiago",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16531,
    "pob": "Villanueva De Guadamejud",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16230,
    "pob": "Villanueva De La Jara",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16840,
    "pob": "Villar De Domingo Garcia",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16648,
    "pob": "Villar De La Encina",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16432,
    "pob": "Villarejo De Fuentes",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16442,
    "pob": "Villares Del Saz",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16280,
    "pob": "Villarta",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 17869,
    "pob": "Abella",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17199,
    "pob": "Adri",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17185,
    "pob": "Aeroport De Girona - Costa Brava",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17529,
    "pob": "Age",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17707,
    "pob": "Agullana",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17255,
    "pob": "Aiguablava, D', Platja",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17181,
    "pob": "Aiguaviva",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17733,
    "pob": "Albanya",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17136,
    "pob": "Albons",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17539,
    "pob": "All",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17538,
    "pob": "Alp",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17170,
    "pob": "Amer",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17160,
    "pob": "Angles",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17401,
    "pob": "Arbucies",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17476,
    "pob": "Arenys D'emporda",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17853,
    "pob": "Argelaguer",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17472,
    "pob": "Armentera, L'",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17742,
    "pob": "Avinyonet De Puigventos",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17820,
    "pob": "Banyoles",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17483,
    "pob": "Bascara",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17534,
    "pob": "Batet (ayto Ribes Freser)",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17812,
    "pob": "Batet De La Serra",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17867,
    "pob": "Beget",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17857,
    "pob": "Beguda",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17141,
    "pob": "Bellcaire D'emporda",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17245,
    "pob": "Bell-lloc",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17850,
    "pob": "Besalu",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17162,
    "pob": "Bescano",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17100,
    "pob": "Bisbal D'emporda, La",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17723,
    "pob": "Biure",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17300,
    "pob": "Blanes",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17462,
    "pob": "Bordils",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17844,
    "pob": "Borgonya",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17770,
    "pob": "Borrassa",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17178,
    "pob": "Boscdetosca",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17400,
    "pob": "Breda",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17510,
    "pob": "Brucs",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17533,
    "pob": "Bruguera (ayto Ribes Frese)",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17441,
    "pob": "Brunyola",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17746,
    "pob": "Cabanelles",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17761,
    "pob": "Cabanes",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17120,
    "pob": "CaÇa De Pelras",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17488,
    "pob": "Cadaques",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17455,
    "pob": "Caldes De Malavella",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17210,
    "pob": "Calella De Palafrugell",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17251,
    "pob": "Calonge",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17465,
    "pob": "Camallera",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17834,
    "pob": "Camos",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17530,
    "pob": "Campdevanol",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17461,
    "pob": "Campdora",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17459,
    "pob": "Campllong",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17811,
    "pob": "Can Blanc",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17113,
    "pob": "Canapost",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17744,
    "pob": "Canelles",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17134,
    "pob": "Canet De La Tallada",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17708,
    "pob": "Cantallops",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17858,
    "pob": "Canya, La (ayto Vall Bianya)",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17310,
    "pob": "Canyelles (ayto Lloret Mar)",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17320,
    "pob": "Canyelles (ayto Tossa)",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17480,
    "pob": "Canyelles Almadraba (ajuntament Roses)",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17240,
    "pob": "Canyera, La",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17246,
    "pob": "Canyet De Mar",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17750,
    "pob": "Capmany",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17121,
    "pob": "Casavells",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17535,
    "pob": "Casetes, Les",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17244,
    "pob": "Cassa De La Selva",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17443,
    "pob": "Castanyet",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17115,
    "pob": "Castell D'emporda",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17242,
    "pob": "Castellar De La Selva",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17856,
    "pob": "Castellfollit De La Roca",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17486,
    "pob": "Castello D'empuries",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17249,
    "pob": "Castell-platja D'aro",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17165,
    "pob": "Cellera De Ter, La",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17460,
    "pob": "Celra",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17710,
    "pob": "Centro Militar Sant Climent Sescebes",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17527,
    "pob": "Cereja",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17464,
    "pob": "Cervia De Ter",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17130,
    "pob": "Cinclaus",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17741,
    "pob": "Cistella",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17515,
    "pob": "Ciuret",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17444,
    "pob": "Cladells",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17173,
    "pob": "Cogolls",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17496,
    "pob": "Colera, Villa",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17166,
    "pob": "Coll, El (ayto Susqueda)",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17830,
    "pob": "Collell, El",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17144,
    "pob": "Colomers",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17161,
    "pob": "Colonia De Les Mines",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17866,
    "pob": "Colonia Estevenell, La",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17860,
    "pob": "Colonia Jordana, La",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17500,
    "pob": "Colonia Rocafiguera",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17404,
    "pob": "Comte D'alba De Liste",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17164,
    "pob": "Constantins",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17832,
    "pob": "Crespia",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17241,
    "pob": "Creueta, La",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17116,
    "pob": "Cruilles",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17734,
    "pob": "Cursuvell",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17722,
    "pob": "Darnius",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17755,
    "pob": "Delfia",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17536,
    "pob": "Dorria",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17851,
    "pob": "Dosquers",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17487,
    "pob": "Empuriabrava",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17172,
    "pob": "Encies, Les",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17243,
    "pob": "Erols",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17213,
    "pob": "Esclanya",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17421,
    "pob": "Esparra, L'",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17868,
    "pob": "Espinavell",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17747,
    "pob": "Espinavessa",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17405,
    "pob": "Espinelves",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17753,
    "pob": "Espolla",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17182,
    "pob": "Estanyol",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17258,
    "pob": "Estartit, L'",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17177,
    "pob": "Falgars D'en Bas",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17831,
    "pob": "Falgons",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17469,
    "pob": "Far D'emporda, El",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17532,
    "pob": "Farga De Bebie, La",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17600,
    "pob": "Figueres",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17463,
    "pob": "FlaÇa",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17403,
    "pob": "Fogueres De Montsoriu",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17132,
    "pob": "Foixa",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17133,
    "pob": "Fonolleres",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17257,
    "pob": "Fontanilles",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17256,
    "pob": "Fontclara",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17833,
    "pob": "Fontcoberta",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17110,
    "pob": "Fonteta",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17458,
    "pob": "Fornells De La Selva",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17456,
    "pob": "Franciach, Pueblo",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17468,
    "pob": "Galliners",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17466,
    "pob": "Garrigoles",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17780,
    "pob": "Garriguella",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17451,
    "pob": "Gaserans",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17151,
    "pob": "Ginestar",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17000,
    "pob": "Girona (ver Callejero)",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17531,
    "pob": "Gombren",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17153,
    "pob": "Granollers De Rocacorba",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17490,
    "pob": "Grifeu",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17528,
    "pob": "Guils De Cerdanya",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17730,
    "pob": "Hostalets, Els",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17450,
    "pob": "Hostalric",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17143,
    "pob": "Jafre",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17402,
    "pob": "Joanet",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17176,
    "pob": "Joanetes",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17700,
    "pob": "Jonquera, La",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17709,
    "pob": "Limits, Els",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17745,
    "pob": "Llado",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17211,
    "pob": "Llafranc",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17124,
    "pob": "Llofriu",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17152,
    "pob": "Llora",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17512,
    "pob": "Llosses, Les (municipio)",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17720,
    "pob": "MaÇanet De Cabrenys",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17412,
    "pob": "MaÇanet De La Selva",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17420,
    "pob": "Mallorquines",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17410,
    "pob": "Mallorquines, Les",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17453,
    "pob": "Martorell De La Selva",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17493,
    "pob": "Marza",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17763,
    "pob": "Masarac",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17470,
    "pob": "Masos, Els",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17452,
    "pob": "Massanes",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17846,
    "pob": "Mata",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17482,
    "pob": "Medinya",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17537,
    "pob": "Molina, La",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17752,
    "pob": "Mollet De Peralada",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17855,
    "pob": "Montagut, Pueblo",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17481,
    "pob": "Montagut (ajuntament San Julia Ram)",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17843,
    "pob": "Montbo",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17253,
    "pob": "Mont-ras",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17861,
    "pob": "Ogassa",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17800,
    "pob": "Olot",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17772,
    "pob": "Ordis",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17137,
    "pob": "Palaborrell",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17200,
    "pob": "Palafrugell",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17230,
    "pob": "Palamos",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17731,
    "pob": "Palau Surroca",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17495,
    "pob": "Palau-saverdera",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17514,
    "pob": "Palmerola",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17494,
    "pob": "Pau",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17491,
    "pob": "Peralada",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17180,
    "pob": "Perello, El",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17179,
    "pob": "Pinya, La, Pueblo",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17250,
    "pob": "Platja D'aro",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17706,
    "pob": "Pont De Molins",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17773,
    "pob": "Pontos",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17489,
    "pob": "Port De La Selva, El",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17497,
    "pob": "Portbou",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17520,
    "pob": "Puigcerda",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17754,
    "pob": "Rabos D'emporda",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17845,
    "pob": "Ravos Del Terri",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17214,
    "pob": "Regencos, Pueblo",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17457,
    "pob": "Riudellots De La Selva",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17131,
    "pob": "Rupia",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17248,
    "pob": "S'agaro",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17473,
    "pob": "Saldet",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17184,
    "pob": "Salitja",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17190,
    "pob": "Salt",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17220,
    "pob": "Sant Amanc",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17454,
    "pob": "Sant Andreu Salou, Pueblo",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17154,
    "pob": "Sant Aniol De Finestres",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17252,
    "pob": "Sant Antoni De Calonge",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17863,
    "pob": "Sant Bernabe De Les Tenes",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17751,
    "pob": "Sant Climent Sescebes",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17810,
    "pob": "Sant Cristofol Les Fonts",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17183,
    "pob": "Sant Dalmai",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17174,
    "pob": "Sant Feliu De Pallerols",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17150,
    "pob": "Sant Gregori (municipio)",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17854,
    "pob": "Sant Jaume De Llierca",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17732,
    "pob": "Sant LlorenÇ De La Muga",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17813,
    "pob": "Sant Marti Del Clot",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17171,
    "pob": "Sant Marti Sacalm",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17117,
    "pob": "Sant Miquel De Cruilles",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17475,
    "pob": "Sant Miquel De Fluvia",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17175,
    "pob": "Sant Miquel De Pineda",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17467,
    "pob": "Sant Mori",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17864,
    "pob": "Sant Pau De Seguries",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17445,
    "pob": "Sant Pere Cercada",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17118,
    "pob": "Sant Sadurni De L'heura",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17513,
    "pob": "Sant Sadurni De Sovelles",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17474,
    "pob": "Sant Tomas De Fluvia",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17430,
    "pob": "Santa Coloma De Farners",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17442,
    "pob": "Santa Creu D'horta",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17771,
    "pob": "Santa Llogaia D'alguema",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17840,
    "pob": "Sarria De Dalt, Pueblo",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17852,
    "pob": "Serinya",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17212,
    "pob": "Tamariu",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17721,
    "pob": "Tapis",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17123,
    "pob": "Torrent",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17140,
    "pob": "Ulla",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17114,
    "pob": "Ullastret",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17862,
    "pob": "Vallfogona De Ripolles",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17142,
    "pob": "Verges",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17411,
    "pob": "Vidreres",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17760,
    "pob": "Vilabertran",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17406,
    "pob": "Viladrau",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17740,
    "pob": "Vilafant",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17781,
    "pob": "Vilamaniscle",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17743,
    "pob": "Vilanant",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17492,
    "pob": "Vilanova De La Muga",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17762,
    "pob": "Vilarnadal",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17485,
    "pob": "Vila-sacra",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17484,
    "pob": "Vilatenim",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17111,
    "pob": "Vullpellac",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 18656,
    "pob": "Acequias",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18360,
    "pob": "Agicampe (loja)",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18132,
    "pob": "Agron",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18418,
    "pob": "Agustines, Los",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18520,
    "pob": "Alamedilla",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18220,
    "pob": "Albolote",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18708,
    "pob": "Albondon",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18518,
    "pob": "AlbuÑan",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18700,
    "pob": "AlbuÑol",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18659,
    "pob": "AlbuÑuelas",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18710,
    "pob": "Alcazar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18511,
    "pob": "Alcudia De Guadix",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18514,
    "pob": "Aldeire",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18170,
    "pob": "Alfacar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18280,
    "pob": "Algarinejo",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18120,
    "pob": "Alhama De Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18620,
    "pob": "Alhendin",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18260,
    "pob": "Alhondiguilla",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18538,
    "pob": "Alicun De Ortega",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18438,
    "pob": "Almegijar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18690,
    "pob": "AlmuÑecar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18350,
    "pob": "Alomartes",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18182,
    "pob": "Alqueria Del Fargue",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18102,
    "pob": "Ambroz",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18291,
    "pob": "Anzola",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18126,
    "pob": "Arenas Del Rey",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18100,
    "pob": "Armilla",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18414,
    "pob": "Atalbeitar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18230,
    "pob": "Atarfe",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18860,
    "pob": "Bacor",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18870,
    "pob": "Balcones, Los",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18193,
    "pob": "Barrio De La Vega",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18410,
    "pob": "Bayacas",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18800,
    "pob": "Baza",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18184,
    "pob": "Beas De Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18516,
    "pob": "Beas De Guadix",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18515,
    "pob": "Bejarin, El",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18101,
    "pob": "Belicena",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18510,
    "pob": "Benalua",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18566,
    "pob": "Benalua De Las Villas",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18817,
    "pob": "Benamaurel",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18451,
    "pob": "Berchules",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18562,
    "pob": "Bogarre",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18381,
    "pob": "Bracana",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18412,
    "pob": "Bubion",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18416,
    "pob": "Busquistar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18129,
    "pob": "Cacin",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18440,
    "pob": "Cadiar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18199,
    "pob": "Cajar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18730,
    "pob": "Calahonda",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18512,
    "pob": "Calahorra, La",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18680,
    "pob": "Caleta-guardia, La",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18290,
    "pob": "Calicasas",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18815,
    "pob": "Campo Camara",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18565,
    "pob": "Campotejar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18191,
    "pob": "Canales",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18810,
    "pob": "Caniles",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18480,
    "pob": "Canteras, Las (ugijar)",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18413,
    "pob": "Capileira",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18311,
    "pob": "Carrera De La ViÑa",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18439,
    "pob": "Castaras",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18740,
    "pob": "Castell De Ferro",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18818,
    "pob": "Castillejar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18750,
    "pob": "Castillo De BaÑos",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18329,
    "pob": "Castillo Tajarja",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18816,
    "pob": "Castril De La PeÑa",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18190,
    "pob": "Cenes De La Vega",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18330,
    "pob": "Chauchina",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18494,
    "pob": "Cherin",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18194,
    "pob": "Churriana De La Vega",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18339,
    "pob": "Cijuela",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18211,
    "pob": "Cogollos Vega",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18492,
    "pob": "Cojayar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18564,
    "pob": "Colomera",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18814,
    "pob": "Cortes De Baza",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18517,
    "pob": "Cortes Y Graena",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18813,
    "pob": "Cuevas Del Campo",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18850,
    "pob": "Cullar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18195,
    "pob": "Cullar Vega",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18181,
    "pob": "Darro",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18567,
    "pob": "Dehesas Viejas",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18570,
    "pob": "Deifontes",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18540,
    "pob": "Delgadillo",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18180,
    "pob": "Diezma",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18152,
    "pob": "Dilar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18192,
    "pob": "Dudar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18650,
    "pob": "Durcal",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18293,
    "pob": "Escoznar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18130,
    "pob": "Escuzar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18300,
    "pob": "Esperanza, La (huetor Tajar)",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18513,
    "pob": "Ferreira",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18127,
    "pob": "Fornes",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18812,
    "pob": "Freila",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18328,
    "pob": "Fuensanta",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18314,
    "pob": "Fuente Camacho",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18295,
    "pob": "Fuente De Cesna",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18340,
    "pob": "Fuente Vaqueros",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18110,
    "pob": "Gabia La Chica",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18840,
    "pob": "Galera",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18614,
    "pob": "Garnatilla, La",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18563,
    "pob": "Gobernador",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18150,
    "pob": "Gojar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18450,
    "pob": "Golco",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18890,
    "pob": "Gorafe",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18616,
    "pob": "Gorgoracha",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18000,
    "pob": "Granada (ver Callejero)",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18560,
    "pob": "Guadahortuna",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18500,
    "pob": "Guadix",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18615,
    "pob": "Guajar Alto",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18160,
    "pob": "Guejar Sierra",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18212,
    "pob": "GÜevejar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18569,
    "pob": "Gumiel",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18880,
    "pob": "Hernan Valle",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18697,
    "pob": "Herradura, La",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18830,
    "pob": "Huescar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18183,
    "pob": "Huetor De Santillan",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18198,
    "pob": "Huetor Vega",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18612,
    "pob": "Itrabo",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18660,
    "pob": "Izbor",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18550,
    "pob": "Iznalloz",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18891,
    "pob": "Jamula",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18699,
    "pob": "Jete",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18213,
    "pob": "Jun",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18210,
    "pob": "Juncaril (peligros), Poligono Industrial",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18452,
    "pob": "Juviles",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18327,
    "pob": "Lachar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18420,
    "pob": "Lanjaron",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18249,
    "pob": "Limones",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18449,
    "pob": "Lobras",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18610,
    "pob": "Lobres",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18200,
    "pob": "Maracena",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18859,
    "pob": "Matian",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18470,
    "pob": "Mecina Alfahar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18658,
    "pob": "Melegis",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18713,
    "pob": "Melicena",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18270,
    "pob": "Milanos (montefrio)",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18247,
    "pob": "Moclin",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18611,
    "pob": "Molvizar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18561,
    "pob": "Montejicar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18370,
    "pob": "Moraleda De Zafayona",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18600,
    "pob": "Motril",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18490,
    "pob": "Murtas",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18448,
    "pob": "Narila",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18657,
    "pob": "Niguelas",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18214,
    "pob": "Nivar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18151,
    "pob": "Ogijares",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18248,
    "pob": "Olivares",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18858,
    "pob": "Orce",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18400,
    "pob": "Orgiva",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18698,
    "pob": "Otivar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18630,
    "pob": "Otura",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18640,
    "pob": "Padul",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18411,
    "pob": "Pampaneira",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18519,
    "pob": "Paulenca",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18530,
    "pob": "Pedro Martinez",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18125,
    "pob": "Pilas De Algaida",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18240,
    "pob": "Pinos Puente",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18568,
    "pob": "PiÑar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18613,
    "pob": "Playa Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18415,
    "pob": "Portugos",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18770,
    "pob": "Pozuelo, El",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18820,
    "pob": "Puebla Don Fadrique",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18197,
    "pob": "Pulianas",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18760,
    "pob": "Rabita, La",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18711,
    "pob": "Rambla Del Agua (rubite)",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18313,
    "pob": "Riofrio",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18310,
    "pob": "Salar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18320,
    "pob": "Santafe",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18196,
    "pob": "Sierra Nevada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18380,
    "pob": "Tocon",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18720,
    "pob": "Torrenueva",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18430,
    "pob": "Torvizcon",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18417,
    "pob": "Trevelez",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18491,
    "pob": "Turon",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18250,
    "pob": "Valderrubio",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18670,
    "pob": "Velez De Benaudalla",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18131,
    "pob": "Ventas De Huelma",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18312,
    "pob": "Ventorros De Balerma",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18539,
    "pob": "Villanueva De Las Torres",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18369,
    "pob": "Villanueva Mesia",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18179,
    "pob": "Viznar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18460,
    "pob": "Yegen",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18128,
    "pob": "Zafarraya",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18140,
    "pob": "Zubia, La",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18811,
    "pob": "Zujar",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 19432,
    "pob": "Abanades",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19442,
    "pob": "Ablanque",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19200,
    "pob": "Acequilla",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19325,
    "pob": "Adobes",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19283,
    "pob": "Aguilar De Anguita",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19490,
    "pob": "Alaminos",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19227,
    "pob": "Alarilla",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19117,
    "pob": "Albalate De Zorita",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19112,
    "pob": "Albares",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19275,
    "pob": "Albendiego",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19160,
    "pob": "Albolleque",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19264,
    "pob": "Alboreca",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19125,
    "pob": "Alcocer",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19277,
    "pob": "Alcolea De Las PeÑas",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19260,
    "pob": "Alcolea Del Pinar",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19310,
    "pob": "Alcoroches",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19244,
    "pob": "Aldeanueva De Atienza",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19152,
    "pob": "Aldeanueva De Guadalajara",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19354,
    "pob": "Aldehuela",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19237,
    "pob": "Aleas",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19332,
    "pob": "Algar De Mesa",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19268,
    "pob": "Algora",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19132,
    "pob": "Alhondiga",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19129,
    "pob": "Alique",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19225,
    "pob": "Almiruete",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19115,
    "pob": "Almoguera",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19118,
    "pob": "Almonacid De Zorita",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19133,
    "pob": "Alocen",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19208,
    "pob": "Alovera",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19184,
    "pob": "Alpedrete De La Sierra",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19276,
    "pob": "Alpedroches",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19320,
    "pob": "Alustante",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19287,
    "pob": "Anchuela Del Campo",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19350,
    "pob": "Anchuela Del Pedregal",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19128,
    "pob": "Anclas, Las",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19245,
    "pob": "Angon",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19119,
    "pob": "Anguix",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19357,
    "pob": "Anquela Del Pedregal",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19344,
    "pob": "Aragoncillo",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19294,
    "pob": "Aragosa",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19345,
    "pob": "Arandilla",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19141,
    "pob": "Aranzueque",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19492,
    "pob": "Arbeteta",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19411,
    "pob": "Archilla",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19196,
    "pob": "Arcipreste, Urbanizacion",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19314,
    "pob": "Armalla",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19461,
    "pob": "Armallones",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19135,
    "pob": "ArmuÑa De TajuÑa",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19238,
    "pob": "Arroyo De Fraguas",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19266,
    "pob": "Atance, El",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19153,
    "pob": "Atanzon",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19270,
    "pob": "Atienza",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19130,
    "pob": "AuÑon",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19295,
    "pob": "Baides",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19281,
    "pob": "Balbacil",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19390,
    "pob": "BaÑos De Tajo",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19262,
    "pob": "Barbatona",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19269,
    "pob": "Barbolla, La",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19247,
    "pob": "Barrio Estacion De Jadraque",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19192,
    "pob": "Beltraneja, Urbanizacion",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19290,
    "pob": "Berjafel, Finca",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 28190,
    "pob": "Bocigano",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 19278,
    "pob": "Bodera, La",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19400,
    "pob": "Brihuega",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19443,
    "pob": "Buenafuente Del Sistal",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19263,
    "pob": "Bujarrabal",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19243,
    "pob": "Bustares",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19171,
    "pob": "Cabanillas Del Campo",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19267,
    "pob": "Cabrera, La",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19223,
    "pob": "Campillejo",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19360,
    "pob": "Campillo De DueÑas",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19495,
    "pob": "Campillo, El",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19343,
    "pob": "Canales De Molina",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19431,
    "pob": "Canredondo",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19197,
    "pob": "CaÑizar",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19188,
    "pob": "Caraquiz, Urbanizacion",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19170,
    "pob": "Casar, El",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19246,
    "pob": "Casas De San Galindo",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19412,
    "pob": "CaspueÑas",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19328,
    "pob": "Castellar De La Muela",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19392,
    "pob": "Castellote",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19127,
    "pob": "Castilforte",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19413,
    "pob": "Castilmimbre",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19391,
    "pob": "Castilnuevo",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19151,
    "pob": "Centenera",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19459,
    "pob": "Central Nuclear De Trillo",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19229,
    "pob": "Cerezo De Mohernando",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19353,
    "pob": "Chera",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19420,
    "pob": "Cifuentes",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19339,
    "pob": "Cillas",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19285,
    "pob": "Ciruelos Del Pinar",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19163,
    "pob": "Ciudad Residencial El Clavin",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19139,
    "pob": "Ciudad Valdeluz",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19230,
    "pob": "Cogolludo",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19292,
    "pob": "Copernal",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19341,
    "pob": "Corduente",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19261,
    "pob": "Cortes De TajuÑa",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19362,
    "pob": "Cubillejo De La Sierra",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19363,
    "pob": "Cubillejo Del Sitio",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19186,
    "pob": "Cubillo De Uceda, El",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19116,
    "pob": "Driebes",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19445,
    "pob": "Esplegares",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19182,
    "pob": "Fuentelahiguera De Albatages",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19144,
    "pob": "Fuentelencina",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19113,
    "pob": "Fuentenovilla",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19174,
    "pob": "Galapagos",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19000,
    "pob": "Guadalajara (ver Callejero)",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19491,
    "pob": "Henche",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19342,
    "pob": "Herreria",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19242,
    "pob": "Hiendelaencina",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19334,
    "pob": "Hinojosa",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19248,
    "pob": "Hita",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19140,
    "pob": "Horche",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19441,
    "pob": "Huertahernando",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19429,
    "pob": "Huetos",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19220,
    "pob": "Humanes De Mohernando",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19458,
    "pob": "Instituto Leprologico De Trillo",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19150,
    "pob": "Iriepal",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19143,
    "pob": "Irueste",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19240,
    "pob": "Jadraque",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19333,
    "pob": "Labros",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19185,
    "pob": "Lago Del Jaral, Urbanizacion",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19142,
    "pob": "Lupiana",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19219,
    "pob": "Malaga Del Fresno",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19280,
    "pob": "Maranchon",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19180,
    "pob": "Marchamalo",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19224,
    "pob": "Matallana, Presa De",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19286,
    "pob": "Mazarete",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19114,
    "pob": "Mazuecos",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19315,
    "pob": "Megina",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19226,
    "pob": "Mohernando",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19300,
    "pob": "Molina De Aragon",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19239,
    "pob": "Monasterio",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19110,
    "pob": "Mondejar",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19162,
    "pob": "Monte Alcarria",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19444,
    "pob": "Olmeda De Cobeta",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19311,
    "pob": "Orea",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19356,
    "pob": "Otilla",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19336,
    "pob": "Pardos",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19100,
    "pob": "Pastrana",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19327,
    "pob": "Pedregal, El",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19462,
    "pob": "PeÑalen",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19134,
    "pob": "PeÑalver",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19313,
    "pob": "Peralejos De Las Truchas",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19493,
    "pob": "Peralveche",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19312,
    "pob": "Pinilla De Molina",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19326,
    "pob": "Pobo De DueÑas, El",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19463,
    "pob": "Poveda De La Sierra",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19265,
    "pob": "Pozancos",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19161,
    "pob": "Pozo De Guadalajara",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19352,
    "pob": "Prados Redondos",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19209,
    "pob": "Quer",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19145,
    "pob": "Renera",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19340,
    "pob": "Rillo De Gallo",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19120,
    "pob": "Sacedon",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19126,
    "pob": "Salmeron",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 28189,
    "pob": "Santuy",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 19346,
    "pob": "Selas",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19324,
    "pob": "Setiles",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19250,
    "pob": "SigÜenza",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19222,
    "pob": "Tamajon",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19193,
    "pob": "Taracena",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19351,
    "pob": "Tordelpalo",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19323,
    "pob": "Tordesilos",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19190,
    "pob": "Torija",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19355,
    "pob": "Torrecuadrada De Molina",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19337,
    "pob": "Torrubia",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19198,
    "pob": "Tortola De Henares",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19338,
    "pob": "Tortuera",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19450,
    "pob": "Trillo",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19187,
    "pob": "Uceda",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19274,
    "pob": "Villacadima",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19460,
    "pob": "Villanueva De Alcoron",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19111,
    "pob": "Yebra",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19210,
    "pob": "Yunquera De Henares",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19361,
    "pob": "Yunta, La",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 20269,
    "pob": "Abaltzisketa",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20150,
    "pob": "Aduna",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20600,
    "pob": "Aginaga (eibar)",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20170,
    "pob": "Aginaga (usurbil)",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20710,
    "pob": "Aginaga (zumarraga)",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20809,
    "pob": "Aia",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20211,
    "pob": "Aia (ataun)",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20870,
    "pob": "Aiastia (san Miguel)",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20800,
    "pob": "Aitza",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20749,
    "pob": "Aizarna",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20280,
    "pob": "Akartegi",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20120,
    "pob": "Akerregi",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20495,
    "pob": "Albiztur",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20267,
    "pob": "Aldaba",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20260,
    "pob": "Alegia",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20217,
    "pob": "Alegia (gabiria)",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20494,
    "pob": "Alkiza",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20200,
    "pob": "Altamira",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20248,
    "pob": "Altzaga",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20180,
    "pob": "Altzibar",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20268,
    "pob": "Altzo",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20860,
    "pob": "Altzola (elgoibar)",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20140,
    "pob": "Andoain",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20709,
    "pob": "Anduaga (santa Lutzia)",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20578,
    "pob": "Angiozar",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20270,
    "pob": "Anoeta",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20577,
    "pob": "Antzuola",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20550,
    "pob": "Aozaratza",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20530,
    "pob": "Apotzaga",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20567,
    "pob": "Arantzazu",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20738,
    "pob": "Aratz Erreka",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20218,
    "pob": "Aratz-matxinbenta",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20737,
    "pob": "Argisain (santa Marina)",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20500,
    "pob": "Arrasate",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20215,
    "pob": "Arrieta",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20720,
    "pob": "Arrietamendi",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20759,
    "pob": "Artadi",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20830,
    "pob": "Artzainerreka",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20808,
    "pob": "Askizu",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20159,
    "pob": "Asteasu",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20115,
    "pob": "Astigarraga",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20160,
    "pob": "Atsobakar",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20400,
    "pob": "Auzotxikia",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20730,
    "pob": "Azpeitia",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20850,
    "pob": "Azpilgoeta",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20220,
    "pob": "Aztiria",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20018,
    "pob": "AÑorga",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20259,
    "pob": "Baliarrain",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20570,
    "pob": "Basalgo",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20739,
    "pob": "Beizama",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20491,
    "pob": "Belauntza",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20492,
    "pob": "Berastegi",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20569,
    "pob": "Berezao",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20493,
    "pob": "Berrobi",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20496,
    "pob": "Bidania-goiatz",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20304,
    "pob": "Bidasoa",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20820,
    "pob": "Deba",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20000,
    "pob": "Donostia-san Sebastian (ver Callejero)",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20690,
    "pob": "Elgeta",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20740,
    "pob": "Endoia",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20128,
    "pob": "Epela",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20100,
    "pob": "Errenteria",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20540,
    "pob": "Eskoriatza",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20213,
    "pob": "Idiazabal",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20008,
    "pob": "Igeldo",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20212,
    "pob": "Ihurre",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20271,
    "pob": "Irura",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20249,
    "pob": "Itsasondo",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20829,
    "pob": "Itziar",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20210,
    "pob": "Lazkao",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20230,
    "pob": "Legazpi",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20250,
    "pob": "Legorreta",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20490,
    "pob": "Lizartza",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20214,
    "pob": "Mutiloa",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20240,
    "pob": "Ordizia",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20810,
    "pob": "Orio",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20216,
    "pob": "Ormaiztegi",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20580,
    "pob": "Osintxu",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20560,
    "pob": "OÑati",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20110,
    "pob": "Pasai Antxo",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20590,
    "pob": "Placencia De Las Armas",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20579,
    "pob": "Ubera",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20568,
    "pob": "Uribarri",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20130,
    "pob": "Urnieta",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20700,
    "pob": "Urretxu",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20247,
    "pob": "Zaldibia",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20750,
    "pob": "Zumaia",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 21342,
    "pob": "Acebuche",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21290,
    "pob": "Aguafria",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21340,
    "pob": "Alajar",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21649,
    "pob": "Aldea De Traslasierra",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21880,
    "pob": "Aldea De Tujena",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21110,
    "pob": "Aljaraque",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21593,
    "pob": "Almendro, El",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21350,
    "pob": "Almonaster La Real",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21730,
    "pob": "Almonte",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21520,
    "pob": "Alosno",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21239,
    "pob": "Andreses, Los",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21449,
    "pob": "Antilla, La",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21200,
    "pob": "Aracena",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21240,
    "pob": "Aroche",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21280,
    "pob": "Arroyomolinos De Leon",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21400,
    "pob": "Ayamonte",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21630,
    "pob": "Beas",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21122,
    "pob": "Bellavista",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21647,
    "pob": "Berrocal",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21710,
    "pob": "Bollullos Par Del Condado",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21830,
    "pob": "Bonares",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21580,
    "pob": "Cabezas Rubias",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21270,
    "pob": "Cala",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21300,
    "pob": "CalaÑas",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21650,
    "pob": "Campillo, El",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21668,
    "pob": "Campofrio",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21639,
    "pob": "Candon",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21388,
    "pob": "CaÑaveral De Leon",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21208,
    "pob": "Carboneras",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21450,
    "pob": "Cartaya",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21292,
    "pob": "CastaÑo Del Robledo, Poblado",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21320,
    "pob": "Cerro De Andevalo, El",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21291,
    "pob": "Chinas, Las",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21891,
    "pob": "Chucena",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21120,
    "pob": "Corrales",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21209,
    "pob": "Corteconcepcion",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21230,
    "pob": "Cortegana",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21330,
    "pob": "Cueva De La Mora",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21387,
    "pob": "Cumbres De Enmedio",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21386,
    "pob": "Cumbres De San Bartolome",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21380,
    "pob": "Cumbres Mayores",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21669,
    "pob": "Dehesa, Aldea De La",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21390,
    "pob": "Encinasola",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21870,
    "pob": "Escacena Del Campo",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21609,
    "pob": "Fuente De La Corcha",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21359,
    "pob": "Fuente Del Oro",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21500,
    "pob": "Gibraleon",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21594,
    "pob": "Granado, El",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21220,
    "pob": "Higuera De La Sierra",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21740,
    "pob": "Hinojos",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21000,
    "pob": "Huelva (ver Callejero)",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21410,
    "pob": "Isla Cristina",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21409,
    "pob": "Isla De Canela",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21431,
    "pob": "Islantilla (isla Cristina)",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21440,
    "pob": "Lepe",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21207,
    "pob": "Linares De La Sierra",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21820,
    "pob": "Lucena Del Puerto",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21890,
    "pob": "Manzanilla",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21760,
    "pob": "MatalascaÑas",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21130,
    "pob": "Mazagon",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21559,
    "pob": "Minas De Herrerias",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21319,
    "pob": "Minas De Perrunal",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21660,
    "pob": "Minas De Riotinto",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21530,
    "pob": "Minas De Tharsis",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21800,
    "pob": "Moguer",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21670,
    "pob": "Nerva",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21840,
    "pob": "Niebla",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21700,
    "pob": "Palma Del Condado, La",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21810,
    "pob": "Palos De La Frontera",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21560,
    "pob": "Paymogo",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21600,
    "pob": "Pinos, Los",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21459,
    "pob": "Portil, El",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21420,
    "pob": "Pozo Del Camino",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21550,
    "pob": "Puebla De Guzman",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21100,
    "pob": "Punta Umbria",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21819,
    "pob": "Rabida, La",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21430,
    "pob": "Redondela, La",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21360,
    "pob": "Repilado, El",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21720,
    "pob": "Rociana Del Condado",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21750,
    "pob": "Rocio, El",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21250,
    "pob": "Rosal De La Frontera",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21510,
    "pob": "San Bartolome De La Torre",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21610,
    "pob": "San Juan Del Puerto",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21591,
    "pob": "San Silvestre De Guzman",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21595,
    "pob": "Sanlucar De Guadiana",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21570,
    "pob": "Santa Barbara De Casa",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21260,
    "pob": "Santa Olalla Del Cala",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21309,
    "pob": "Sotiel Coronada",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21620,
    "pob": "Trigueros",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21590,
    "pob": "Villablanca",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21860,
    "pob": "Villalba Del Alcor",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21592,
    "pob": "Villanueva De Las Cruces",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21540,
    "pob": "Villanueva De Los Castillejos",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21850,
    "pob": "Villarrasa",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21640,
    "pob": "Zalamea La Real",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21310,
    "pob": "Zarza, La",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21210,
    "pob": "Zufre",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 22713,
    "pob": "Abay",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22472,
    "pob": "Abella",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22620,
    "pob": "Abena",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22621,
    "pob": "Abenilla",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22437,
    "pob": "Abenozas",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22463,
    "pob": "Abi",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22143,
    "pob": "Abiego",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22392,
    "pob": "Abizanda",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22612,
    "pob": "Acumuer",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22147,
    "pob": "Adahuesca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22141,
    "pob": "Aguas",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22451,
    "pob": "Aguascaldas",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22808,
    "pob": "Aguero",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22461,
    "pob": "Aguilar",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22348,
    "pob": "Aguilar (boltaÑa)",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22588,
    "pob": "Aguinaliu",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22623,
    "pob": "Aineto",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22330,
    "pob": "Ainsa",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22860,
    "pob": "Aisa",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22760,
    "pob": "Alastruey",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22534,
    "pob": "Albalate De Cinca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22220,
    "pob": "Albalatillo",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22558,
    "pob": "Albelda",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22371,
    "pob": "Albella",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22112,
    "pob": "Albero Alto",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22255,
    "pob": "Albero Bajo",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22212,
    "pob": "Alberuela De Tubo",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22282,
    "pob": "Alcala De Gurrea",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22135,
    "pob": "Alcala Del Obispo",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22560,
    "pob": "Alcampell",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22410,
    "pob": "Alcolea De Cinca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22251,
    "pob": "Alcubierre",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22589,
    "pob": "Aler",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22194,
    "pob": "Alerre",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22416,
    "pob": "Alfantega",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22559,
    "pob": "Algayon",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22473,
    "pob": "Alins",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22421,
    "pob": "Alins Del Monte",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22610,
    "pob": "Allue",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22148,
    "pob": "Almazorre",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22531,
    "pob": "Almudafar",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22270,
    "pob": "Almudevar",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22420,
    "pob": "Almunia De San Juan",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22585,
    "pob": "Almunia De San Lorenzo",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22144,
    "pob": "Almunias, Las",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22145,
    "pob": "Alquezar",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22540,
    "pob": "Altorricon",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22337,
    "pob": "Alueza",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22469,
    "pob": "Anciles",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22487,
    "pob": "Aneto",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22123,
    "pob": "Angues",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22162,
    "pob": "Anies",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22728,
    "pob": "Anso",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22133,
    "pob": "Antillon",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22830,
    "pob": "Anzanigo",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22195,
    "pob": "Apies",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22625,
    "pob": "Aquilue",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22338,
    "pob": "Araguas",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22730,
    "pob": "Aragues Del Puerto",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22466,
    "pob": "Arasan",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22193,
    "pob": "Arascues",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22121,
    "pob": "Arbanies",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22149,
    "pob": "Arcusa",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22474,
    "pob": "Ardanue",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22583,
    "pob": "Aren",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22150,
    "pob": "Arguis",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22636,
    "pob": "Arguisal",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22417,
    "pob": "Ariestolas",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22364,
    "pob": "Arinzue",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22622,
    "pob": "Arraso",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22751,
    "pob": "Arres",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22372,
    "pob": "Arresa",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22336,
    "pob": "Arro",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22390,
    "pob": "Artasona",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22283,
    "pob": "Artasona Del Llano",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22715,
    "pob": "Ascara",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22347,
    "pob": "Ascaso",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22638,
    "pob": "Aso De Sobremonte",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22889,
    "pob": "Astun",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22700,
    "pob": "Atares",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22452,
    "pob": "Atiart",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22609,
    "pob": "Aurin",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22140,
    "pob": "Ayera",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22800,
    "pob": "Ayerbe",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22311,
    "pob": "Azara",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22462,
    "pob": "Bacamorta",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22587,
    "pob": "Badias De Monesma",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22569,
    "pob": "Baells",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22571,
    "pob": "Baldellou",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22484,
    "pob": "Ballabriga",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22234,
    "pob": "Ballobar",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22339,
    "pob": "Banaston",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22714,
    "pob": "Baraguas",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22464,
    "pob": "Barbaruens",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22300,
    "pob": "Barbastro",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22637,
    "pob": "Barbenuta",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22132,
    "pob": "BarbuÑales",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22192,
    "pob": "Barluenga",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22712,
    "pob": "Baros",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22483,
    "pob": "Barrio Del Pou",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22450,
    "pob": "Beleder",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22436,
    "pob": "Bellestar (graus)",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22196,
    "pob": "Bellestar Del Flumen",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22363,
    "pob": "Belsierre",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22533,
    "pob": "Belver De Cinca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22580,
    "pob": "Benabarre",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22440,
    "pob": "Benasque",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22131,
    "pob": "Berbegal",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22770,
    "pob": "Berdun",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22373,
    "pob": "Bergua",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22711,
    "pob": "Bernues",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22710,
    "pob": "Bescos De Garcipollera",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22460,
    "pob": "Besians",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22362,
    "pob": "Bestue",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22486,
    "pob": "Bibiles",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22350,
    "pob": "Bielsa",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22630,
    "pob": "Biescas",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22510,
    "pob": "Binaced",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22791,
    "pob": "Binacua",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22500,
    "pob": "Binefar",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22773,
    "pob": "Binies",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22470,
    "pob": "Bisaurri",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22807,
    "pob": "Biscarrues",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22160,
    "pob": "Bolea",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22340,
    "pob": "BoltaÑa",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22370,
    "pob": "Broto",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22146,
    "pob": "Buera",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22375,
    "pob": "Buerba",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22315,
    "pob": "Burceat",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22216,
    "pob": "CabaÑas",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22514,
    "pob": "Calasanz",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22624,
    "pob": "Caldearenas",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22485,
    "pob": "Calvera",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22280,
    "pob": "Camporredondo",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22570,
    "pob": "Camporrells",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22395,
    "pob": "Camporrotuno",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22591,
    "pob": "Candasnos",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22880,
    "pob": "Canfranc, Estacion",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22888,
    "pob": "Canfranc, Pueblo",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22480,
    "pob": "Capella",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22213,
    "pob": "Cartuja De Monegros, La",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22361,
    "pob": "Casal, El",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22810,
    "pob": "Casas De Nuevo, Las",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22142,
    "pob": "Casbas De Huesca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22222,
    "pob": "Castejon De Monegros",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22394,
    "pob": "Castejon De Sobrarbe",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22310,
    "pob": "Castejon Del Puente",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22215,
    "pob": "Castelflorite",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22313,
    "pob": "Castillazuelo",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22572,
    "pob": "Castillonroy",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22449,
    "pob": "Cerler",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22233,
    "pob": "Chalamera",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22465,
    "pob": "Chia",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22584,
    "pob": "Chiriveta",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22365,
    "pob": "Chisagues",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22438,
    "pob": "Clamosa",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22481,
    "pob": "Collada, La",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22414,
    "pob": "Conchel",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22111,
    "pob": "Corvinos",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22312,
    "pob": "Coscojuela De Fantova",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22197,
    "pob": "Cuarte",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22269,
    "pob": "Curbe",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22740,
    "pob": "Embun",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22467,
    "pob": "Eresue",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22393,
    "pob": "Escanilla",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22660,
    "pob": "Escarrilla",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22482,
    "pob": "Esdolomada",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22351,
    "pob": "Espierba",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22535,
    "pob": "Esplus",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22424,
    "pob": "Estada",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22423,
    "pob": "Estadilla",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22412,
    "pob": "Estiche De Cinca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22729,
    "pob": "Fago",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22611,
    "pob": "Fanlillo",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22809,
    "pob": "Fontellas",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22422,
    "pob": "Fonz",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22640,
    "pob": "Formigal",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22415,
    "pob": "Fornillos De Ilche",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22268,
    "pob": "Fraella",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22520,
    "pob": "Fraga",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22377,
    "pob": "Fragen",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22639,
    "pob": "Gavin",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22367,
    "pob": "Gistain",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22349,
    "pob": "Grado, El (ainsa)",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22260,
    "pob": "GraÑen",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22430,
    "pob": "Graus",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22468,
    "pob": "Guayente, Santuario",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22720,
    "pob": "Hecho",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22662,
    "pob": "Hoz De Jaca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22771,
    "pob": "Huertalo",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22210,
    "pob": "Huerto",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22000,
    "pob": "Huesca (ver Callejero)",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22122,
    "pob": "Ibieca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22613,
    "pob": "Isun De Basa",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22731,
    "pob": "Jasa",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22750,
    "pob": "Javierregay",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22360,
    "pob": "Labuerda",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22125,
    "pob": "Laluenga",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22214,
    "pob": "Lalueza",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22250,
    "pob": "Lanaja",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22126,
    "pob": "Laperdiguera",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22666,
    "pob": "Larrede",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22761,
    "pob": "Larues",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22124,
    "pob": "Lascellas",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22586,
    "pob": "Lascuarre",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22471,
    "pob": "Laspaules",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22161,
    "pob": "Lierta",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22378,
    "pob": "Linas De Broto",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22811,
    "pob": "LupiÑen",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22772,
    "pob": "Martes",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22549,
    "pob": "Melusa, La",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22529,
    "pob": "Miralsot",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22536,
    "pob": "Monte Julia",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22400,
    "pob": "Monzon",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22320,
    "pob": "Naval",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22113,
    "pob": "Novales",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22439,
    "pob": "Olvena",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22232,
    "pob": "OntiÑena",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22532,
    "pob": "Osso De Cinca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22221,
    "pob": "Pallaruelo De Monegros",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22650,
    "pob": "Panticosa, Balneario",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22661,
    "pob": "Panticosa, Pueblo",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22281,
    "pob": "Paul, La",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22820,
    "pob": "PeÑa, La",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22592,
    "pob": "PeÑalba",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22513,
    "pob": "Peralta De Calasanz",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22665,
    "pob": "Piedrafita De Jaca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22413,
    "pob": "Pomar De Cinca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22435,
    "pob": "Puebla De Castro, La",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22753,
    "pob": "Puente La Reina",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22191,
    "pob": "Quicena",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22821,
    "pob": "Rasal",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22252,
    "pob": "Robres",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22600,
    "pob": "SabiÑanigo",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22314,
    "pob": "Salas Altas",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22110,
    "pob": "Salillas",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22822,
    "pob": "Salinas De Jaca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22512,
    "pob": "San Esteban De Litera",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22664,
    "pob": "Sandinies",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22100,
    "pob": "Sangarren",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22792,
    "pob": "Santa Cruz De La Seros",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22411,
    "pob": "Santalecina",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22366,
    "pob": "Saravillo",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22200,
    "pob": "SariÑena",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22374,
    "pob": "Sarvise",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22230,
    "pob": "Sena",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22253,
    "pob": "Senes De Alcubierre",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22120,
    "pob": "Sietamo",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22231,
    "pob": "Sigena, Monasterio",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22790,
    "pob": "Siresa",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22752,
    "pob": "Somanes",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22550,
    "pob": "Tamarite De Litera",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22240,
    "pob": "Tardienta",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22376,
    "pob": "Torla",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22254,
    "pob": "Torralba De Aragon",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22391,
    "pob": "Torreciudad, Santuario",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22590,
    "pob": "Torrente De Cinca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22134,
    "pob": "Torres De Montes",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22663,
    "pob": "Tramacastilla De Tena",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22732,
    "pob": "Urdues",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22511,
    "pob": "Valcarca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22223,
    "pob": "Valfarta",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22528,
    "pob": "Velilla De Cinca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22190,
    "pob": "Vicien",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22870,
    "pob": "Villanua",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22530,
    "pob": "Zaidin",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 23211,
    "pob": "Acebuchal",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23488,
    "pob": "Acra",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23369,
    "pob": "Agracea",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23312,
    "pob": "Agrupacion De Mogon",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23311,
    "pob": "Agrupacion Santo Tome",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23538,
    "pob": "Albanchez De Magina",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23680,
    "pob": "Alcala La Real",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23660,
    "pob": "Alcaudete",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23210,
    "pob": "Aldea De Los Rios",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23267,
    "pob": "Aldeahermosa De Montizon",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23215,
    "pob": "Aldeaquemada",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23477,
    "pob": "Almansas, Los",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23485,
    "pob": "Almiceran, El",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23294,
    "pob": "Anchos, Los",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23740,
    "pob": "Andujar",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23193,
    "pob": "Arbuniel",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23760,
    "pob": "Arjona",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23750,
    "pob": "Arjonilla",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23230,
    "pob": "Arquillos",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23300,
    "pob": "Arroturas",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23340,
    "pob": "Arroyo Del Ojanco",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23293,
    "pob": "Arroyo Frio",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23478,
    "pob": "Arroyo Frio (la Iruela)",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23440,
    "pob": "Baeza",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23710,
    "pob": "Bailen",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23711,
    "pob": "BaÑos De La Encina",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23379,
    "pob": "Batan, El",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23310,
    "pob": "Batanejo",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23280,
    "pob": "Beas De Segura",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23537,
    "pob": "Bedmar",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23520,
    "pob": "Begijar",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23489,
    "pob": "Belerda Alta",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23568,
    "pob": "Belmez De La Moraleda",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23390,
    "pob": "Benatae",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23649,
    "pob": "Berrueco, El",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23669,
    "pob": "Bobadilla, La  (alcaudete)",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23529,
    "pob": "Boticario, El",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23479,
    "pob": "Burunchel",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23550,
    "pob": "Cabra De Santo Cristo",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23590,
    "pob": "Cabra De Santo Cristo, Estacion",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23569,
    "pob": "Cabrita, La",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23120,
    "pob": "Cambil",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23130,
    "pob": "Campillo De Arenas",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23519,
    "pob": "Campillo Del Rio",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23269,
    "pob": "Campillo, El",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23420,
    "pob": "Canena",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23289,
    "pob": "CaÑada Catena",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23470,
    "pob": "CaÑada Del Moro",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23292,
    "pob": "CaÑada Morales",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23191,
    "pob": "Carchel",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23192,
    "pob": "Carchelejo",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23200,
    "pob": "Carolina, La",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23614,
    "pob": "Carrasca, La",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23297,
    "pob": "Casicas Del Rio Segura",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23686,
    "pob": "Casillas De Mures",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23260,
    "pob": "Castellar",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23670,
    "pob": "Castillo De Locubin",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23628,
    "pob": "Cazalilla",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23214,
    "pob": "Centenillo, El",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23290,
    "pob": "Cerezo, El",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23190,
    "pob": "Cerradura, La",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23687,
    "pob": "Charilla",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23264,
    "pob": "Chiclana De Segura",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23159,
    "pob": "Chircales",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23509,
    "pob": "Donadio, El",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23657,
    "pob": "EscaÑuela",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23539,
    "pob": "Escuelas, Las",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23486,
    "pob": "Fontanar",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23690,
    "pob": "Frailes",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23610,
    "pob": "Fuensanta De Martos",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23689,
    "pob": "Fuente Alamo",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23692,
    "pob": "Fuente Del Rey",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23180,
    "pob": "Fuerte Del Rey",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23392,
    "pob": "Genave",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23685,
    "pob": "Grageras, Las",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23713,
    "pob": "Guadalen Del Caudillo",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23170,
    "pob": "Guardia De Jaen, La",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23339,
    "pob": "Gutar",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23315,
    "pob": "Herrera, La",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23611,
    "pob": "Higuera De Calatrava",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23468,
    "pob": "Hornos De Peal",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23560,
    "pob": "Huelma",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23487,
    "pob": "Huesa",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23450,
    "pob": "Ibros",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23639,
    "pob": "Infantas, Las",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23476,
    "pob": "Iruela, La",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23338,
    "pob": "Iznatoraf",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23194,
    "pob": "Jabalcuz",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23712,
    "pob": "Jabalquinto",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23000,
    "pob": "Jaen (ver Callejero)",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23658,
    "pob": "Jamilena",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23530,
    "pob": "Jimena",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23500,
    "pob": "Jodar",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23746,
    "pob": "Lahiguera",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23591,
    "pob": "Larva",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23613,
    "pob": "Lendinez",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23700,
    "pob": "Linares",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23490,
    "pob": "Linares-baeza, Estacion",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23747,
    "pob": "Llanos Del Sotillo",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23780,
    "pob": "Lopera",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23528,
    "pob": "Lupion",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23100,
    "pob": "Mancha Real",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23296,
    "pob": "Marchena",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23412,
    "pob": "Marmol, El",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23770,
    "pob": "Marmolejo",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23600,
    "pob": "Martos",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23615,
    "pob": "Media Panilla",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23620,
    "pob": "Mengibar",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23499,
    "pob": "Mira El Rio",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23213,
    "pob": "Miranda Del Rey",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23268,
    "pob": "Mochuelos, Los",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23469,
    "pob": "Molar, El",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23266,
    "pob": "Montizon",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23240,
    "pob": "Navas De San Juan",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23212,
    "pob": "Navas De Tolosa",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23140,
    "pob": "Noalejo",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23393,
    "pob": "Onsares",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23370,
    "pob": "Orcera",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23460,
    "pob": "Peal De Becerro",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23688,
    "pob": "Pedriza, La",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23110,
    "pob": "Pegalajar",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23298,
    "pob": "Peguera Del MadroÑo",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23359,
    "pob": "PeÑolite",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23291,
    "pob": "Ponton Alto",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23790,
    "pob": "Porcuna",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23239,
    "pob": "Porrosillo, El",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23196,
    "pob": "Puente De La Sierra",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23350,
    "pob": "Puente Genave",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23360,
    "pob": "Puerta De Segura, La",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23480,
    "pob": "Quesada",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23749,
    "pob": "Quinteria, La",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23684,
    "pob": "Rabita, La",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23691,
    "pob": "Ribera Alta",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23295,
    "pob": "Rio Madera",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23430,
    "pob": "Rus",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23410,
    "pob": "Sabiote",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23413,
    "pob": "Santa Eulalia",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23612,
    "pob": "Santiago De Calatrava",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23250,
    "pob": "Santisteban Del Puerto",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23380,
    "pob": "Siles",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23314,
    "pob": "Solana De Torralba",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23270,
    "pob": "Sorihuela De Guadalimar",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23526,
    "pob": "Sotogordo",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23640,
    "pob": "Torre Del Campo",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23510,
    "pob": "Torreblascopedro",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23650,
    "pob": "Torredonjimeno",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23320,
    "pob": "Torreperogil",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23638,
    "pob": "Torrequebradilla",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23540,
    "pob": "Torres",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23391,
    "pob": "Torres De Albanchez",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23400,
    "pob": "Ubeda",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23150,
    "pob": "ValdepeÑas De Jaen",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23616,
    "pob": "Veletas",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23265,
    "pob": "Venta De Los Santos",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23693,
    "pob": "Ventas Del Carrizal",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23313,
    "pob": "Veracruz, De, Poblado",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23220,
    "pob": "Vilches",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23730,
    "pob": "Villanueva De La Reina",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23330,
    "pob": "Villanueva Del Arzobispo",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23659,
    "pob": "Villardompardo",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23160,
    "pob": "Villares, Los",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23630,
    "pob": "Villargordo",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23748,
    "pob": "ViÑas De PeÑallana",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23411,
    "pob": "Yedra",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 24891,
    "pob": "Abadengo De Torio",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24397,
    "pob": "Abano",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24145,
    "pob": "Abelgas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24996,
    "pob": "Acebedo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24357,
    "pob": "Acebes Del Paramo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24413,
    "pob": "Acebo De San Miguel",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24877,
    "pob": "Acisa De Las Arrimadas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24859,
    "pob": "Adrados",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24277,
    "pob": "Adrados De Ordas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24133,
    "pob": "Aguasmestas, Ventas De",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24569,
    "pob": "Aguiar",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24521,
    "pob": "Albaredos",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24310,
    "pob": "Albares De La Ribera",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24293,
    "pob": "Albires",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24768,
    "pob": "Alcaidon O Alquidon",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24649,
    "pob": "Alcedo De Alba",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24393,
    "pob": "Alcoba De La Ribera",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24207,
    "pob": "Alcuetas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24391,
    "pob": "Aldea De La Valdoncina",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24920,
    "pob": "Aldea Del Puente",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24960,
    "pob": "Aleje",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24238,
    "pob": "Algadefe",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24199,
    "pob": "Alija De La Ribera",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24761,
    "pob": "Alija Del Infantado",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24374,
    "pob": "Almagarinos",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24170,
    "pob": "Almanza",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24398,
    "pob": "Almazcara",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24838,
    "pob": "Almuzara",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24792,
    "pob": "Altobar De La Encomienda",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24743,
    "pob": "Ambasaguas De Cabrera",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24150,
    "pob": "Ambasaguas De CurueÑo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24524,
    "pob": "Ambasmestas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24127,
    "pob": "Andarraso",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24722,
    "pob": "AndiÑuela",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24488,
    "pob": "Anllares Del Sil",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24796,
    "pob": "Antigua, La",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24251,
    "pob": "Antimio De Abajo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24281,
    "pob": "AntoÑan Del Valle",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24146,
    "pob": "Aralla",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24690,
    "pob": "Arbas Del Puerto",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24547,
    "pob": "Arborbuena",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24227,
    "pob": "Arcahueja",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24171,
    "pob": "Arcayos",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24232,
    "pob": "Ardon",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24329,
    "pob": "Arenillas De Valderaduey",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24546,
    "pob": "Arganza",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24478,
    "pob": "Argayo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24526,
    "pob": "Argenteiro",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24989,
    "pob": "Argovejo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24132,
    "pob": "Arienza",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24845,
    "pob": "Arintero",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24319,
    "pob": "Arlanza",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24284,
    "pob": "Armellada",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24009,
    "pob": "Armunia",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24567,
    "pob": "Arnadelo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24568,
    "pob": "Arnado",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24700,
    "pob": "Astorga",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24849,
    "pob": "Aviados",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24121,
    "pob": "Azadinos",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24272,
    "pob": "Azadon",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24253,
    "pob": "Azares Del Paramo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24740,
    "pob": "Baillo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24525,
    "pob": "Balboa",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24433,
    "pob": "Balouta",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24343,
    "pob": "Banecidas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24746,
    "pob": "BaÑa, La",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24750,
    "pob": "BaÑeza, La",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24429,
    "pob": "Barcena De La Abadia",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24491,
    "pob": "Barcena Del Bierzo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24239,
    "pob": "Bariones De La Vega",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24913,
    "pob": "Barniedo De La Reina",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24441,
    "pob": "Barosa, La",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24394,
    "pob": "Barrientos",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24151,
    "pob": "Barrillos De CurueÑo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24492,
    "pob": "Barrio Compostilla Ii",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24356,
    "pob": "Barrio De Buenos Aires",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24830,
    "pob": "Barrio De La Estacion (matallana)",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24689,
    "pob": "Barrio De La Tercia",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24852,
    "pob": "Barrio De Las Ollas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24609,
    "pob": "Barrios De Gordon, Los",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24148,
    "pob": "Barrios De Luna, Los",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24368,
    "pob": "Barrios De Nistoso, Los",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24137,
    "pob": "Bayos Los",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24608,
    "pob": "Beberino",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24300,
    "pob": "Bembibre",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24396,
    "pob": "Benamarias",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24233,
    "pob": "Benamariel",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24280,
    "pob": "Benavides De Orbigo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24123,
    "pob": "Benllera",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24389,
    "pob": "Benuza",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24252,
    "pob": "Bercianos Del Paramo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24325,
    "pob": "Bercianos Del Real Camino",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24438,
    "pob": "Berlanga Del Bierzo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24885,
    "pob": "Besande",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24124,
    "pob": "Bobia",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24911,
    "pob": "Boca De Huergano",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24860,
    "pob": "Bodas, Las",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24312,
    "pob": "Boeza",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24717,
    "pob": "Boisan",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24714,
    "pob": "Bonillos",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24850,
    "pob": "BoÑar",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24443,
    "pob": "Borrenes",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24414,
    "pob": "Bouzas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24520,
    "pob": "BraÑa, La (villafranca)",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24360,
    "pob": "BraÑuelas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24716,
    "pob": "Brazuelo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24719,
    "pob": "Brimeda",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24648,
    "pob": "Brugos De Fenar",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24437,
    "pob": "Burbia",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24994,
    "pob": "Buron",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24724,
    "pob": "Busnadiego",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24172,
    "pob": "Bustillo De Cea",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24793,
    "pob": "Bustos",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24620,
    "pob": "Cabanillas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24205,
    "pob": "CabaÑas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24412,
    "pob": "CabaÑas Raras",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24234,
    "pob": "CabaÑeros",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24110,
    "pob": "Caboalles De Abajo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24111,
    "pob": "Caboalles De Arriba",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24888,
    "pob": "Cabrera De Almanza",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24224,
    "pob": "Cabreros Del Rio",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24142,
    "pob": "Cabrillanes",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24540,
    "pob": "Cacabelos",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24517,
    "pob": "Cadafresnas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24915,
    "pob": "Cain",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24914,
    "pob": "Caldevilla",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24760,
    "pob": "Calzada De La Valderia",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24342,
    "pob": "Calzada Del Coto",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24883,
    "pob": "Caminayo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24221,
    "pob": "Campazas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24699,
    "pob": "Camplongo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24225,
    "pob": "Campo De Villavidel",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24511,
    "pob": "Campo Del Agua",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24610,
    "pob": "Campo Y SantibaÑez",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24410,
    "pob": "Camponaraya",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24125,
    "pob": "Camposalinas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24197,
    "pob": "Canaleja",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24120,
    "pob": "Canales-la Magdalena",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24848,
    "pob": "Candana De CurueÑo, La",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24152,
    "pob": "Candanedo De BoÑar",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24144,
    "pob": "Candemuela",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24165,
    "pob": "CaÑizal De Rueda",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24918,
    "pob": "Carande",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24206,
    "pob": "Carbajal De Fuentes",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24196,
    "pob": "Carbajal De La Legua",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24161,
    "pob": "Carbajal De Rueda",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24328,
    "pob": "Carbajal De Valderaduey",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24195,
    "pob": "Carbajosa",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24549,
    "pob": "Carracedelo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24544,
    "pob": "Carracedo De Monasterio",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24141,
    "pob": "Carrasconte",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24711,
    "pob": "Carrera De Otero, La",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24270,
    "pob": "Carrizo De La Ribera",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24442,
    "pob": "Carucedo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24688,
    "pob": "Casares De Arbas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24166,
    "pob": "Casasola De Rueda",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24917,
    "pob": "Casasuertes",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24630,
    "pob": "Cascantes",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24742,
    "pob": "Castrillo De Cabrera",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24721,
    "pob": "Castrillo De La Valduerna",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24718,
    "pob": "Castrillo De Los Polvazares",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24327,
    "pob": "Castrillo De Valderaduey",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24163,
    "pob": "Castrillo Del Condado",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24153,
    "pob": "Castro Del Condado",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24344,
    "pob": "CastroaÑe",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24735,
    "pob": "Castrocontrigo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24222,
    "pob": "Castrofuerte",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24314,
    "pob": "Castropodame",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24765,
    "pob": "Castrotierra De La Valduerna",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24323,
    "pob": "Castrotierra De Valmadrigal",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24174,
    "pob": "Cea",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24892,
    "pob": "Cebanico",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24769,
    "pob": "Cebrones Del Rio",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24889,
    "pob": "CegoÑal",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24510,
    "pob": "Cela",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24395,
    "pob": "Celada",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24326,
    "pob": "Celada De Cea",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24392,
    "pob": "Celadilla Del Paramo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24231,
    "pob": "Cembranos",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24257,
    "pob": "Cerecedo De BoÑar",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24893,
    "pob": "Cerezal De La GuzpeÑa",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24844,
    "pob": "Cerulleda",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24723,
    "pob": "Chana De Somoza",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24444,
    "pob": "Chana, La",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24991,
    "pob": "Ciguera",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24660,
    "pob": "CiÑera",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24800,
    "pob": "Cistierna",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24857,
    "pob": "CofiÑal",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24712,
    "pob": "Cogorderos",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24836,
    "pob": "Coladilla",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24313,
    "pob": "Colinas Del Campo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24858,
    "pob": "Colle",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24490,
    "pob": "Columbrianos",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24715,
    "pob": "Combarros",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24228,
    "pob": "Corbillos De La Sobarriba",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24480,
    "pob": "Corbon Del Sil",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24980,
    "pob": "Corniero",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24514,
    "pob": "Corullon",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24143,
    "pob": "Cospedal",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24940,
    "pob": "Cubillas De Rueda",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24545,
    "pob": "Cueto",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24496,
    "pob": "Cuevas Del Sil",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24738,
    "pob": "Cunas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24732,
    "pob": "Curillas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24390,
    "pob": "Dehesas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24730,
    "pob": "Destriana",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24745,
    "pob": "Encinedo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24870,
    "pob": "Ercina, La",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24341,
    "pob": "Escobar De Campos",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24494,
    "pob": "Escobio, El",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24376,
    "pob": "Espina De Tremor",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24439,
    "pob": "Espinareda De Vega",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24274,
    "pob": "Espinosa De La Ribera",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24288,
    "pob": "Estebanez De La Calzada",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24420,
    "pob": "Fabero",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24837,
    "pob": "Felmin",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24415,
    "pob": "Ferradillo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24282,
    "pob": "Ferral Del Bernesga",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24260,
    "pob": "Ferral, Base Militar",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24886,
    "pob": "Ferreras Del Puerto",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24311,
    "pob": "Folgoso De La Ribera",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24378,
    "pob": "Fonfria",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24291,
    "pob": "Fontanil De Los Oteros",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24250,
    "pob": "Fontecha",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24434,
    "pob": "Fontoria",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24878,
    "pob": "Fresnedo De Valdellorma",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24223,
    "pob": "Fresno De La Vega",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24209,
    "pob": "Fuentes De Los Oteros",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24813,
    "pob": "Fuentes De PeÑacorada",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24411,
    "pob": "Fuentes Nuevas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24160,
    "pob": "Garfin",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24285,
    "pob": "Gavilanes",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24294,
    "pob": "Gordoncillo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24340,
    "pob": "Grajal De Campos",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24339,
    "pob": "Grajalejo De Las Matas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24346,
    "pob": "Grulleros",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24287,
    "pob": "Gualtares De Orbigo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24131,
    "pob": "Guisatecha",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24767,
    "pob": "Herreros De Jamuz",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24763,
    "pob": "Hinojo, De, Caserio",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24515,
    "pob": "Hornija",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24516,
    "pob": "Horta",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24286,
    "pob": "Hospital De Orbigo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24283,
    "pob": "Huerga Del Rio",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24530,
    "pob": "Iglesias Del Campo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24741,
    "pob": "Iruela",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24855,
    "pob": "Isoba",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24324,
    "pob": "Joarilla De Las Matas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24440,
    "pob": "Lago De Carucedo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24126,
    "pob": "Lago De OmaÑa",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24248,
    "pob": "Laguna Dalga",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24513,
    "pob": "Landoiro",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24995,
    "pob": "Lario",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24136,
    "pob": "Lazado",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24566,
    "pob": "Leiroso",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24000,
    "pob": "Leon (ver Callejero)",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24457,
    "pob": "Libran",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24428,
    "pob": "Lillo Del Bierzo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24388,
    "pob": "Llamas De Cabrera",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24271,
    "pob": "Llamas De La Ribera",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24114,
    "pob": "Llamas De Laceana",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24843,
    "pob": "Llamazares",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24869,
    "pob": "Llamera",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24912,
    "pob": "Llanaves De La Reina",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24122,
    "pob": "Lorenzana",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24318,
    "pob": "Losada",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24140,
    "pob": "Lumajo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24226,
    "pob": "Mancilleros",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24210,
    "pob": "Mansilla De Las Mulas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24218,
    "pob": "Mansilla Del Esla, Urbanizacion",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24217,
    "pob": "Mansilla Mayor",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24369,
    "pob": "Manzanal Del Puerto",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24744,
    "pob": "Marrubio",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24847,
    "pob": "Mata De La Berbula, La",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24887,
    "pob": "Mata De Monteagudo, La",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24495,
    "pob": "Matalavilla",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24290,
    "pob": "Matallana De Valmadrigal",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24273,
    "pob": "Mataluenga",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24460,
    "pob": "Matarrosa Del Sil",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24359,
    "pob": "Matilla De La Vega",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24820,
    "pob": "Matueca De Torio",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24815,
    "pob": "Modino",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24379,
    "pob": "Montealegre",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24130,
    "pob": "Montrondo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24846,
    "pob": "Montuerto",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24149,
    "pob": "Mora De Luna",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24523,
    "pob": "Moral De Valcarce",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24155,
    "pob": "Moral Del Condado",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24731,
    "pob": "Morales Del Arcediano",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24436,
    "pob": "Moreda",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24884,
    "pob": "Morgovejo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24736,
    "pob": "Morla De La Valderia",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24791,
    "pob": "Moscas Del Paramo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24720,
    "pob": "Murias De Pedredo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24839,
    "pob": "Naredo De Fenar",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24156,
    "pob": "Navafria",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24193,
    "pob": "Navatejera",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24734,
    "pob": "Nogarejas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24811,
    "pob": "Olleros De Sabero",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24134,
    "pob": "OmaÑon",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24113,
    "pob": "Orallo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24916,
    "pob": "Oseja De Sajambre",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24853,
    "pob": "Oville",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24764,
    "pob": "Palacios De La Valduerna",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24890,
    "pob": "Palazuelo De Torio",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24856,
    "pob": "Pallide",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24446,
    "pob": "Paradela De Muces",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24512,
    "pob": "ParadiÑa",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24470,
    "pob": "Paramo Del Sil",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24522,
    "pob": "Pereje",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24854,
    "pob": "Poblado Del Pantano Del Porma",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24375,
    "pob": "Pobladura De Las Regueras",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24249,
    "pob": "Pobladura De Pelayo Garcia",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24600,
    "pob": "Pola De Gordon, La",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24795,
    "pob": "Posadilla De La Vega",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24459,
    "pob": "Pradilla",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24448,
    "pob": "Priaranza Del Bierzo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24479,
    "pob": "Primout",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24880,
    "pob": "Puente Almuhey",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24380,
    "pob": "Puente Domingo Florez",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24548,
    "pob": "Quilos",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24816,
    "pob": "Quintana De La PeÑa",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24930,
    "pob": "Quintana De Rueda",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24762,
    "pob": "Quintana Del Marco",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24733,
    "pob": "Quintanilla De Florez",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24208,
    "pob": "Quintanilla De Oteros",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24990,
    "pob": "Remolina",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24900,
    "pob": "RiaÑo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24766,
    "pob": "Ribas De La Valduerna",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24794,
    "pob": "Riego De La Vega",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24139,
    "pob": "Rioscuro",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24275,
    "pob": "Rioseco De Tapia",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24640,
    "pob": "Robla, La",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24458,
    "pob": "Robledo De Las Traviesas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24387,
    "pob": "Robledo De Sobrecastro",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24687,
    "pob": "Rodiezmo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24315,
    "pob": "Rozuelo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24810,
    "pob": "Sabero",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24812,
    "pob": "Saelices De Sabero",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24320,
    "pob": "Sahagun",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24384,
    "pob": "Salas De La Ribera",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24797,
    "pob": "San Adrian Del Valle",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24317,
    "pob": "San Andres De Los Puentes",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24154,
    "pob": "San Cipriano Del Condado",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24710,
    "pob": "San Justo De La Vega",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24237,
    "pob": "San Millan De Los Caballeros",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24316,
    "pob": "San Pedro CastaÑero",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24879,
    "pob": "San Pedro De Foncallada",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24385,
    "pob": "San Pedro De Trones",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24469,
    "pob": "San Pedro Mallo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24650,
    "pob": "Santa Lucia",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24276,
    "pob": "Santa Maria De Ordas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24240,
    "pob": "Santa Maria Del Paramo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24493,
    "pob": "Santa Marina Del Sil",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24164,
    "pob": "Santa Olaja De Eslonza",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24445,
    "pob": "Santalla",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24330,
    "pob": "Santas Martas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24416,
    "pob": "Santo Tomas De Las Ollas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24550,
    "pob": "Sorribas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24882,
    "pob": "Sota De Valderrueda, La",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24347,
    "pob": "Sotico",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24814,
    "pob": "Sotillos De Sabero",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24713,
    "pob": "Sueros De Cepeda",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24489,
    "pob": "SusaÑe Del Sil",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24497,
    "pob": "Tejedo Del Sil",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24560,
    "pob": "Toral De Los Vados",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24450,
    "pob": "Toreno",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24370,
    "pob": "Torre Del Bierzo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24377,
    "pob": "Tremor De Arriba",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24192,
    "pob": "Trobajo Del Cerecedo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24790,
    "pob": "Valcabado Del Paramo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24220,
    "pob": "Valdefuentes De Valderas",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24230,
    "pob": "Valdevimbre",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24970,
    "pob": "Valdore",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24200,
    "pob": "Valencia De Don Juan",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24435,
    "pob": "Valle De Finolledo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24219,
    "pob": "Valle De Mansilla",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24292,
    "pob": "Valverde Enrique",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24840,
    "pob": "Vecilla De CurueÑo, La",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24430,
    "pob": "Vega De Espinareda",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24607,
    "pob": "Vega De Gordon",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24386,
    "pob": "Vega De Yeres",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24350,
    "pob": "Veguellina De Orbigo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24670,
    "pob": "Vid, La",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24950,
    "pob": "Vidanes",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24191,
    "pob": "Villabalter",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24100,
    "pob": "Villablino",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24565,
    "pob": "Villadepalos",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24162,
    "pob": "VillafaÑe",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24236,
    "pob": "Villafer",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24500,
    "pob": "Villafranca Del Bierzo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24367,
    "pob": "Villagaton",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24112,
    "pob": "Villager De Laciana",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24447,
    "pob": "Villalibre De La Jurisdicion",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24680,
    "pob": "Villamanin",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24345,
    "pob": "Villamarco",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24175,
    "pob": "Villamol",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24135,
    "pob": "Villanueva De OmaÑa",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24235,
    "pob": "Villaquejida",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24138,
    "pob": "Villar De Santiago",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24358,
    "pob": "Villarejo De Orbigo",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24498,
    "pob": "Villarino Del Sil",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24198,
    "pob": "Virgen Del Camino, La",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 25556,
    "pob": "Abella D'adons",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25651,
    "pob": "Abella De La Conca",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25640,
    "pob": "Academia General Basica De Suboficiales",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25717,
    "pob": "Adraen",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25797,
    "pob": "Adrall",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25691,
    "pob": "Ager",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25310,
    "pob": "Agramunt",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25790,
    "pob": "Aguilar De Bassella",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25511,
    "pob": "Aguiro",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25692,
    "pob": "Agullo",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25595,
    "pob": "Aidi",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25515,
    "pob": "Aiguabella",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25573,
    "pob": "Ainet De Besan",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25571,
    "pob": "Ainet De Cardos",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25577,
    "pob": "Aineto",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25182,
    "pob": "Aitona",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25221,
    "pob": "Alamus, Els",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25718,
    "pob": "Alas",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25155,
    "pob": "Albages, L'",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25171,
    "pob": "Albatarrec",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25611,
    "pob": "Alberola",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25135,
    "pob": "Albesa",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25712,
    "pob": "Albet",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25450,
    "pob": "Albi, L'",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25162,
    "pob": "Alcano",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25180,
    "pob": "Alcarras",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25660,
    "pob": "Alcoletge",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25736,
    "pob": "Alentorn",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25120,
    "pob": "Alfarras",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25161,
    "pob": "Alfes",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25130,
    "pob": "Algerri",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25125,
    "pob": "Alguaire",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25574,
    "pob": "Alins",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25794,
    "pob": "Alinya",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25100,
    "pob": "Almacelles",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25261,
    "pob": "Almassor",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25187,
    "pob": "Almatret",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25126,
    "pob": "Almenar",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25331,
    "pob": "Almenara Alta",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25737,
    "pob": "Alos De Balaguer",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25586,
    "pob": "Alos D'isil",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25110,
    "pob": "Alpicat",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25632,
    "pob": "Alsamora",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25215,
    "pob": "Alta-riba",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25289,
    "pob": "Altes",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25350,
    "pob": "Altet",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25567,
    "pob": "Altron",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25748,
    "pob": "Alzina, L' (vilanova De L'aguda)",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25217,
    "pob": "Ametlla De Segarra, L'",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25271,
    "pob": "Amoros",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25572,
    "pob": "Anas",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25591,
    "pob": "Ancs",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25121,
    "pob": "Andani",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25000,
    "pob": "Andorra La Vella (no Dispone De Codigos Postales)",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25320,
    "pob": "Anglesola",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25798,
    "pob": "Anserall",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25722,
    "pob": "Ansovell",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25513,
    "pob": "Antist",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25738,
    "pob": "Anya",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25518,
    "pob": "Aramunt",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25726,
    "pob": "Aranser",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25638,
    "pob": "Aransis",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25218,
    "pob": "Aranyo, L'",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25140,
    "pob": "Arbeca",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25593,
    "pob": "Arcalis",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25799,
    "pob": "Arcavell",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25144,
    "pob": "Arcs, Els",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25287,
    "pob": "Ardevol De Pinos",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25727,
    "pob": "Ardovol",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25587,
    "pob": "Ares, Les",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25575,
    "pob": "Areu",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25713,
    "pob": "Arfa",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25795,
    "pob": "Argestues",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25551,
    "pob": "Arres De Jos",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25537,
    "pob": "Arros",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25150,
    "pob": "Artesa De Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25730,
    "pob": "Artesa De Segre",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25599,
    "pob": "Arties",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25151,
    "pob": "Aspa",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25612,
    "pob": "Avellanes, Les",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25714,
    "pob": "Avellanet",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25555,
    "pob": "Avellanos",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25592,
    "pob": "Baen",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25598,
    "pob": "Bagergue",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25600,
    "pob": "Balaguer",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25721,
    "pob": "Baltarga",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25723,
    "pob": "Bar",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25262,
    "pob": "Barbens",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25615,
    "pob": "Baronia De Sant Oisme, La",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25527,
    "pob": "Barruera",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25715,
    "pob": "Bastida D'hortons, La",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25655,
    "pob": "Basturs",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25549,
    "pob": "Bausen",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25725,
    "pob": "Beixec",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25266,
    "pob": "Belianes",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25177,
    "pob": "Bellaguarda",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25337,
    "pob": "Bellcaire D'urgell",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25335,
    "pob": "Bellestar (penelles)",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25747,
    "pob": "Bellfort",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25220,
    "pob": "Bell-lloc D'urgell",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25213,
    "pob": "Bellmunt (talavera)",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25336,
    "pob": "Bellmunt D'urgell",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25250,
    "pob": "Bellpuig",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25211,
    "pob": "Bellvei",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25720,
    "pob": "Bellver De Cerdanya",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25318,
    "pob": "Bellver D'osso",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25142,
    "pob": "Bellvis",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25132,
    "pob": "Benavent De Segria",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25658,
    "pob": "Benavent De Tremp",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25594,
    "pob": "Berani",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25510,
    "pob": "Beranui",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25597,
    "pob": "Berros Jussa",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25719,
    "pob": "Bescaran",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25286,
    "pob": "Besora",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25539,
    "pob": "Betren",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25752,
    "pob": "Biosca",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25657,
    "pob": "Biscarri",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25528,
    "pob": "Boi",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25652,
    "pob": "Boixols",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25332,
    "pob": "Boldu",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25554,
    "pob": "Bordes, Les (el Pont De Suert)",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25548,
    "pob": "Bordius",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25400,
    "pob": "Borges Blanques, Les",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25550,
    "pob": "Bossost",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25178,
    "pob": "Bovera",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25568,
    "pob": "Bressui",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25588,
    "pob": "Burgo",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25514,
    "pob": "Burguet, El",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25315,
    "pob": "Butsenit (montgai)",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25526,
    "pob": "Cabanasses",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25613,
    "pob": "Camarasa",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25283,
    "pob": "Cambrils I Llinars",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25285,
    "pob": "Casa Nova De Valls, La",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25538,
    "pob": "Casau",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25552,
    "pob": "Casos",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25333,
    "pob": "Castell Del Remei, El",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25529,
    "pob": "Castellars (el Pont De Suert)",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25710,
    "pob": "Castellciutat",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25154,
    "pob": "Castelldans",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25792,
    "pob": "Castellnou De Bassella",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25265,
    "pob": "Castellnou De Seana",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25214,
    "pob": "Castellnou D'oluges",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25136,
    "pob": "Castello De Farfanya",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25334,
    "pob": "Castellsera",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25280,
    "pob": "Castellvell",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25635,
    "pob": "Castissent",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25631,
    "pob": "Cellers",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25751,
    "pob": "Cellers De Tora",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25200,
    "pob": "Cervera",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25460,
    "pob": "Cervia De Les Garrigues",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25341,
    "pob": "Ciutadilla",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25634,
    "pob": "Claramunt",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25353,
    "pob": "Claravalls",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25749,
    "pob": "Claret (oliola)",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25290,
    "pob": "Clariana De Cardener",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25517,
    "pob": "Claverol",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25152,
    "pob": "Cogul, El",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25793,
    "pob": "Coll De Nargo",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25739,
    "pob": "Colldelrat",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25124,
    "pob": "Colonia Al Kanis",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25284,
    "pob": "Coma, La",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25656,
    "pob": "Conques",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25137,
    "pob": "Corbins",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25653,
    "pob": "Covet",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25317,
    "pob": "Donzell D'urgell",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25566,
    "pob": "Embonui",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25516,
    "pob": "Erinya",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25596,
    "pob": "Escalo",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25410,
    "pob": "Espluga Calba, L'",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25580,
    "pob": "Esterri D'aneu",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25216,
    "pob": "Ferran",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25351,
    "pob": "Figuerosa, La",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25413,
    "pob": "Floresta, La",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25244,
    "pob": "Fondarella",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25639,
    "pob": "Fontsagrada",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25411,
    "pob": "Fulleda",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25614,
    "pob": "Gerb",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25590,
    "pob": "Gerri De La Sal",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25112,
    "pob": "Gimenells",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25241,
    "pob": "Golmes",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25716,
    "pob": "Gosol",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25185,
    "pob": "Granja D'escarp, La",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25160,
    "pob": "Granyena De Les Garrigues",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25210,
    "pob": "Guissona",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25636,
    "pob": "Gurp",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25281,
    "pob": "Hostal Nou, L'",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25617,
    "pob": "Hostal-nou I La Codosa, L'",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25650,
    "pob": "Isona",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25122,
    "pob": "Ivars De Noguera",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25260,
    "pob": "Ivars D'urgell",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25165,
    "pob": "Juncosa",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25430,
    "pob": "Juneda",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25540,
    "pob": "Les",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25240,
    "pob": "Linyola",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25576,
    "pob": "Lladorre",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25186,
    "pob": "Llardecans",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25288,
    "pob": "Llena, La",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25753,
    "pob": "Lloberola",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25267,
    "pob": "LlorenÇ De Rocafort",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25179,
    "pob": "Maials",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25569,
    "pob": "Malmercat",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25724,
    "pob": "Martinet",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25340,
    "pob": "Mas De Bondia, El",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25109,
    "pob": "Mas Del Lleo, El",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25184,
    "pob": "Massalc0reig",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25139,
    "pob": "Menarguens",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25242,
    "pob": "Miralcamp",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25791,
    "pob": "Miralpeix",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25512,
    "pob": "Molinos",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25230,
    "pob": "Mollerussa",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25268,
    "pob": "Montblanquet",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25711,
    "pob": "Montferrer",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25616,
    "pob": "Montgai",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25172,
    "pob": "Montoliu De Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25212,
    "pob": "Mont-roig De Segarra",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25245,
    "pob": "Novella Alta",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25412,
    "pob": "Omellons, Els",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25610,
    "pob": "Os De Balaguer",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25788,
    "pob": "Os De Civis",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25243,
    "pob": "Palau D'anglesola, El",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25633,
    "pob": "Palau De Noguera",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25114,
    "pob": "Pla De La Font, El",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25796,
    "pob": "Pla De Sant Tirs, El",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25143,
    "pob": "Poal, El",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25471,
    "pob": "Pobla De Cervoles, La",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25500,
    "pob": "Pobla De Segur, La",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25520,
    "pob": "Pont De Suert, El",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25740,
    "pob": "Ponts",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25134,
    "pob": "Portella, La",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25316,
    "pob": "Pradell",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25263,
    "pob": "Preixana",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25420,
    "pob": "Puiggros",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25153,
    "pob": "Puigverd De Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25111,
    "pob": "Raimat",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25570,
    "pob": "Ribera De Cardos",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25352,
    "pob": "Riudovelles",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25693,
    "pob": "Rivert",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25344,
    "pob": "Rocafort De Vallbona",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25269,
    "pob": "Rocallaura",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25637,
    "pob": "Sant Adria",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25270,
    "pob": "Sant Guim De Freixenet",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25282,
    "pob": "Sant LlorenÇ De Morunys",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25700,
    "pob": "Santa Magdalena",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25354,
    "pob": "Santa Maria De Montmagastrell",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25175,
    "pob": "Sarroca De Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25553,
    "pob": "Senet",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25183,
    "pob": "Seros",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25222,
    "pob": "Sidamon",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25163,
    "pob": "Soleras, El",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25589,
    "pob": "Son",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25560,
    "pob": "Sort",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25181,
    "pob": "Soses",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25113,
    "pob": "Sucs",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25173,
    "pob": "Sudanell",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25174,
    "pob": "Sunyer",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25654,
    "pob": "Suterranya",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25630,
    "pob": "Talarn",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25360,
    "pob": "Talladell, El",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25300,
    "pob": "Tarrega",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25480,
    "pob": "Tarres",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25670,
    "pob": "Termens",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25750,
    "pob": "Tora",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25164,
    "pob": "Torms, Els",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25176,
    "pob": "Torrebesses",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25746,
    "pob": "Torreblanca",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25123,
    "pob": "Torrefarrera",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25141,
    "pob": "Torregrossa",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25138,
    "pob": "Torrelameu",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25170,
    "pob": "Torres De Segre",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25131,
    "pob": "Torre-serona",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25620,
    "pob": "Tremp",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25680,
    "pob": "Vallfogona De Balaguer",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25530,
    "pob": "Vielha/viella",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25330,
    "pob": "Vilagrassa",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25264,
    "pob": "Vilanova De Bellpuig",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25690,
    "pob": "Vilanova De La Barca",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25735,
    "pob": "Vilanova De Meia",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25133,
    "pob": "Vilanova De Segria",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25343,
    "pob": "Vilet, El",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25457,
    "pob": "Vilosell, El",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25440,
    "pob": "Vinaixa",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 26339,
    "pob": "Abalos",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26160,
    "pob": "Aeropuerto De Algoncillo",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26530,
    "pob": "Aguilar Del Rio Alhama",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26133,
    "pob": "Ajamil",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26120,
    "pob": "Albelda De Iregua",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26141,
    "pob": "Alberite",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26509,
    "pob": "Alcanadre",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26145,
    "pob": "Aldealobos",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26123,
    "pob": "Aldeanueva De Cameros",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26559,
    "pob": "Aldeanueva De Ebro",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26324,
    "pob": "Alesanco",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26315,
    "pob": "Aleson",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26540,
    "pob": "Alfaro",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26111,
    "pob": "Almarza De Cameros",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26586,
    "pob": "Ambasaguas",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26270,
    "pob": "Amunartia",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26210,
    "pob": "Anguciana",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26322,
    "pob": "Anguiano",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26288,
    "pob": "Anguta",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26311,
    "pob": "Arenzana De Abajo",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26312,
    "pob": "Arenzana De Arriba",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26589,
    "pob": "Arnedillo",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26580,
    "pob": "Arnedo",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26151,
    "pob": "Arrubal",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26513,
    "pob": "Ausejo",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26560,
    "pob": "Autol",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26289,
    "pob": "Ayabarrena",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26323,
    "pob": "Azofra",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26310,
    "pob": "Badaran",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26257,
    "pob": "BaÑares",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26320,
    "pob": "BaÑos De Rio Tobia",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26241,
    "pob": "BaÑos De Rioja",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26327,
    "pob": "Berceo",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26588,
    "pob": "Bergasa",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26321,
    "pob": "Bobadilla",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26290,
    "pob": "BriÑas",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26330,
    "pob": "Briones",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26132,
    "pob": "Bucesta",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26135,
    "pob": "Cabezon De Cameros",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26529,
    "pob": "Cabreton",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26500,
    "pob": "Calahorra",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26329,
    "pob": "Canales De La Sierra",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26325,
    "pob": "Canillas De Rio Tuerto",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26230,
    "pob": "Casalarreina",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26291,
    "pob": "Casas Blancas",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26121,
    "pob": "CastaÑares De Las Cuevas",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26240,
    "pob": "CastaÑares De Rioja",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26350,
    "pob": "Cenicero",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26520,
    "pob": "Cervera Del Rio Alhama",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26258,
    "pob": "CiriÑuela",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26130,
    "pob": "Clavijo",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26144,
    "pob": "Corera",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26526,
    "pob": "Cornago",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26259,
    "pob": "Corporales",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26000,
    "pob": "Cortijo, El (ver Callejero LogroÑo)",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26214,
    "pob": "Cuzcurrita De Rio Tiron",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26373,
    "pob": "Daroca De Rioja",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26375,
    "pob": "Entrena",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26328,
    "pob": "Estollo",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26340,
    "pob": "Estrella, Noviciado De La",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26280,
    "pob": "Ezcaray",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26211,
    "pob": "Foncea",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26360,
    "pob": "Fuenmayor",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26122,
    "pob": "Gallinero De Cameros",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26221,
    "pob": "Gimileo",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26587,
    "pob": "Gravalos",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26200,
    "pob": "Haro",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26584,
    "pob": "Herce",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26213,
    "pob": "Herramelluri",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26126,
    "pob": "Horcajo, El",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26223,
    "pob": "Hormilleja",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26372,
    "pob": "Hornos De Moncalvillo",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26314,
    "pob": "Huercanos",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26525,
    "pob": "Igea",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26531,
    "pob": "Inestrillas",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26134,
    "pob": "Jalon De Cameros",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26131,
    "pob": "Jubera",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26140,
    "pob": "Lardero",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26374,
    "pob": "Medrano",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26124,
    "pob": "Montemediano",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26143,
    "pob": "Murillo De Rio Leza",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26300,
    "pob": "Najera",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26190,
    "pob": "Nalda",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26533,
    "pob": "Navajun",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26370,
    "pob": "Navarrete",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26110,
    "pob": "Nestares",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26148,
    "pob": "Ocon",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26220,
    "pob": "Ollauri",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26261,
    "pob": "Pazuengos",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26147,
    "pob": "Pipaona",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26510,
    "pob": "Pradejon",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26570,
    "pob": "Quel",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26146,
    "pob": "Redal, El",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26100,
    "pob": "Ribabellosa",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26550,
    "pob": "Rincon De Soto",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26326,
    "pob": "Rio, El",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26222,
    "pob": "Rodezno",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26292,
    "pob": "San Felices",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26216,
    "pob": "San Millan De Yecora",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26338,
    "pob": "San Vicente De La Sonsierra",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26585,
    "pob": "Santa Eulalia Bajera",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26250,
    "pob": "Santo Domingo De La Calzada",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26260,
    "pob": "Santurde",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26150,
    "pob": "Sequero, El  (agoncillo), Poligono Industrial",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26376,
    "pob": "Sojuela",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26313,
    "pob": "Somalo",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26191,
    "pob": "Sorzano",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26371,
    "pob": "Sotes",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26224,
    "pob": "Torrecilla Sobre Alesanco",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26359,
    "pob": "Torremontalbo",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26215,
    "pob": "Treviana",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26512,
    "pob": "Tudelilla",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26532,
    "pob": "Valdemadera",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26527,
    "pob": "Valdeperillo",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26528,
    "pob": "Valverde",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26256,
    "pob": "Villalobar De Rioja",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26142,
    "pob": "Villamediana De Iregua",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26511,
    "pob": "Villar De Arnedo, El",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26125,
    "pob": "Villoslada De Cameros",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 27730,
    "pob": "Abadin (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27164,
    "pob": "Abragan",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27546,
    "pob": "Abuime",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27437,
    "pob": "Acedre",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27243,
    "pob": "Acevo (santiago)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27511,
    "pob": "Ada",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27297,
    "pob": "Adai (santa Maria Magdalena)(lugo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27363,
    "pob": "Adai (santa MariÑa)(paramo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27162,
    "pob": "Adai (santiago) (corgo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27773,
    "pob": "Adelan",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27513,
    "pob": "Agrade, A (san Vicente)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27529,
    "pob": "Aguada (santa Baia)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27248,
    "pob": "Aguarda, A (san MartiÑo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27205,
    "pob": "Aguas Santas (san Xurxo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27678,
    "pob": "AgÜeira (san Xoan)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27578,
    "pob": "Aguela",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27153,
    "pob": "Aguiar (san Clodio)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27143,
    "pob": "Agustin",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27813,
    "pob": "Alba (san Xoan)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27207,
    "pob": "Alba (santiago De)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27619,
    "pob": "Alban (santa Maria)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27843,
    "pob": "Aldixe (san Pedro)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27611,
    "pob": "Aldosende",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27744,
    "pob": "Aldurfe (san Pedro)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27677,
    "pob": "Alence, A (santa Lucia)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27776,
    "pob": "Alfoz (alfoz)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27639,
    "pob": "Alfoz (triacastela)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27113,
    "pob": "Allonca, A",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27230,
    "pob": "Alta",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27233,
    "pob": "Alto (san Xoan) (lugo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27579,
    "pob": "Alvidron",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27423,
    "pob": "Amandi",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27576,
    "pob": "Amarante (san MartiÑo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27662,
    "pob": "Ambasvias (santalla)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27836,
    "pob": "Ambosores (santa Maria) (muras)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27865,
    "pob": "Ambosores (santa Maria) (ourol)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27204,
    "pob": "Ambreixo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27229,
    "pob": "Anafreita",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27270,
    "pob": "Andion",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27466,
    "pob": "Anllo (san MartiÑo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27554,
    "pob": "Ansar (santo Estebo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27163,
    "pob": "Ansean",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27259,
    "pob": "Ansemar",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27570,
    "pob": "Antas De Ulla (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27160,
    "pob": "Anxos (san Mamede)(nadela)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27235,
    "pob": "Apregacion (san Cibrao) (friol)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27568,
    "pob": "Arada",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27714,
    "pob": "Arante",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27689,
    "pob": "Aranza",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27818,
    "pob": "Arbol (san Lorenzo) (villalba)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27577,
    "pob": "Arbol (sta Eulalia) (antas De Ulla)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27379,
    "pob": "Arcilla",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27142,
    "pob": "Arcos (san Paio) (castroverde)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27150,
    "pob": "Arcos (san Pedro) (otero De Rey)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27279,
    "pob": "Arcos (santiago) (pol)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27518,
    "pob": "Arcos (sta Maria) (chantada)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27748,
    "pob": "Argomoso (san Pedro)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27294,
    "pob": "Arieiras, Das",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27693,
    "pob": "Armea (san Pedro) (lancara)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27649,
    "pob": "Armesto (san Roman)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27832,
    "pob": "Arredoada, A",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27515,
    "pob": "Arriba (santiago) (chantada)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27115,
    "pob": "Arroxo (san MartiÑo) (fonsagrada)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27460,
    "pob": "Arroxo (san MartiÑo)(sober)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27687,
    "pob": "Arroxo (san Xoan)(baralla)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27166,
    "pob": "Arrubial",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27168,
    "pob": "Arxemil (san Pedro)(corgo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27558,
    "pob": "Arxiz",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27516,
    "pob": "Asma (san Fiz)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27519,
    "pob": "Asma (san Salvador)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27527,
    "pob": "Asma (santa Cristina)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27157,
    "pob": "Aspai (san Cibrao)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27419,
    "pob": "Atan",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27391,
    "pob": "Augas Mestas (santiago)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27257,
    "pob": "Azumara",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27371,
    "pob": "Baamonde",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27417,
    "pob": "Baamorto",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27232,
    "pob": "Bacurin",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27178,
    "pob": "Bagude",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27569,
    "pob": "Balboa (san Salvador) (monterroso)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27130,
    "pob": "Baleira (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27631,
    "pob": "Balsa (san Breixo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27817,
    "pob": "Balsa, A (santa Maria) (muras)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27246,
    "pob": "Baltar",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27367,
    "pob": "Bande",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27680,
    "pob": "Baralla (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27612,
    "pob": "Baran",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27421,
    "pob": "Barantes",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27616,
    "pob": "Barbadelo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27656,
    "pob": "Barcia (san Miguel)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27341,
    "pob": "Bardaos (san Xoan)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27845,
    "pob": "Baroncelle",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27127,
    "pob": "Barredo (san Xoan)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27790,
    "pob": "Barreiros (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27124,
    "pob": "Barreiros (san Cosme) (castroverde)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27520,
    "pob": "Barrela, A",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27412,
    "pob": "Bascos",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27146,
    "pob": "Bascuas",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27112,
    "pob": "Bastida, A (san Miguel)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27258,
    "pob": "Bazar (san Pedro) (castro De Rey)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27210,
    "pob": "Bazar (san Remixio)(lugo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27531,
    "pob": "Beascos (santa MariÑa)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27640,
    "pob": "Becerrea (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27305,
    "pob": "Becin",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27373,
    "pob": "Begonte",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27514,
    "pob": "Belesar (san Bartolomeu) (chantada)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27814,
    "pob": "Belesar (san Martin) (villalba)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27556,
    "pob": "Bembibre",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27191,
    "pob": "Benade",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27289,
    "pob": "Bendia",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27329,
    "pob": "Bendillo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27792,
    "pob": "Benquerencia",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27206,
    "pob": "Berbetoros (san Miguel)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27686,
    "pob": "Berselos",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27617,
    "pob": "Betote (san Vicenzo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27377,
    "pob": "Bexan (san Paio)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27296,
    "pob": "Bocamaos",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27722,
    "pob": "Bogo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27864,
    "pob": "Boimente",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27122,
    "pob": "BolaÑo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27425,
    "pob": "Bolmente",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27555,
    "pob": "Bouzoa (san Xoan)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27340,
    "pob": "Boveda (san MartiÑo)(boveda)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27372,
    "pob": "Boveda (santa Eulalia) (begonte)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27140,
    "pob": "Boveda (santa Maria) (lugo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27277,
    "pob": "BraÑa, A (san Miguel)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27867,
    "pob": "Bravos (santiago)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27331,
    "pob": "Brence (san Xoan)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27286,
    "pob": "BretoÑa",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27135,
    "pob": "Bruicedo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27528,
    "pob": "Bubal (san Salvador, De)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27779,
    "pob": "Budian (santa Eulalia)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27422,
    "pob": "Bulso",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27880,
    "pob": "Burela",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27306,
    "pob": "Buriz (san Pedro)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27328,
    "pob": "Bustelo De Fisteus",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27203,
    "pob": "Cabana (santiago)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27868,
    "pob": "Cabanas (santa Maria) (vicedo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27651,
    "pob": "Cabanela (santa Maria)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27798,
    "pob": "Cabarcos (san Xulian)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27374,
    "pob": "Caboi (san MartiÑo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27177,
    "pob": "Caborrecelle",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27834,
    "pob": "Cabreiros",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27287,
    "pob": "Cadavedo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27648,
    "pob": "Cadoalla",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27777,
    "pob": "Cadramon, O (san Xurxo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27299,
    "pob": "Camoira",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27182,
    "pob": "Campo (san Xoan)(lugo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27559,
    "pob": "Campo (san Xulian)(taboada)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27440,
    "pob": "Canaval",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27634,
    "pob": "Cancelo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27849,
    "pob": "Candia",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27415,
    "pob": "Caneda, A",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27335,
    "pob": "Canedo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27892,
    "pob": "Cangas (san Pedro) (foz)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27272,
    "pob": "Carazo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27208,
    "pob": "Carballal (san Mamede)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27110,
    "pob": "Carballido (santa Maria) (a Fonsagrada)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27228,
    "pob": "Carballo (san Xiao)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27550,
    "pob": "Carballo (santo Tome) (taboada)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27217,
    "pob": "Carteire",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27695,
    "pob": "Cascalla",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27658,
    "pob": "CastaÑedo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27664,
    "pob": "Castelo (san Pedro)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27888,
    "pob": "Castelo (san Xiao) (cervo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27190,
    "pob": "Castelo (santiago) (lugo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27185,
    "pob": "Castelo De Pallares (san Salvador)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27152,
    "pob": "Castelo De Rei (san Salvador)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27610,
    "pob": "Castelo Dos Infantes",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27438,
    "pob": "Castillon (san Vicente)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27530,
    "pob": "Castro (san Cristobo) (carballedo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27213,
    "pob": "Castro (san Martin) (portomarin)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27250,
    "pob": "Castro De Rei (castro De Rei)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27260,
    "pob": "Castro Riberas De Lea",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27188,
    "pob": "Castromaior (sta Maria) (portomarin)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27635,
    "pob": "Castroncan",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27334,
    "pob": "Castroncelos",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27332,
    "pob": "Castrosante",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27120,
    "pob": "Castroverde (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27824,
    "pob": "Cazas",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27671,
    "pob": "Cebreiro",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27711,
    "pob": "Cedofeita",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27692,
    "pob": "Cedron",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27151,
    "pob": "Cela (santa Maria) (otero De Rey)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27863,
    "pob": "Celeiro (santiago) (viveiro)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27614,
    "pob": "Celtigos",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27666,
    "pob": "Cereixedo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27342,
    "pob": "Cervela, A (san Cristobo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27891,
    "pob": "Cervo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27618,
    "pob": "Cesar",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27169,
    "pob": "Chamoso (san Cristobo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27500,
    "pob": "Chantada (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27418,
    "pob": "Chao De Fabeiro, O",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27245,
    "pob": "Chao De Pousadoiro, O",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27411,
    "pob": "Chavaga",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27544,
    "pob": "Chave (san SadurniÑo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27392,
    "pob": "Chorente",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27532,
    "pob": "Chouzan",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27596,
    "pob": "Cicillon",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27273,
    "pob": "Cirio",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27825,
    "pob": "Codesido",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27181,
    "pob": "Coeses",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27728,
    "pob": "Conforto",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27685,
    "pob": "Constantin",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27131,
    "pob": "Corneas",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27690,
    "pob": "Corvelle (santa Maria) (sarria)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27376,
    "pob": "Cospeito (santa Maria)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27815,
    "pob": "Costa (san Simon) (vilalba)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27749,
    "pob": "Couboeira (santa Maria Madanela)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27628,
    "pob": "Couto (san Mamede)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27548,
    "pob": "Cova, A (san MartiÑo) (o SaviÑao)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27861,
    "pob": "Covas (san Xoan) (viveiro)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27125,
    "pob": "Covelas (san Miguel) (castroverde)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27679,
    "pob": "Cruzul",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27278,
    "pob": "Cubilledo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27116,
    "pob": "CuiÑas",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27215,
    "pob": "Cumbraos De Abaixo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27375,
    "pob": "Damil (el Salvador)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27133,
    "pob": "Degolada, A",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27796,
    "pob": "Devesa, A (santalla)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27822,
    "pob": "Distriz (san Martin) (villalba)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27424,
    "pob": "Doade",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27676,
    "pob": "Doncos",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27667,
    "pob": "Donis (san Fiz)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27271,
    "pob": "Duancos",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27269,
    "pob": "Duarria",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27439,
    "pob": "Eire",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27547,
    "pob": "Eirexafeita",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27345,
    "pob": "Eirexalba",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27390,
    "pob": "EnciÑeira, A (santa Isabel)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27234,
    "pob": "Entrambasaguas",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27540,
    "pob": "Escairon (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27450,
    "pob": "Espasantes",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27324,
    "pob": "Esperante (san Pedro) (folgoso De Caurel)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27738,
    "pob": "Fanoy (santa Maria Magdalena)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27789,
    "pob": "Fazouro (santiago)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27770,
    "pob": "Ferreira (santa Maria) (valadouro, O)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27211,
    "pob": "Ferreira De Pallares",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27430,
    "pob": "Ferreira De Panton (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27283,
    "pob": "Ferreiros (san Andres) (pol)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27615,
    "pob": "Ferreiros (san SadurniÑo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27336,
    "pob": "Ferreiros (san Salvador)(pobra De Brollon)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27647,
    "pob": "Ferreiros Balboa (becerrea)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27325,
    "pob": "Ferreiros De Abaixo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27187,
    "pob": "Fiz De Rozas",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27346,
    "pob": "Foilebar",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27145,
    "pob": "Folgosa (san Estebo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27659,
    "pob": "Folgueiras",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27100,
    "pob": "Fonsagrada (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27698,
    "pob": "Fontaron (santi Spiritus)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27636,
    "pob": "Formigueiros",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27768,
    "pob": "Fornea, A (santo Estevo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27780,
    "pob": "Foz (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27186,
    "pob": "Francos (el Salvador)(guntin)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27155,
    "pob": "Francos (santiago) (otero De Rey)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27344,
    "pob": "Freituxe (santiago)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27624,
    "pob": "Freixo (san Silvestre) (samos)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27134,
    "pob": "Freixo (san Xulian) (a Fonsagrada)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27220,
    "pob": "Friol (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27637,
    "pob": "Froian (san Vicente)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27638,
    "pob": "Frollais",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27123,
    "pob": "Furis De Abaixo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27657,
    "pob": "Galegos (santiago)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27378,
    "pob": "Goa",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27840,
    "pob": "Goiriz",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27821,
    "pob": "Gondaisque",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27293,
    "pob": "Gondar",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27890,
    "pob": "Gondras",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27183,
    "pob": "Grolos",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27154,
    "pob": "Guillar (san MartiÑo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27300,
    "pob": "Guitiriz (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27416,
    "pob": "Gullade",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27469,
    "pob": "Gundivos",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27623,
    "pob": "Gundriz",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27280,
    "pob": "Hermunde",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27320,
    "pob": "Hospital, 0 (san Salvador)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27347,
    "pob": "Hospital, O (san Pedro)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27837,
    "pob": "Irixoa",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27737,
    "pob": "Labrada (san Pedro) (abadin)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27307,
    "pob": "Labrada (santa Maria) (guitiriz)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27811,
    "pob": "Ladra (o Salvador)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27878,
    "pob": "Lago (santa Eulalia)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27774,
    "pob": "Lagoa, A (san Vicente) (alfoz)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27309,
    "pob": "Lagostelle (santa Marina)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27348,
    "pob": "Laiosa (san MartiÑo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27339,
    "pob": "Lamaiglesia",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27226,
    "pob": "Lamas (santa Maria) (friol)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27117,
    "pob": "Lamas De Moreira",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27632,
    "pob": "Lamas Do Biduedo (santo Isidro)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27866,
    "pob": "Landrove (san Xiao)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27816,
    "pob": "Lanzos (san MartiÑo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27512,
    "pob": "Laxe (san Xoan)(chantada)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27778,
    "pob": "Laxe, A (san Xoan)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27216,
    "pob": "Lestedo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27688,
    "pob": "Lexo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27742,
    "pob": "Lindin",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27413,
    "pob": "LiÑares (san Cosme)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27266,
    "pob": "Loentia",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27627,
    "pob": "Loureiro (sta Maria) (samos)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27760,
    "pob": "Lourenza (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27751,
    "pob": "Lourenza (santo Tome)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27835,
    "pob": "Lousada (san Andres) (xermade)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27673,
    "pob": "Lousada (san Vicente) (pedrafita)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27625,
    "pob": "Louzara (san Cristobo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27626,
    "pob": "Louzara (san Xoan)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27672,
    "pob": "Louzarela",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27247,
    "pob": "Lua",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27000,
    "pob": "Lugo (ver Callejero)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27111,
    "pob": "Maderne (san Pedro)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27850,
    "pob": "Magazos (santa Maria)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27308,
    "pob": "Mariz (santa Eulalia) (guitiriz)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27349,
    "pob": "Martin (san Cristobo) (boveda)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27276,
    "pob": "Martin (santiago) (baleira)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27844,
    "pob": "MartiÑan",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27209,
    "pob": "Marza",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27549,
    "pob": "Mato (santo Estebo)(panton)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27282,
    "pob": "Matodoso, O",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27290,
    "pob": "Mazoi",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27240,
    "pob": "Meira (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27517,
    "pob": "Merlan (santo Tome) (chantada)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27557,
    "pob": "Mesonfrio (santa Maria)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27533,
    "pob": "Milleiros (san Xoan) (carballedo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27275,
    "pob": "Milleiros (santiago) (pol)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27144,
    "pob": "Miranda",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27833,
    "pob": "Miraz (san Pedro) (xermade)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27655,
    "pob": "Moia",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27826,
    "pob": "Moman (san Mamede)(xermade)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27740,
    "pob": "MondoÑedo (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27400,
    "pob": "Monforte De Lemos (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27879,
    "pob": "Monte, O (santo Isidoro)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27128,
    "pob": "Montecubeiro (san Cibrao)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27560,
    "pob": "Monterroso (sector Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27876,
    "pob": "Moras (san Clemente)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27592,
    "pob": "Moreda (san Roman) (panton)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27268,
    "pob": "Mos",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27364,
    "pob": "Moscan",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27184,
    "pob": "Mosteiro (santa Maria) (guntin)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27665,
    "pob": "Mosteiro, O (san Xoan)(cervantes)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27820,
    "pob": "Mourence",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27510,
    "pob": "Mouricios",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27652,
    "pob": "MuÑis",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27368,
    "pob": "Muro",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27192,
    "pob": "Muxa (san Salvador)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27743,
    "pob": "Muxueira, A",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27244,
    "pob": "Navallos (san Pedro) (ribeira De Piquin)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27650,
    "pob": "Navia De Suarna (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27696,
    "pob": "Neira De Rey (san MartiÑo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27468,
    "pob": "Neiras",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27212,
    "pob": "Nespereira (san Cipriano) (portomarin)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27613,
    "pob": "Nespereira (santiago) (sarria)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27675,
    "pob": "Noceda (san Xoan) (nogais, As)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27414,
    "pob": "Nocedas, As (santo Estevo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27812,
    "pob": "Noche",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27317,
    "pob": "Nogueira (nosa SeÑora Neves) (ribas De Sil)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27893,
    "pob": "Nois",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27713,
    "pob": "Obe (san Xoan)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27775,
    "pob": "Oiras, As (san Mamede)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27156,
    "pob": "Ousa",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27668,
    "pob": "Ouselle (san Cosme)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27333,
    "pob": "Outara (santa Maria)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27256,
    "pob": "Outeiro (santa Maria) (castro De Rei)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27136,
    "pob": "Pacios (santa Maria) (fonsagrada)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27670,
    "pob": "Padornelo (san Xoan)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27200,
    "pob": "Palas De Rei (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27663,
    "pob": "Pando (san Xoan) (becerrea)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27380,
    "pob": "Parga (parga Estacion)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27410,
    "pob": "Parte, A",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27389,
    "pob": "Pedrafita (san Mamede) (guitiriz)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27595,
    "pob": "Pedrafita (santa Baia)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27318,
    "pob": "Peites",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27129,
    "pob": "Pena (santa Maria Madanela) (castroverde)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27697,
    "pob": "Penarrubia",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27420,
    "pob": "PiÑeira (san MartiÑo) (monforte)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27710,
    "pob": "PiÑeira (san Xoan) (ribadeo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27362,
    "pob": "PiÑeiro (san Salvador) (paramo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27330,
    "pob": "Pobra De Brollon, A (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27360,
    "pob": "Pobra De San Xiao, A",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27470,
    "pob": "Pombeiro",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27720,
    "pob": "Pontenova, A (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27877,
    "pob": "Portocelo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27170,
    "pob": "Portomarin (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27132,
    "pob": "Pousada (san Lourenzo) (baleira)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27227,
    "pob": "Prado (san MartiÑo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27660,
    "pob": "Quinta (santa Eulalia) (becerrea)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27370,
    "pob": "Rabade (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27633,
    "pob": "Real, O (san Cristovo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27725,
    "pob": "Rececende (san Xoan)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27726,
    "pob": "Rececende (santo Estebo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27793,
    "pob": "Reinante (san Miguel)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27794,
    "pob": "Reinante (santiago)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27747,
    "pob": "Remedios, Os (nosa SeÑora Dos Remedios)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27359,
    "pob": "Remesar",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27766,
    "pob": "Ria De Abres, A (santiago)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27700,
    "pob": "Ribadeo (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27594,
    "pob": "Ribas De MiÑo (san Esteban) (saviÑao)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27545,
    "pob": "Ribas De MiÑo (san Vitorio) (saviÑao)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27310,
    "pob": "Ribas De Sil (san Clodio)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27654,
    "pob": "Ribeira, A (san Estebo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27661,
    "pob": "Ribeira, A (san MartiÑo) (cervantes)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27715,
    "pob": "Rinlo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27691,
    "pob": "Rio (san MartiÑo) (lancara)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27274,
    "pob": "Rioxoan",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27795,
    "pob": "Rochela, A",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27841,
    "pob": "Roman",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27141,
    "pob": "Romean",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27369,
    "pob": "Ronfe",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27889,
    "pob": "Rua (santa Maria)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27350,
    "pob": "Rubian (san Fiz) (boveda)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27338,
    "pob": "Salcedo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27842,
    "pob": "Samarugo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27620,
    "pob": "Samos (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27786,
    "pob": "San Acisclo Do Valadouro (foz)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27388,
    "pob": "San Breixo De Parga",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27118,
    "pob": "San Martin De Suarna",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27787,
    "pob": "San MartiÑo De MondoÑedo (foz)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27791,
    "pob": "San Vicente De Trigas (san Vicente) (mondoÑedo)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27810,
    "pob": "Sancobade (santiago) (vilalba)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27785,
    "pob": "Santa Cilla Do Valadouro (santa Cilla)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27161,
    "pob": "Santa Comba",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27745,
    "pob": "Santa Marta De Meilan (riotorto)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27830,
    "pob": "Santaballa",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27752,
    "pob": "Santiago De MondoÑedo (santiago)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27750,
    "pob": "Santo Adrao De Lourenza (santo Adrao)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27600,
    "pob": "Sarria (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27165,
    "pob": "Segovia (san Xoan)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27241,
    "pob": "Seixosmil (san Isidro)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27590,
    "pob": "Seoane (el Salvador) (monforte)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27126,
    "pob": "Seres (san Pedro)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27214,
    "pob": "Soengas",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27653,
    "pob": "Son (santa Maria)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27180,
    "pob": "SoÑar",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27823,
    "pob": "Tardade",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27298,
    "pob": "Tirimol",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27366,
    "pob": "Toiran",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27593,
    "pob": "Toiriz (santa Maria)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27591,
    "pob": "Tor (san Xoan)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27646,
    "pob": "Tores (san Xoan)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27765,
    "pob": "Trabada (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27267,
    "pob": "Triaba",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27630,
    "pob": "Triacastela (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27343,
    "pob": "Tuimil",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27231,
    "pob": "Veral, O",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27860,
    "pob": "Vicedo, O",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27869,
    "pob": "Vieiro (san Cibrao) (viveiro)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27721,
    "pob": "Vilaboa (san Xulian)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27114,
    "pob": "Vilabol De Suarna",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27543,
    "pob": "Vilaesteva (san Salvador) (saviÑao)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27699,
    "pob": "Vilaesteva (santa Maria) (lancara)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27767,
    "pob": "Vilaforman",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27797,
    "pob": "Vilaframil",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27800,
    "pob": "Vilalba (casco Urbano)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27712,
    "pob": "Vilaosende",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27729,
    "pob": "Vilaoudriz (santiago)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27727,
    "pob": "Vilaouruz",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27724,
    "pob": "Vilarmide, O (salvador)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27788,
    "pob": "Vilaronte (san Xoan)",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27694,
    "pob": "Vilouta",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27870,
    "pob": "Xove",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27723,
    "pob": "Xudan",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 28755,
    "pob": "Acebeda, La",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28791,
    "pob": "Agustina, La",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28864,
    "pob": "Ajalvir",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28130,
    "pob": "Alalpardo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28880,
    "pob": "Alameda",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28749,
    "pob": "Alameda Del Valle",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28630,
    "pob": "Alamin, El (finca)",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28607,
    "pob": "Alamo, El (navalcarnero)",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28000,
    "pob": "Alcala De Henares (ver Callejero)",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28219,
    "pob": "Alcor I, Urbanizacion",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28620,
    "pob": "Aldea Del Fresno",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28110,
    "pob": "Algete",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28310,
    "pob": "Algodor",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28430,
    "pob": "Alpedrete",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28580,
    "pob": "Ambite",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28818,
    "pob": "Anchuelo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28680,
    "pob": "Apartamentos Pronto, Urbanizacion",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28300,
    "pob": "Aranjuez",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28500,
    "pob": "Arganda Del Rey",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28810,
    "pob": "Arlita",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28939,
    "pob": "Arroyomolinos",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28380,
    "pob": "Balcon Del Tajo, Urbanizacion",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28850,
    "pob": "Barrio De El Castillo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28480,
    "pob": "Barrio Tablada",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28976,
    "pob": "Batres",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28490,
    "pob": "Becerril De La Sierra",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28390,
    "pob": "Belmonte De Tajo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28862,
    "pob": "Belvis De Jarama",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28861,
    "pob": "Berrocales De Jarama, Los",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28439,
    "pob": "Berrocales, Los (alpedrete)",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28192,
    "pob": "Berrueco, El",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28194,
    "pob": "Berzosa De Lozoya",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28660,
    "pob": "Boadilla Del Monte",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28413,
    "pob": "Boalo, El",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28669,
    "pob": "Bonanza",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28670,
    "pob": "Bosque, El, Urbanizacion",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28737,
    "pob": "Braojos",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28596,
    "pob": "Brea De Tajo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28690,
    "pob": "Brunete",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28730,
    "pob": "Buitrago De Lozoya",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28720,
    "pob": "Bustarviejo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28721,
    "pob": "Cabanillas De La Sierra",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28751,
    "pob": "Cabrera, La",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28640,
    "pob": "Cadalso De Los Vidrios",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28600,
    "pob": "Calipo, Urbanizacion",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28816,
    "pob": "Camarma De Esteruelas",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28510,
    "pob": "Campo Real",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28743,
    "pob": "Canencia De La Sierra",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28294,
    "pob": "Canopus",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28560,
    "pob": "CarabaÑa",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28860,
    "pob": "Carretera Barajas, Viviendas",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28440,
    "pob": "Casa Forestal Tablada, Edificio",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28977,
    "pob": "Casarrubuelos",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28311,
    "pob": "Castillejo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28760,
    "pob": "Castillo De ViÑuelas",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28210,
    "pob": "Cazadero Real I, Urbanizacion",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28650,
    "pob": "Cenicientos",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28780,
    "pob": "Centro Militar San Pedro",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28412,
    "pob": "Cerceda",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28470,
    "pob": "Cercedilla",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28297,
    "pob": "Cereda, La",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28292,
    "pob": "Cerrillo (las Zorreras)",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28691,
    "pob": "Cerro Del Venero",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28193,
    "pob": "Cervera De Buitrago",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28694,
    "pob": "Chapineria",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28370,
    "pob": "Chinchon",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28350,
    "pob": "Ciempozuelos",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28119,
    "pob": "Cigarral, El",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28754,
    "pob": "Cinco Villas",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28280,
    "pob": "Ciudad Bosque De Los Arroyos, Urbanizacion",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28709,
    "pob": "Ciudad Deportiva Del Jarama",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28248,
    "pob": "Ciudad Residencial La Berzosa, Urbanizacion",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28120,
    "pob": "Ciudad Santo Domingo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28707,
    "pob": "Ciudalcampo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28863,
    "pob": "CobeÑa",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28450,
    "pob": "Collado Mediano",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28400,
    "pob": "Collado Villalba",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28213,
    "pob": "Colmenar Del Arroyo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28770,
    "pob": "Colmenar Viejo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28270,
    "pob": "Colmenarejo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28420,
    "pob": "Colonia Alto De La Navata",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28293,
    "pob": "Colonia PeÑa Rosal",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28938,
    "pob": "Combos, Los (arroyomolinos)",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28811,
    "pob": "Corpa",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28729,
    "pob": "Cotos De Monterrey",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28978,
    "pob": "Cubas De La Sagra",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28814,
    "pob": "Daganzo De Arriba",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28479,
    "pob": "Dehesas, Las",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28805,
    "pob": "Encin, El",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28109,
    "pob": "Encinar De Los Reyes",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28793,
    "pob": "Endrinales, Los",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28648,
    "pob": "Entrepinos, Urbanizacion",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28722,
    "pob": "Espartal, El",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28595,
    "pob": "Estremera",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28917,
    "pob": "Fortuna, La",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28214,
    "pob": "Fresnedilla De La Oliva",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28815,
    "pob": "Fresno De Torote",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28708,
    "pob": "Fuente Del Fresno",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28140,
    "pob": "Fuente El Saz De Jarama",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28597,
    "pob": "FuentidueÑa De Tajo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28260,
    "pob": "Galapagar",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28739,
    "pob": "Gargantilla Del Lozoya Y Pinil",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28971,
    "pob": "GriÑon",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28794,
    "pob": "Guadalix De La Sierra",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28296,
    "pob": "Herreras, Las",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28191,
    "pob": "Hiruela, La",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28240,
    "pob": "Hoyo De Manzanares",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28970,
    "pob": "Humanes De Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28312,
    "pob": "Infantas, Las (aranjuez)",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28250,
    "pob": "Jarales, Los (galapagar), Urbanizacion",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28890,
    "pob": "Loeches",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28742,
    "pob": "Lozoya Del Valle",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28752,
    "pob": "Lozoyuela",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28410,
    "pob": "Manzanares El Real",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28492,
    "pob": "Matalpino",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28840,
    "pob": "Mejorada Del Campo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28649,
    "pob": "Mirador De Cadalso, El",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28792,
    "pob": "Miraflores De La Sierra",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28710,
    "pob": "Molar, El",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28753,
    "pob": "Molino De Mazacortas",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28460,
    "pob": "Molinos, Los",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28668,
    "pob": "Monteprincipe",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28950,
    "pob": "Moraleja De Enmedio",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28411,
    "pob": "Moralzarzal",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28530,
    "pob": "Morata De TajuÑa",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28491,
    "pob": "Navacerrada, Pueblo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28212,
    "pob": "Navalagamella",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28695,
    "pob": "Navas Del Rey",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28419,
    "pob": "Nieves Y Sol",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28514,
    "pob": "Nuevo Baztan",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28515,
    "pob": "Olmeda De Las Fuentes",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28570,
    "pob": "Orusco",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28048,
    "pob": "Pardo, El",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28196,
    "pob": "Paredes De Buitrago",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28851,
    "pob": "Parque Corredor Del Henares, Mercado",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28108,
    "pob": "Parque Empresarial La Moraleja",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28830,
    "pob": "Parque Empresarial San Fernando De Henares",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28741,
    "pob": "Paular, Real El, Monasterio",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28723,
    "pob": "Pedrezuela",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28696,
    "pob": "Pelayos De La Presa",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28211,
    "pob": "Peralejo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28540,
    "pob": "Perales De TajuÑa",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28812,
    "pob": "Pezuela De Las Torres",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28320,
    "pob": "Pinto",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28330,
    "pob": "Poligono Industrial Aimayr",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28813,
    "pob": "Pozuelo Del Rey",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28740,
    "pob": "Puerto De Cotos",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28693,
    "pob": "Quijorna",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28679,
    "pob": "Residencia Ancianos (villaviciosa Odon)",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28295,
    "pob": "Rio, El, Urbanizacion",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28750,
    "pob": "San Agustin Del Guadalix",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28200,
    "pob": "San Lorenzo De El Escorial",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28229,
    "pob": "Santa Maria (via Del Pardo), Urbanizacion",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28817,
    "pob": "Santos De La Humosa, Los",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28195,
    "pob": "Serrada De La Fuente",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28979,
    "pob": "Serranillos Del Valle",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28609,
    "pob": "Sevilla La Nueva",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28756,
    "pob": "Somosierra",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28160,
    "pob": "Talamanca Del Jarama",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28550,
    "pob": "Tielmes De TajuÑa",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28359,
    "pob": "Titulcia",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28991,
    "pob": "Torrejon De La Calzada",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28990,
    "pob": "Torrejon De Velasco",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28180,
    "pob": "Torrelaguna",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28706,
    "pob": "Urbanizacion Club De Campo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28594,
    "pob": "Valdaracete",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28391,
    "pob": "Valdelaguna",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28170,
    "pob": "Valdepielagos",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28150,
    "pob": "Valdetorres De Jarama",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28511,
    "pob": "Valdilecha",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28209,
    "pob": "Valle De Los Caidos, Sta Cruz",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28891,
    "pob": "Velilla De San Antonio",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28360,
    "pob": "Villaconejos",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28692,
    "pob": "Villafranca Del Castillo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28598,
    "pob": "Villamanrique De Tajo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28610,
    "pob": "Villamanta",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28512,
    "pob": "Villar Del Olmo",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28590,
    "pob": "Villarejo De Salvanes",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 29530,
    "pob": "Alameda",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29130,
    "pob": "Alamillo, El, Urbanizacion",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29310,
    "pob": "Albaicin",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29711,
    "pob": "Alcaucin",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29692,
    "pob": "Alcorrin",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29712,
    "pob": "Aldea, La",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29194,
    "pob": "Alfarnate",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29750,
    "pob": "Algarrobo",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29491,
    "pob": "Algatocin",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29120,
    "pob": "Alhaurin El Grande",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29718,
    "pob": "Almachar",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29330,
    "pob": "Almargen",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29749,
    "pob": "Almayate Alto",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29150,
    "pob": "Almogia",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29500,
    "pob": "Alora",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29567,
    "pob": "Alozaina",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29460,
    "pob": "Alpandeire",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29195,
    "pob": "Alqueria (comares)",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29650,
    "pob": "Alqueria, La (mijas)",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29200,
    "pob": "Antequera",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29720,
    "pob": "AraÑa, La",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29753,
    "pob": "Archez",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29300,
    "pob": "Archidona",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29314,
    "pob": "Archidona, Estacion",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29550,
    "pob": "Ardales",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29108,
    "pob": "Ardite",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29350,
    "pob": "Arriate",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29160,
    "pob": "Arroyo Coche (casabermeja)",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29239,
    "pob": "Arroyo Coche (villanueva De La Concepcion)",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29510,
    "pob": "Arroyo Corrales",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29631,
    "pob": "Arroyo De La Miel",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29678,
    "pob": "Artola (benahavis)",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29494,
    "pob": "Atajate",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29688,
    "pob": "Atalaya Isdabe",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29311,
    "pob": "Atalaya, La",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29693,
    "pob": "Bahia Dorada",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29710,
    "pob": "BaÑos De Vilo",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29540,
    "pob": "Barriada Ortiz",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29493,
    "pob": "Benadalid",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29738,
    "pob": "Benagalbon",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29679,
    "pob": "Benahavis",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29790,
    "pob": "Benajarafe",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29639,
    "pob": "Benalmadena, Pueblo",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29630,
    "pob": "Benalmadena Costa",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29719,
    "pob": "Benamocarra",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29370,
    "pob": "Benaojan",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29791,
    "pob": "Benaque",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29490,
    "pob": "Benarraba",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29593,
    "pob": "Bermejo",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29420,
    "pob": "Burgo, El",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29700,
    "pob": "Cabrillas",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29792,
    "pob": "Cajiz",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29649,
    "pob": "Cala De Mijas, La",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29751,
    "pob": "Caleta De Velez",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29590,
    "pob": "Campanillas",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29320,
    "pob": "Campillos",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29230,
    "pob": "Campo De Camara (almogia)",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29716,
    "pob": "Canillas De Aceituno",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29755,
    "pob": "Canillas De Albaida",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29391,
    "pob": "CaÑada Del Real Tesoro, La",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29340,
    "pob": "CaÑete La Real",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29170,
    "pob": "Caravaca",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29551,
    "pob": "Carratraca",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29452,
    "pob": "Cartajima",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29570,
    "pob": "Cartama",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29250,
    "pob": "Cartaojal",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29533,
    "pob": "Carvajales",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29566,
    "pob": "Casarabonela",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29690,
    "pob": "Casares",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29193,
    "pob": "Caseta De Obras Publicas",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29713,
    "pob": "Casillas, Las (los Romanes)",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29569,
    "pob": "Cerralba",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29552,
    "pob": "Chorro, El",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29140,
    "pob": "Churriana",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29394,
    "pob": "Cimada, La",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29100,
    "pob": "Coin",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29580,
    "pob": "Comendador",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29754,
    "pob": "Competa",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29380,
    "pob": "Cortes De La Frontera",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29220,
    "pob": "Cuevas Bajas",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29210,
    "pob": "Cuevas De San Marcos",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29470,
    "pob": "Cuevas Del Becerro",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29680,
    "pob": "Estepona",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29461,
    "pob": "Farajan",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29788,
    "pob": "Frigiliana",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29640,
    "pob": "Fuengirola",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29520,
    "pob": "Fuente Piedra",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29480,
    "pob": "Gaucin",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29492,
    "pob": "Genalguacil",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29793,
    "pob": "Generacion Del 27, Urbanizacion",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29327,
    "pob": "Huertas De Guadalteba",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29531,
    "pob": "Humilladero",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29440,
    "pob": "Igualeja",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29393,
    "pob": "Indiana, La",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29196,
    "pob": "Intelhorce, Poblado",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29611,
    "pob": "Istan",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29392,
    "pob": "Jimera De Libar",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29260,
    "pob": "Joya, La",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29462,
    "pob": "Juzcar",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29760,
    "pob": "Lagos",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29612,
    "pob": "Mairena, La, Urbanizacion",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29000,
    "pob": "Malaga (ver Callejero)",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29691,
    "pob": "Manilva",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29591,
    "pob": "Maqueda",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29787,
    "pob": "Maro",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29789,
    "pob": "Molineta, La (frigiliana)",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29532,
    "pob": "Mollina",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29110,
    "pob": "Monda",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29430,
    "pob": "Montecorto",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29360,
    "pob": "Montejaque",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29329,
    "pob": "Navahermosa",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29780,
    "pob": "Nerja",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29610,
    "pob": "Ojen",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29197,
    "pob": "Olias",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29689,
    "pob": "Padron, El",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29451,
    "pob": "Parauta",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29560,
    "pob": "Pizarra",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29190,
    "pob": "Puerto De La Torre",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29450,
    "pob": "Pujerra",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29730,
    "pob": "Rincon De La Victoria",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29180,
    "pob": "Riogordo",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29400,
    "pob": "Ronda",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29714,
    "pob": "Salares",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29315,
    "pob": "Salinas, De, Estacion",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29752,
    "pob": "Sayalonga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29715,
    "pob": "Sedella",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29471,
    "pob": "Serrato",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29328,
    "pob": "Sierra De Yeguas",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29109,
    "pob": "Tolox",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29740,
    "pob": "Torre Del Mar",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29620,
    "pob": "Torremolinos",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29770,
    "pob": "Torrox",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29240,
    "pob": "Valle De Abdalajis",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29312,
    "pob": "Villanueva Del Rosario",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29313,
    "pob": "Villanueva Del Trabuco",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29410,
    "pob": "Yunquera",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 30189,
    "pob": "Arroyo Hurtado",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30640,
    "pob": "Abanilla",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30550,
    "pob": "Abaran",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30399,
    "pob": "Abrevadero",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30729,
    "pob": "Academia General Del Aire",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30439,
    "pob": "Agua Salada",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30815,
    "pob": "Aguaderas",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30880,
    "pob": "Aguilas",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30628,
    "pob": "Ajauque",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30868,
    "pob": "Alamillo",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30000,
    "pob": "Albatalia, La (ver Callejero De Murcia)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30150,
    "pob": "Alberca, La",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30190,
    "pob": "Albudeite",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30330,
    "pob": "Albujon, El",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30813,
    "pob": "Alcanara",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30820,
    "pob": "Alcantarilla",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30507,
    "pob": "Alcayna, La",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30710,
    "pob": "Alcazares, Los",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30859,
    "pob": "Aledo",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30609,
    "pob": "Algaida, La",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30366,
    "pob": "Algar, El",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30648,
    "pob": "Algarrobo, El",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30157,
    "pob": "Algezares",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30560,
    "pob": "Alguazas",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30561,
    "pob": "Alguazas, Estacion",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30840,
    "pob": "Alhama De Murcia",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30390,
    "pob": "Aljorra, La",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30152,
    "pob": "Aljucer",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30333,
    "pob": "Almagros, Los",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30893,
    "pob": "Almendricos",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30410,
    "pob": "Almudema, La",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30528,
    "pob": "Alqueria, La",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30580,
    "pob": "Alquerias",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30540,
    "pob": "Alto Palomo",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30506,
    "pob": "Altorreal",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30351,
    "pob": "Alumbres",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30393,
    "pob": "Angeles, Los (califa)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30740,
    "pob": "Antolinos, Los",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30395,
    "pob": "Aparecida, La",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30600,
    "pob": "Arboledas, Las",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30195,
    "pob": "Archivel",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30889,
    "pob": "Arejos, Los",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30398,
    "pob": "Arroyos, Los",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30535,
    "pob": "Ascoy",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30559,
    "pob": "Asomada, La (abaran)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30878,
    "pob": "Atalaya (morata)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30385,
    "pob": "Atamaria",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30812,
    "pob": "Aviles",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30592,
    "pob": "Avileses",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30848,
    "pob": "Azaraque",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30163,
    "pob": "Azarbe (parroquia)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30196,
    "pob": "Bajil",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30593,
    "pob": "Balanzas, Los",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30332,
    "pob": "Balsapintada",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30591,
    "pob": "Balsicas",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30626,
    "pob": "BaÑos",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30420,
    "pob": "BaÑos De Gilico",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30193,
    "pob": "BaÑos De Mula",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30155,
    "pob": "BaÑos Y Mendigo",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30509,
    "pob": "Barceloneta, La",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30179,
    "pob": "Barqueros",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30876,
    "pob": "Barranco De Los Asensios",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30814,
    "pob": "Barranco Hondo",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30412,
    "pob": "Barranda",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30310,
    "pob": "Barreros, Los",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30530,
    "pob": "Barretera",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30300,
    "pob": "Barriada Cuatro Santos",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30558,
    "pob": "Barriada De La Virgen De Oro",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30202,
    "pob": "Barriada Santiago",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30120,
    "pob": "Barrio La Victoria",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30154,
    "pob": "Bastidas, Los",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30382,
    "pob": "Beal",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30367,
    "pob": "Beatos, Los",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30570,
    "pob": "Beniajan",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30130,
    "pob": "Beniel",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30442,
    "pob": "Benizar",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30610,
    "pob": "Bermeja, La",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30396,
    "pob": "Blases, Los (cuesta Blanca)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30369,
    "pob": "Bolarin, Lo (roche)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30877,
    "pob": "Bolnuevo",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30590,
    "pob": "Borrambla",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30153,
    "pob": "Brianes",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30180,
    "pob": "Bullas",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30164,
    "pob": "Cabezo De Plata",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30110,
    "pob": "Cabezo De Torres",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30370,
    "pob": "Cabo De Palos",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30170,
    "pob": "Cagitan",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30441,
    "pob": "Calar De La Santa",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30720,
    "pob": "Calavera, La (santiago De La Rivera)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30335,
    "pob": "Campillo De Abajo (la Pinilla)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30438,
    "pob": "Campillo Y Suertes",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30191,
    "pob": "Campos Del Rio",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30875,
    "pob": "Camposol",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30627,
    "pob": "Campotejar Alta",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30817,
    "pob": "Canales, Las",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30338,
    "pob": "Canovas",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30858,
    "pob": "Cantareros, Los",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30394,
    "pob": "Canteras (canteras)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30414,
    "pob": "CaÑada De La Cruz",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30837,
    "pob": "CaÑada Hermosa",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30849,
    "pob": "CaÑadas, Las",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30400,
    "pob": "Caravaca De La Cruz",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30397,
    "pob": "Carceles, Los (la Magdalena)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30529,
    "pob": "Carche, El",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30368,
    "pob": "Carmoli, El, Urbanizacion",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30649,
    "pob": "Carrillos, Los",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30594,
    "pob": "Carriones, Los (pozo Estrecho)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30177,
    "pob": "Casas Nuevas (casas Nuevas)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30319,
    "pob": "Casicas, Las",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30739,
    "pob": "Casicas, Las (dolores Pacheco)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30629,
    "pob": "Casicas, Las (peÑa Zafra)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30890,
    "pob": "Casicas, Las (puerto Lumbreras)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30818,
    "pob": "Cazalla",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30430,
    "pob": "Cehegin",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30100,
    "pob": "Cementerio Nuestro Padre Jesus",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30562,
    "pob": "Ceuti",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30566,
    "pob": "Condomina, La",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30331,
    "pob": "Conesas, Los (lobosillo)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30365,
    "pob": "Cruz Chiquita",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30160,
    "pob": "Cueva, La",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30834,
    "pob": "Cuevas Del Norte",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30168,
    "pob": "Era Alta",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30891,
    "pob": "Esparragal De Puerto Lumbreras",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30329,
    "pob": "Espinar, El",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30381,
    "pob": "Estrecho De San Gines",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30709,
    "pob": "Ferro, Lo",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30565,
    "pob": "Florida, La (las Torres De Cotillas)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30811,
    "pob": "Fontanares",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30620,
    "pob": "Fortuna",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30320,
    "pob": "Fuente Alamo",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30178,
    "pob": "Fuente Librilla",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30613,
    "pob": "Fuente Morra",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30158,
    "pob": "Garres, Los",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30879,
    "pob": "Garrobo, El",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30708,
    "pob": "Gimenado, El",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30107,
    "pob": "Guadalupe",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30391,
    "pob": "Guia, La",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30816,
    "pob": "Hinojar",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30392,
    "pob": "Hondon, El (media Legua)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30413,
    "pob": "Hornico, El",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30700,
    "pob": "Hoya Morena",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30384,
    "pob": "Islas Menores",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30832,
    "pob": "Javali Nuevo",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30831,
    "pob": "Javali Viejo",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30109,
    "pob": "Jeronimos, Los (los Jeronimos)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30520,
    "pob": "Jumilla",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30892,
    "pob": "Librilla",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30161,
    "pob": "Llano De Brujas",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30334,
    "pob": "Loma, La (las Palas)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30800,
    "pob": "Lorca",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30564,
    "pob": "Lorqui",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30389,
    "pob": "Manga Club, La, Urbanizacion",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30380,
    "pob": "Manga Del Mar Menor, La",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30156,
    "pob": "Martinez Del Puerto, Los",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30148,
    "pob": "Matanza, La (santomera)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30870,
    "pob": "Mazarron",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30588,
    "pob": "Mojon De Zeneta",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30500,
    "pob": "Molina De Segura",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30440,
    "pob": "Moratalla",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30383,
    "pob": "Nietos Viejos",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30194,
    "pob": "NiÑo De Mula",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30166,
    "pob": "Nonduermas",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30830,
    "pob": "Ñora, La",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30611,
    "pob": "Ojos",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30835,
    "pob": "Pepitos, Los",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30176,
    "pob": "Pliego",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30353,
    "pob": "Poligono Industrial Cabezo Beaza",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30364,
    "pob": "Portman",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30836,
    "pob": "Puebla De Soto",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30860,
    "pob": "Puerto De Mazarron",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30139,
    "pob": "Raal, El",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30589,
    "pob": "Ramos, Los",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30167,
    "pob": "Raya, La (la Raya)",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30508,
    "pob": "Ribera De Molina, La",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30108,
    "pob": "Rincon De Beniscornia",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30165,
    "pob": "Rincon De Seca",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30192,
    "pob": "Rodeo De Enmedio",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30411,
    "pob": "Royos, Los",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30169,
    "pob": "San Gines",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30730,
    "pob": "San Javier",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30833,
    "pob": "Sangonera La Verde",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30162,
    "pob": "Santa Cruz Santa Cruz",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30151,
    "pob": "Santo Angel",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30140,
    "pob": "Santomera",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30149,
    "pob": "Siscar, El",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30563,
    "pob": "Torraos, Los",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30579,
    "pob": "Torreaguera",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30850,
    "pob": "Totana",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30612,
    "pob": "Ulea",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30360,
    "pob": "Union, La",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30350,
    "pob": "Valle Escombreras",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30510,
    "pob": "Yecla",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30810,
    "pob": "Zarcilla De Ramos",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 31178,
    "pob": "Abarzuza",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31692,
    "pob": "Abaurregaina/abaurrea Alta",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31264,
    "pob": "Aberin",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31473,
    "pob": "Abinzano",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31523,
    "pob": "Ablitas",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31282,
    "pob": "Acedo",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31454,
    "pob": "Adansa",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31194,
    "pob": "Aderiz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31153,
    "pob": "Adios",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31448,
    "pob": "Adoain",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31110,
    "pob": "Aeropuerto De Noain (pamplona)",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31430,
    "pob": "Agoitz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31639,
    "pob": "Agorreta",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31228,
    "pob": "Aguilar De Codes",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31867,
    "pob": "Aguinaga De Iza",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31460,
    "pob": "Aibar/oibar",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31790,
    "pob": "Aientsa",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31713,
    "pob": "Aintzialde",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31697,
    "pob": "Aintzioa",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31754,
    "pob": "Aitasemegi",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31730,
    "pob": "Aitzano",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31866,
    "pob": "Aizarotz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31195,
    "pob": "Aizoain",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31172,
    "pob": "Aizpun",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31698,
    "pob": "Akerreta",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31481,
    "pob": "Akotain",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31877,
    "pob": "Albiasu",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31892,
    "pob": "Aldaba",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31878,
    "pob": "Aldatz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31449,
    "pob": "Aldunate",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31789,
    "pob": "Alkaiaga",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31711,
    "pob": "Alkerdi",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31797,
    "pob": "Alkotz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31879,
    "pob": "Alli",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31262,
    "pob": "Allo",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31292,
    "pob": "Alloz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31796,
    "pob": "Almandoz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31800,
    "pob": "Altsasu/alsasua",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31472,
    "pob": "Alzorriz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31486,
    "pob": "Alzuza",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31715,
    "pob": "Amaiur/maya",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31193,
    "pob": "Amalain",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31395,
    "pob": "Amatriain",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31745,
    "pob": "Ameztia",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31290,
    "pob": "Amillano",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31840,
    "pob": "Amurgin",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31281,
    "pob": "Ancin",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31261,
    "pob": "Andosilla",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31799,
    "pob": "Anocibar",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31000,
    "pob": "Ansoain (31013) Ver Callejero De Pamplona",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31699,
    "pob": "Antxoritz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31700,
    "pob": "Antzanborda",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31154,
    "pob": "AÑorbe",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31271,
    "pob": "Aranarache",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31192,
    "pob": "Aranguren",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31239,
    "pob": "Aras",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31170,
    "pob": "Arazuri",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31241,
    "pob": "Arbeiza",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31839,
    "pob": "Arbizu",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31438,
    "pob": "Arce/artzi",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31210,
    "pob": "Arcos, Los",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31421,
    "pob": "Ardanaz De Izagaondoa",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31263,
    "pob": "Arellano",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31876,
    "pob": "Areso",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31513,
    "pob": "Arguedas",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31176,
    "pob": "ArguiÑano",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31174,
    "pob": "ArguiÑariz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31671,
    "pob": "Aria",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31798,
    "pob": "Aritzu",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31177,
    "pob": "Arizala",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31880,
    "pob": "Arkiskil",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31191,
    "pob": "Arlegui",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31794,
    "pob": "Aroztegia",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31891,
    "pob": "Arribe",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31243,
    "pob": "Arroniz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31422,
    "pob": "Artaiz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31140,
    "pob": "Artajona",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31272,
    "pob": "Artaza",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31173,
    "pob": "Artazcoz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31109,
    "pob": "Artazu",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31480,
    "pob": "Artieda",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31439,
    "pob": "Artozqui/artozki",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31291,
    "pob": "Arzoz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31171,
    "pob": "Asiain",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31190,
    "pob": "Astrain",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31610,
    "pob": "Atarrabia",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31868,
    "pob": "Atondo",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31640,
    "pob": "Auritz/burguete",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31694,
    "pob": "Aurizberri/espinal",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31746,
    "pob": "Aurkidi",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31770,
    "pob": "Auzoberri",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31240,
    "pob": "Ayegui",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31492,
    "pob": "Ayesa",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31560,
    "pob": "Azagra",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31710,
    "pob": "Azkar",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31810,
    "pob": "Bakaiku",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31229,
    "pob": "Bargota",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31396,
    "pob": "Bariain",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31133,
    "pob": "Basongaiz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31179,
    "pob": "Bearin",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31753,
    "pob": "Beintza Labaien",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31393,
    "pob": "Beire",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31483,
    "pob": "Beortegui",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31780,
    "pob": "Bera/vera De Bidasoa",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31869,
    "pob": "Beramendi",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31252,
    "pob": "Berbinzana",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31890,
    "pob": "Betelu",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31398,
    "pob": "Biurrun",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31695,
    "pob": "Bizkarreta-gerendiain",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31512,
    "pob": "Bocal, El",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31540,
    "pob": "BuÑuel",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31412,
    "pob": "Burgui/burgi",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31600,
    "pob": "Burlada/burlata",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31511,
    "pob": "Cabanillas",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31227,
    "pob": "Cabredo",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31219,
    "pob": "Cabrega",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31515,
    "pob": "Cadreita",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31397,
    "pob": "Campanas",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31380,
    "pob": "Caparroso",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31579,
    "pob": "Carcar",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31310,
    "pob": "Carcastillo",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31520,
    "pob": "Cascante",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31490,
    "pob": "Caseda",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31590,
    "pob": "Castejon",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31592,
    "pob": "Cintruenigo",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31131,
    "pob": "Cirauqui",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31591,
    "pob": "Corella",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31530,
    "pob": "Cortes",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31712,
    "pob": "Dantxarinea",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31750,
    "pob": "Donamaria",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31740,
    "pob": "Doneztebe/santesteban",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31829,
    "pob": "Dorrao/torrano",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31744,
    "pob": "Elgorriaga",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31470,
    "pob": "Elorz/elortz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31748,
    "pob": "Eratsun",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31714,
    "pob": "Erratzu",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31494,
    "pob": "Eslava",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31453,
    "pob": "Esparza De Salazar",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31200,
    "pob": "Estella/lizarra",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31760,
    "pob": "Etxalar",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31820,
    "pob": "Etxarri-aranatz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31638,
    "pob": "Eugi",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31690,
    "pob": "Ezcaroz/ezkaroze",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31749,
    "pob": "Ezkurra",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31370,
    "pob": "Falces",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31311,
    "pob": "Figarol",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31593,
    "pob": "Fitero",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31360,
    "pob": "Funes",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31510,
    "pob": "FustiÑana",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31409,
    "pob": "Gabarderal",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31283,
    "pob": "Galbarra",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31493,
    "pob": "Gallipienzo",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31451,
    "pob": "Gallues/galoze",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31414,
    "pob": "Garde",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31100,
    "pob": "Gares",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31693,
    "pob": "Garralda",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31620,
    "pob": "Gorraiz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31119,
    "pob": "Guerendiain (elorz)",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31452,
    "pob": "GÜesa/gorza",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31850,
    "pob": "Hiriberri-villanueva, Pueblo",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31484,
    "pob": "Iloz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31587,
    "pob": "Imaz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31208,
    "pob": "Irache, Urbanizacion",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31849,
    "pob": "IraÑeta",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31860,
    "pob": "Irurtzun",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31417,
    "pob": "Isaba/izaba",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31689,
    "pob": "Izalzu/itzaltzu",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31175,
    "pob": "Izurzu",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31691,
    "pob": "Jaurrieta",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31411,
    "pob": "Javier",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31830,
    "pob": "Lakuntza",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31251,
    "pob": "Larraga",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31270,
    "pob": "Larraona",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31670,
    "pob": "Larraun (orbaitzeta)",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31588,
    "pob": "Lazagurria",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31792,
    "pob": "Legasa",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31795,
    "pob": "Lekaroz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31870,
    "pob": "Lekunberri",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31260,
    "pob": "Lerin",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31482,
    "pob": "Lerruz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31410,
    "pob": "Leyre (monasterio)",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31487,
    "pob": "Liedena",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31696,
    "pob": "Lintzoain",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31580,
    "pob": "Lodosa",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31440,
    "pob": "Lumbier",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31660,
    "pob": "Luzaide/valcarlos",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31130,
    "pob": "MaÑeru",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31340,
    "pob": "Marcilla",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31382,
    "pob": "Melida",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31150,
    "pob": "Mendigorria",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31280,
    "pob": "Mendilibarri",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31016,
    "pob": "Mendillorri",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31485,
    "pob": "Mendioroz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31320,
    "pob": "Milagro",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31253,
    "pob": "Miranda De Arga",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31471,
    "pob": "Monreal",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31522,
    "pob": "Monteagudo",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31491,
    "pob": "Moriones",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31720,
    "pob": "Mugairi",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31521,
    "pob": "Murchante",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31391,
    "pob": "Murillo El Cuende",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31313,
    "pob": "Murillo El Fruto",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31152,
    "pob": "Muruzabal",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31793,
    "pob": "Narbarte",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31450,
    "pob": "Navascues",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31151,
    "pob": "Obanos",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31680,
    "pob": "Ochagavia",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31751,
    "pob": "Oitz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31809,
    "pob": "Olazti/olatzagutia",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31390,
    "pob": "Olite",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31160,
    "pob": "Orkoien",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31650,
    "pob": "Orreaga/roncesvalles",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31630,
    "pob": "Osteritz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31250,
    "pob": "Oteiza",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31350,
    "pob": "Peralta",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 50686,
    "pob": "Petilla De Aragon, Pueblo",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 31392,
    "pob": "Pitillas",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31394,
    "pob": "Pueyo",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31383,
    "pob": "Rada",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31230,
    "pob": "Recajo",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31550,
    "pob": "Ribaforada",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31415,
    "pob": "Roncal/erronkari",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31747,
    "pob": "Saldias",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31570,
    "pob": "San Adrian",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31312,
    "pob": "San Isidro Del Pinar",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31495,
    "pob": "San Martin De Unx",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31400,
    "pob": "SangÜesa/zangoza",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31220,
    "pob": "Sansol",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31314,
    "pob": "Santacara",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31621,
    "pob": "Sarriguren",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31589,
    "pob": "Sartaguda",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31293,
    "pob": "Sesma",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31791,
    "pob": "Sunbilla",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31300,
    "pob": "Tafalla",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31315,
    "pob": "Traibuenas",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31500,
    "pob": "Tudela",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31496,
    "pob": "Ujue",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31752,
    "pob": "Urrotz, Pueblo",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31420,
    "pob": "Urroz",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31416,
    "pob": "Urzainqui",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31418,
    "pob": "Uztarroz/uztarroze",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31514,
    "pob": "Valtierra",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31254,
    "pob": "Vergalijo",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31413,
    "pob": "Vidangoz/bidankoze",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31330,
    "pob": "Villafranca",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31242,
    "pob": "Villamayor De Monjardin",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31132,
    "pob": "Villatuerta",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31180,
    "pob": "Zizur Mayor/zizur Nagusia",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31284,
    "pob": "ZuÑiga",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 32453,
    "pob": "(astro, O (san Amaro)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32633,
    "pob": "Abades",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32695,
    "pob": "Abavides",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32615,
    "pob": "Abedes",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32150,
    "pob": "Abedin",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32779,
    "pob": "Abelaido",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32764,
    "pob": "Abeleda (teixeira)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32980,
    "pob": "Abeleda, A (ourense)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32679,
    "pob": "Abeleda, A (xunqueira De Ambia)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32690,
    "pob": "Abeledo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32790,
    "pob": "Abeledos, Os",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32520,
    "pob": "Abeleira, A (beariz)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32228,
    "pob": "Abeleira, A (padrenda)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32433,
    "pob": "Abelenda Das Penas",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32213,
    "pob": "Abelenda De Balongo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32212,
    "pob": "Abelida, A",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32817,
    "pob": "Abellal, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32235,
    "pob": "Abelleira De Arriba, A",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32816,
    "pob": "Abelleira, A (celanova)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32897,
    "pob": "Abelleira, A (muiÑos)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32172,
    "pob": "AbruciÑos",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32668,
    "pob": "Acea, A (allariz)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32870,
    "pob": "Aceredo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32372,
    "pob": "Aceveda",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32768,
    "pob": "Acevedo (chandrexa De Queixa)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32164,
    "pob": "Acevedo (nogueira Ramuin)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32678,
    "pob": "Acevedo (xunqueira De Ambia)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32769,
    "pob": "Acevedo, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32789,
    "pob": "Acevoso",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32794,
    "pob": "Aciveiro, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32815,
    "pob": "Ademouran",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32711,
    "pob": "Agra (pereiro De Aguiar)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32236,
    "pob": "Agra De Desteriz",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32425,
    "pob": "Agra, A (leiro)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32141,
    "pob": "Agra, A (san Cristovo De Cea)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32880,
    "pob": "Agrelo, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32573,
    "pob": "Agro De Quinta",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32101,
    "pob": "Agro Maior",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32136,
    "pob": "Agro SantiÑo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32814,
    "pob": "Agua Levada",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32634,
    "pob": "Aguis",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32515,
    "pob": "Aguisar",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32667,
    "pob": "Aira Vella, A",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32920,
    "pob": "Airas, As",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32641,
    "pob": "Airavella (porqueira)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32449,
    "pob": "Airoa De Beacan",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32766,
    "pob": "Alais (castro Caldelas)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32152,
    "pob": "Alban",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32514,
    "pob": "Albarellos (boboras)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32618,
    "pob": "Albarellos (monterrei)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32137,
    "pob": "Albarona",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32622,
    "pob": "Albergueria, A (laza)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32702,
    "pob": "Albergueria, A (vilar De Barrio)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32430,
    "pob": "Albin",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32516,
    "pob": "Alboio, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32813,
    "pob": "Albos",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32160,
    "pob": "Alcouce, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32849,
    "pob": "Aldea De Arriba (bande)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32211,
    "pob": "Aldea De Souto (cortegada)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32652,
    "pob": "Aldea Do MuiÑo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32812,
    "pob": "Aldea Ferreiro",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32811,
    "pob": "Aldea, A",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32348,
    "pob": "Aldea, A (vilamartin De Valdeorras)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32539,
    "pob": "Alemparte (irixo, O)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32705,
    "pob": "Alemparte (vilar De Barrio)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32990,
    "pob": "Alen (ourense)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32112,
    "pob": "Alen (paderne De Allariz)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32138,
    "pob": "Alen (torcela)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32765,
    "pob": "Alende (teixeira, A)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32770,
    "pob": "Alenza, A",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32314,
    "pob": "Alixo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32660,
    "pob": "Allariz",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32704,
    "pob": "Almoite",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32448,
    "pob": "Almorfe",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32940,
    "pob": "Alongos",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32890,
    "pob": "Alto Do Ponton, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32636,
    "pob": "Amea",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32226,
    "pob": "Amean",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32691,
    "pob": "Amendo, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32664,
    "pob": "Amiadoso, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32654,
    "pob": "Amieiro Longo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32170,
    "pob": "Amoeiro",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32151,
    "pob": "Andelo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32793,
    "pob": "AnduriÑas",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32825,
    "pob": "Anfeoz",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32455,
    "pob": "Anllo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32120,
    "pob": "Ansariz",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32526,
    "pob": "Antas, As (o Irixo)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32879,
    "pob": "Arauxo (lobios)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32140,
    "pob": "Arbor",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32838,
    "pob": "Arcas",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32693,
    "pob": "Arcos (sandias)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32500,
    "pob": "Arcos (santa Maria)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32315,
    "pob": "Arcos (vilamartin De Valdeorras)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32621,
    "pob": "Arcucelos",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32557,
    "pob": "Ardexarxe",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32139,
    "pob": "Ariz",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32161,
    "pob": "Armariz (nogueira De Ramuin)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32574,
    "pob": "Armeses",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32417,
    "pob": "Arnoia, A (san Salvador)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32616,
    "pob": "Arzadegos",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32617,
    "pob": "Arzoa",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32708,
    "pob": "Asadur",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32915,
    "pob": "Aspera (san Cibrao Das ViÑas)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32860,
    "pob": "Asperelo Y Olin",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32981,
    "pob": "Astres",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32512,
    "pob": "Astureses",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32680,
    "pob": "Atas",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32229,
    "pob": "Azoreira",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32655,
    "pob": "Azoreiros",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32456,
    "pob": "Bacelo, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32510,
    "pob": "Bagarelas",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32821,
    "pob": "Bagullo, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32820,
    "pob": "Baldariz (cartelle)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32850,
    "pob": "Baldemir",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32632,
    "pob": "Baltar",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32950,
    "pob": "Bamio",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32840,
    "pob": "Bande",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32369,
    "pob": "BaÑos (veiga, A)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32701,
    "pob": "BaÑos De Molgas",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32848,
    "pob": "BaÑos, Os",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32895,
    "pob": "BaÑos, Os (lobios)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32450,
    "pob": "Barbantes (cenlle), Estacion",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32537,
    "pob": "Barcia (irixo, O)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32411,
    "pob": "Barcia (melon)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32300,
    "pob": "Barco, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32534,
    "pob": "Barreira, A (carballiÑo, O)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32740,
    "pob": "Barreiros (maceda)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32847,
    "pob": "Barrio (bande)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32898,
    "pob": "Barrio (cados)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32788,
    "pob": "Barrio (pobra De Trives, A)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32311,
    "pob": "Barrio De Cascalla, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32899,
    "pob": "Barrio, O (barxes)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32530,
    "pob": "Barro (cusanca)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32545,
    "pob": "Barxa (gudiÑa, A)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32748,
    "pob": "Barxacova",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32103,
    "pob": "Barxas, As",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32420,
    "pob": "Barzamedelle",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32419,
    "pob": "Barzamedelle (bieite)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32336,
    "pob": "Bascois",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32431,
    "pob": "Beade (beade)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32525,
    "pob": "Beariz",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32900,
    "pob": "Belmonte",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32562,
    "pob": "Bembibre",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32970,
    "pob": "Bemposta (ourense)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32153,
    "pob": "Bergaza, A",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32784,
    "pob": "Bidueira",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32823,
    "pob": "Bidueiro, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32631,
    "pob": "Boado",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32792,
    "pob": "Boeiros (pereiro De Aguiar)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32577,
    "pob": "Bolo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32373,
    "pob": "Bolo, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32100,
    "pob": "Boncomezo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32523,
    "pob": "Borraxas",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32785,
    "pob": "Borruga",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32613,
    "pob": "Bouses",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32563,
    "pob": "Bouza (viana Do Bolo)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32868,
    "pob": "Bouzadragro",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32786,
    "pob": "Bozqueimado",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32651,
    "pob": "Breixomes",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32521,
    "pob": "Brues",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32536,
    "pob": "Bugalleira De Abaixo, A",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32846,
    "pob": "Buxan (bande)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32375,
    "pob": "Buxan (bolo, O)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32828,
    "pob": "Buzacos (celanova)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32839,
    "pob": "Cabanas De Abaixo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32778,
    "pob": "Cabanas, As (rio)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32780,
    "pob": "Cabeza De Manzaneda",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32428,
    "pob": "Cabo, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32600,
    "pob": "Cabreiroa",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32710,
    "pob": "CachamuiÑa (pereiro De Aguiar)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32548,
    "pob": "Cadavos",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32669,
    "pob": "Cal, A (allariz)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32554,
    "pob": "CaldesiÑos",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32901,
    "pob": "Calvos (san Cibrao Das ViÑas)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32648,
    "pob": "Calvos De Randin",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32415,
    "pob": "Campo Redondo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32774,
    "pob": "Campo, O (castro Caldelas)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32626,
    "pob": "Campobecerros",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32549,
    "pob": "Canda, A",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32135,
    "pob": "Canda, A (piÑor)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32338,
    "pob": "Candeda (carballeda)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32368,
    "pob": "Candeda (veiga, A)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32960,
    "pob": "Cangos",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32357,
    "pob": "Carballal (petin)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32413,
    "pob": "Carballeda De Avia",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32810,
    "pob": "Carballeiras, As (ramiras)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32692,
    "pob": "Cardeita",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32547,
    "pob": "Carracedo Da Serra",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32824,
    "pob": "Carregal, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32892,
    "pob": "Carreira, A (lobios)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32689,
    "pob": "Carzoa",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32337,
    "pob": "Casaio",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32635,
    "pob": "Casas Da Veiga, As",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32650,
    "pob": "Casas Da Veiga, As (vilar De Santos)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32356,
    "pob": "Casasola (petin)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32751,
    "pob": "Cascarballo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32366,
    "pob": "Casdenodres",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32730,
    "pob": "Caseta, A",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32370,
    "pob": "Caseta, A (petin)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32644,
    "pob": "Castelaus (calvos De Randin)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32787,
    "pob": "Casteligo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32696,
    "pob": "Castelo, O (trasmiras)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32558,
    "pob": "CastiÑeira (viana Do Bolo)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32610,
    "pob": "Castrelo De Abaixo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32625,
    "pob": "Castrelo Do Val",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32760,
    "pob": "Castro De Caldelas, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32706,
    "pob": "Castro De Escuadro",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32318,
    "pob": "Castro VilariÑo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32111,
    "pob": "Caxamonde",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32749,
    "pob": "Caxide",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32130,
    "pob": "Cea",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32004,
    "pob": "CeboliÑo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32800,
    "pob": "Celanova",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32454,
    "pob": "Cenlle",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32747,
    "pob": "Chandreixa (parada De Sil)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32410,
    "pob": "Cima De Vila (melon)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32620,
    "pob": "Cimadevila (laza)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32782,
    "pob": "Cimadevila (manzaneda)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32750,
    "pob": "Cimadevila (montederramo)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32666,
    "pob": "Coedo (allariz)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32001,
    "pob": "CoiÑas, Os",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32910,
    "pob": "Condado (san Cibrao Das ViÑas)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32551,
    "pob": "Conso",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32340,
    "pob": "Correxais (vilamartin De Valdeorras)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32200,
    "pob": "Cortegada (cortegada)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32707,
    "pob": "Costa, A (maceda)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32412,
    "pob": "Costeira, A (carballeda De Avia)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32971,
    "pob": "CotoriÑo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32556,
    "pob": "Covelo (viana Do Bolo)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32429,
    "pob": "Cubilledo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32767,
    "pob": "Drados",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32102,
    "pob": "Eirexa, A (readigos)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32227,
    "pob": "Enfornadas, As",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32552,
    "pob": "Entrecinsa",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32555,
    "pob": "Ermida, A (viana)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32720,
    "pob": "Esgos",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32826,
    "pob": "EspiÑoso",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32624,
    "pob": "EstevesiÑos",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32858,
    "pob": "Facos",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32643,
    "pob": "FaramiÑas",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32637,
    "pob": "Faramontaos (xinzo De Limia)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32699,
    "pob": "Feces De Abaixo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32698,
    "pob": "Feces De Cima",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32611,
    "pob": "Feilas (vilardevos)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32317,
    "pob": "Ferradal (barco De Valdeorras, O)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32930,
    "pob": "Fondon, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32350,
    "pob": "Fontei",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32859,
    "pob": "Forcadas, As (bande)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32400,
    "pob": "Foz, A (ribadavia)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32418,
    "pob": "Francelos",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32416,
    "pob": "Franqueiran, A",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32457,
    "pob": "Freas (punxin)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32640,
    "pob": "Ganade (san Bartolomeu)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32540,
    "pob": "GudiÑa, A",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32358,
    "pob": "Hermida Vella",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32522,
    "pob": "Igresario, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32869,
    "pob": "Illa, A",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32575,
    "pob": "Lago, O",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32911,
    "pob": "Lagoa, A (san Cibrao Das ViÑas)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32459,
    "pob": "Laias",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32688,
    "pob": "Lamalonga (cualedro)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32700,
    "pob": "Maceda",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32830,
    "pob": "Manchica, A (nosa SeÑora De Lourdes)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32781,
    "pob": "Manzaneda",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32570,
    "pob": "Maside",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32546,
    "pob": "Meson De Herosa",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32619,
    "pob": "Monterrei (verin)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32697,
    "pob": "Mourazos",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32329,
    "pob": "Nogueiras, As (carballeda)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32426,
    "pob": "Orega",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32312,
    "pob": "Oulego",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32452,
    "pob": "Ourantes",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32000,
    "pob": "Ourense (ver Callejero)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32896,
    "pob": "Parada De Ventosa",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32646,
    "pob": "Paradela (calvos De Randin)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32665,
    "pob": "Pazo (allariz)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32440,
    "pob": "Peares, Os",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32703,
    "pob": "Piuca",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32365,
    "pob": "Ponte, A (veiga, A)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32371,
    "pob": "Portela De Portomourisco, A",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32560,
    "pob": "Pradocabalos",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32612,
    "pob": "Queirugas",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32320,
    "pob": "QuereÑo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32627,
    "pob": "Rasela, A (verin)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32432,
    "pob": "Regadas, As (beade)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32310,
    "pob": "Reporicelo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32330,
    "pob": "Sobradelo (carballeda)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32791,
    "pob": "Trios (san Pedro)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32002,
    "pob": "Valenza",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32360,
    "pob": "Veiga, A (santa Maria)",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32550,
    "pob": "Viana Do Bolo",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32334,
    "pob": "Viladequinta",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32630,
    "pob": "Xinzo De Limia",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32670,
    "pob": "Xunqueira De Ambia",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 33317,
    "pob": "Abadia, La",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33559,
    "pob": "Aballe",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33556,
    "pob": "Abamia",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33814,
    "pob": "AbanceÑa",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33579,
    "pob": "Abandames",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33836,
    "pob": "Abango",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33890,
    "pob": "Abaniella",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33994,
    "pob": "Abantro",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33424,
    "pob": "Abarrio",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33315,
    "pob": "Abayu",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33844,
    "pob": "Abedul (belmonte)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33979,
    "pob": "Abedul (laviana)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33919,
    "pob": "Abedul (oviedo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33538,
    "pob": "Abedul (piloÑa)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33347,
    "pob": "Abeo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33310,
    "pob": "Abeu (fuentes-villaviciosa)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33316,
    "pob": "Abeu De Arriba (carda-villaviciosa)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33557,
    "pob": "Abiegos",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33416,
    "pob": "Ablaneda (corbera)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33869,
    "pob": "Ablaneda (salas)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33875,
    "pob": "Ablaneda (tineo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33708,
    "pob": "Ablanedo (luarca)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33162,
    "pob": "Ablanedo (morcin)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33128,
    "pob": "Ablanedo (pravia)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33160,
    "pob": "Ablanedo (riosa)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33891,
    "pob": "Ablanedo (salas)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33610,
    "pob": "Ablanedo (turon)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33637,
    "pob": "Ablano (lena)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33650,
    "pob": "AblaÑa",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33947,
    "pob": "Abonion",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33492,
    "pob": "AboÑo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33775,
    "pob": "Abraido",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33719,
    "pob": "Abranedo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33779,
    "pob": "Abres",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33717,
    "pob": "AbruÑeiros",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33010,
    "pob": "Abuli",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33987,
    "pob": "Acebal (pola De Laviana)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33527,
    "pob": "Acebal, La (bimenes)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33580,
    "pob": "Acebal, La (lieres)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33685,
    "pob": "Acebedo (aller)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33829,
    "pob": "Acebedo (grado)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33616,
    "pob": "Acebedo (mieres)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33470,
    "pob": "Acebo (corvera)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33518,
    "pob": "Acebo (sariego)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33794,
    "pob": "Acebreiral",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33710,
    "pob": "AceÑas",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33828,
    "pob": "Aces De Candamo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33866,
    "pob": "Acevedo (salas)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33747,
    "pob": "Acevedo (tapia)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33868,
    "pob": "Aciana",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33118,
    "pob": "Aciera",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33469,
    "pob": "Admiracion De Iglesia",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33783,
    "pob": "Adrado",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33813,
    "pob": "Adrales",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33769,
    "pob": "Agelan",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33129,
    "pob": "Agones",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33116,
    "pob": "Aguadina",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33600,
    "pob": "Aguain",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33683,
    "pob": "Agualestro",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33718,
    "pob": "Aguamaroza",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33887,
    "pob": "Aguanes",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33842,
    "pob": "Aguasmestas",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33392,
    "pob": "Aguda",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33785,
    "pob": "Aguera",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33547,
    "pob": "Aguera (cangas De Onis)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33190,
    "pob": "Aguera (las Regueras)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33425,
    "pob": "Aguera (llanera)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33876,
    "pob": "Aguera De La Barca",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33827,
    "pob": "Aguera De Salcedo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33678,
    "pob": "Agueria (moreda)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33993,
    "pob": "Agues",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33619,
    "pob": "Aguilar",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33778,
    "pob": "Aguillon (castropol)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33840,
    "pob": "Aguino",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33730,
    "pob": "Airela",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33194,
    "pob": "Ajuyan",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33612,
    "pob": "Alameda, La",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33846,
    "pob": "Alava",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33867,
    "pob": "Alba, El",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33791,
    "pob": "Albarde",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33422,
    "pob": "Albares (llanera)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33157,
    "pob": "Albuerne",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33629,
    "pob": "Alcedo De Caballeros",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33825,
    "pob": "Alcubiella",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33992,
    "pob": "Aldea (pola De Laviana)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33394,
    "pob": "Aldea, La (gijon)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33787,
    "pob": "Aldin",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33345,
    "pob": "Alea",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33111,
    "pob": "Alesga",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33410,
    "pob": "Alfaraz",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33311,
    "pob": "Algara",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33810,
    "pob": "Alguerdo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33782,
    "pob": "Alienes",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33508,
    "pob": "Allende (vibaÑo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33578,
    "pob": "Alles",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33700,
    "pob": "AlmuÑa",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33843,
    "pob": "Almurfe",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33910,
    "pob": "Alperi",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33449,
    "pob": "Altamira (gozon)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33439,
    "pob": "Alto De La Iglesia",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33450,
    "pob": "Alvare (castrillon)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33819,
    "pob": "Amago",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33438,
    "pob": "Ambas (carreÑo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33826,
    "pob": "Ambas (grado)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33312,
    "pob": "Ambas (villaviciosa)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33490,
    "pob": "Ambiedes, Pueblo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33776,
    "pob": "Amieiros",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33558,
    "pob": "Amieva",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33534,
    "pob": "Anayo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33939,
    "pob": "Andarujo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33816,
    "pob": "Anderve",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33756,
    "pob": "Andina, La",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33590,
    "pob": "Andines",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33596,
    "pob": "Andrin",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33427,
    "pob": "Anduerga",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33189,
    "pob": "Anes (san Martin)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33780,
    "pob": "Anguilero",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33774,
    "pob": "Antigua (san Tirso De Abres)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33583,
    "pob": "Antrialgo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33935,
    "pob": "AntuÑa",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33878,
    "pob": "Anzas",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33969,
    "pob": "Aparadas",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33402,
    "pob": "Arabuya (aviles)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33946,
    "pob": "Aragustin",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33457,
    "pob": "Arances",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33554,
    "pob": "Arangas",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33815,
    "pob": "Araniego",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33818,
    "pob": "Arbas (san Julian)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33313,
    "pob": "Arbellia",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33980,
    "pob": "Arbeya, La",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33888,
    "pob": "Arbeyales (pola De Allande)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33986,
    "pob": "Arbin",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33757,
    "pob": "Arboces",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33529,
    "pob": "Arboleya",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33300,
    "pob": "Arcenoyo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33874,
    "pob": "Arcillero",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33817,
    "pob": "Ardaliz",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33569,
    "pob": "Ardines",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33507,
    "pob": "Ardisana",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33448,
    "pob": "Arena, La",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33195,
    "pob": "Arenales",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33936,
    "pob": "Arenas (carbayin-siero)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33458,
    "pob": "Arenas (soto Del Barco)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33491,
    "pob": "Arenas, Las (carreÑo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33191,
    "pob": "AreÑes (oviedo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33613,
    "pob": "Argalladas, Las",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33163,
    "pob": "Argame",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33528,
    "pob": "Argamoso",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33539,
    "pob": "Argandenes",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33414,
    "pob": "ArgaÑosa",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33314,
    "pob": "ArgaÑoso",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33155,
    "pob": "Argaton",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33957,
    "pob": "Argayadas",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33188,
    "pob": "Arguelles",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33735,
    "pob": "Argul",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33784,
    "pob": "Argumosin",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33638,
    "pob": "Armada",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33725,
    "pob": "Armal",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33159,
    "pob": "Armayor",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33886,
    "pob": "Armenande",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33734,
    "pob": "Arne, El",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33326,
    "pob": "Arnin",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33677,
    "pob": "Arnizo (moreda)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33792,
    "pob": "Arnizo (otur)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33627,
    "pob": "Arnon",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33546,
    "pob": "Arobes",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33350,
    "pob": "Aroles",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33154,
    "pob": "Aronces",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33140,
    "pob": "Arquera, La (oviedo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33540,
    "pob": "Arriondas",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33777,
    "pob": "Arrojina",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33117,
    "pob": "Arrojo (quiros)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33428,
    "pob": "Arroyo (llanera)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33697,
    "pob": "Arroyo (serin)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33536,
    "pob": "Artedosa",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33909,
    "pob": "Artosa (langreo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33617,
    "pob": "Arzolar, El",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33555,
    "pob": "Asiego",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33795,
    "pob": "Astas",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33873,
    "pob": "Atalaya (tineo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33519,
    "pob": "Atras (siero)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33171,
    "pob": "Atrollo, El",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33000,
    "pob": "Aviles (ver Callejero De Aviles)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33193,
    "pob": "Ayones (oviedo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33731,
    "pob": "Baboreira",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33682,
    "pob": "BaiÑa",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33728,
    "pob": "Bajada, La",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33429,
    "pob": "Balbona (siero)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33391,
    "pob": "Baldornon",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33859,
    "pob": "Ballota (cornellana)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33158,
    "pob": "Ballota (cudillero)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33595,
    "pob": "Balmori",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33459,
    "pob": "Banda, La",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33344,
    "pob": "Bandalisque",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33114,
    "pob": "Bandujo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33812,
    "pob": "Bao (ibias)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33793,
    "pob": "Bao Y Barayo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33879,
    "pob": "Baradal",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33958,
    "pob": "Baraosa",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33865,
    "pob": "Barcena (salas)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33468,
    "pob": "Bardasquera",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33535,
    "pob": "Bargaedo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33192,
    "pob": "Barganiza",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33949,
    "pob": "Barraca, La (ciaÑo-langreo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33920,
    "pob": "Barraca, La (langreo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33639,
    "pob": "Barraca, La (pola Lena)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33948,
    "pob": "Barredo (el Entrego)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33892,
    "pob": "Barredo (vega De Anzo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33970,
    "pob": "Barredos (laviana)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33640,
    "pob": "Barredos (ujo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33196,
    "pob": "Barredos, Los (manjoya-oviedo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33411,
    "pob": "Barrera, La (illas)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33127,
    "pob": "Barrera, La (soto Del Barco)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33199,
    "pob": "Barreros (limanes)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33412,
    "pob": "Barriero, El",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33990,
    "pob": "Barrio (caso)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33618,
    "pob": "Barrio Gonzalin",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33929,
    "pob": "Barripies",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33930,
    "pob": "Barros",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33858,
    "pob": "Barrudo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33456,
    "pob": "Barzanas, Las",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33401,
    "pob": "Bastian (aviles)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33393,
    "pob": "Batiao",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33119,
    "pob": "Bayo (grado)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33768,
    "pob": "Becharro",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33877,
    "pob": "Bedures",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33327,
    "pob": "Beldredo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33707,
    "pob": "Belen",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33996,
    "pob": "Belerda",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33845,
    "pob": "Bello (belmonte)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33686,
    "pob": "Bello (cabaÑaquinta-aller)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33830,
    "pob": "Belmonte",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33591,
    "pob": "Belmonte De Pria",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33937,
    "pob": "Bendicion",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33346,
    "pob": "Berbes",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33186,
    "pob": "Berron, El",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33690,
    "pob": "Bervola",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33960,
    "pob": "Blimea",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33720,
    "pob": "Boal",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33549,
    "pob": "Bode",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33548,
    "pob": "Bodes",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33426,
    "pob": "Bonielles",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33675,
    "pob": "Boo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33820,
    "pob": "Borbolla, La (grado)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33988,
    "pob": "Boza (pola Laviana)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33870,
    "pob": "BraÑa, La (tineo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33729,
    "pob": "BraÑalibel",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33611,
    "pob": "BraÑanocedo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33739,
    "pob": "BraÑela (grandas Salime)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33693,
    "pob": "BraÑillin (pajares)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33403,
    "pob": "Breton, El (aviles)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33594,
    "pob": "Bricia",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33684,
    "pob": "Buciello",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33694,
    "pob": "Buelles",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33598,
    "pob": "Buelna",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33187,
    "pob": "Buenavista (el Berron)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33749,
    "pob": "Buenavista (tapia)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33857,
    "pob": "Bulse",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33885,
    "pob": "Buslabin",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33161,
    "pob": "BusloÑe",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33796,
    "pob": "Busnovo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33995,
    "pob": "Buspriz",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33841,
    "pob": "Bustariega",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33112,
    "pob": "Bustiello (proaza)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33789,
    "pob": "Busto (luarca)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33726,
    "pob": "Cabanas Trabazas",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33614,
    "pob": "Cabanin, El (santa Rosa-mieres)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33945,
    "pob": "CabaÑa, La (cocaÑin) (el Entrego)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33692,
    "pob": "Cabezon",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33589,
    "pob": "Cabielles",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33889,
    "pob": "Cabral",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33174,
    "pob": "Caces",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33788,
    "pob": "Cadavedo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33566,
    "pob": "Calabrez",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33584,
    "pob": "Caldevilla",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33811,
    "pob": "Caldevilla De Rengos",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33170,
    "pob": "Caleyo, El (oviedo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33959,
    "pob": "Caleyo, El (sotrondio)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33934,
    "pob": "Caleyu, El (langreo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33405,
    "pob": "Caliero, El (castrillon)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33568,
    "pob": "Camango",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33006,
    "pob": "Campiello (la Manjoya)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33418,
    "pob": "Campo De La Iglesia (gozon)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33628,
    "pob": "Campo, El (campomanes)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33620,
    "pob": "Campomanes",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33581,
    "pob": "Campones (nava)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33746,
    "pob": "Campos (tapia)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33850,
    "pob": "Candanonegro",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33430,
    "pob": "Candas",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33615,
    "pob": "Cangas De Abajo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33800,
    "pob": "Cangas De Narcea",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33550,
    "pob": "Cangas De Onis",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33989,
    "pob": "Canto De Abajo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33582,
    "pob": "Carancos",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33576,
    "pob": "Caraves",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33343,
    "pob": "Caravia Baja",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33420,
    "pob": "Carbayo, El (lugones)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33759,
    "pob": "Carbeje",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33318,
    "pob": "Carcabada (celada-villaviciosa)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33593,
    "pob": "Cardoso (llanes)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33750,
    "pob": "Caridad, La",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33567,
    "pob": "Carmen",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33325,
    "pob": "Carrandena",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33329,
    "pob": "Carrandi",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33172,
    "pob": "Carrera, La",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33592,
    "pob": "Carriles, Los (llanes)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33669,
    "pob": "Casares (oviedo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33115,
    "pob": "Casina, La",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33681,
    "pob": "Casomera",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33758,
    "pob": "Castello (el Franco)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33938,
    "pob": "Castiello (bendicion)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33340,
    "pob": "Castiello (colunga)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33695,
    "pob": "Castiello (villallana)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33585,
    "pob": "Castiello De Parres",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33727,
    "pob": "Castrillon De Boal",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33737,
    "pob": "Castro (grandas De Salime)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33760,
    "pob": "Castropol",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33586,
    "pob": "Cayarga",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33991,
    "pob": "Celleruelo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33156,
    "pob": "Cepedo, El",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33320,
    "pob": "Cerracin",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33900,
    "pob": "CiaÑo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33738,
    "pob": "Coba, La (grandas Salime)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33342,
    "pob": "CoceÑa",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33679,
    "pob": "Collada (moreda)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33680,
    "pob": "Collanzo (aller)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33011,
    "pob": "Corredoria (oviedo)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33126,
    "pob": "Cotollano",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33150,
    "pob": "Cudillero",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33509,
    "pob": "Cue",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33537,
    "pob": "Cuerrias De Espinaredo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33460,
    "pob": "Cueto, El (trasona-corvera)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33786,
    "pob": "Cueva (luarca)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33688,
    "pob": "Cuevas (felechosa - Aller)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33588,
    "pob": "Cuevas De Agua",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33940,
    "pob": "Entrego, El",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33173,
    "pob": "Entrepuentes",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33138,
    "pob": "Era",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33404,
    "pob": "Estrada, La (corvera)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33716,
    "pob": "Fojos",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33696,
    "pob": "Fresnedo (soto Rey)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33733,
    "pob": "Gio",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33328,
    "pob": "Huerres",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33530,
    "pob": "Infiesto",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33341,
    "pob": "Isla, La",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33299,
    "pob": "Jove",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33330,
    "pob": "Lastres",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33390,
    "pob": "Leorio",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33839,
    "pob": "Llamoso",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33500,
    "pob": "Llanes",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33687,
    "pob": "Llanos",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33440,
    "pob": "Luanco",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33660,
    "pob": "Malpica (olloniego)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33577,
    "pob": "Mier",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33798,
    "pob": "MiÑagon",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33691,
    "pob": "Monteana",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33670,
    "pob": "Moreda De Aller",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33676,
    "pob": "Murias (aller)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33520,
    "pob": "Nava",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33180,
    "pob": "NoreÑa",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33570,
    "pob": "Panes",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33997,
    "pob": "Pendones",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33880,
    "pob": "Pola De Allande",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33630,
    "pob": "Pola De Lena",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33510,
    "pob": "Pola De Siero",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33120,
    "pob": "Pravia",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33597,
    "pob": "Puertas De Vidiago",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33790,
    "pob": "Puerto De Vega",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33560,
    "pob": "Ribadesella",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33324,
    "pob": "Riera, La (colunga)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33860,
    "pob": "Salas",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33599,
    "pob": "San Esteban (p. Baja)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33130,
    "pob": "San Esteban De Pravia",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33125,
    "pob": "San Juan De La Arena",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33417,
    "pob": "San Juan De Nieva",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33736,
    "pob": "Santa Maria (grandas De Salime)",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33139,
    "pob": "Somado",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33423,
    "pob": "Soto De Llanera, Urbanizacion",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33950,
    "pob": "Sotrondio",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33740,
    "pob": "Tapia De Casariego",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33587,
    "pob": "ToraÑo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33100,
    "pob": "Trubia",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33770,
    "pob": "Vegadeo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33721,
    "pob": "Villur",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 34338,
    "pob": "Abarca De Campos",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34307,
    "pob": "Abastas",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34479,
    "pob": "Abia De Las Torres",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34111,
    "pob": "Acera De La Vega",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34800,
    "pob": "Aguilar De Campoo",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34480,
    "pob": "Alar Del Rey",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34219,
    "pob": "Alba De Cerrato",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34888,
    "pob": "Alba De Los CardaÑos",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34126,
    "pob": "Albala De La Vega",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34191,
    "pob": "Alconada, La, Monasterio",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34429,
    "pob": "Amayuelas De Abajo",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34485,
    "pob": "Amayuelas De Ojeda",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34420,
    "pob": "Amusco",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34248,
    "pob": "Antiguedad",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34846,
    "pob": "Arbejal",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34449,
    "pob": "Arconada",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34477,
    "pob": "Arenillas De NuÑo Perez",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34473,
    "pob": "Arenillas De San Pelayo",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34849,
    "pob": "AreÑos",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34347,
    "pob": "Arroyo",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34450,
    "pob": "Astudillo",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34869,
    "pob": "AviÑante De La PeÑa",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34127,
    "pob": "Bahillo",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34240,
    "pob": "Baltanas",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34200,
    "pob": "BaÑos De Cerrato",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34878,
    "pob": "BaÑos De La PeÑa",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34305,
    "pob": "Baquerin De Campos",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34839,
    "pob": "Barcenilla De Pisuerga",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34810,
    "pob": "Barrio De San Pedro",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34470,
    "pob": "Barrio La Puebla",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34820,
    "pob": "Barruelo De Santullan",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34406,
    "pob": "Bascones De Ojeda",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34813,
    "pob": "Bascones De Valdivia",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34310,
    "pob": "Becerril De Campos",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34487,
    "pob": "Becerril Del Carpio",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34304,
    "pob": "Belmonte De Campos",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34349,
    "pob": "Boadilla De Rioseco",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34468,
    "pob": "Boadilla Del Camino",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34859,
    "pob": "Boedo De Castrejon",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34829,
    "pob": "BraÑosera",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34116,
    "pob": "Bustillo De La Vega",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34828,
    "pob": "Bustillo De Santullan",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34129,
    "pob": "Bustillo Del Paramo De Carrion",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34113,
    "pob": "Bustocirio, De, Dehesa",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34469,
    "pob": "CabaÑas De Castilla, Las",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34811,
    "pob": "Cabria",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34190,
    "pob": "Calabazanos",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34407,
    "pob": "Calahorra De Boedo",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34309,
    "pob": "Calzadilla De La Cueza",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34847,
    "pob": "Campo, El",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34117,
    "pob": "Carbonera",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34120,
    "pob": "Carrion De Los Condes",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34170,
    "pob": "Cascon De La Nava",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34220,
    "pob": "Castillo De Magaz",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34850,
    "pob": "Castrejon De La PeÑa",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34131,
    "pob": "Castrillejo De La Olma",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34246,
    "pob": "Castrillo De Don Juan",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34478,
    "pob": "Castrillo De Villavega",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34208,
    "pob": "Cementos Hontoria",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34840,
    "pob": "Cervera De Pisuerga",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34218,
    "pob": "Cevico De La Torre",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34247,
    "pob": "Cevico Navero",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34320,
    "pob": "Cisneros",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34483,
    "pob": "Colmenares De Ojeda",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34882,
    "pob": "Congosto De Valdavia",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34259,
    "pob": "Cordovilla La Real",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34879,
    "pob": "Cornon De La PeÑa",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34488,
    "pob": "Cozuelos De Ojeda",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34484,
    "pob": "Dehesa De Montejo",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34160,
    "pob": "Dehesilla, La",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34210,
    "pob": "DueÑas",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34491,
    "pob": "Espinosa De Villagonzalo",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34306,
    "pob": "Frechilla",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34889,
    "pob": "Fresno Del Rio",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34440,
    "pob": "Fromista",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34337,
    "pob": "Fuentes De Nava",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34419,
    "pob": "Fuentes De Valdepero",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34128,
    "pob": "Gozon De Ucieza",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34192,
    "pob": "Grijota",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34880,
    "pob": "Guardo",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34400,
    "pob": "Herrera De Pisuerga",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34405,
    "pob": "Hijosa De Boedo",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34209,
    "pob": "Hontoria De Cerrato",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34249,
    "pob": "Hornillos De Cerrato",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34887,
    "pob": "Lastra, La",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34848,
    "pob": "Lores",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34467,
    "pob": "Melgar De Yuso",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34115,
    "pob": "Membrillar",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34486,
    "pob": "Moarves De Ojeda",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34159,
    "pob": "Monte La Torre",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34410,
    "pob": "Monzon De Campos",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34460,
    "pob": "Osorno",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34490,
    "pob": "Palacios Del Alcor",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34000,
    "pob": "Palencia (ver Callejero)",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34257,
    "pob": "Palenzuela",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34300,
    "pob": "Paredes De Nava",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34133,
    "pob": "Perales",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34110,
    "pob": "Pino Del Rio",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34430,
    "pob": "PiÑa De Campos",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34858,
    "pob": "Pison De Castrejon",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34114,
    "pob": "Portillejo",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34250,
    "pob": "Quintana Del Puente",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34844,
    "pob": "Rebanal De Las Llantas",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34830,
    "pob": "Renedo De Zalima",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34870,
    "pob": "Respenda De La PeÑa",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34447,
    "pob": "Revenga De Campos",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34411,
    "pob": "Ribas De Campos",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34100,
    "pob": "SaldaÑa",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34112,
    "pob": "San Andres De La Regla",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34465,
    "pob": "San Cebrian De Buena Madre, Finca",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34409,
    "pob": "Santa Cruz Del Monte",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34439,
    "pob": "Tamara De Campos",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34230,
    "pob": "Torquemada",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34239,
    "pob": "Valdeolmillos",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34886,
    "pob": "Velilla Del Rio Carrion",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34475,
    "pob": "Villabasta De Valdavia",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34340,
    "pob": "Villada",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34261,
    "pob": "Villalaco",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34408,
    "pob": "Villameriel",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34350,
    "pob": "Villarramiel",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34132,
    "pob": "Villasarracino",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34466,
    "pob": "Villodre",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34118,
    "pob": "Villota Del Duque",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 35329,
    "pob": "Acequia Marrero",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35350,
    "pob": "Acusa Verde",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35610,
    "pob": "Aeropuerto De Fuerteventura",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35230,
    "pob": "Aeropuerto De Gran Canaria",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35509,
    "pob": "Aeropuerto De Lanzarote",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35107,
    "pob": "Agadir",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35480,
    "pob": "Agaete (capital Municipal)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35468,
    "pob": "Agazal, La",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35638,
    "pob": "Agua De Bueyes",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35299,
    "pob": "Agualatente",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35259,
    "pob": "Aguatona El Nuevo",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35260,
    "pob": "Aguimes (capital Municipal)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35469,
    "pob": "Agujero, El",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35628,
    "pob": "Ajuy",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35339,
    "pob": "Alamo, El",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35639,
    "pob": "Alares",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35458,
    "pob": "Albercon De La Virgen",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35479,
    "pob": "Albercon, El",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35627,
    "pob": "Albertos, Los",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35119,
    "pob": "Aldea Blanca",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35470,
    "pob": "Aldea De San Nicolas, La",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35510,
    "pob": "Alemanes, Los (puerto Del Carmen), Urbanizacion",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35637,
    "pob": "Almacigo",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35149,
    "pob": "Almacigos, Los",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35018,
    "pob": "Almatriche Alto",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35000,
    "pob": "Almatriche Alto (ver Callejero De Las Palmas)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35216,
    "pob": "Almendrillo, El",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35217,
    "pob": "Almendros, Los, Urbanizacion",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35412,
    "pob": "Altabacales, Los",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35300,
    "pob": "Alternativa Uno",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35414,
    "pob": "Altillo",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35310,
    "pob": "Alvarados, Los",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35508,
    "pob": "Ancones, Los",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35400,
    "pob": "Angostillo, El",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35309,
    "pob": "Angostura, La",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35630,
    "pob": "Antigua, La (capital Municipal)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35459,
    "pob": "Anzo",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35129,
    "pob": "Aquamarina",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35333,
    "pob": "Arbejales",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35307,
    "pob": "Arco, El (santa Brigida)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35211,
    "pob": "Arenales, Los (telde)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35319,
    "pob": "Arenillas, Las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35120,
    "pob": "Arguineguin",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35118,
    "pob": "Arinaga",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35328,
    "pob": "AriÑez",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35500,
    "pob": "Arrecife",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35542,
    "pob": "Arrieta",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35108,
    "pob": "Arteara",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35478,
    "pob": "Artejeves, Zona",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35612,
    "pob": "Asomada, La (puerto Del Rosario)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35571,
    "pob": "Asomada, La (tias)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35450,
    "pob": "Atalaya De Guia, La",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35369,
    "pob": "Ayacata",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35109,
    "pob": "Ayagaures",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35100,
    "pob": "Bahia Feliz, Urbanizacion",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35210,
    "pob": "Balcon De Telde",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35110,
    "pob": "Balos",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35349,
    "pob": "Barranco",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35488,
    "pob": "Barranco Canario",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35431,
    "pob": "Barranco De Las Madres",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35421,
    "pob": "Barranco Del Laurel",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35626,
    "pob": "Barranco Del Mal Nombre",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35457,
    "pob": "Barranco Del Pinar (santa Maria De Guia)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35338,
    "pob": "Barranco Zapatero",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35128,
    "pob": "Barranquillo Andres",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35432,
    "pob": "Barranquillos, Los",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35411,
    "pob": "Barreto",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35330,
    "pob": "Barrio Del Pino (teror)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35489,
    "pob": "Berrazales, Los",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35422,
    "pob": "Brecito, El",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35570,
    "pob": "BreÑas, Las (yaiza)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35308,
    "pob": "Brisas, Las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35240,
    "pob": "Burrero, El",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35507,
    "pob": "Cabreras, Las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35558,
    "pob": "Caldereta (san Bartolome De Lanzarote)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35649,
    "pob": "Caldereta, La (la Oliva)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35106,
    "pob": "Calderin",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35215,
    "pob": "Calero, El",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35540,
    "pob": "Caleta De Sebo (isla Graciosa)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35017,
    "pob": "Calzada, La",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35560,
    "pob": "CaÑada, La (tinajo)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35413,
    "pob": "Cardonal (arucas)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35415,
    "pob": "Cardones",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35423,
    "pob": "Carreteria",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35250,
    "pob": "Carrion, El",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35368,
    "pob": "Carrizal De Tejeda",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35629,
    "pob": "Casa De Violante",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35280,
    "pob": "Casas Blancas (san Bartolome De Tirajana)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35214,
    "pob": "Casas Nuevas",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35218,
    "pob": "Caserones Altos",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35611,
    "pob": "Casillas Del Angel",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35580,
    "pob": "Castillo Del Aguila",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35130,
    "pob": "Chaparral, El (puerto Rico)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35572,
    "pob": "Conil",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35660,
    "pob": "Corralejo",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35650,
    "pob": "Cotillo, El",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35220,
    "pob": "Cruz De La Gallina",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35269,
    "pob": "Eden, El",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35212,
    "pob": "Estrella, La",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35430,
    "pob": "Firgas (capital Municipal)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35559,
    "pob": "Florida, La (san Bartolome De Lanzarote)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35460,
    "pob": "Galdar (capital Municipal)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35219,
    "pob": "Gando",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35625,
    "pob": "Gaviotas, Las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35620,
    "pob": "Gran Tarajal",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35544,
    "pob": "Guatiza",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35541,
    "pob": "Guinate",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35520,
    "pob": "Haria (capital Municipal)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35613,
    "pob": "Herradura, La (pto Rosario)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35340,
    "pob": "Lanzarote (valleseco)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35229,
    "pob": "Llanos De Cuatro Caminos",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35600,
    "pob": "Llanos De La Mareta",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35139,
    "pob": "Lomo Quiebre",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35213,
    "pob": "Majadilla, La (telde)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35543,
    "pob": "Mala",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35015,
    "pob": "Matula, La",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35140,
    "pob": "Mogan (capital Municipal)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35138,
    "pob": "Mogan, De, Playa",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35539,
    "pob": "Mojon, El",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35420,
    "pob": "Moya",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35561,
    "pob": "Mozaga",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35640,
    "pob": "Oliva, La",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35550,
    "pob": "San Bartolome De Lanzarote (capital Municipal)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35290,
    "pob": "San Bartolome De Tirajana (capital Municipal)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35200,
    "pob": "San Francisco",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35320,
    "pob": "San Mateo",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35530,
    "pob": "Teguise (capital Municipal)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35360,
    "pob": "Tejeda (capital Municipal)",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35270,
    "pob": "Temisas",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 36589,
    "pob": "Abades (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36612,
    "pob": "Abalo (san Mamede)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36954,
    "pob": "Abelendo (moaÑa)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36867,
    "pob": "Abelleira (angoares)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36690,
    "pob": "Acevedo (ponte Sampaio)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36937,
    "pob": "Achadiza, A (beluso)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36888,
    "pob": "Achas, As (san Sebastian)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36555,
    "pob": "Aciveiro (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36141,
    "pob": "AcuÑa (vilaboa)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36979,
    "pob": "Adina (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36629,
    "pob": "Aduana De Coron, A (caleiro)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36685,
    "pob": "Agar (santa MariÑa)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36520,
    "pob": "Agolada",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36526,
    "pob": "Agra (san Miguel)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36854,
    "pob": "Aguasantas (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36190,
    "pob": "Agudelo (san MartiÑo )",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36913,
    "pob": "Aguete (seixo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36990,
    "pob": "Aios (noalla)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36157,
    "pob": "Alba (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36516,
    "pob": "Albarellos (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36418,
    "pob": "Albarin (atios)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36720,
    "pob": "Albelos (guillarei)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36429,
    "pob": "Albeos (san Xoan)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36528,
    "pob": "Albergue (agolada)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36391,
    "pob": "Albores Grande (priegue)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36000,
    "pob": "Alcabre (ver Callejero De Vigo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36511,
    "pob": "Alceme (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36945,
    "pob": "Aldan (san Cibran)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36812,
    "pob": "Aldea, A (cedeira)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36859,
    "pob": "Almofrei (san Lourenzo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36458,
    "pob": "Alxen (san Paio)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36419,
    "pob": "Ameiro Longo (sanguiÑeda)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36885,
    "pob": "Ameixeira, A (san Bernabeu)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36669,
    "pob": "Amil (san Mamede)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36840,
    "pob": "Amoedo (san SaturniÑo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36791,
    "pob": "Amorin (san Xoan)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36829,
    "pob": "Anceu (santo Andre)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36628,
    "pob": "Andras (san Lourenzo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36428,
    "pob": "Angudes (san Xoan)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36350,
    "pob": "Angustia, A (nigran)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36519,
    "pob": "Ansean (santiago)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36546,
    "pob": "Ansemil (san Pedro)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36853,
    "pob": "Antas (santiago)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36512,
    "pob": "Anzo (san Xoan)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36585,
    "pob": "AÑobre (san Pedro)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36611,
    "pob": "Aralde (sobran)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36691,
    "pob": "Aranza (soutomaior)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36430,
    "pob": "Arbo (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36684,
    "pob": "Arca (san Miguel)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36677,
    "pob": "Arcos (cuntis)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36865,
    "pob": "Arcos (ponteareas)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36655,
    "pob": "Arcos Da Condesa (santa MariÑa)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36912,
    "pob": "Ardan (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36989,
    "pob": "Ardia (grove, O)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36340,
    "pob": "Area Alta, A (panxon)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36915,
    "pob": "Arealonga (marin)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36613,
    "pob": "Arealonga (santa Baia)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36164,
    "pob": "Areas (mourente)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36861,
    "pob": "Areas (ponteareas)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36711,
    "pob": "Areas (santa MariÑa)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36992,
    "pob": "Aren (samieira)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36995,
    "pob": "Aris (poio)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36192,
    "pob": "Armenteira (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36599,
    "pob": "Arnego (vila De Cruces)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36584,
    "pob": "Arnois (san Xiao)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36892,
    "pob": "Arnoso (san Lourenzo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36794,
    "pob": "Arrabal, O (oia)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36412,
    "pob": "Arrotea, A (pontellas)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36415,
    "pob": "Arrufana (louredo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36598,
    "pob": "Asorei (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36510,
    "pob": "Bailas (goias)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36308,
    "pob": "BaiÑa (santa MariÑa)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36614,
    "pob": "Baion (san Xoan)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36300,
    "pob": "Baiona (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36729,
    "pob": "Baldrans (santiago)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36949,
    "pob": "Balea (darbo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36988,
    "pob": "Balea (grove, O)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36682,
    "pob": "Baloira (san Salvador)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36140,
    "pob": "Balteiro (figueirido)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36618,
    "pob": "Bamio (san Xens)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36570,
    "pob": "Bandeira, A",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36826,
    "pob": "Barbudo (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36683,
    "pob": "Barcala (santa MariÑa)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36435,
    "pob": "Barcela (san Xoan )",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36878,
    "pob": "Barcia De Mera (san MartiÑo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36837,
    "pob": "Barcia Do Seixo (santa Ana)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36158,
    "pob": "Barcia, A (marcon)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36142,
    "pob": "Barciela (cobres)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36309,
    "pob": "Baredo (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36636,
    "pob": "Barrantes (ribadumia)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36749,
    "pob": "Barrantes (san Vicente)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36911,
    "pob": "Barriada, A (mogor)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36191,
    "pob": "Barro (san Breixo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36527,
    "pob": "Basadre (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36580,
    "pob": "Bascuas (santa MariÑa)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36389,
    "pob": "Bastida, A (couso)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36448,
    "pob": "Batallans (santa Eulalia)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36307,
    "pob": "Belesar (san Lourenzo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36659,
    "pob": "Bemil (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36517,
    "pob": "Bermes (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36688,
    "pob": "Berres (san Vicenzo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36518,
    "pob": "Bidueiros (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36154,
    "pob": "Bora (santa MariÑa)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36842,
    "pob": "Borben (santiago)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36966,
    "pob": "Bordons (san Pedro)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36858,
    "pob": "Borela (san MartiÑo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36525,
    "pob": "Borraxeiros (san Cristovo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36378,
    "pob": "Borreiros (san MartiÑo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36639,
    "pob": "Bouza, A (castrelo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36316,
    "pob": "Brandufe (vincios)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36524,
    "pob": "Brantega (san Lourenzo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36692,
    "pob": "BretoÑa (curro)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36658,
    "pob": "Briallos (san Cristovo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36828,
    "pob": "Buchabade (touron)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36930,
    "pob": "Bueu (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36939,
    "pob": "Bueu (s. MartiÑo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36869,
    "pob": "Bugarin (santa Cristina)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36779,
    "pob": "Burgueira (san Pedro)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36634,
    "pob": "Cabana, A (corvillon)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36143,
    "pob": "Cabanas (salcedo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36436,
    "pob": "Cabeiras (san Sebastian )",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36813,
    "pob": "Cabeiro (san Xoan)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36370,
    "pob": "Cabreira, A (ramallosa)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36914,
    "pob": "Cadrelo (piÑeiro)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36514,
    "pob": "Cadron (santo Estevo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36650,
    "pob": "Caldas De Reis (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36820,
    "pob": "Caldelas (ponte Caldelas)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36721,
    "pob": "Caldelas De MiÑo (san MartiÑo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36610,
    "pob": "CaldigÜela, A  (carril)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36957,
    "pob": "Calvar, O (domaio)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36770,
    "pob": "Calvario, O (rosal,o)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36845,
    "pob": "Calvos (santo Adrian)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36587,
    "pob": "Camanzo (san Salvador)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36539,
    "pob": "Camba (san Xoan)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36630,
    "pob": "Cambados (santa MariÑa)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36360,
    "pob": "Camos (santa Baia)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36647,
    "pob": "CampaÑa (santa Cristina)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36873,
    "pob": "Campo (covelo, O)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36110,
    "pob": "Campo Lameiro (san Miguel)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36596,
    "pob": "Camposancos (san Cristovo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36788,
    "pob": "Camposancos (santa Isabel)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36860,
    "pob": "Canedo (ponteareas)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36940,
    "pob": "Cangas Do Morrazo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36390,
    "pob": "Canido (oia)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36411,
    "pob": "Cans (santo Estevo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36880,
    "pob": "CaÑiza, A (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36969,
    "pob": "Carabuxeira, A (padriÑan)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36856,
    "pob": "Carballedo (san Miguel)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36910,
    "pob": "Carballeira, A (lourizan)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36582,
    "pob": "Carbia (san Xoan)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36627,
    "pob": "Cardalda (deiro)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36640,
    "pob": "Carreiras (pontecesures)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36646,
    "pob": "Casal De Eirigo (setecoros)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36475,
    "pob": "Casal, O (budiÑo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36416,
    "pob": "Casal, O (tameiga)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36876,
    "pob": "Castelans (santo Estevo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36156,
    "pob": "Castelo, O (lerez",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36450,
    "pob": "Castelo, O (salvaterra)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36938,
    "pob": "CastiÑans, As (cela)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36558,
    "pob": "Castrelo (santa MariÑa)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36117,
    "pob": "Castro (cerdedo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36116,
    "pob": "Castro (santa Baia)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36789,
    "pob": "Castro, O (salcidos)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36617,
    "pob": "Castrogudin (cea)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36417,
    "pob": "Castros, Os (dornelas)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36100,
    "pob": "Centro Militar Base General Morillo (usba)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36817,
    "pob": "Cepeda (san Pedro)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36438,
    "pob": "Cequelinos (san Miguel )",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36678,
    "pob": "Cequeril (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36139,
    "pob": "Cerdedo (san Xoan)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36447,
    "pob": "Cerdeira (san Xoan)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36687,
    "pob": "Cereixo (san Xurxo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36875,
    "pob": "Cernadela (riofrio)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36152,
    "pob": "Cerponzons (san Vicente)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36579,
    "pob": "CervaÑa (san Salvador)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36693,
    "pob": "Cesantes (san Pedro)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36456,
    "pob": "Chan Da Igrexa (pesqueiras)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36827,
    "pob": "Chan Do Casal, O (xustans)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36993,
    "pob": "Chancelas (combarro)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36320,
    "pob": "Chapela (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36414,
    "pob": "Chenlo (san Xoan)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36947,
    "pob": "Coiro (san Salvador)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36967,
    "pob": "Coiron (dena)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36379,
    "pob": "Con (parada)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36619,
    "pob": "Cornazo (san Pedro)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36545,
    "pob": "Cortegada (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36457,
    "pob": "Corzans (san Miguel)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36668,
    "pob": "Cosoirado (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36119,
    "pob": "Couso (campo Lameiro)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36955,
    "pob": "Couso (meira)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36150,
    "pob": "Couso, O (santo Andre De Xeve)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36886,
    "pob": "Couto, O (san Bartolomeu)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36151,
    "pob": "Covadaspera (verducido)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36638,
    "pob": "Covas (santa Cristina)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36633,
    "pob": "Covas De Lobos (vilariÑo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36839,
    "pob": "Covelo (san Sebastian)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36420,
    "pob": "Crecente (san Pedro)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36863,
    "pob": "CristiÑade (san Salvador)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36590,
    "pob": "Cruces (nosa SeÑora Da Piedade)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36893,
    "pob": "Cumiar (santo Estevo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36670,
    "pob": "Cuntis (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36686,
    "pob": "Curantes (san Miguel)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36649,
    "pob": "Devesa, A (campaÑa)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36388,
    "pob": "Donas (santa Baia)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36515,
    "pob": "Donramiro (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36559,
    "pob": "Duas Igrexas (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36760,
    "pob": "Eiras, As (san Bartolomeu)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36471,
    "pob": "Entenza (santos Xusto E Pastor)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36841,
    "pob": "Ermida, A (nosa SeÑora Da Anunciacion)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36838,
    "pob": "Escuadra (san Lourenzo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36547,
    "pob": "Escuadro (san Salvador)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36470,
    "pob": "Esfarrapada, A (salceda)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36679,
    "pob": "Estacas (san Fiz)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36851,
    "pob": "Estacas, As (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36730,
    "pob": "Estas (santiago)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36680,
    "pob": "Estrada, A (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36713,
    "pob": "Estrada, A (randufe)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36681,
    "pob": "Estrada, A (san Paio)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36537,
    "pob": "Fafian (santiago)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36548,
    "pob": "Fiestras (san MartiÑo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36792,
    "pob": "Figueiro (san MartiÑo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36490,
    "pob": "Filgueira (san Pedro)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36583,
    "pob": "Fontao (santiago)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36600,
    "pob": "Fontecarmoa (san Pedro)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36750,
    "pob": "Fontenla (goian)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36958,
    "pob": "Fontes (tiran)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36550,
    "pob": "Forcarei (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36556,
    "pob": "Forcarei (san MartiÑo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36455,
    "pob": "Fornelos (san Xoan)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36847,
    "pob": "Fornelos De Montes (san Lourenzo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36866,
    "pob": "Fozara (san Bartolomeu)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36118,
    "pob": "Fragas (santa MariÑa)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36889,
    "pob": "Franqueira, A  (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36946,
    "pob": "Gandon (aldan)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36615,
    "pob": "Godos (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36380,
    "pob": "Gondomar (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36843,
    "pob": "Gorgoreiro, O (moscoso)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36980,
    "pob": "Grove, O (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36780,
    "pob": "Guarda, A (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36868,
    "pob": "Guillade (san Miguel)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36538,
    "pob": "Guillar (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36529,
    "pob": "Gurgueiro (san Miguel)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36626,
    "pob": "Illa De Arousa, A (san Xulian)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36500,
    "pob": "Lalin (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36830,
    "pob": "Lama, A (san Salvador)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36657,
    "pob": "LantaÑo (san Pedro)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36544,
    "pob": "Laro (san Salvador)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36852,
    "pob": "Laxe, A (fornelos De Montes)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36635,
    "pob": "Leiro (san Xoan)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36449,
    "pob": "LiÑares (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36459,
    "pob": "Lira (san Simon)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36588,
    "pob": "Loimil (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36855,
    "pob": "Loureiro (santiago)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36153,
    "pob": "Lourizan (santo Andre)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36887,
    "pob": "Luneda (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36567,
    "pob": "Madanela De Montes, A (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36714,
    "pob": "Malvas (santiago)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36900,
    "pob": "Marin (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36920,
    "pob": "Marin (escuela Naval)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36959,
    "pob": "Marrua, A (moaÑa)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36689,
    "pob": "Matalobos (santa Baia)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36557,
    "pob": "Meavia (san Xoan)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36877,
    "pob": "Meirol (santo Andre)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36637,
    "pob": "Meis (san Salvador)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36950,
    "pob": "MoaÑa (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36870,
    "pob": "Mondariz (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36890,
    "pob": "Mondariz-balneario",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36413,
    "pob": "Mosende (san Xurxo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36437,
    "pob": "Mourentan (san Cristovo )",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36814,
    "pob": "Negros (santo Estevo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36440,
    "pob": "Neves, As (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36410,
    "pob": "Noval, O (torneiros)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36846,
    "pob": "Oitaven (san Vicente)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36862,
    "pob": "Oliveira (ponteareas)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36864,
    "pob": "Oliveira (santiago)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36586,
    "pob": "Ollares (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36393,
    "pob": "Outeiro, O (ramallosa, A)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36616,
    "pob": "Paradela (meis)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36472,
    "pob": "Parderrubias (san Tome)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36568,
    "pob": "Pardesoa (santiago)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36715,
    "pob": "Pazos De Reis ( O Sagrario)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36194,
    "pob": "Perdecanai (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36884,
    "pob": "Petan (san Xian)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36895,
    "pob": "Pias (santa MariÑa)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36474,
    "pob": "PicoÑa, A (san MartiÑo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36739,
    "pob": "PiÑeiro (tomiÑo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36163,
    "pob": "Poio (san Salvador)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36543,
    "pob": "Ponte (san Miguel)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36400,
    "pob": "PorriÑo, O (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36970,
    "pob": "Portonovo (adina)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36492,
    "pob": "Quintela (creciente)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36712,
    "pob": "Rebordans (san Bartolomeu)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36491,
    "pob": "Rebordechan (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36857,
    "pob": "Rebordelo (san MartiÑo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36818,
    "pob": "Reboreda (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36800,
    "pob": "Redondela (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36542,
    "pob": "Refoxos (san Paio)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36710,
    "pob": "Ribadelouro (santa Comba)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36493,
    "pob": "Ribeira (creciente)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36891,
    "pob": "Rocha, A (xinzo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36530,
    "pob": "Rodeiro (san Vicente)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36652,
    "pob": "Romai (san Xian)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36581,
    "pob": "Sabrexo (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36121,
    "pob": "Sacos (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36656,
    "pob": "Saiar (santo Estevo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36446,
    "pob": "San Cibran De Ribarteme (san Cibran)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36654,
    "pob": "San Clemente De Cesar (san Clemente)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36667,
    "pob": "San Lourenzo De MoraÑa (san Lourenzo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36569,
    "pob": "San Miguel De Presqueiras (forcarei)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36748,
    "pob": "San Salvador De Tebra (tomiÑo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36676,
    "pob": "San Xiao De Vea (estrada, A)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36660,
    "pob": "Santa Lucia De MoraÑa",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36872,
    "pob": "Santiago De Covelo (covelo, O)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36653,
    "pob": "Santo  Andre De Cesar (santo Andre)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36960,
    "pob": "Sanxenxo (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36816,
    "pob": "Saxamonde (san Roman)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36835,
    "pob": "Seixido (san Bartolomeu)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36740,
    "pob": "Seixo, O (tomiÑo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36494,
    "pob": "Sela (santa Maria )",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36540,
    "pob": "Silleda (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36620,
    "pob": "Sinas, As (vilanova De Arousa)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36790,
    "pob": "Sobrada (san Salvador)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36560,
    "pob": "Soutelo  De Montes",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36460,
    "pob": "Soutelo (san Vicente)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36746,
    "pob": "Tebra (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36120,
    "pob": "Tenorio (san Pedro)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36597,
    "pob": "Toiriz (san Xoan)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36648,
    "pob": "Torre, A (campaÑa)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36874,
    "pob": "Touton (san Mateo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36991,
    "pob": "Toxa (illa Da)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36811,
    "pob": "TrasmaÑo (san Vicente)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36849,
    "pob": "Traspielas (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36675,
    "pob": "Troans (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36700,
    "pob": "Tui (casco Urbano)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36883,
    "pob": "Valeixe (santa Cristina)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36645,
    "pob": "Valga (san Miguel)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36193,
    "pob": "ValiÑas (santo  Andre)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36848,
    "pob": "Ventin (san Miguel)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36747,
    "pob": "Vilamean (san Bieito)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36815,
    "pob": "Vilar De Infesta (san MartiÑo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36879,
    "pob": "Vilasobroso (san MartiÑo)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36810,
    "pob": "Viso, O (santa Maria)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36836,
    "pob": "Xesta (san Bartolomeu)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36968,
    "pob": "Xil (santa Eulalia)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36844,
    "pob": "Xunqueiras (san Salvador)",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 37640,
    "pob": "Abusejo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37510,
    "pob": "Agallas",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37594,
    "pob": "Agueda Del Caudillo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37248,
    "pob": "Ahigal De Los Aceiteros",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37173,
    "pob": "Ahigal De Villarino",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37497,
    "pob": "Alameda De Gardon, La",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37554,
    "pob": "Alamedilla, La",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37312,
    "pob": "Alaraz",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37800,
    "pob": "Alba De Tormes",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37478,
    "pob": "Alba De Yeltes",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37191,
    "pob": "Albahonda, Urbanizacion",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37624,
    "pob": "Alberca, La",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37555,
    "pob": "Albergueria De ArgaÑan, La",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37759,
    "pob": "Albergueria De Herguijuela",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37449,
    "pob": "Albergueria De La Valmuza",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37607,
    "pob": "Alcazaren, Finca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37329,
    "pob": "Alconada",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37460,
    "pob": "Alcornocal, Finca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37488,
    "pob": "Aldea Del Obispo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37718,
    "pob": "Aldeacipreste",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37250,
    "pob": "Aldeadavila De La Ribera",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37187,
    "pob": "Aldeagallega",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37116,
    "pob": "Aldeagutierrez",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37350,
    "pob": "Aldealengua",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37193,
    "pob": "Aldealengua, De, Carretera",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37183,
    "pob": "Aldealgordo De Abajo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37767,
    "pob": "Aldeanueva De Campomojado",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37429,
    "pob": "Aldeanueva De Figueroa",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37621,
    "pob": "Aldeanueva De La Sierra, Pueblo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37110,
    "pob": "Aldearrodrigo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37340,
    "pob": "Aldearrubia",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37870,
    "pob": "Aldeaseca De Alba",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37189,
    "pob": "Aldeaseca De La ArmuÑa",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37317,
    "pob": "Aldeaseca De La Frontera",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37493,
    "pob": "Aldeavieja (alaveria)",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37779,
    "pob": "Aldeavieja De Tormes",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37639,
    "pob": "Aldehuela De Yeltes",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37115,
    "pob": "Almenara De Tormes",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37176,
    "pob": "Almendra",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37430,
    "pob": "Almunia, La, Urbanizacion",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37890,
    "pob": "Amatos De Alba",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37792,
    "pob": "Amatos De Salvatierra",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37181,
    "pob": "Amatos Del Rio",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37863,
    "pob": "Anaya De Alba",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37465,
    "pob": "Anaya De Huebra",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37786,
    "pob": "Andarromero",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37111,
    "pob": "AÑover De Tormes",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37418,
    "pob": "Arabayona De Mogica",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37796,
    "pob": "Arapiles",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37129,
    "pob": "Argentina",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37455,
    "pob": "Arguijo, Finca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37755,
    "pob": "Armenteros",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37500,
    "pob": "Arrabal De San Sebastian",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37797,
    "pob": "Arroyo De La Encina, Urbanizacion",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37591,
    "pob": "Atalaya, La",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37609,
    "pob": "Avililla De La Sierra",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37874,
    "pob": "Azud De Villagonzalo, Chalets",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37330,
    "pob": "Babilafuente",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37271,
    "pob": "BaÑobarez",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37495,
    "pob": "BaÑos De Retortillo, Balneario",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37440,
    "pob": "Barbadillo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37217,
    "pob": "Barceino",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37799,
    "pob": "Bardal, El, Finca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37256,
    "pob": "Barreras",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37255,
    "pob": "Barruecopardo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37148,
    "pob": "Becerril",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37700,
    "pob": "Bejar",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37789,
    "pob": "BeleÑa",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37750,
    "pob": "Bercimuelle, Pueblo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37159,
    "pob": "Berganciano",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37291,
    "pob": "Bermellar",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37130,
    "pob": "Berrocal De Padierno, Finca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37795,
    "pob": "Berrocal De Salvatierra",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37798,
    "pob": "Bizarricas, Las, Urbanizacion",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37290,
    "pob": "Boada",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37208,
    "pob": "Boadilla",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37593,
    "pob": "Bocacara",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37520,
    "pob": "Bodon, El",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37316,
    "pob": "Boveda Del Rio Almar",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37209,
    "pob": "Buenamadre",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37773,
    "pob": "Cabeza De Bejar, La",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37214,
    "pob": "Cabeza De Caballo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37174,
    "pob": "Cabeza De Framontanos",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37490,
    "pob": "Cabezabellosa De La Calzada",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37170,
    "pob": "Cabra, La, Finca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37120,
    "pob": "Cabrasmalas",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37630,
    "pob": "Cabrillas",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37714,
    "pob": "Calzada De Bejar, La",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37448,
    "pob": "Calzada De Don Diego",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37550,
    "pob": "Campillo De Azaba",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37778,
    "pob": "Campillo De Salvatierra",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37140,
    "pob": "Campo De Ledesma, El",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37494,
    "pob": "Campocerrado",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37710,
    "pob": "Candelario",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37451,
    "pob": "Canillas De Arriba",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37716,
    "pob": "Cantagallo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37400,
    "pob": "Cantalapiedra",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37405,
    "pob": "Cantalpino",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37319,
    "pob": "Cantaracillo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37185,
    "pob": "Canto, El",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37188,
    "pob": "Carbajosa De La Sagrada",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37453,
    "pob": "Carneruelo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37893,
    "pob": "Carpio Bernardo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37186,
    "pob": "Carrascalino De La Valmuza",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37891,
    "pob": "Cartala, Finca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37219,
    "pob": "Casales, Los, Finca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37452,
    "pob": "Casanueva",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37659,
    "pob": "Casarito, El",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37765,
    "pob": "Casas De Monleon",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37541,
    "pob": "Casillas De Flores",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37749,
    "pob": "Casillas, Las",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37894,
    "pob": "CastaÑeda, Finca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37439,
    "pob": "Castellanos De Moriscos",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37552,
    "pob": "Castillejo De Azaba",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37496,
    "pob": "Castillejo De Yeltes",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37592,
    "pob": "Castillejo Martin Viejo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37656,
    "pob": "Cepeda",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37253,
    "pob": "Cerezal De PeÑahorcada",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37720,
    "pob": "Cerro, El",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37861,
    "pob": "Chagarcia-medianero",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37216,
    "pob": "Ciperez",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37190,
    "pob": "Cisnes, Los, Urbanizacion",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37830,
    "pob": "Coca De Alba",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37711,
    "pob": "Colmenar De Montemayor",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37456,
    "pob": "Corbacera",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37337,
    "pob": "Cordovilla",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37251,
    "pob": "Corporario",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37684,
    "pob": "Cristobal",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37149,
    "pob": "Cuadrillero De Los Gusanos",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37466,
    "pob": "Cuarton Del Pilar",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37281,
    "pob": "Cubo De Don Sancho, El",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37766,
    "pob": "DueÑas De Arriba",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37114,
    "pob": "Encina De San Silvestre",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37515,
    "pob": "Encina, La",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37892,
    "pob": "Encinas De Arriba",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37762,
    "pob": "Escurial De La Sierra",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37419,
    "pob": "Espino De La Orbada",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37220,
    "pob": "Fregeneda, Pueblo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37775,
    "pob": "Fresnedoso",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37200,
    "pob": "Fuente De San Esteban, La",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37793,
    "pob": "Fuentebuena",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37540,
    "pob": "Fuenteguinaldo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37768,
    "pob": "Fuenterroble De Salvatierra",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37790,
    "pob": "Fuentes De Bejar",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37480,
    "pob": "Fuentes De OÑoro",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37481,
    "pob": "Fuentes De OÑoro, Pueblo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37468,
    "pob": "Fuentes De Sando",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37785,
    "pob": "Galinduste",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37751,
    "pob": "Gallegos De Solmiron",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37658,
    "pob": "Garcibuey",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37810,
    "pob": "Garcihernandez",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37150,
    "pob": "Gejo De Los Reyes",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37420,
    "pob": "Gomecello",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37596,
    "pob": "Guadapero",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37774,
    "pob": "Guijo De Avila",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37770,
    "pob": "Guijuelo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37516,
    "pob": "Herguijuela De Ciudad Rodrigo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37619,
    "pob": "Herguijuela De La Sierra",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37864,
    "pob": "Herrezuelo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37230,
    "pob": "Hinojosa De Duero",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37712,
    "pob": "Horcajo De Montemayor",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37860,
    "pob": "Horcajo Medianero",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37336,
    "pob": "Huerta",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37754,
    "pob": "IÑigo Blasco",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37551,
    "pob": "Ituero De Azaba",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37724,
    "pob": "Lagunilla",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37865,
    "pob": "Larrodrigo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37100,
    "pob": "Ledesma",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37730,
    "pob": "Ledrada",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37760,
    "pob": "Linares De Riofrio",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37240,
    "pob": "Lumbrales",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37310,
    "pob": "Macotera",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37313,
    "pob": "Malpartida",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37315,
    "pob": "Mancera De Abajo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37171,
    "pob": "Manzano, El",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37450,
    "pob": "Matilla De Los CaÑos",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37780,
    "pob": "Maya, La",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37254,
    "pob": "Mieza",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37660,
    "pob": "Miranda Del CastaÑar",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37610,
    "pob": "Mogarras",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37683,
    "pob": "Molinillo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37618,
    "pob": "Monforte De La Sierra",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37532,
    "pob": "Monsagro",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37192,
    "pob": "Montalvo I",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37727,
    "pob": "Montemayor Del Rio",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37781,
    "pob": "Monterrubio De La Sierra",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37590,
    "pob": "Morasverdes",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37258,
    "pob": "Moronta",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37776,
    "pob": "Nava De Bejar",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37850,
    "pob": "Nava De Sotrobal",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37753,
    "pob": "Navahombela",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37882,
    "pob": "Navales De Alba",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37794,
    "pob": "Navalmoral De Bejar",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37542,
    "pob": "Navasfrias",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37426,
    "pob": "Negrilla De Palencia",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37292,
    "pob": "Olmedo De Camaces",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37428,
    "pob": "Orbada, La",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37406,
    "pob": "Palacios Rubios",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37318,
    "pob": "Paradinas De San Juan",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37524,
    "pob": "Payo, El",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37267,
    "pob": "Pedro Alvaro",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37871,
    "pob": "Pedrosillo De Alba",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37788,
    "pob": "Pedrosillo De Los Aires",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37427,
    "pob": "Pedrosillo El Ralo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37410,
    "pob": "Pedroso De La ArmuÑa, El",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37595,
    "pob": "Pedrotoro",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37787,
    "pob": "Pelayos",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37523,
    "pob": "PeÑaparda",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37300,
    "pob": "PeÑaranda De Bracamonte",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37820,
    "pob": "PeÑarandilla",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37175,
    "pob": "PereÑa De La Ribera",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37791,
    "pob": "Peromingo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37184,
    "pob": "Poligono Los Villares Reina",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37553,
    "pob": "Puebla De Azaba",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37606,
    "pob": "Puebla De Yeltes",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37748,
    "pob": "Puente Del Congosto",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37617,
    "pob": "Rebollosa",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37247,
    "pob": "Redonda, La",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37756,
    "pob": "Revalbos",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37521,
    "pob": "Robleda",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37447,
    "pob": "Rollan",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37000,
    "pob": "Salamanca (ver Callejero)",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37259,
    "pob": "Saldeana",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37314,
    "pob": "Salmoral",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37257,
    "pob": "Salto De Saucelle",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37671,
    "pob": "San Esteban De La Sierra",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37270,
    "pob": "San Felices De Los Gallegos",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37492,
    "pob": "San Fernando",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37763,
    "pob": "San Miguel De Valero",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37470,
    "pob": "Sancti Spiritus",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37682,
    "pob": "Santa Maria De Los Llanos",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37900,
    "pob": "Santa Marta De Tormes",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37690,
    "pob": "Santa Olalla De Yeltes",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37311,
    "pob": "Santiago De La Puebla",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37740,
    "pob": "SantibaÑez De Bejar",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37670,
    "pob": "SantibaÑez De La Sierra",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37172,
    "pob": "Sardon De Los Frailes",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37638,
    "pob": "Sepulcro-hilario",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37650,
    "pob": "Sequeros",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37531,
    "pob": "Serradilla Del Arroyo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37530,
    "pob": "Serradilla Del Llano",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37246,
    "pob": "Sobradillo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37777,
    "pob": "Sorihuela",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37657,
    "pob": "Sotoserrano",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37752,
    "pob": "Tala, La",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37600,
    "pob": "Tamames",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37409,
    "pob": "Tarazona De La GuareÑa",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37589,
    "pob": "Tenebron",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37840,
    "pob": "Tordillos",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37881,
    "pob": "Valdecarros",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37680,
    "pob": "Valdefuentes De Sangusin",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37713,
    "pob": "Valdehijaderos",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37717,
    "pob": "Valdesangil",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37764,
    "pob": "Valero",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37873,
    "pob": "Valeros",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37454,
    "pob": "Veguillas, Las",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37446,
    "pob": "Villalba De Los Llanos",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37320,
    "pob": "Villar De Gallimazo",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37147,
    "pob": "Villar De Peralonso",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37160,
    "pob": "Villarino De Los Aires",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37522,
    "pob": "Villasrubias",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37260,
    "pob": "Villavieja De Yeltes",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37339,
    "pob": "Villoria",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37338,
    "pob": "Villoruela",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37210,
    "pob": "Vitigudino",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37408,
    "pob": "Zorita De La Frontera",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 38687,
    "pob": "Abama",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38310,
    "pob": "Abejera, La",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38439,
    "pob": "Abiertas, Las",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38618,
    "pob": "Abrigos, Los",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38683,
    "pob": "Acantilados De Los Gigantes",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38892,
    "pob": "Acardece",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38890,
    "pob": "AceviÑos, Los",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38688,
    "pob": "Acojeja",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38111,
    "pob": "Acoran",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38670,
    "pob": "Adeje Casco",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38357,
    "pob": "Adelantado, El",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38910,
    "pob": "Aeropuerto De Hierro",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38738,
    "pob": "Aeropuerto De La Palma",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38297,
    "pob": "Aeropuerto De Los Rodeos (santa Cruz De Tenerife)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38610,
    "pob": "Aeropuerto Reina Sofia (santa Cruz De Tenerife)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38139,
    "pob": "Afur",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38355,
    "pob": "Agua Garcia",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38429,
    "pob": "Aguas, Las",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38780,
    "pob": "Aguatavar",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38591,
    "pob": "Aguerche",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38830,
    "pob": "Agulo",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38812,
    "pob": "Alajero",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38713,
    "pob": "Alamos, Los (santa Cruz De La Palma)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38686,
    "pob": "Alcala",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38628,
    "pob": "Aldea Blanca",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38129,
    "pob": "Almaciga",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38852,
    "pob": "Alojera",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38389,
    "pob": "Altos-arroyos, Los",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38639,
    "pob": "Amarilla Golf",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38438,
    "pob": "Amparo, El",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38108,
    "pob": "Andenes, Los",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38360,
    "pob": "Angeles, Los, Urbanizacion",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38813,
    "pob": "Antoncojo",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38107,
    "pob": "Apartacaminos",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38550,
    "pob": "Arafo",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38540,
    "pob": "Araya",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38312,
    "pob": "Arenas, Las (la Orotava)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38760,
    "pob": "Argual",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38850,
    "pob": "Arguamul",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38690,
    "pob": "Arguayo",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38589,
    "pob": "Arico El Nuevo",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38678,
    "pob": "ArmeÑime",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38509,
    "pob": "Aroba",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38640,
    "pob": "Arona",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38629,
    "pob": "Asomada Alta",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38801,
    "pob": "Atajo, El",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38611,
    "pob": "Atogo",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38811,
    "pob": "Ayamosna",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38315,
    "pob": "Aza, La",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38250,
    "pob": "Bajamar",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38291,
    "pob": "Baldios, Los",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38849,
    "pob": "Banda De Las Rosas",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38726,
    "pob": "Barlovento",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38510,
    "pob": "Barranco Hondo",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38627,
    "pob": "Barranco Oscuro",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38616,
    "pob": "Barrancos, Los",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38290,
    "pob": "Barreras, Las",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38758,
    "pob": "Barrial De Abajo",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38160,
    "pob": "Barrio De La Alegria",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38479,
    "pob": "Barrio De San Jose Bajo",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38768,
    "pob": "Barros (los Llanos)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38294,
    "pob": "Batan De Abajo",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38434,
    "pob": "Belmonte Bajo",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38313,
    "pob": "Benijos",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38729,
    "pob": "Bermudez",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38311,
    "pob": "Blas Luis",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38109,
    "pob": "Bocacangrejo (el Rosario)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38330,
    "pob": "Boqueron, El",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38870,
    "pob": "Borbalan",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38710,
    "pob": "BreÑa Alta",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38480,
    "pob": "Buenavista Del Norte",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38727,
    "pob": "Cabezadas Bajas",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38829,
    "pob": "Cabezadas, Las (hermigua)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38660,
    "pob": "Caldera, La",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38460,
    "pob": "Caleta De Interian, La",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38679,
    "pob": "Caleta, La (adeje)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38749,
    "pob": "Caletas, Las (fuencalite)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38530,
    "pob": "Caletillas, Las",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38820,
    "pob": "Callejon De Ordaiz",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38715,
    "pob": "Camacha, La",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38293,
    "pob": "Camino De Jardina",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38170,
    "pob": "Campitos, Los",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38340,
    "pob": "Campo De Golf (tacoronte)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38712,
    "pob": "Cancajos, Los",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38350,
    "pob": "Cantiillo, El (tacoronte)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38399,
    "pob": "Cantillo",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38000,
    "pob": "Cardonal, El (ver Callejero De San Cristobal De La Laguna), Urbanizacion",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38150,
    "pob": "Cardonera",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38419,
    "pob": "Carrera, La",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38489,
    "pob": "Carrizales, Los",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38769,
    "pob": "Casa De La Bombilla",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38879,
    "pob": "Casa De La Seda",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38588,
    "pob": "Casablanca (poris)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38914,
    "pob": "Casas, Las",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38649,
    "pob": "Casas, Las (arona)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38470,
    "pob": "Casco, El",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38788,
    "pob": "Castillo, El (garafia)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38110,
    "pob": "Centro Militar Hoya Fria",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38714,
    "pob": "Cercado PeÑon (puntallana)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38869,
    "pob": "Cercado, El (vallehermoso)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38379,
    "pob": "Chamiana",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38595,
    "pob": "Charco Del Pino",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38652,
    "pob": "Chayofa",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38594,
    "pob": "Chimiche",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38689,
    "pob": "Chio",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38632,
    "pob": "Cho",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38449,
    "pob": "Comenitas, Las",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38677,
    "pob": "Concepcion, La",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38441,
    "pob": "Convento, El (la Guancha)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38630,
    "pob": "Costa Del Silencio",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38779,
    "pob": "Costa, La (tazacorte)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38626,
    "pob": "Cruz De Guanche",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38413,
    "pob": "Cruz Santa",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38759,
    "pob": "Cuatro Caminos (llanos)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38390,
    "pob": "Cuesta De La Villa",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38320,
    "pob": "Cuesta, La",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38180,
    "pob": "Cueva Bermeja",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38314,
    "pob": "Cuevas, Las, Urbanizacion",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38617,
    "pob": "Draguito, El",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38592,
    "pob": "Eras, Las (arico)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38570,
    "pob": "Eras, Las (fasnia)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38916,
    "pob": "Erese",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38435,
    "pob": "Erjos",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38614,
    "pob": "Escalona, La",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38789,
    "pob": "Fagundo",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38398,
    "pob": "Farrobillo",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38911,
    "pob": "Frontera",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38740,
    "pob": "Fuencaliente",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38631,
    "pob": "Galletas, Las",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38450,
    "pob": "Garachico",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38787,
    "pob": "Garafia",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38459,
    "pob": "Genoves",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38600,
    "pob": "Granadilla",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38619,
    "pob": "Granadilla, Poligono Industrial",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38440,
    "pob": "Guancha, La",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38358,
    "pob": "Guayonje",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38680,
    "pob": "Guia De Isora",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38500,
    "pob": "Guimar",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38458,
    "pob": "Guincho, El (garachico)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38430,
    "pob": "Icod",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38414,
    "pob": "Icod El Alto",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38615,
    "pob": "Ifonche",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38520,
    "pob": "Igueste De Candelaria",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38140,
    "pob": "Igueste De San Andres",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38292,
    "pob": "Infierno, El",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38915,
    "pob": "Isora",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38415,
    "pob": "Llanadas",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38739,
    "pob": "Lodero",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38329,
    "pob": "Lomo De Las Casillas",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38590,
    "pob": "Lomo De Mena",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38418,
    "pob": "Longuera",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38190,
    "pob": "Machado",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38370,
    "pob": "Matanza De Acentejo, La",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38730,
    "pob": "Mazo",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38612,
    "pob": "Medano, El",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38560,
    "pob": "Medida, La",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38260,
    "pob": "Milan",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38700,
    "pob": "Morro, El (santa Cruz De La Palma)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38280,
    "pob": "Naciente",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38300,
    "pob": "Orotava, La",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38750,
    "pob": "Paso, El",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38650,
    "pob": "Playa De Los Cristianos",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38711,
    "pob": "Polvacera, La",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38508,
    "pob": "Puertito De Guimar, El",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38400,
    "pob": "Puerto De La Cruz",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38240,
    "pob": "Punta Del Hidalgo",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38428,
    "pob": "Quevedos, Los",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38860,
    "pob": "Rajita, La",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38359,
    "pob": "Ravelo",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38410,
    "pob": "Realejo Alto",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38412,
    "pob": "Realejo Bajo",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38917,
    "pob": "Restinga, La",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38593,
    "pob": "Rio De Arico, El",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38728,
    "pob": "Roque Faro",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38767,
    "pob": "Rosas, Las (los Llanos)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38579,
    "pob": "Sabina Alta (fasnia)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38912,
    "pob": "Sabinosa",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38120,
    "pob": "San Andres (santa Cruz De Tenerife)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38720,
    "pob": "San Andres Y Sauces",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38356,
    "pob": "San Jeronimo (tacoronte)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38420,
    "pob": "San Juan De La Rambla",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38620,
    "pob": "San Miguel De Abona",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38296,
    "pob": "San Miguel De Geneto",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38800,
    "pob": "San Sebastian Gomera",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38810,
    "pob": "Santiago, De, Playa",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38130,
    "pob": "Taganana",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38684,
    "pob": "Tamaimo (santiago Del Teide)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38891,
    "pob": "Tamargada",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38770,
    "pob": "Tazacorte",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38913,
    "pob": "Tejeguate",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38685,
    "pob": "Tejina De Isora",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38416,
    "pob": "Tigaiga",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38417,
    "pob": "Toscal, El (los Realejos)",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38270,
    "pob": "Valle Guerra",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38840,
    "pob": "Vallehermoso",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38900,
    "pob": "Valverde Del Hierro",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38380,
    "pob": "Victoria De Acentejo, La",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38613,
    "pob": "Vilaflor",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38580,
    "pob": "Villa De Arico",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 39626,
    "pob": "Abadilla, La",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39549,
    "pob": "Abanillas",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39210,
    "pob": "Abiada",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39639,
    "pob": "Abionzo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39698,
    "pob": "Acereda",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39761,
    "pob": "Adal",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39788,
    "pob": "Adino",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39670,
    "pob": "Aes",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39880,
    "pob": "Aguera",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39719,
    "pob": "AgÜero",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39213,
    "pob": "Aguilera, La",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39849,
    "pob": "Ahedo (ampuero)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39806,
    "pob": "Aja",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39728,
    "pob": "Ajanedo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39170,
    "pob": "Ajo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39680,
    "pob": "Alceda",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39683,
    "pob": "Aldano",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39419,
    "pob": "Aldea De Ebro",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39292,
    "pob": "Aldueso",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39584,
    "pob": "Aliezo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39813,
    "pob": "Alisas (ramales)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39798,
    "pob": "Allendelagua",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39649,
    "pob": "AloÑos",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39193,
    "pob": "Alvareo, El",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39791,
    "pob": "Ambrosero",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39840,
    "pob": "Ampuero",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39718,
    "pob": "Anaz",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39197,
    "pob": "Ancillo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39794,
    "pob": "Anero",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39727,
    "pob": "Angustina (riotuerto)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39571,
    "pob": "Aniezo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39592,
    "pob": "Ansar, El",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39212,
    "pob": "Aradillos",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39220,
    "pob": "Arantiones",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39430,
    "pob": "Arca",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39478,
    "pob": "Arce",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39627,
    "pob": "Arenal De Penagos, El",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39553,
    "pob": "Arenas (rionansa)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39450,
    "pob": "Arenas De IguÑa",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39593,
    "pob": "Arenas, Las",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39777,
    "pob": "Arenosa, La",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39582,
    "pob": "AreÑos",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39586,
    "pob": "Arguebanes",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39195,
    "pob": "Arnuero",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39360,
    "pob": "Arroyo (santillana Del Mar)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39577,
    "pob": "Arroyo, El",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39610,
    "pob": "Astillero",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39572,
    "pob": "Avellanedo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39108,
    "pob": "AzoÑos",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39764,
    "pob": "Badames",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39706,
    "pob": "Baltezana",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39790,
    "pob": "Bao, El",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39575,
    "pob": "Barago",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39795,
    "pob": "Baranda",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39525,
    "pob": "Barcena (alfoz)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39809,
    "pob": "Barcena (gibaja)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39318,
    "pob": "Barcena De Cudon",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39689,
    "pob": "Barcena De Toranzo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39420,
    "pob": "Barcena Pie De Concha",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39409,
    "pob": "Barcena, La (san Felices Buelna)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39590,
    "pob": "Barcenaciones",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39518,
    "pob": "Barcenamayor",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39477,
    "pob": "Barcenilla De Pielagos",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39513,
    "pob": "Barcenillas (ruente)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39587,
    "pob": "Baro",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39311,
    "pob": "Barquera, La",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39300,
    "pob": "Barreda (torrelavega)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39720,
    "pob": "Barrio De Arriba (riotuerto)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39313,
    "pob": "Barrio Obrero (polanco)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39451,
    "pob": "Barriopalacio (anievas)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39418,
    "pob": "Barriopalacio (valdeolea)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39408,
    "pob": "Barros",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39250,
    "pob": "Bascones De Ebro (palencia)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39580,
    "pob": "Bejes",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39557,
    "pob": "Belmonte",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39730,
    "pob": "Beranga",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39550,
    "pob": "Bielva",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39681,
    "pob": "Bollacin",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39613,
    "pob": "Boo De Guarnizo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39699,
    "pob": "BorleÑa",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39716,
    "pob": "Bosque, El",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39766,
    "pob": "Bueras",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39850,
    "pob": "Bulco",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39507,
    "pob": "Bustablado (cabezon De La Sal)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39686,
    "pob": "Bustaleguin",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39696,
    "pob": "Bustantegua",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39417,
    "pob": "Bustasur (las Rozas)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39688,
    "pob": "Bustasur (luena)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39551,
    "pob": "Cabanzon",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39500,
    "pob": "Cabezon De La Sal",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39509,
    "pob": "Cabrojo (cabezon De La Sal)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39558,
    "pob": "Cabrojo (rionansa)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39608,
    "pob": "Cacicedo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39460,
    "pob": "Caldas De Besaya, Las",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39805,
    "pob": "Calera, La (vizcaya)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39770,
    "pob": "Callejo, El",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39609,
    "pob": "Camargo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39594,
    "pob": "Camijanes",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39685,
    "pob": "Candolias",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39723,
    "pob": "Cantolla, La",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39491,
    "pob": "CaÑeda",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39528,
    "pob": "Cara",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39591,
    "pob": "Caranceja",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39479,
    "pob": "Carandia",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39762,
    "pob": "Carasa",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39687,
    "pob": "Carrascal De Cocejon",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39150,
    "pob": "Carriazo (ribamontan Al Mar)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39438,
    "pob": "Casares",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39527,
    "pob": "Casasola (ruiloba)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39860,
    "pob": "Casavieja (rasines)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39660,
    "pob": "CastaÑeda",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39700,
    "pob": "Castro Urdiales",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39583,
    "pob": "Castro-cillorigo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39595,
    "pob": "CaviÑa",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39724,
    "pob": "CeceÑas",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39539,
    "pob": "Cerrazo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39612,
    "pob": "Cianca",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39526,
    "pob": "CigÜenza",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39320,
    "pob": "Cobreces",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39750,
    "pob": "Colindres",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39407,
    "pob": "Collado (cieza)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39520,
    "pob": "Comillas",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39690,
    "pob": "Concha, La (villaescu)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39294,
    "pob": "Corconte",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39400,
    "pob": "Corrales De Buelna, Los",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39350,
    "pob": "Cortiguera",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39573,
    "pob": "Cos, Los",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39559,
    "pob": "Cosio",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39760,
    "pob": "Coz De Monte",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39793,
    "pob": "Cubas",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39574,
    "pob": "Cueva (pesaguero)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39650,
    "pob": "Cueva, La",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39749,
    "pob": "Dueso",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39130,
    "pob": "Elechas",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39715,
    "pob": "Entrambasaguas",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39682,
    "pob": "Entrambasmestas",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39638,
    "pob": "Escobedo De Villafufre",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39694,
    "pob": "Esles",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39588,
    "pob": "Espinama",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39738,
    "pob": "Fresnedo (solorzano)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39792,
    "pob": "Gajano",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39160,
    "pob": "Galizano",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39310,
    "pob": "Gornazo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39611,
    "pob": "Guarnizo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39191,
    "pob": "Guemes",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39776,
    "pob": "Hazas (liendo)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39538,
    "pob": "Helguera (reocin)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39569,
    "pob": "Helgueras",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39808,
    "pob": "Incedo De Soba",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39691,
    "pob": "Iruz",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39556,
    "pob": "Laguna, La",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39490,
    "pob": "Lantueno",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39555,
    "pob": "Lastra, La (tudanca)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39692,
    "pob": "LiaÑo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39120,
    "pob": "Liencres",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39722,
    "pob": "Lierganes",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39820,
    "pob": "Limpias",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39547,
    "pob": "Llaos, Los",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39600,
    "pob": "MaliaÑo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39200,
    "pob": "Matamorosa",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39410,
    "pob": "Mataporquera",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39812,
    "pob": "Matienzo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39314,
    "pob": "Mijares",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39709,
    "pob": "MioÑo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39180,
    "pob": "Noja",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39554,
    "pob": "Obeso",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39707,
    "pob": "OtaÑes",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39630,
    "pob": "Penilla (villafufre)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39548,
    "pob": "Pesues",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39230,
    "pob": "Poblacion De Abajo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39618,
    "pob": "Pontejos",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39570,
    "pob": "Potes",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39697,
    "pob": "Prases",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39679,
    "pob": "Presillas, Las",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39530,
    "pob": "Puente San Miguel",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39800,
    "pob": "Ramales De La Victoria",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39511,
    "pob": "Renedo De Cabuerniga",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39470,
    "pob": "Renedo De Pielagos",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39312,
    "pob": "Requejada",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39815,
    "pob": "Riva De Ruesga",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39517,
    "pob": "Saja",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39192,
    "pob": "San Bartolome (meruelo)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39619,
    "pob": "San Salvador (medio Cudeyo)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39540,
    "pob": "San Vicente De La Barquera",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39110,
    "pob": "Sancibrian (soto De La Marina)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39100,
    "pob": "Santa Cruz De Bezana",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39000,
    "pob": "Santander (ver Callejero)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39330,
    "pob": "Santillana Del Mar",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39740,
    "pob": "SantoÑa",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39620,
    "pob": "Saron",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39778,
    "pob": "SeÑa",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39710,
    "pob": "Solares",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39140,
    "pob": "Somo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39510,
    "pob": "SopeÑa (cabuerniga)",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39340,
    "pob": "Suances",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39329,
    "pob": "ToÑanes",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39560,
    "pob": "Unquera",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39315,
    "pob": "Viernoles",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39640,
    "pob": "Villacarriedo",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 40141,
    "pob": "Abades",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40192,
    "pob": "Adrada De Piron",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40354,
    "pob": "Adrados",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40340,
    "pob": "Aguilafuente",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40311,
    "pob": "Alameda (sotillo)",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40529,
    "pob": "Alconada De Maderuelo",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40292,
    "pob": "Aldea Real",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40513,
    "pob": "Aldealazaro",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40389,
    "pob": "Aldealcorvo",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40162,
    "pob": "Aldealengua De Pedraza",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40555,
    "pob": "Aldealengua De Santa Maria",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40153,
    "pob": "Aldeallana",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40532,
    "pob": "Aldeanueva De La Serrezuela",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40568,
    "pob": "Aldeanueva Del Campanario",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40462,
    "pob": "Aldeanueva Del Codonal",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40517,
    "pob": "Aldeanueva Del Monte",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40181,
    "pob": "Aldeasaz De La Cuesta",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40235,
    "pob": "AldeasoÑa",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40533,
    "pob": "Aldehorno",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40160,
    "pob": "Aldehuela (torrecaballeros)",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40380,
    "pob": "Aldeonsancho",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40531,
    "pob": "Aldeonte",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40510,
    "pob": "Alquite",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40419,
    "pob": "Alto De Los Leones",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40121,
    "pob": "Anaya",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40424,
    "pob": "Angeles De San Rafael, Los",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40492,
    "pob": "AÑe",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40123,
    "pob": "Aragoneses",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40173,
    "pob": "Arahuetes",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40164,
    "pob": "Arconcillos",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40176,
    "pob": "Arenal, El",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40185,
    "pob": "Arevalillo De Cega",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40494,
    "pob": "ArmuÑa",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40215,
    "pob": "Arroyo De Cuellar",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40520,
    "pob": "Ayllon",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40449,
    "pob": "Balisa",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40530,
    "pob": "Barbolla",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40180,
    "pob": "Basardilla",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40144,
    "pob": "Bercial",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40550,
    "pob": "Bercimuel",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40430,
    "pob": "Bernardos",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40460,
    "pob": "Bernuy De Coca",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40190,
    "pob": "Bernuy De Porreros",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40174,
    "pob": "Berzal",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40560,
    "pob": "Boceguillas",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40331,
    "pob": "Burgomillodo",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40182,
    "pob": "Caballar",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40392,
    "pob": "CabaÑas De Polendos",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40396,
    "pob": "Cabezuela",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40356,
    "pob": "Calabazas De FuentidueÑa",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40242,
    "pob": "Campo De Cuellar",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40551,
    "pob": "Campo De San Pedro",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40320,
    "pob": "Cantalejo",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40360,
    "pob": "Cantimpalos",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40163,
    "pob": "CaÑicosa",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40540,
    "pob": "Carabias",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40491,
    "pob": "Carbonero De Ahusin",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40270,
    "pob": "Carbonero El Mayor",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40165,
    "pob": "Casas Altas",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40518,
    "pob": "Cascajares",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40590,
    "pob": "Casla",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40593,
    "pob": "Castillejo De Mesleon",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40318,
    "pob": "Castillo De Castilnovo",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40317,
    "pob": "Castrillo De Sepulveda",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40315,
    "pob": "Castro De FuentidueÑa",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40591,
    "pob": "Cerezo De Abajo",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40592,
    "pob": "Cerezo De Arriba",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40216,
    "pob": "ChaÑe",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40241,
    "pob": "Chatun",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40171,
    "pob": "Chavida",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40496,
    "pob": "Ciruelos De Coca",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40332,
    "pob": "Cobos De FuentidueÑa",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40480,
    "pob": "Coca",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40463,
    "pob": "Codorniz",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40170,
    "pob": "Collado Hermoso",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40312,
    "pob": "Corral De Duraton",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40200,
    "pob": "Cuellar",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40239,
    "pob": "Cuevas De Provanco",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40230,
    "pob": "Dehesa De Cuellar",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40495,
    "pob": "Domingo Garcia",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40469,
    "pob": "Donhierro",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40554,
    "pob": "Embalse De Linares",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40391,
    "pob": "Encinillas",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40350,
    "pob": "Escalona Del Prado",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40291,
    "pob": "Escarabajosa De Cabezas",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40210,
    "pob": "Escarabajosa De Cuellar",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40393,
    "pob": "Escobar De Polendos",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40400,
    "pob": "Espinar, De El, Estacion",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40191,
    "pob": "Espirdo",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40514,
    "pob": "Estebanvela",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40134,
    "pob": "Etreros",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40217,
    "pob": "Fresneda De Cuellar",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40516,
    "pob": "Fresno De Cantespino",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40298,
    "pob": "Frumales",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40359,
    "pob": "Fuente El Olmo De FuentidueÑa",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40218,
    "pob": "Fuente El Olmo De Iscar",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40552,
    "pob": "Fuentemizarra",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40260,
    "pob": "Fuentepelayo",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40358,
    "pob": "FuentepiÑel",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40330,
    "pob": "Fuenterrebollo",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40232,
    "pob": "Fuentes De Cuellar",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40355,
    "pob": "Fuentesauco De FuentidueÑa",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40314,
    "pob": "Fuentesoto",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40357,
    "pob": "FuentidueÑa",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40120,
    "pob": "Garcillan",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40240,
    "pob": "Gomezserracin",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40512,
    "pob": "Grado Del Pico",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40569,
    "pob": "Grajera",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40423,
    "pob": "Guijasalbas",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40541,
    "pob": "Honrubia De La Cuesta",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40353,
    "pob": "Hontalbilla",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40490,
    "pob": "Hontanares De Eresma",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40195,
    "pob": "Hontoria",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40136,
    "pob": "Hoyuelos",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40151,
    "pob": "Ituero Y Lama",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40135,
    "pob": "JemenuÑo",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40130,
    "pob": "Juarros De Riomoros",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40445,
    "pob": "Juarros De Voltoya",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40146,
    "pob": "Labajos",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40236,
    "pob": "Laguna De Contreras",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40556,
    "pob": "Languilla",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40352,
    "pob": "Lastras De Cuellar",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40142,
    "pob": "Lastras Del Pozo",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40196,
    "pob": "Lastrilla, La",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40420,
    "pob": "Losa, La",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40231,
    "pob": "Lovingos",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40154,
    "pob": "Madrona",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40133,
    "pob": "Marazuela",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40466,
    "pob": "Martin MuÑoz De La Dehesa",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40446,
    "pob": "Martin MuÑoz De Las Posadas",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40214,
    "pob": "Mata De Cuellar",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40175,
    "pob": "Matilla, La",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40444,
    "pob": "Melque De Cercos",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40234,
    "pob": "Membibre De La Hoz",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40441,
    "pob": "MiguelaÑez",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40468,
    "pob": "Montejo De Arevalo",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40542,
    "pob": "Montejo De La Vega De La Serre",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40464,
    "pob": "Montuenga",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40461,
    "pob": "Moraleja De Coca",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40233,
    "pob": "Moraleja De Cuellar",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40250,
    "pob": "Mozoncillo",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40295,
    "pob": "Mudrian",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40145,
    "pob": "MuÑopedro",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40183,
    "pob": "MuÑoveros",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40443,
    "pob": "Narros De Cuellar",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40450,
    "pob": "Nava De La Asuncion",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40161,
    "pob": "Navafria",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40280,
    "pob": "Navalmanzano",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40470,
    "pob": "Navas De Oro",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40408,
    "pob": "Navas De San Antonio",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40447,
    "pob": "Nieva",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40220,
    "pob": "Olombrada",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40421,
    "pob": "Ortigosa Del Monte",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40422,
    "pob": "Otero De Herreros",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40394,
    "pob": "Otones De Benjumea",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40567,
    "pob": "Pajarejos",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40184,
    "pob": "Pajares De Pedraza",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40194,
    "pob": "Palazuelos De Eresma",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40193,
    "pob": "Parque De Robledo",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40122,
    "pob": "Pascuales",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40238,
    "pob": "Pecharroman",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40172,
    "pob": "Pedraza",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40310,
    "pob": "Perorrubio",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40296,
    "pob": "Pinarejos",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40294,
    "pob": "Pinarnegrillo",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40397,
    "pob": "Pinillos De Polendos",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40500,
    "pob": "Riaza",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40515,
    "pob": "Riofrio De Riaza",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40290,
    "pob": "Roda De Eresma",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40237,
    "pob": "Sacramenia",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40442,
    "pob": "Samboal",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40212,
    "pob": "San Cristobal De Cuellar",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40465,
    "pob": "San Cristobal De La Vega",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40197,
    "pob": "San Cristobal De Segovia",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40100,
    "pob": "San Ildefonso O La Granja",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40410,
    "pob": "San Rafael",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40297,
    "pob": "SanchonuÑo",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40594,
    "pob": "Santa Maria De Riaza",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40440,
    "pob": "Santa Maria La Real De Nieva",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40351,
    "pob": "Sauquillo De Cabezas",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40000,
    "pob": "Segovia (ver Callejero)",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40300,
    "pob": "Sepulveda",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40467,
    "pob": "Tolocirio",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40313,
    "pob": "Torreadrada",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40211,
    "pob": "Torregutierrez",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40370,
    "pob": "Turegano",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40553,
    "pob": "Valdevarnes",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40213,
    "pob": "Vallelado",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40109,
    "pob": "Valsain",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40390,
    "pob": "Valseca",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40140,
    "pob": "Valverde Del Majano",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40395,
    "pob": "Veganzones",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40150,
    "pob": "Villacastin",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40219,
    "pob": "Villaverde De Iscar",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40493,
    "pob": "Yanguas De Eresma",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40152,
    "pob": "Zarzuela Del Monte",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40293,
    "pob": "Zarzuela Del Pinar",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 41500,
    "pob": "Acebuchal, El (alcala De Guadaira)",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41440,
    "pob": "Acebuchal, El (lora Del Rio)",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41728,
    "pob": "Adriano",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41550,
    "pob": "Aguadulce",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41560,
    "pob": "Alamedilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41898,
    "pob": "Alamo, El (el MadroÑo)",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41380,
    "pob": "Alanis",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41809,
    "pob": "Albaida Del Aljarafe",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41200,
    "pob": "Alcala Del Rio",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41449,
    "pob": "Alcolea Del Rio",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41899,
    "pob": "Alcornocosa, La",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41980,
    "pob": "Algaba, La",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41661,
    "pob": "Algamitas",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41889,
    "pob": "Alisar, El",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41240,
    "pob": "Almaden De La Plata",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41111,
    "pob": "Almensilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41600,
    "pob": "Arahal",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41989,
    "pob": "Aral, El",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41849,
    "pob": "Aznalcazar",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41870,
    "pob": "Aznalcollar",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41570,
    "pob": "Badolatosa",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41805,
    "pob": "Benacazon",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41510,
    "pob": "Bencarron",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41710,
    "pob": "Bencarron (utrera)",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41110,
    "pob": "Bollullos De La Mitacion",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41930,
    "pob": "Bormujos",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41310,
    "pob": "Brenes",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41220,
    "pob": "Burguillos",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41730,
    "pob": "Cabezas De San Juan, Las",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41610,
    "pob": "Calvario, El",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41900,
    "pob": "Camas",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41429,
    "pob": "Campana, La",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41430,
    "pob": "Campillo, El (la Luisiana)",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41320,
    "pob": "Cantillana",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41439,
    "pob": "CaÑada Rosal",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41360,
    "pob": "CaÑadas Del Romero",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41910,
    "pob": "Carambolo, El",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41359,
    "pob": "Carbonal",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41100,
    "pob": "Carchena",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41410,
    "pob": "Carmona",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41820,
    "pob": "Carrion De Los Cespedes",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41580,
    "pob": "Casariche",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41230,
    "pob": "Castilblanco De Los Arroyos",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41908,
    "pob": "Castilleja De Guzman",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41950,
    "pob": "Castilleja De La Cuesta",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41810,
    "pob": "Castilleja Del Campo",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41890,
    "pob": "Castillo De Las Guardas, El",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41370,
    "pob": "Cazalla De La Sierra",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41389,
    "pob": "Cerro Del Hierro",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41409,
    "pob": "Cerro Perea",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41450,
    "pob": "Constantina",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41599,
    "pob": "Corcoya",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41780,
    "pob": "Coripe",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41760,
    "pob": "Coronil, El",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41657,
    "pob": "Corrales, Los",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41749,
    "pob": "Cuervo, El",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41000,
    "pob": "Dos Hermanas (ver Callejero)",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41400,
    "pob": "Ecija",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41807,
    "pob": "Espartinas",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41209,
    "pob": "Esquivel",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41703,
    "pob": "Fuente Del Rey",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41420,
    "pob": "Fuentes De Andalucia",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41888,
    "pob": "Garrobo, El",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41120,
    "pob": "Gelves",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41860,
    "pob": "Gerena",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41565,
    "pob": "Gilena",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41960,
    "pob": "Gines",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41339,
    "pob": "Guadajoz",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41390,
    "pob": "Guadalcanal",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41719,
    "pob": "Guadalema De Los Quintero",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41210,
    "pob": "Guillena",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41567,
    "pob": "Herrera",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41880,
    "pob": "Hoyuelo, El",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41830,
    "pob": "Huevar De Aljarafe",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41140,
    "pob": "Isla Mayor",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41563,
    "pob": "Isla Redonda La AceÑuela",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41309,
    "pob": "Jarilla, La",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41630,
    "pob": "Lantejuela, La",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41740,
    "pob": "Lebrija",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41564,
    "pob": "Lora De Estepa",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41897,
    "pob": "MadroÑo, El",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41927,
    "pob": "Mairena Del Aljarafe",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41620,
    "pob": "Marchena",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41727,
    "pob": "MaribaÑez",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41569,
    "pob": "Marinaleda",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41731,
    "pob": "Marismillas",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41658,
    "pob": "Martin De La Jara",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41659,
    "pob": "Mezquitilla, La",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41750,
    "pob": "Molares, Los",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41770,
    "pob": "Montellano",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41089,
    "pob": "Montequinto (ver Callejero De Dos Hermanas)",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41530,
    "pob": "Moron De La Frontera",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41460,
    "pob": "Navas De Las Concepcion, Las",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41804,
    "pob": "Olivares",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41640,
    "pob": "Osuna",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41219,
    "pob": "Pajanosas, Las",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41720,
    "pob": "Palacios Y Villafranca, Los",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41928,
    "pob": "Palomares Del Rio",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41566,
    "pob": "Pedrera",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41470,
    "pob": "PeÑaflor",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41840,
    "pob": "Pilas",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41150,
    "pob": "Poblado De Alfonso Xiii",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41130,
    "pob": "Poblado De Colinas",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41300,
    "pob": "Poligono Industrial El Gordillo",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41670,
    "pob": "Pruna",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41540,
    "pob": "Puebla De Cazalla, La",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41479,
    "pob": "Puebla De Los Infantes, La",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41250,
    "pob": "Real De La Jara, El",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41590,
    "pob": "Roda De Andalucia, La",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41330,
    "pob": "Rosales, Los",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41568,
    "pob": "Rubio, El",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41909,
    "pob": "Salteras",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41920,
    "pob": "San Juan De Aznalfarache",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41388,
    "pob": "San Nicolas Del Puerto",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41800,
    "pob": "Sanlucar La Mayor",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41970,
    "pob": "Santiponce",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41650,
    "pob": "Saucejo, El",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41340,
    "pob": "Tocina",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41940,
    "pob": "Tomares",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41218,
    "pob": "Torre De La Reina",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41806,
    "pob": "Umbrete",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41907,
    "pob": "Valencina De La Concepcion",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41319,
    "pob": "Viar, El",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41850,
    "pob": "Villamanrique De La Condesa",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41660,
    "pob": "Villanueva De San Juan",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41808,
    "pob": "Villanueva Del Ariscal",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41350,
    "pob": "Villanueva Del Rio Y Minas",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41318,
    "pob": "Villaverde Del Rio",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41520,
    "pob": "Viso Del Alcor, El",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 42368,
    "pob": "Abanco",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42146,
    "pob": "Abejar",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42127,
    "pob": "Abion",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42193,
    "pob": "Abioncillo",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42216,
    "pob": "Adradas",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42100,
    "pob": "Agreda",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42258,
    "pob": "Aguaviva De La Vega",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42259,
    "pob": "Aguilar De Montuenga",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42366,
    "pob": "Aguilera",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42126,
    "pob": "Alameda, La",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42132,
    "pob": "Albocabe",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42351,
    "pob": "Alcoba De La Torre",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42134,
    "pob": "Alconaba",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42320,
    "pob": "Alcozar",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42213,
    "pob": "Alcubilla De Las PeÑas",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42391,
    "pob": "Alcubilla Del Marques",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42345,
    "pob": "Aldea De San Esteban",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42180,
    "pob": "Aldealices",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42112,
    "pob": "Aldealpozo",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42107,
    "pob": "Aldehuela De Agreda",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42166,
    "pob": "Aldehuela Del Rincon",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42173,
    "pob": "Aldehuelas, Las",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42225,
    "pob": "Alentisque",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42212,
    "pob": "Almantiga",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42191,
    "pob": "Almarail",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42169,
    "pob": "Almarza",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42200,
    "pob": "Almazan",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42130,
    "pob": "Almenar",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42218,
    "pob": "Alparrache",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42230,
    "pob": "Ambrona",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42108,
    "pob": "AÑavieja",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42240,
    "pob": "Arbujuelo",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42250,
    "pob": "Arcos De Jalon",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42161,
    "pob": "Arevalo De La Sierra",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42141,
    "pob": "Arganza",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42172,
    "pob": "Ausejo De La Sierra",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42317,
    "pob": "Aylagas",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42162,
    "pob": "Aylloncillo",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42291,
    "pob": "Barbolla, La",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42210,
    "pob": "Barca",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42318,
    "pob": "Barcebal",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42248,
    "pob": "Beltejar",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42360,
    "pob": "Berlanga De Duero",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42128,
    "pob": "Bliecos",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42329,
    "pob": "Bocigas De Perales",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42223,
    "pob": "Borchicayada",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42367,
    "pob": "Bordecorex",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42300,
    "pob": "Burgo De Osma, El",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42190,
    "pob": "Camaretas, Las, Urbanizacion",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42290,
    "pob": "CamparaÑon",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42153,
    "pob": "Canredondo De La Sierra",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42148,
    "pob": "Cantalucia",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42220,
    "pob": "CaÑamaque",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42315,
    "pob": "CaÑicera",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42311,
    "pob": "Caracena",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42138,
    "pob": "Cardejon",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42344,
    "pob": "Carrascosa De Arriba",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42294,
    "pob": "Cascajosa",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42328,
    "pob": "Castillejo De Robledo",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42113,
    "pob": "Castilruiz",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42342,
    "pob": "Cenegro",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42211,
    "pob": "Centenera De Andaluz",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42181,
    "pob": "Cerbon",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42222,
    "pob": "Chercoles",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42145,
    "pob": "Cidones",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42157,
    "pob": "Covaleda",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42167,
    "pob": "Cubo De La Sierra",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42192,
    "pob": "Cuenca, La",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42158,
    "pob": "Duruelo De La Sierra",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42313,
    "pob": "Enebral, El",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42142,
    "pob": "Espeja De San Marcelino",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42164,
    "pob": "Espejo De Tera",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42189,
    "pob": "Espino, El",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42214,
    "pob": "Fuentegelmes",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42174,
    "pob": "Fuentes De San Pedro, Las",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42365,
    "pob": "Fuentetovar",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42175,
    "pob": "Fuesas, Las",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42120,
    "pob": "Gomara",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42341,
    "pob": "Hoz De Abajo",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42269,
    "pob": "Iruecha",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42257,
    "pob": "Jubera",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42159,
    "pob": "Langosto",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42156,
    "pob": "Molinos De Duero",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42165,
    "pob": "Molinos De Razon",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 26127,
    "pob": "Montenegro De Cameros",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 42340,
    "pob": "Morcuera",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42149,
    "pob": "Navaleno",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42110,
    "pob": "Olvega",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42310,
    "pob": "Rasa, La",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42330,
    "pob": "San Esteban De Gormaz",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42140,
    "pob": "San Leonardo De Yague",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42260,
    "pob": "Santa Maria De Huerta",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42000,
    "pob": "Soria (ver Callejero)",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42150,
    "pob": "Vinuesa",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 43429,
    "pob": "Aguilo",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43815,
    "pob": "Aiguamurcia",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43714,
    "pob": "Aiguaviva",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43364,
    "pob": "Aixabiga, L'",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43839,
    "pob": "Alba, L', Camping",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43360,
    "pob": "Albarca",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43716,
    "pob": "Albinyana",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43427,
    "pob": "Albio",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43479,
    "pob": "Albiol, L'",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43530,
    "pob": "Alcanar",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43460,
    "pob": "Alcover",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43896,
    "pob": "Aldea, L'",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43591,
    "pob": "Aldover",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43381,
    "pob": "Aleixar L'",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43528,
    "pob": "Alfara De Carles",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43365,
    "pob": "Alforja",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43813,
    "pob": "Alio",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43393,
    "pob": "Almoster",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43893,
    "pob": "Altafulla",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43860,
    "pob": "Ametlla De Mar, L'",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43895,
    "pob": "Ampolla, L'",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43870,
    "pob": "Amposta",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43720,
    "pob": "ArboÇ, L'",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43312,
    "pob": "Arbocet, L'",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43762,
    "pob": "Ardenya",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43773,
    "pob": "Argentera, L'",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43765,
    "pob": "Argilaga, L'",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43597,
    "pob": "Arnes",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43791,
    "pob": "Asco",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43718,
    "pob": "Atalaya Mediterranea, Urbanitzacio",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43879,
    "pob": "Balada",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43711,
    "pob": "Banyeres Del Penedes",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43422,
    "pob": "Barbera De La Conca",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43883,
    "pob": "Barquera, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43880,
    "pob": "Barri Maritim De Sant Salvador",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43120,
    "pob": "Basal",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43786,
    "pob": "Batea",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43738,
    "pob": "Bellmunt Del Priorat",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43413,
    "pob": "Belltall",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43719,
    "pob": "Bellvei",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43512,
    "pob": "Benifallet",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43747,
    "pob": "Benissanet",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43372,
    "pob": "Bisbal De Falset, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43717,
    "pob": "Bisbal Del Penedes, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43510,
    "pob": "Bitem",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43428,
    "pob": "Biure",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43411,
    "pob": "Blancafort",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43884,
    "pob": "Bonastre",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43100,
    "pob": "Bonavista",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43350,
    "pob": "Borges Del Camp, Les",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43785,
    "pob": "Bot",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43772,
    "pob": "Botarell",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43812,
    "pob": "Brafim",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43373,
    "pob": "Cabaces",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43811,
    "pob": "Cabra Del Camp",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43881,
    "pob": "Cal Cego",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43820,
    "pob": "Calafell",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43894,
    "pob": "Camarles",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43850,
    "pob": "Cambrils",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43897,
    "pob": "Camp-redo",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43110,
    "pob": "Canonja, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43776,
    "pob": "CapÇanes",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43517,
    "pob": "Capuchinos, Barranc",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43713,
    "pob": "Carronya Alta, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43787,
    "pob": "Caseres",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43569,
    "pob": "Cases D'alcanar, Les",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43559,
    "pob": "Castell, El",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43392,
    "pob": "Castellvell Del Camp",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43764,
    "pob": "Catllar, El",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43560,
    "pob": "Cenia, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43310,
    "pob": "Colldejou",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43784,
    "pob": "Corbera D'ebre",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43746,
    "pob": "Darmos",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43580,
    "pob": "Deltebre",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43816,
    "pob": "Esblada",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43379,
    "pob": "Escaladei",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43440,
    "pob": "Espluga De Francoli, L'",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43730,
    "pob": "Falset",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43459,
    "pob": "Farena",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43781,
    "pob": "Fatarella, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43000,
    "pob": "Ferran (ver Callejero De Tarragona)",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43736,
    "pob": "Figuera, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43750,
    "pob": "Flix",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43425,
    "pob": "Fores",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43558,
    "pob": "Freginals",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43515,
    "pob": "Galera, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43780,
    "pob": "Gandesa",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43749,
    "pob": "Garcia",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43153,
    "pob": "Garidells, Els",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43748,
    "pob": "Ginestar",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43516,
    "pob": "Godall",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43737,
    "pob": "Gratallops",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43410,
    "pob": "Guardia Dels Prats, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43777,
    "pob": "Guiamets, Els",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43154,
    "pob": "Gunyoles, Les",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43529,
    "pob": "Horta Baixa, L'",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43596,
    "pob": "Horta De Sant Joan",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43890,
    "pob": "Hospitalet De L'infant, L'",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43390,
    "pob": "Irles, Les",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43590,
    "pob": "Jesus",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43414,
    "pob": "Lilla",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43320,
    "pob": "Llaberia",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43712,
    "pob": "LlorenÇ Del Penedes",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43775,
    "pob": "MarÇa",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43371,
    "pob": "Margalef",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43892,
    "pob": "Marinada, Camping",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43514,
    "pob": "Mas De Barberans",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43891,
    "pob": "Masboquera",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43878,
    "pob": "Masdenverge",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43143,
    "pob": "Maso, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43763,
    "pob": "Masos De Vespella, Els",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43382,
    "pob": "Maspujols",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43150,
    "pob": "Medol, El, Urbanitzacio",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43400,
    "pob": "Montblanc",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43340,
    "pob": "Montbrio Del Camp",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43300,
    "pob": "Mont-roig Del Camp",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43740,
    "pob": "Mora D'ebre",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43770,
    "pob": "Mora La Nova",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43760,
    "pob": "Morell, El",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43361,
    "pob": "Morera De Montsant, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43887,
    "pob": "Nulles",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43151,
    "pob": "Pallaresos, Els",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43370,
    "pob": "Palma D'ebre, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43178,
    "pob": "Papiola, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43206,
    "pob": "Parcelas Plana",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43593,
    "pob": "Pauls",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43710,
    "pob": "Pedreres, Les, Urbanitzacio",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43152,
    "pob": "Perafort",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43519,
    "pob": "Perello, El",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43491,
    "pob": "Picamoixons",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43481,
    "pob": "Pineda, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43594,
    "pob": "Pinell De Brai, El",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43423,
    "pob": "Pira",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43810,
    "pob": "Pla De Santa Maria, El",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43461,
    "pob": "Plana, Ña",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43817,
    "pob": "Planeta, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43140,
    "pob": "Pobla De Mafumet, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43783,
    "pob": "Pobla De Massaluca, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43761,
    "pob": "Pobla De Montornes, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43549,
    "pob": "Poble Nou Del Delta, El",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43449,
    "pob": "Poblet (balneari)",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43448,
    "pob": "Poblet (monestir)",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43376,
    "pob": "Poboleda",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43739,
    "pob": "Porrera",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43774,
    "pob": "Pradell De La Teixeta",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43595,
    "pob": "Prat De Comte",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43155,
    "pob": "Puigdelfi",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43513,
    "pob": "Rasquera",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43840,
    "pob": "Reco De Salou",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43527,
    "pob": "Reguers, Els",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43886,
    "pob": "Renau",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43450,
    "pob": "Riba, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43790,
    "pob": "Riba-roja D'ebre",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43771,
    "pob": "Riudecanyes",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43330,
    "pob": "Riudoms",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43426,
    "pob": "Rocafort De Queralt",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43415,
    "pob": "Rojals",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43520,
    "pob": "Roquetes",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43142,
    "pob": "Rourell, El",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43715,
    "pob": "Sabartes, Edificio",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43548,
    "pob": "Salines De La Trinitat, Les",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43885,
    "pob": "Salomo",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43540,
    "pob": "Sant Carles De La Rapita",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43877,
    "pob": "Sant Jaume D'enveja",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43130,
    "pob": "Sant Salvador, Urbanitzacio",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43570,
    "pob": "Santa Barbara",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43420,
    "pob": "Santa Coloma De Queralt",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43321,
    "pob": "Santa Marina, Urbanitzacio",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43424,
    "pob": "Sarral",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43882,
    "pob": "Segur De Calafell",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43470,
    "pob": "Selva Del Camp, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43500,
    "pob": "Simpatica, Carretera",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43362,
    "pob": "Siurana",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43412,
    "pob": "Solivella",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43511,
    "pob": "Tivenys",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43792,
    "pob": "Torre De L'espanyol, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43830,
    "pob": "Torredembarra",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43550,
    "pob": "Ulldecona",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43363,
    "pob": "Ulldemolins",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43439,
    "pob": "Vallclara",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43144,
    "pob": "Vallmoll",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43800,
    "pob": "Valls",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43700,
    "pob": "Vendrell, El",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43782,
    "pob": "Vilalba Dels Arcs",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43141,
    "pob": "Vilallonga Del Camp",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43311,
    "pob": "Vilanova D'escornalbou",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43380,
    "pob": "Vilaplana",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43814,
    "pob": "Vila-rodona",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43480,
    "pob": "Vila-seca",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43490,
    "pob": "Vilaverd",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43375,
    "pob": "Vilella Alta, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43374,
    "pob": "Vilella Baixa, La",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43430,
    "pob": "Vimbodi",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43391,
    "pob": "Vinyols I Els Arcs",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43592,
    "pob": "Xerta",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 44155,
    "pob": "Ababuj",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44422,
    "pob": "Abejuela",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44565,
    "pob": "Abenfigo",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44382,
    "pob": "Aguaton",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44566,
    "pob": "Aguaviva",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44156,
    "pob": "Aguilar Del Alfambra",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44549,
    "pob": "Alacon",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44395,
    "pob": "Alba",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44540,
    "pob": "Albalate Del Arzobispo",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44100,
    "pob": "Albarracin",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44477,
    "pob": "Albentosa",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44792,
    "pob": "Alcaine",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44432,
    "pob": "Alcala De La Selva",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44600,
    "pob": "AlcaÑiz",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44550,
    "pob": "Alcorisa",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44192,
    "pob": "Aldehuela",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44160,
    "pob": "Alfambra",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44423,
    "pob": "Alhambras, Las",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44150,
    "pob": "Aliaga",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44145,
    "pob": "Allepuz",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44509,
    "pob": "Alloza",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44492,
    "pob": "Allueva",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44369,
    "pob": "Almohaja",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44134,
    "pob": "Alobras",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44721,
    "pob": "AlpeÑes",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44212,
    "pob": "Anadon",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44500,
    "pob": "Andorra",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44421,
    "pob": "Arcos De Las Salinas",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44622,
    "pob": "Arens De Lledo",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44165,
    "pob": "Argente",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44547,
    "pob": "AriÑo",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44742,
    "pob": "Armillas",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44123,
    "pob": "Arroyofrio",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44590,
    "pob": "Azaila",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44491,
    "pob": "Badenas",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44320,
    "pob": "Baguena",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44357,
    "pob": "BaÑon",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44220,
    "pob": "Barrachina",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44588,
    "pob": "Beceite",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44232,
    "pob": "Bello",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44642,
    "pob": "Belmonte De San Jose",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44556,
    "pob": "Berge",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44121,
    "pob": "Bezas",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44314,
    "pob": "Blancas",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44790,
    "pob": "Blesa",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44563,
    "pob": "Bordon",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44367,
    "pob": "Bronchales",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44394,
    "pob": "BueÑa",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44330,
    "pob": "Burbaguena",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44409,
    "pob": "Cabra De Mora",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44610,
    "pob": "Calaceite",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44200,
    "pob": "Calamocha",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44570,
    "pob": "Calanda",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44126,
    "pob": "Calomarde",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44167,
    "pob": "CamaÑas",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44459,
    "pob": "Camarena De La Sierra",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44350,
    "pob": "Caminreal",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44158,
    "pob": "Campos",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44140,
    "pob": "Cantavieja",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44559,
    "pob": "CaÑada De Benatanduz",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44643,
    "pob": "CaÑada De Verich, La",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44168,
    "pob": "CaÑada Vellida",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44707,
    "pob": "CaÑizar Del Olivar",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44191,
    "pob": "Cascante Del Rio",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44231,
    "pob": "Castejon De Tornos",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44706,
    "pob": "Castel De Cabra",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44560,
    "pob": "Castellote",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44592,
    "pob": "Castelnou",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44630,
    "pob": "Castelseras",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44412,
    "pob": "Castelvispal",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44396,
    "pob": "Caude",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44147,
    "pob": "Cedrillas",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44194,
    "pob": "Celadas",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44370,
    "pob": "Cella",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44651,
    "pob": "Cerollera, La",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44720,
    "pob": "Cervera Del Rincon",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44157,
    "pob": "Cobatillas",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44640,
    "pob": "CodoÑera, La",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44211,
    "pob": "Collados",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44397,
    "pob": "Concud",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44193,
    "pob": "Corbalan",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44791,
    "pob": "Cortes De Aragon",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44358,
    "pob": "Cosa",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44623,
    "pob": "Cretas",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44557,
    "pob": "Crivillen",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44141,
    "pob": "Cuba, La",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44495,
    "pob": "Cuencabuena",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44169,
    "pob": "Cuevas De Almuden",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44562,
    "pob": "Cuevas De CaÑart",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44730,
    "pob": "Cuevas De Portalrubio",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44162,
    "pob": "Cuevas Labradas",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44210,
    "pob": "Cutanda",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44424,
    "pob": "Escaleruela, La",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44161,
    "pob": "Escorihuela",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44770,
    "pob": "Escucha",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44558,
    "pob": "Estercuel",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44490,
    "pob": "Ferreruela De Huerva",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44440,
    "pob": "Formiche Alto",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44441,
    "pob": "Formiche Bajo",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44650,
    "pob": "Fornoles",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44143,
    "pob": "Fortanete",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44579,
    "pob": "Foz Calanda",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44596,
    "pob": "Fresneda, La",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44741,
    "pob": "Fuenferrada",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44711,
    "pob": "Fuentes Calientes",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44340,
    "pob": "Fuentes Claras",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44415,
    "pob": "Fuentes De Rubielos",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44587,
    "pob": "Fuentespalda",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44110,
    "pob": "Gea De Albarracin",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44221,
    "pob": "Godos",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44114,
    "pob": "Griegos",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44115,
    "pob": "Guadalaviar",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44433,
    "pob": "Gudar",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44530,
    "pob": "Hijar",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44213,
    "pob": "Huesa Del Comun",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44142,
    "pob": "Iglesuela Del Cid, La",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44122,
    "pob": "Jabaloyas",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44132,
    "pob": "Libros",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44164,
    "pob": "Lidon",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44624,
    "pob": "Lledo",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44493,
    "pob": "Loscos",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44391,
    "pob": "Luco De Jiloca",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44420,
    "pob": "Manzanera",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44750,
    "pob": "Martin Del Rio",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44564,
    "pob": "Mas De Las Matas",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44621,
    "pob": "Mazaleon",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44159,
    "pob": "Miravete De La Sierra",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44300,
    "pob": "Monreal Del Campo",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44652,
    "pob": "Monroyo",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44700,
    "pob": "Montalban",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44146,
    "pob": "Monteagudo Del Castillo",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44368,
    "pob": "Monterde De Albarracin",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44400,
    "pob": "Mora De Rubielos",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44124,
    "pob": "Moscardon",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44410,
    "pob": "Mosqueruela",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44780,
    "pob": "Muniesa",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44496,
    "pob": "Navarrete Del Rio",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44113,
    "pob": "Noguera De Albarracin",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44497,
    "pob": "Nogueras",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44414,
    "pob": "Nogueruelas",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44233,
    "pob": "Odon",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44313,
    "pob": "Ojos Negros",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44479,
    "pob": "Olba",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44548,
    "pob": "Oliete",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44366,
    "pob": "Orihuela Del Tremedal",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44708,
    "pob": "Palomar De Arroyos",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44769,
    "pob": "Parras De Martin, Las",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44586,
    "pob": "PeÑarroya De Tastavins",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44709,
    "pob": "PeÑarroyas",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44163,
    "pob": "Perales Del Alfambra",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44478,
    "pob": "Pertegaces, Los",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44555,
    "pob": "Pitarque",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44195,
    "pob": "Poligono Industrial La Paz",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44589,
    "pob": "Portellada, La",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44392,
    "pob": "Poyo, El",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44315,
    "pob": "Pozuel Del Campo",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44510,
    "pob": "Puebla De Hijar, La",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44450,
    "pob": "Puebla De Valverde, La",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44411,
    "pob": "Puertomingalvo",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44660,
    "pob": "Puigmoreno",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44710,
    "pob": "Rillo",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44133,
    "pob": "Riodeva",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44310,
    "pob": "Rodenas",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44125,
    "pob": "Royuela",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44166,
    "pob": "Rubielos De La Cerida",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44793,
    "pob": "Salcedillo",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44520,
    "pob": "Samper De Calanda",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44480,
    "pob": "San Agustin",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44390,
    "pob": "San Martin Del Rio",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44360,
    "pob": "Santa Eulalia",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44460,
    "pob": "Sarrion",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44561,
    "pob": "Seno",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44712,
    "pob": "Son Del Puerto",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44120,
    "pob": "Terriente",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44000,
    "pob": "Teruel (ver Callejero)",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44230,
    "pob": "Tornos",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44359,
    "pob": "Torralba De Los Sisones",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44653,
    "pob": "Torre De Arcas",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44597,
    "pob": "Torre Del Compte",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44222,
    "pob": "Torrecilla Del Rebollar",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44381,
    "pob": "Torremocha De Jiloca",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44111,
    "pob": "Torres De Albarracin",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44641,
    "pob": "Torrevelilla",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44393,
    "pob": "Torrijo Del Campo",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44112,
    "pob": "Tramacastilla",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44593,
    "pob": "Urrea De Gaen",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44760,
    "pob": "Utrillas",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44430,
    "pob": "Valbona",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44594,
    "pob": "Valdealgorfa",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44779,
    "pob": "Valdeconejos",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44413,
    "pob": "Valdelinares",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44620,
    "pob": "Valdeltormo",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44580,
    "pob": "Valderrobres",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44595,
    "pob": "Valjunquera",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44661,
    "pob": "Valmuel",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44494,
    "pob": "Villahermosa Del Campo",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44223,
    "pob": "Villanueva Del Rebollar",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44311,
    "pob": "Villar Del Salz",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44380,
    "pob": "Villarquemado",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44144,
    "pob": "Villarroya De Los Pinares",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44190,
    "pob": "Villaspesa",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44130,
    "pob": "Villastar",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44131,
    "pob": "Villel",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44591,
    "pob": "Vinaceite",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44431,
    "pob": "Virgen De La Vega",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44740,
    "pob": "Vivel Del Rio",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 45292,
    "pob": "Aceca",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45110,
    "pob": "Ajofrin",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45240,
    "pob": "Alameda De La Sagra",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45138,
    "pob": "Alares",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45522,
    "pob": "Albarreal De Tajo, Pueblo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45695,
    "pob": "Alberche Del Caudillo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45523,
    "pob": "Alcabon, Pueblo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45687,
    "pob": "AlcaÑizo, Pueblo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45662,
    "pob": "Alcaudete De La Jara",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45571,
    "pob": "Alcolea De Tajo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45908,
    "pob": "Aldea En Cabo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45661,
    "pob": "Aldeanueva De Barbarroya",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45575,
    "pob": "Aldeanueva De San Bartolome",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45631,
    "pob": "Almendral De La CaÑada",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45420,
    "pob": "Almonacid De Toledo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45900,
    "pob": "Almorox",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45250,
    "pob": "AÑover De Tajo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45182,
    "pob": "Arcicollar",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45122,
    "pob": "Arges",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45109,
    "pob": "Arisgotas",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45525,
    "pob": "Barcience, Pueblo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45593,
    "pob": "Bargas",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45660,
    "pob": "Belvis De La Jara",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45693,
    "pob": "Bernuy",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45222,
    "pob": "Borox",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45673,
    "pob": "Buenasbodas",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45634,
    "pob": "Buenaventura",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45112,
    "pob": "Burguillos De Toledo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45521,
    "pob": "Burujon, Pueblo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45592,
    "pob": "CabaÑas De La Sagra",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45312,
    "pob": "CabaÑas De Yepes",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45890,
    "pob": "Cabezamesada",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45909,
    "pob": "Calalberche, Urbanizacion",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45686,
    "pob": "Calera Y Chozas, Pueblo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45589,
    "pob": "Caleruela",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45580,
    "pob": "Calzada De Oropesa",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45180,
    "pob": "Camarena",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45181,
    "pob": "Camarenilla",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45578,
    "pob": "Campillo De La Jara, El",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45720,
    "pob": "CamuÑas",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45642,
    "pob": "Cardiel De Los Montes",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45531,
    "pob": "Carmena",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45533,
    "pob": "Carpio De Tajo, El",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45216,
    "pob": "Carranque",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45532,
    "pob": "Carriches",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45542,
    "pob": "Casar De Escalona, El",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45614,
    "pob": "Casar De Talavera",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45950,
    "pob": "Casarrubios Del Monte",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45124,
    "pob": "Casasbuenas",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45126,
    "pob": "CastaÑar, El",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45641,
    "pob": "Castillo De Bayuela",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45683,
    "pob": "Cazalegas, Pueblo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45680,
    "pob": "Cebolla, Pueblo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45214,
    "pob": "Cedillo Del Condado",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45682,
    "pob": "Cerralbos, Los, Pueblo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45637,
    "pob": "Cervera De Los Montes",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45960,
    "pob": "Chozas De Canales",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45113,
    "pob": "Chueca",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45314,
    "pob": "Ciruelos",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45291,
    "pob": "Cobeja",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45111,
    "pob": "Cobisa",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45700,
    "pob": "Consuegra",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45569,
    "pob": "Corchuela",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45880,
    "pob": "Corral De Almaguer",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45544,
    "pob": "Domingo Perez",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45311,
    "pob": "Dos Barrios",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45540,
    "pob": "Erustes",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45910,
    "pob": "Escalona",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45517,
    "pob": "Escalonilla",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45650,
    "pob": "Espinoso Del Rey",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45221,
    "pob": "Esquivias",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45574,
    "pob": "Estrella, La",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45651,
    "pob": "Fresneda, La",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45510,
    "pob": "Fuensalida",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45164,
    "pob": "Galvez",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45613,
    "pob": "Gamonal",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45643,
    "pob": "Garciotun",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45671,
    "pob": "Gargantilla",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45518,
    "pob": "Gerindote",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45160,
    "pob": "Guadamur",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45760,
    "pob": "Guardia, La",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45664,
    "pob": "Herencias, Las",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45588,
    "pob": "Herreruela De Oropesa",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45645,
    "pob": "Hinojosa De San Vicente",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45159,
    "pob": "Hontanar",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45919,
    "pob": "Hormigos",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45511,
    "pob": "Huecas",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45750,
    "pob": "Huerta De Valdecarabanos",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45675,
    "pob": "Hunfrias, Las",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45633,
    "pob": "Iglesuela, La",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45681,
    "pob": "Illan De Vacas, Pueblo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45200,
    "pob": "Illescas",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45567,
    "pob": "Lagartera",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45730,
    "pob": "Lagunas, Las",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45123,
    "pob": "Layos",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45870,
    "pob": "Lillo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45479,
    "pob": "Lituero De Abajo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45212,
    "pob": "Lominchar",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45684,
    "pob": "Lucillos, Pueblo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45710,
    "pob": "Madridejos",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45590,
    "pob": "Magan",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45692,
    "pob": "Malpica De Tajo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45460,
    "pob": "Manzaneque",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45515,
    "pob": "Maqueda",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45636,
    "pob": "Marrupe",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45430,
    "pob": "Mascaraque",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45534,
    "pob": "Mata, La",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45114,
    "pob": "Mazarambroz",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45622,
    "pob": "Mejorada",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45663,
    "pob": "Membrillo, El",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45128,
    "pob": "Menasalbas",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45930,
    "pob": "Mentrida",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45541,
    "pob": "Mesegar De Tajo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45830,
    "pob": "Miguel Esteban",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45672,
    "pob": "Mina De Sta Quiteria",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45270,
    "pob": "Mocejon",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45576,
    "pob": "Mohedas De La Jara",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45685,
    "pob": "Montearagon, Pueblo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45620,
    "pob": "Montesclaros",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45400,
    "pob": "Mora",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45190,
    "pob": "Nambroca",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45670,
    "pob": "Nava De Ricomalillo, La",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45150,
    "pob": "Navahermosa",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45610,
    "pob": "Navalcan",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45573,
    "pob": "Navalmoralejo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45140,
    "pob": "Navalmorales, Los",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45677,
    "pob": "Navaltoril",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45130,
    "pob": "Navalucillos, Los",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45630,
    "pob": "Navamorcuende",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45121,
    "pob": "Navillas, Las",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45191,
    "pob": "Nieves, Las, Urbanizacion",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45350,
    "pob": "Noblejas",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45162,
    "pob": "Noez",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45917,
    "pob": "Nombela",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45519,
    "pob": "Noves, Pueblo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45230,
    "pob": "Numancia De La Sagra",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45644,
    "pob": "NuÑo Gomez",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45300,
    "pob": "OcaÑa",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45280,
    "pob": "Olias Del Rey",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45340,
    "pob": "Ontigola",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45450,
    "pob": "Orgaz",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45560,
    "pob": "Oropesa",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45543,
    "pob": "Otero",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45213,
    "pob": "Palomeque",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45290,
    "pob": "Pantoja",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45611,
    "pob": "Parrillas",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45918,
    "pob": "Pelahustan",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45638,
    "pob": "Pepino",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45678,
    "pob": "Piedraescrita",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45161,
    "pob": "Polan",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45512,
    "pob": "Portillo De Toledo, El",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45220,
    "pob": "Pradillos, Los, Urbanizacion",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45840,
    "pob": "Puebla De Almoradiel, La",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45516,
    "pob": "Puebla De Montalban, La",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45690,
    "pob": "Pueblanueva, La",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45570,
    "pob": "Puente Del Arzobispo, El",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45577,
    "pob": "Puerto De San Vicente",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45125,
    "pob": "Pulgar",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45790,
    "pob": "Quero",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45800,
    "pob": "Quintanar De La Orden",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45514,
    "pob": "Quismondo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45640,
    "pob": "Real De San Vicent, El",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45211,
    "pob": "Recas",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45652,
    "pob": "Retamoso De La Jara",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45524,
    "pob": "Rielves, Pueblo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45676,
    "pob": "Robledillo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45674,
    "pob": "Robledo Del Mazo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45770,
    "pob": "Romeral, El",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45691,
    "pob": "San Antonio",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45654,
    "pob": "San Bartolome De Las Abiertas",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45165,
    "pob": "San Martin De Montalban",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45170,
    "pob": "San Martin De Pusa",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45120,
    "pob": "San Pablo De Los Montes",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45646,
    "pob": "San Roman De Los Montes",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45653,
    "pob": "Santa Ana De Pusa",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45370,
    "pob": "Santa Cruz De La Zarza",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45513,
    "pob": "Santa Cruz De Retamar",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45530,
    "pob": "Santa Olalla",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45526,
    "pob": "Santo Domingo-caudilla",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45632,
    "pob": "Sartajada",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45621,
    "pob": "Segurilla",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45224,
    "pob": "SeseÑa Nuevo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45223,
    "pob": "SeseÑa Viejo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45100,
    "pob": "Sonseca",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45635,
    "pob": "Sotillo De Las Palomas",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45600,
    "pob": "Talavera De La Reina",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45694,
    "pob": "Talavera La Nueva",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45780,
    "pob": "Tembleque",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45820,
    "pob": "Toboso, El",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45000,
    "pob": "Toledo (ver Callejero)",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45920,
    "pob": "Torre De Esteban Hambran, La",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45572,
    "pob": "Torrico",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45500,
    "pob": "Torrijos",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45163,
    "pob": "Totanes",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45789,
    "pob": "Turleque",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45217,
    "pob": "Ugena",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45480,
    "pob": "Urda",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45139,
    "pob": "Valdeazores",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45940,
    "pob": "Valmojado",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45612,
    "pob": "Velada",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45127,
    "pob": "Ventas Con PeÑa Aguilera, Las",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45183,
    "pob": "Ventas De Retamosa, Las",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45568,
    "pob": "Ventas De San Julian, Las",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45850,
    "pob": "Villa De Don Fadrique, La",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45860,
    "pob": "VillacaÑas",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45520,
    "pob": "Villaluenga De La Sagra",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45594,
    "pob": "Villamiel De Toledo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45440,
    "pob": "Villaminaya",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45749,
    "pob": "Villamuelas",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45810,
    "pob": "Villanueva De Alcardete",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45410,
    "pob": "Villanueva De Bogas",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45179,
    "pob": "Villarejo De Montalban",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45360,
    "pob": "Villarrubia De Santiago",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45260,
    "pob": "Villaseca De La Sagra",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45740,
    "pob": "Villasequilla",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45310,
    "pob": "Villatobas",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45215,
    "pob": "Viso De San Juan, El",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45470,
    "pob": "Yebenes, Los",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45313,
    "pob": "Yepes",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45529,
    "pob": "Yuncler",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45591,
    "pob": "Yunclillos",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45210,
    "pob": "Yuncos",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 46140,
    "pob": "Ademuz",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46729,
    "pob": "Ador",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46940,
    "pob": "Aeropuerto De Manises (valencia)",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46890,
    "pob": "Agullent",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46176,
    "pob": "Ahillas",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46812,
    "pob": "Aielo De Malferit",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46842,
    "pob": "Aielo De Rugat",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46970,
    "pob": "AlaquÀs",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46860,
    "pob": "Albaida",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46470,
    "pob": "Albal",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46687,
    "pob": "Albalat De La Ribera",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46135,
    "pob": "Albalat Dels Sorells",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46591,
    "pob": "Albalat Dels Taronchers",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46260,
    "pob": "Alberic",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46369,
    "pob": "Alborache",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46120,
    "pob": "Alboraya",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46894,
    "pob": "Alboy",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46550,
    "pob": "Albuixech",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46293,
    "pob": "AlcÀntera De Xuquer",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46290,
    "pob": "Alcasser",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46172,
    "pob": "Alcublas",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46690,
    "pob": "Alcudia De Crespins, L'",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46250,
    "pob": "Alcudia, L'",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46960,
    "pob": "Aldaia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46300,
    "pob": "Aldea De Estenas",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46910,
    "pob": "Alfafar",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46594,
    "pob": "Alfara De Algimia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46115,
    "pob": "Alfara Del Patriarca",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46197,
    "pob": "Alfarp",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46893,
    "pob": "Alfarrasi",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46725,
    "pob": "Alfauir",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46593,
    "pob": "Algar De Palancia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46680,
    "pob": "Algemesi",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46148,
    "pob": "Algimia De Alfara",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46230,
    "pob": "Alginet",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46529,
    "pob": "Almarda, De, Playa",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46132,
    "pob": "AlmÀssera",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46178,
    "pob": "Almeza, La",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46726,
    "pob": "AlmiserÀ",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46723,
    "pob": "Almoines",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46440,
    "pob": "Almussafes",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46715,
    "pob": "Alqueria De La Comtessa, L'/alqueria De La Condesa",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46220,
    "pob": "Alter, L'",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46600,
    "pob": "Alzira",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46800,
    "pob": "Anahuir",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46162,
    "pob": "Andilla",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46820,
    "pob": "Anna",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46266,
    "pob": "Antella",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46179,
    "pob": "Aras De Los Olmos",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46869,
    "pob": "Atzeneta D'albaida",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46240,
    "pob": "Ausias March",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46659,
    "pob": "Ayacor",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46620,
    "pob": "Ayora",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46357,
    "pob": "Azagador",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46792,
    "pob": "Barraca D'aigues Vives",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46390,
    "pob": "Barrio Arroyo",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46930,
    "pob": "Barrio De Porta",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46758,
    "pob": "Barx",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46667,
    "pob": "Barxeta",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46868,
    "pob": "BÈlgida",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46713,
    "pob": "Bellreguard Poble",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46839,
    "pob": "Bellus",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46173,
    "pob": "Benageber",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46180,
    "pob": "Benaguasil",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46514,
    "pob": "Benavites",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46722,
    "pob": "Beniarjo",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46844,
    "pob": "Beniatjar",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46838,
    "pob": "Benicolet",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46689,
    "pob": "Benicull De Xuquer",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46450,
    "pob": "Benifaio",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46791,
    "pob": "Benifairo De La Valldigna",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46511,
    "pob": "Benifairo De Les Valls",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46000,
    "pob": "Benifaraig (ver Callejero De Valencia)",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46830,
    "pob": "Beniganim",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46035,
    "pob": "Benimamet",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46291,
    "pob": "Benimodo",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46611,
    "pob": "Benimuslem",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46469,
    "pob": "Beniparrell",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46181,
    "pob": "Benisano",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46666,
    "pob": "Berfull",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46117,
    "pob": "Betera",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46825,
    "pob": "Bicorp",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46880,
    "pob": "Bocairent",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46822,
    "pob": "Bolbaite",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46131,
    "pob": "BonrepÒs I Mirambell",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46409,
    "pob": "Brosquil, El",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46891,
    "pob": "Bufali",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46165,
    "pob": "Bugarra",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46360,
    "pob": "BuÑol",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46100,
    "pob": "Burjassot",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46199,
    "pob": "Cabezuela, La",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46175,
    "pob": "Calles",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46150,
    "pob": "Campamento De Marines",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46127,
    "pob": "Campamento Militar De Betera",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46352,
    "pob": "Campo Arcis",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46110,
    "pob": "Campo Olivar",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46330,
    "pob": "Camporrobles",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46650,
    "pob": "Canals",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46182,
    "pob": "CaÑada, La",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46351,
    "pob": "CaÑada, La (requena)",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46740,
    "pob": "Carcaixent",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46294,
    "pob": "Carcer",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46147,
    "pob": "Casas Altas",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46146,
    "pob": "Casas Bajas",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46355,
    "pob": "Casas De Juan Vich",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46621,
    "pob": "Casas De Madrona",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46312,
    "pob": "Casas De Medina",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46310,
    "pob": "Casas De Moya",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46635,
    "pob": "Casas De Vidal",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46356,
    "pob": "Casas Del Rio",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46313,
    "pob": "Casas, Las",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46171,
    "pob": "Casinos",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46841,
    "pob": "Castello De Rugat",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46141,
    "pob": "Castielfabib",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46196,
    "pob": "Catadau",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46315,
    "pob": "Caudete De Las Fuentes",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46225,
    "pob": "Centro Penitenciario Picassent",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46813,
    "pob": "CerdÀ",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46821,
    "pob": "Chella",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46350,
    "pob": "Chera",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46380,
    "pob": "Cheste",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46370,
    "pob": "Chiva",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46167,
    "pob": "Chulilla",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46625,
    "pob": "Cofrentes",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46749,
    "pob": "Cogullada",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46354,
    "pob": "Cojos, Los",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46389,
    "pob": "Conde Cortichelles, Del, Masia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46612,
    "pob": "Corbera",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46759,
    "pob": "Corrales De Valldigna",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46980,
    "pob": "Cruz De Gracia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46134,
    "pob": "Cuiper",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46400,
    "pob": "Cullera",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46710,
    "pob": "Daimus",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46174,
    "pob": "DomeÑo",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46198,
    "pob": "Dos Aguas",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46408,
    "pob": "Dosel, El",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46183,
    "pob": "Eliana, L'",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46810,
    "pob": "Enguera",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46669,
    "pob": "Ènova, L'",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46513,
    "pob": "Estacion De Los Valles",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46590,
    "pob": "Estivella",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46817,
    "pob": "Estubeny",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46512,
    "pob": "Faura",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46614,
    "pob": "Favara",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46630,
    "pob": "Font De La Figuera, La",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46717,
    "pob": "Font D'en CarrÒs, La",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46418,
    "pob": "Fortaleny",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46229,
    "pob": "Fuente Del Omet",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46314,
    "pob": "Fuenterrobles",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46268,
    "pob": "Garrofera De Alzira, La",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46169,
    "pob": "Gatova",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46267,
    "pob": "Gavarda",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46166,
    "pob": "Gestalgar",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46149,
    "pob": "Gilet",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46388,
    "pob": "Godelleta",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46818,
    "pob": "Granja De La Costera, La",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46610,
    "pob": "Guadassuar",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46711,
    "pob": "Guardamar De La Safor",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46624,
    "pob": "Jalance",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46623,
    "pob": "Jarafuel",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46311,
    "pob": "Jaraguas",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46909,
    "pob": "Juez, Del, Masia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46814,
    "pob": "Llanera De Ranes",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46613,
    "pob": "Llauri",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46160,
    "pob": "Lliria",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46668,
    "pob": "Llocnou D'en Fellonet",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46195,
    "pob": "Llombai",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46815,
    "pob": "Llosa De Ranes, La",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46339,
    "pob": "Loberuela, La",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46393,
    "pob": "Loriguilla",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46168,
    "pob": "Losa Del Obispo",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46368,
    "pob": "Macastre",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46660,
    "pob": "Manuel",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46728,
    "pob": "Marchuquera",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46419,
    "pob": "Mareny Blau",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46163,
    "pob": "Marines",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46144,
    "pob": "Mas De Jacinto",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46292,
    "pob": "Masalaves",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46112,
    "pob": "Masarrochos",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46116,
    "pob": "Masias",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46560,
    "pob": "Massalfassar",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46130,
    "pob": "Massamagrell",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46133,
    "pob": "Meliana",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46920,
    "pob": "Mislata",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46640,
    "pob": "Mogente/moixent",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46730,
    "pob": "Moli Santa Maria",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46113,
    "pob": "Moncada",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46192,
    "pob": "Monserrat",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46892,
    "pob": "Montaverner",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46185,
    "pob": "Montecolorado, Urbanizacion",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46692,
    "pob": "Montesa",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46193,
    "pob": "Montroy",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46136,
    "pob": "Museros",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46119,
    "pob": "Naquera",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46811,
    "pob": "Navalon De Abajo",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46823,
    "pob": "Navarres",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46145,
    "pob": "Negron",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46819,
    "pob": "Novele/novetle",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46780,
    "pob": "Oliva",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46394,
    "pob": "Oliveral, Del, Masia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46850,
    "pob": "Olleria, L'",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46870,
    "pob": "Ontinyent",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46200,
    "pob": "Paiporta",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46724,
    "pob": "Palma De Gandia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46714,
    "pob": "Palmera",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46164,
    "pob": "Pedralba",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46420,
    "pob": "Perello, El",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46501,
    "pob": "Petres",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46210,
    "pob": "Picanya",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46712,
    "pob": "Piles",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46139,
    "pob": "Pobla De Farnals, La",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46840,
    "pob": "Pobla Del Duc, La",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46670,
    "pob": "Pobla Llarga, La",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46988,
    "pob": "Poligono Fuente Del Jarro",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46688,
    "pob": "PolinyÀ De Xuquer",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46721,
    "pob": "Potries",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46530,
    "pob": "PuÇol",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46137,
    "pob": "Puebla De Farnals, Playa",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46520,
    "pob": "Puerto De Sagunto",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46540,
    "pob": "Puig, Del, Playa",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46515,
    "pob": "Quart De Les Valls",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46510,
    "pob": "Quartell",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46837,
    "pob": "Quatretonda",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46824,
    "pob": "Quesa",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46138,
    "pob": "RafelbuÑol",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46716,
    "pob": "Rafelcofer",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46843,
    "pob": "Rafol De Salem",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46727,
    "pob": "Real De Gandia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46194,
    "pob": "Real De Montroi",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46900,
    "pob": "Realon, El",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46391,
    "pob": "Rebollar, El",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46340,
    "pob": "Requena",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46190,
    "pob": "Riba-roja De Turia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46417,
    "pob": "Riola",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46111,
    "pob": "Rocafort",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46439,
    "pob": "Romani",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46816,
    "pob": "Rotgla I Corbera",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46353,
    "pob": "Ruices, Los",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46500,
    "pob": "Sagunt/sagunto",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46184,
    "pob": "San Antonio De Benageber",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46142,
    "pob": "Santos, Los",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46592,
    "pob": "Segart",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46295,
    "pob": "Sellent",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46118,
    "pob": "Serra",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46392,
    "pob": "Siete Aguas",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46460,
    "pob": "Silla",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46750,
    "pob": "Simat De La Valldigna",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46320,
    "pob": "Sinarcas",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46430,
    "pob": "Sollana",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46410,
    "pob": "Sueca",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46760,
    "pob": "Tavernes De La Valldigna",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46622,
    "pob": "Teresa De Cofrentes",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46989,
    "pob": "Terramelar",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46321,
    "pob": "Torre, Aldea De La",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46143,
    "pob": "Torrebaja",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46595,
    "pob": "Torres-torres",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46269,
    "pob": "Tous",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46177,
    "pob": "Tuejar",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46691,
    "pob": "Vallada",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46901,
    "pob": "Vedat De Torrente, El",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46191,
    "pob": "Vilamarxant",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46720,
    "pob": "Villalonga",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46270,
    "pob": "Villanueva De Castellon",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46170,
    "pob": "Villar Del Arzobispo",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46317,
    "pob": "Villargordo Del Cabriel",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46114,
    "pob": "Vinalesa",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46770,
    "pob": "Xeraco",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46790,
    "pob": "Xeresa",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46950,
    "pob": "Xirivella",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46367,
    "pob": "Yatova",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 47129,
    "pob": "Adalia",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47418,
    "pob": "Aguasal",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47814,
    "pob": "Aguilar De Campos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47281,
    "pob": "Aguilarejo",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47510,
    "pob": "Alaejos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47238,
    "pob": "Alcazaren",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47160,
    "pob": "Aldea De San Miguel",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47313,
    "pob": "Aldealbar",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47162,
    "pob": "Aldeamayor De San Martin",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47419,
    "pob": "Almenara De Adaja",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47177,
    "pob": "Amusquillo",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47239,
    "pob": "Aniago",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47195,
    "pob": "Arroyo De La Encomienda",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47210,
    "pob": "Ataquines",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47312,
    "pob": "Bahabon",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47674,
    "pob": "Barcial De La Loma",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47670,
    "pob": "Becilla De Valderaduey",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47880,
    "pob": "Benafarces",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47115,
    "pob": "Bercero",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47813,
    "pob": "Berrueces",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47462,
    "pob": "Bobadilla Del Campo",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47317,
    "pob": "Bocos De Duero",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47151,
    "pob": "Boecillo",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47675,
    "pob": "BolaÑos De Campos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47461,
    "pob": "Brahojos De Medina",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47608,
    "pob": "Bustillo De Chaves",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47260,
    "pob": "Cabezon De Pisuerga",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47689,
    "pob": "Cabezon De Valderaduey",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47832,
    "pob": "Cabreros Del Monte",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47451,
    "pob": "Calabazas",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47310,
    "pob": "Campaspero",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47460,
    "pob": "Campillo, El",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47165,
    "pob": "Camporredondo",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47311,
    "pob": "Canalejas De PeÑafiel",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47185,
    "pob": "Canillas De Esgueva",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47470,
    "pob": "Carpio",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47110,
    "pob": "Casasola De Arion",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47512,
    "pob": "Castrejon De Trabancos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47318,
    "pob": "Castrillo De Duero",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47329,
    "pob": "Castrillo-tejeriego",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47192,
    "pob": "Castrodeza",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47882,
    "pob": "Castromembibre",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47641,
    "pob": "Castromonte",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47171,
    "pob": "Castronuevo De Esgueva",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47520,
    "pob": "CastronuÑo",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47664,
    "pob": "Castroponce De Valderaduey",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47182,
    "pob": "Castroverde De Cerrato",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47692,
    "pob": "Ceinos De Campos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47494,
    "pob": "Cervillego De La Cruz",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47270,
    "pob": "Cigales",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47191,
    "pob": "CiguÑuela",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47193,
    "pob": "Cisterniga, La",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47440,
    "pob": "Cogeces De Iscar",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47169,
    "pob": "Corala, La",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47280,
    "pob": "Corcos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47290,
    "pob": "Cubillas De Santa Marta",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47650,
    "pob": "Cuenca De Campos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47316,
    "pob": "Curiel De Duero",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47186,
    "pob": "Encinas De Esgueva",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47176,
    "pob": "Esguevillas De Esgueva",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47184,
    "pob": "Fombellida",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47492,
    "pob": "Foncastin",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47609,
    "pob": "Fontihoyuelo",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47480,
    "pob": "Fresno El Viejo",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47194,
    "pob": "FuensaldaÑa",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47134,
    "pob": "Gallegos De Hornija",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47606,
    "pob": "Gaton De Campos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47131,
    "pob": "Geria",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47493,
    "pob": "Gomeznarro",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47161,
    "pob": "Herrera De Duero",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47607,
    "pob": "Herrin De Campos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47219,
    "pob": "Honcalada",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47420,
    "pob": "Iscar",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47140,
    "pob": "Laguna De Duero",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47314,
    "pob": "Langayo",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47133,
    "pob": "Marzales",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47230,
    "pob": "Matapozuelos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47114,
    "pob": "Matilla De Los CaÑos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47680,
    "pob": "Mayorga",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47800,
    "pob": "Medina De Rioseco",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47400,
    "pob": "Medina Del Campo",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47687,
    "pob": "Melgar De Abajo",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47686,
    "pob": "Melgar De Arriba",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47250,
    "pob": "Mojados",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47688,
    "pob": "Monasterio De Vega",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47816,
    "pob": "Montealegre De Campos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47320,
    "pob": "Montemayor De Pililla",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47691,
    "pob": "Moral De La Reina",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47454,
    "pob": "Moraleja De Las Panadera",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47811,
    "pob": "Morales De Campos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47120,
    "pob": "Mota Del Marques",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47630,
    "pob": "Mudarra, La",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47209,
    "pob": "Muedra, Granja",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47500,
    "pob": "Nava Del Rey",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47464,
    "pob": "Nueva Villa De Las Torres",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47359,
    "pob": "Olivares De Duero",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47410,
    "pob": "Olmedo",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47173,
    "pob": "Olmos De Esgueva",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47812,
    "pob": "Palazuelo De Vedija",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47639,
    "pob": "Paramo De Matallana",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47328,
    "pob": "Parrilla, La",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47196,
    "pob": "Pedraja De Portillo, La",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47430,
    "pob": "Pedrajas De San Esteban",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47112,
    "pob": "Pedrosa Del Rey",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47132,
    "pob": "Pedroso De La Abadesa",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47300,
    "pob": "PeÑafiel",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47640,
    "pob": "PeÑaflor De Hornija",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47315,
    "pob": "Pesquera De Duero",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47153,
    "pob": "Pinar De Antequera, El",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47175,
    "pob": "PiÑa De Esgueva",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47881,
    "pob": "Pobladura De Sotiedra",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47116,
    "pob": "Pollos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47450,
    "pob": "Pozal De Gallinas",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47220,
    "pob": "Pozaldez",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47831,
    "pob": "Pozuelo De La Orden",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47152,
    "pob": "Puente Duero",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47360,
    "pob": "Quintanilla De Arriba",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47350,
    "pob": "Quintanilla De Onesimo",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47283,
    "pob": "Quintanilla De Trigueros",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47673,
    "pob": "Quintanilla Del Molar",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47319,
    "pob": "Rabano",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47453,
    "pob": "Ramiro",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47170,
    "pob": "Renedo De Esgueva",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47490,
    "pob": "Rueda",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47164,
    "pob": "San Miguel Del Arroyo",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47851,
    "pob": "San Pedro De Latarce",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47530,
    "pob": "San Roman De La Hornija",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47331,
    "pob": "SantibaÑez De Valcorba",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47155,
    "pob": "Santovenia De Pisuerga",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47340,
    "pob": "Sardon De Duero",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47491,
    "pob": "Seca, La",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47231,
    "pob": "Serrada",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47511,
    "pob": "Siete Iglesias De Trabancos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47130,
    "pob": "Simancas",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47815,
    "pob": "Tamariz De Campos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47870,
    "pob": "Tiedra",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47830,
    "pob": "Tordehumos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47100,
    "pob": "Tordesillas",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47183,
    "pob": "Torre De Esgueva",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47513,
    "pob": "Torrecilla De La Orden",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47509,
    "pob": "Torrecilla Del Valle",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47330,
    "pob": "Traspinedo",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47282,
    "pob": "Trigueros Del Valle",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47671,
    "pob": "Urones De Castroponce",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47862,
    "pob": "UrueÑa",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47240,
    "pob": "Valdestillas",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47672,
    "pob": "Valdunquillo",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47000,
    "pob": "Valladolid (ver Callejero)",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47200,
    "pob": "Valoria La Buena",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47690,
    "pob": "Valverde De Campos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47463,
    "pob": "Velascalvaro",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47232,
    "pob": "Ventosa De La Cuesta",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47150,
    "pob": "Viana De Cega",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47820,
    "pob": "Villabragima",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47181,
    "pob": "Villaco",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47529,
    "pob": "Villafranca De Duero",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47810,
    "pob": "Villafrechos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47180,
    "pob": "Villafuerte",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47840,
    "pob": "Villagarcia De Campos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47111,
    "pob": "Villalar De Los Comuneros",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47237,
    "pob": "Villalba De Adaja",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47113,
    "pob": "Villalbarba",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47600,
    "pob": "Villalon De Campos",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47620,
    "pob": "Villanubla",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47850,
    "pob": "Villanueva De Los Caballeros",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47174,
    "pob": "Villanueva De Los Infantes",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47860,
    "pob": "Villardefrades",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47172,
    "pob": "Villarmentero De Esgueva",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47883,
    "pob": "Villavellid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47465,
    "pob": "Villaverde De Medina",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47676,
    "pob": "Villavicencio De Los Caballeros",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47166,
    "pob": "Viloria",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47190,
    "pob": "Wamba",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47610,
    "pob": "Zaratan",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47452,
    "pob": "Zarza, La",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 48220,
    "pob": "AbadiÑo",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48500,
    "pob": "Abanto",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48395,
    "pob": "AbiÑa (andoni Deuna)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48191,
    "pob": "AceÑa, La",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48180,
    "pob": "Aeropuerto De Loiu",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48330,
    "pob": "Agarre (lemoa)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48111,
    "pob": "Agirre",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48630,
    "pob": "Agirre",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48114,
    "pob": "Agirre (arrieta)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48480,
    "pob": "Agirre (arrigorriaga)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48370,
    "pob": "Agirre (bermeo)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48960,
    "pob": "Agirre-aperribai",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48891,
    "pob": "Ahedo",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48215,
    "pob": "Aita San Miguel",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48391,
    "pob": "Aiuria",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48320,
    "pob": "Ajangiz",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48940,
    "pob": "Aketxe",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48311,
    "pob": "Akorda",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48313,
    "pob": "Akorda-bollar",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48382,
    "pob": "Albiz",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48419,
    "pob": "Albizuelexaga",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48116,
    "pob": "Aldai",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48340,
    "pob": "Aldana",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48160,
    "pob": "Aldekona (san Isidro)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48870,
    "pob": "Alen",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48000,
    "pob": "Algorta (ver Callejero Getxo)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48810,
    "pob": "Alonsotegi",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48350,
    "pob": "Altamira-san Kristobal",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48277,
    "pob": "Altzaa",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48950,
    "pob": "Altzaga",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48144,
    "pob": "Altzuaga",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48890,
    "pob": "Ambasaguas",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48120,
    "pob": "Ametzaga",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48499,
    "pob": "Ametzola",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48289,
    "pob": "Amoroto",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48210,
    "pob": "Andaparaluzeta",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48240,
    "pob": "Andikoa",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48115,
    "pob": "Andra Mari (morga)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48620,
    "pob": "Andraka",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48291,
    "pob": "Apatamonasterio",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48498,
    "pob": "Arakaldo",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48300,
    "pob": "Arana",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48269,
    "pob": "ArandoÑo",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48850,
    "pob": "Aranguren",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48170,
    "pob": "Aranoltza (san Antolin)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48140,
    "pob": "Arantzazu",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48381,
    "pob": "Arbatzegi Gerrikaitz",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48520,
    "pob": "Arboleda, La",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48879,
    "pob": "Arcentales",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48143,
    "pob": "Areatza",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48508,
    "pob": "Arena, La",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48196,
    "pob": "Aretxalde",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48970,
    "pob": "Arizgoiti",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48360,
    "pob": "Arketa-aranburu",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48393,
    "pob": "Armotxerria",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48141,
    "pob": "Aroztegieta",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48383,
    "pob": "Arratzu",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48278,
    "pob": "Arta",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48142,
    "pob": "Artea",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48130,
    "pob": "Artzalde",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48390,
    "pob": "Asteitza",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48710,
    "pob": "Asterrika",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48100,
    "pob": "Atela",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48309,
    "pob": "Atxika-errekalde",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48380,
    "pob": "Aulesti",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48869,
    "pob": "Avellaneda",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48800,
    "pob": "Balmaseda",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48980,
    "pob": "Balparda",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48190,
    "pob": "Baluga, La",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48288,
    "pob": "Barainka",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48650,
    "pob": "Barrika",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48640,
    "pob": "Baserri-santa Ana",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48314,
    "pob": "Basetxeta",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48315,
    "pob": "Basondo",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48150,
    "pob": "Basozabal (sondika)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48287,
    "pob": "Bedaroa",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48460,
    "pob": "Belandia",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48230,
    "pob": "Berrio-aldape",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48003,
    "pob": "Brisketa",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48110,
    "pob": "Butroe",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48530,
    "pob": "Cadegal",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48895,
    "pob": "Calera Del Prado, La",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48610,
    "pob": "Campa, La (urduliz)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48550,
    "pob": "Cobaron",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48880,
    "pob": "Cueto",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48450,
    "pob": "Doneztebe",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48200,
    "pob": "Durango",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48510,
    "pob": "DuraÑona",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48249,
    "pob": "Eitua",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48260,
    "pob": "Eitzaga",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48310,
    "pob": "Elantxobe",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48113,
    "pob": "Elexalde (gamiz-fika)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48312,
    "pob": "Elexalde (nabarniz)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48112,
    "pob": "Erbera (maruri-jatabe)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48250,
    "pob": "Gazaga",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48195,
    "pob": "Gaztelu",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48192,
    "pob": "Gordexola",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48840,
    "pob": "GÜeÑes",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48213,
    "pob": "Izurtza",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48600,
    "pob": "Larrabasterra",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48276,
    "pob": "Larruskain-amalloa",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48280,
    "pob": "Lekeitio",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48145,
    "pob": "Magdalena (ubide)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48270,
    "pob": "Markina-xemein",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48490,
    "pob": "Markio",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48212,
    "pob": "MaÑaria",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48860,
    "pob": "Mimetiz",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48394,
    "pob": "Murueta",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48392,
    "pob": "Muxika",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48700,
    "pob": "Ondarroa",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48410,
    "pob": "Orozko",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48920,
    "pob": "Portugalete",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48820,
    "pob": "Quadra, La",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48903,
    "pob": "Regato, El",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48902,
    "pob": "San Bizenti-barakaldo",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48910,
    "pob": "Sestao",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48830,
    "pob": "Sodupe",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48211,
    "pob": "Urkiola",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48194,
    "pob": "Zaldu",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 49591,
    "pob": "Abejera",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49254,
    "pob": "Abelon",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49834,
    "pob": "Abezames",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49624,
    "pob": "Abraveses De Tera",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49574,
    "pob": "Aciberos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49500,
    "pob": "AlcaÑices",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49517,
    "pob": "Alcorcillo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49696,
    "pob": "Alcubilla De Nogales",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49177,
    "pob": "Alfaraz De Sayago",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49539,
    "pob": "Algodre",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49180,
    "pob": "Almaraz De Duero",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49210,
    "pob": "Almeida De Sayago",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49183,
    "pob": "Almendra",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49722,
    "pob": "Amor, De, Dehesa",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49162,
    "pob": "Andavias",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49349,
    "pob": "Anta De Rioconejos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49151,
    "pob": "Arcenillas",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49514,
    "pob": "Arcillera",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49272,
    "pob": "Arcillo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49699,
    "pob": "Arcos De La Polvorosa",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49251,
    "pob": "ArgaÑin",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49716,
    "pob": "Argujillo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49126,
    "pob": "Arquillinos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49124,
    "pob": "Aspariegos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49325,
    "pob": "Asturianos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49396,
    "pob": "Avedillo De Sanabria",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49619,
    "pob": "Ayoo De Vidriales",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49214,
    "pob": "Badilla",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49157,
    "pob": "Bamba",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49392,
    "pob": "BaÑos De Calabor",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49760,
    "pob": "Barcial Del Barco",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49582,
    "pob": "Barjacoba",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49358,
    "pob": "Barrio De Rabano",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49830,
    "pob": "Belver De Los Montes",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49600,
    "pob": "Benavente",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49123,
    "pob": "Benegiles",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49592,
    "pob": "Bercianos De Aliste",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49333,
    "pob": "Bercianos De Valverde",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49618,
    "pob": "Bercianos De Vidriales",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49168,
    "pob": "Bermillo De Alba",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49200,
    "pob": "Bermillo De Sayago",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49155,
    "pob": "Boveda De Toro, La",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49561,
    "pob": "Boya",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49511,
    "pob": "Brandilanes",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49751,
    "pob": "Breto",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49698,
    "pob": "Bretocino",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49629,
    "pob": "Brime De Sog",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49622,
    "pob": "Brime De Urz",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49831,
    "pob": "Bustillo Del Oro",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49709,
    "pob": "CabaÑas De Sayago",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49627,
    "pob": "CabaÑas De Tera",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49329,
    "pob": "Calzada De Tera",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49331,
    "pob": "Calzadilla De Tera",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49332,
    "pob": "Camarzana",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49440,
    "pob": "CaÑizal",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49128,
    "pob": "CaÑizo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49160,
    "pob": "Carbajales De Alba",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49347,
    "pob": "Carbajales De La Encomienda",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49324,
    "pob": "Carbajalinos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49166,
    "pob": "Carbajosa",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49211,
    "pob": "Carbellino",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49027,
    "pob": "Carrascal",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49708,
    "pob": "Casaseca De Campean",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49390,
    "pob": "Castellanos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49543,
    "pob": "Castillo De Alba",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49571,
    "pob": "Castrelos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49419,
    "pob": "Castrillo De La GuareÑa",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49660,
    "pob": "Castrogonzalo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49572,
    "pob": "Castromil",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49127,
    "pob": "Castronuevo De Los Arcos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49110,
    "pob": "Castroverde De Campos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49191,
    "pob": "Cazurra",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49512,
    "pob": "Ceadea",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49359,
    "pob": "Cerdillo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49640,
    "pob": "Cerecinos De Campos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49125,
    "pob": "Cerecinos Del Carrizal",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49164,
    "pob": "Cerezal De Aliste",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49271,
    "pob": "Cernecina",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49321,
    "pob": "Cervantes",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49573,
    "pob": "Chanos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49230,
    "pob": "Cibanal",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49563,
    "pob": "Cional",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49594,
    "pob": "Codesal",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49623,
    "pob": "Colinas De Transmonte",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49783,
    "pob": "Coomonte",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49530,
    "pob": "Coreses",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49700,
    "pob": "Corrales Del Vino",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49637,
    "pob": "Cotanes Del Monte",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49360,
    "pob": "Cubelo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49730,
    "pob": "Cubillos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49327,
    "pob": "Cubo De Benavente",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49710,
    "pob": "Cubo De Tierra Del Vino, El",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49717,
    "pob": "Cuelgamures",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49153,
    "pob": "Dehesa De PeÑalva",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49542,
    "pob": "Domez",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49319,
    "pob": "Donadillo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49344,
    "pob": "Donado",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49323,
    "pob": "Doney De La Requejada",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49172,
    "pob": "Enillas, Las",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49721,
    "pob": "Entrala",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49540,
    "pob": "Escober",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49342,
    "pob": "EspadaÑedo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49260,
    "pob": "Fadon",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49141,
    "pob": "Faramontanos Tabara",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49213,
    "pob": "Fariza",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49220,
    "pob": "Fermoselle",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49335,
    "pob": "Ferreras De Abajo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49550,
    "pob": "Ferreruela",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49520,
    "pob": "Figueruela De Abajo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49559,
    "pob": "Flores",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49510,
    "pob": "Fonfria",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49743,
    "pob": "Fontanillas De Castro",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49513,
    "pob": "Fornillos De Aliste",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49232,
    "pob": "Fornillos De Fermoselle",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49519,
    "pob": "Fradellos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49255,
    "pob": "Fresnadillo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49318,
    "pob": "Fresno De La Carballeda",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49693,
    "pob": "Fresno De La Polvorosa",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49590,
    "pob": "Fresno De La Rivera",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49216,
    "pob": "Fresno De Sayago",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49706,
    "pob": "Fuente El Carnero",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49410,
    "pob": "FuentelapeÑa",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49670,
    "pob": "Fuentes De Ropel",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49400,
    "pob": "Fuentesauco",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49833,
    "pob": "Fuentesecas",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49714,
    "pob": "Fuentespreadas",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49521,
    "pob": "Gallegos Del Campo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49740,
    "pob": "Granja De Moreruela",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49621,
    "pob": "Granucillo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49156,
    "pob": "Guarrate",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49192,
    "pob": "Hiniesta, La",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49395,
    "pob": "Illanes",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49330,
    "pob": "Junquera De Tera",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49340,
    "pob": "Justel",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49317,
    "pob": "Lanseros",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49516,
    "pob": "Latedo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49346,
    "pob": "Letrillas",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49593,
    "pob": "Linarejos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49334,
    "pob": "Litos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49541,
    "pob": "Losacino",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49161,
    "pob": "Losilla",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49570,
    "pob": "Lubian",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49215,
    "pob": "Luelmo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49719,
    "pob": "Maderal, El",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49522,
    "pob": "Mahide",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49832,
    "pob": "Malva",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49130,
    "pob": "Manganeses De La Lampreana",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49694,
    "pob": "Manganeses De La Polvorosa",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49163,
    "pob": "Manzanal Del Barco",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49147,
    "pob": "Marquiz De Alba",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49692,
    "pob": "Matilla De Arzon",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49718,
    "pob": "Mayalde",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49626,
    "pob": "Melgar De Tera",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49174,
    "pob": "Mogatar",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49120,
    "pob": "Molacillos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49310,
    "pob": "Mombuey",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49352,
    "pob": "Moncabril",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49121,
    "pob": "Monfarracinos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49149,
    "pob": "Montamarta",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49881,
    "pob": "Monte La Reina",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49150,
    "pob": "Moraleja Del Vino",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49810,
    "pob": "Morales De Toro",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49697,
    "pob": "Morales De Valverde",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49190,
    "pob": "Morales Del Vino",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49253,
    "pob": "Moralina",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49731,
    "pob": "Moreruela De Los Infanzones",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49148,
    "pob": "Moreruela De Tabara",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49341,
    "pob": "Muelas De Los Caballeros",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49167,
    "pob": "Muelas Del Pan",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49212,
    "pob": "Muga De Sayago",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49146,
    "pob": "Navianos De Alba",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49450,
    "pob": "Olmo De La GuareÑa, El",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49336,
    "pob": "Otero De Bodas",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49320,
    "pob": "Otero De Sanabria",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49137,
    "pob": "Otero De Sariegos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49142,
    "pob": "Pajares De La Lampreana",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49322,
    "pob": "Palacios De Sanabria",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49781,
    "pob": "Paladinos Del Valle",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49240,
    "pob": "Pasariegos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49361,
    "pob": "Pedrazales",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49154,
    "pob": "Pego, El",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49880,
    "pob": "Peleagonzalo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49178,
    "pob": "PeÑausende",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49720,
    "pob": "Perdigon, El",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49280,
    "pob": "Pereruela",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49145,
    "pob": "Perilla De Castro",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49580,
    "pob": "Pias",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49143,
    "pob": "Piedrahita De Castro",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49231,
    "pob": "Pinilla De Fermoselle",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49850,
    "pob": "Pinilla De Toro",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49715,
    "pob": "PiÑero, El",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49780,
    "pob": "Pobladura Del Valle",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49583,
    "pob": "Porto",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49524,
    "pob": "Poyo, El",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49835,
    "pob": "Pozoantiguo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49638,
    "pob": "Prado",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49300,
    "pob": "Puebla De Sanabria",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49171,
    "pob": "Pueblica De Campean, La",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49350,
    "pob": "Puente De Sanabria, El",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49639,
    "pob": "Quintanilla Del Monte",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49515,
    "pob": "Rabano De Aliste",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49394,
    "pob": "Requejo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49135,
    "pob": "Revellinos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49362,
    "pob": "Ribadelago De Franco",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49165,
    "pob": "Ricobayo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49742,
    "pob": "Riego Del Camino",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49391,
    "pob": "Rihonor De Castilla",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49348,
    "pob": "Rioconejos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49326,
    "pob": "Rionegro Del Puente",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49393,
    "pob": "Robledo De Sanabria",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49357,
    "pob": "Rozas",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49144,
    "pob": "San Cebrian De Castro",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49523,
    "pob": "San Cristobal De Aliste",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49690,
    "pob": "San Cristobal De EntreviÑas",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49650,
    "pob": "San Esteban Del Molar",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49525,
    "pob": "San Juan Del Rebollar",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49518,
    "pob": "San Mamed",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49129,
    "pob": "San Martin De Valderaduey",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49691,
    "pob": "San Miguel Del Esla",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49680,
    "pob": "San Miguel Del Valle",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49628,
    "pob": "San Pedro De Ceque",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49560,
    "pob": "San Pedro De Las Herrerias",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49281,
    "pob": "San Roman De Los Infantes",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49782,
    "pob": "San Roman Del Valle",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49707,
    "pob": "Santa Clara De Avedillo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49620,
    "pob": "Santa Cristina De La Polvorosa",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49625,
    "pob": "SantibaÑez De Tera",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49610,
    "pob": "SantibaÑez De Vidriales",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49750,
    "pob": "Santovenia",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49152,
    "pob": "Sanzoles",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49270,
    "pob": "Sogo De Sayago",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49140,
    "pob": "Tabara",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49741,
    "pob": "Tabla, La",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49836,
    "pob": "Tagarabuena",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49176,
    "pob": "Tamame",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49170,
    "pob": "Tardobispo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49800,
    "pob": "Toro",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49252,
    "pob": "Torregamones",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49122,
    "pob": "Torres Del Carrizal",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49173,
    "pob": "Tuda, La",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49345,
    "pob": "Utrera De La Encomienda",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49420,
    "pob": "Vadillo De La GuareÑa",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49337,
    "pob": "Val De Santa Maria",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49882,
    "pob": "Valdefinjas",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49182,
    "pob": "Valdeperdices",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49133,
    "pob": "Vega De Villalobos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49840,
    "pob": "Vezdemarban",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49770,
    "pob": "Villabrazaro",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49820,
    "pob": "Villabuena Del Puente",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49250,
    "pob": "Villadepera",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49430,
    "pob": "Villaescusa",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49136,
    "pob": "Villafafila",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49695,
    "pob": "VillaferrueÑa",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49158,
    "pob": "Villalazan",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49134,
    "pob": "Villalobos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49860,
    "pob": "Villalonso",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49630,
    "pob": "Villalpando",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49131,
    "pob": "Villamayor De Campos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49100,
    "pob": "Villanueva Del Campo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49132,
    "pob": "Villar De Fallaves",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49159,
    "pob": "Villaralbo",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49562,
    "pob": "Villardeciervos",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49871,
    "pob": "Villardondiego",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49181,
    "pob": "Villaseco Del Pan",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49870,
    "pob": "Villavendimio",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49343,
    "pob": "Villaverde De Justel",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49000,
    "pob": "Zamora (ver Callejero)",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 50375,
    "pob": "Abanto",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50090,
    "pob": "Academia General Militar",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50347,
    "pob": "Acered",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50190,
    "pob": "Aeropuerto De Garrapinillos (zaragoza)",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50560,
    "pob": "Agon",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50408,
    "pob": "Aguaron",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50175,
    "pob": "Aguilar De Ebro",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50155,
    "pob": "Aguilon",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50152,
    "pob": "Ailes",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50570,
    "pob": "Ainzon",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50481,
    "pob": "Aladren",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50630,
    "pob": "Alagon",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50345,
    "pob": "Alarba",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50529,
    "pob": "Alberite De San Juan",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50549,
    "pob": "Albeta",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50781,
    "pob": "Alborge",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50691,
    "pob": "Alcala De Ebro",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50591,
    "pob": "Alcala De Moncayo",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50324,
    "pob": "Aldehuela De Grio",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50374,
    "pob": "Aldehuela De Liestos",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50679,
    "pob": "Alera",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50172,
    "pob": "Alfajarin",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50461,
    "pob": "Alfamen",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50120,
    "pob": "Alfocea",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50783,
    "pob": "Alforque",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50195,
    "pob": "Alfranca, La",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50230,
    "pob": "Alhama De Aragon",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50178,
    "pob": "Almolda, La",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50133,
    "pob": "Almonacid De La Cuba",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50108,
    "pob": "Almonacid De La Sierra",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50100,
    "pob": "Almunia De DoÑa Godina, La",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50109,
    "pob": "Alpartir",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50322,
    "pob": "Aluenda",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50546,
    "pob": "Ambel",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50369,
    "pob": "Anento",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50313,
    "pob": "AniÑon",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50590,
    "pob": "AÑon De Moncayo",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50259,
    "pob": "Aranda De Moncayo",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50266,
    "pob": "Arandiga",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50614,
    "pob": "Ardisa",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50220,
    "pob": "Ariza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50683,
    "pob": "Artieda",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50619,
    "pob": "Asin",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50348,
    "pob": "Atea",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50200,
    "pob": "Ateca",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50192,
    "pob": "Aula-dei (cartuja De)",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50140,
    "pob": "Azuara",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50491,
    "pob": "Badules",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50685,
    "pob": "Bagues",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50366,
    "pob": "Balconchan",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50297,
    "pob": "Barboles",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50296,
    "pob": "Bardallur",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50694,
    "pob": "Bardena Del Caudillo",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50191,
    "pob": "Barrio De Juslibol",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50059,
    "pob": "Barrio MontaÑana",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50130,
    "pob": "Belchite",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50332,
    "pob": "Belmonte De Gracian",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50294,
    "pob": "Berbedel",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50316,
    "pob": "Berdejo",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50373,
    "pob": "Berrueco",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50695,
    "pob": "Biota",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50561,
    "pob": "Bisimbre",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50641,
    "pob": "BoquiÑeni",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50229,
    "pob": "Bordalba",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50540,
    "pob": "Borja",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50441,
    "pob": "Botorrita",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50246,
    "pob": "Brea De Aragon",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50239,
    "pob": "Bubierca",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50177,
    "pob": "Bujaraloz",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50547,
    "pob": "Bureta",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50730,
    "pob": "Burgo De Ebro, El",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50548,
    "pob": "Buste, El",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50638,
    "pob": "CabaÑas De Ebro",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50228,
    "pob": "Cabolafuente",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50420,
    "pob": "Cadrete",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50300,
    "pob": "Calatayud",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50280,
    "pob": "Calatorao",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50268,
    "pob": "Calcena",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50238,
    "pob": "Calmarza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50692,
    "pob": "Camino Real",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50110,
    "pob": "Campamento San Gregorio",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50299,
    "pob": "Campiel",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50214,
    "pob": "Campillo De Aragon",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50689,
    "pob": "Campo Real",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50212,
    "pob": "Carenas",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50400,
    "pob": "CariÑena",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50720,
    "pob": "Cartuja Baja",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50620,
    "pob": "Casetas",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50700,
    "pob": "Caspe",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50346,
    "pob": "Castejon De Alarba",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50211,
    "pob": "Castejon De Las Armas",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50612,
    "pob": "Castejon De Valdejasa",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50696,
    "pob": "Castiliscar",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50312,
    "pob": "Cervera De La CaÑada",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50368,
    "pob": "Cerveruela",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50292,
    "pob": "Cetina",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50792,
    "pob": "Chiprana",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50269,
    "pob": "Chodes",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50213,
    "pob": "Cimballa",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50782,
    "pob": "Cinco Olivas",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50314,
    "pob": "Clares De Ribota",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50132,
    "pob": "Codo",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50326,
    "pob": "Codos",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50409,
    "pob": "Cosuenda",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50410,
    "pob": "Cuarte De Huerva",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50376,
    "pob": "Cubel",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50513,
    "pob": "Cunchillos",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50360,
    "pob": "Daroca",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50600,
    "pob": "Ejea De Los Caballeros",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50470,
    "pob": "Encinacorba",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50290,
    "pob": "Epila",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50611,
    "pob": "Erla",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50790,
    "pob": "Escatron",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50682,
    "pob": "Esco",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50793,
    "pob": "Fabara",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50163,
    "pob": "Farlete",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50795,
    "pob": "Fayon",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50639,
    "pob": "Figueruelas",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50610,
    "pob": "Frago, El",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50320,
    "pob": "Frasno, El",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50562,
    "pob": "Frescano",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50142,
    "pob": "Fuendetodos",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50740,
    "pob": "Fuentes De Ebro",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50390,
    "pob": "Fuentes De Jiloca",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50650,
    "pob": "Gallur",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50786,
    "pob": "Gelsa",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50257,
    "pob": "Gotor",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50150,
    "pob": "Herrera De Los Navarros",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50336,
    "pob": "Huermeda",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50236,
    "pob": "Ibdes",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50250,
    "pob": "Illueca",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50323,
    "pob": "Inoges",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50687,
    "pob": "Isuerre",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50237,
    "pob": "Jaraba",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50258,
    "pob": "Jarque",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50141,
    "pob": "Jaulin",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50615,
    "pob": "Lacorvilla",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50134,
    "pob": "Lagata",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50367,
    "pob": "Langa Del Castillo",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50131,
    "pob": "Lecera",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50160,
    "pob": "LeciÑena",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50136,
    "pob": "Letux",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50582,
    "pob": "Litago",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50581,
    "pob": "Lituenigo",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50830,
    "pob": "Lomas Del Gallego",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50460,
    "pob": "Longares",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50688,
    "pob": "Longas",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50640,
    "pob": "Luceni",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50151,
    "pob": "Luesma",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50295,
    "pob": "Lumpiaque",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50710,
    "pob": "Maella",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50520,
    "pob": "Magallon",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50315,
    "pob": "Malanquilla",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50550,
    "pob": "Mallen",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50511,
    "pob": "Malon",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50340,
    "pob": "Maluenda",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50331,
    "pob": "Mara",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50430,
    "pob": "Maria De Huerva",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50616,
    "pob": "Marracos",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50135,
    "pob": "Mediana De Aragon",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50170,
    "pob": "Mequinenza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50267,
    "pob": "Mesones De Isuela",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50330,
    "pob": "Miedes De Aragon",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50709,
    "pob": "Miraflores, Huerta De",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50210,
    "pob": "Monasterio De Piedra",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50164,
    "pob": "Monegrillo",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50144,
    "pob": "Moneva",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50291,
    "pob": "Monreal De Ariza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50391,
    "pob": "Monton",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 22806,
    "pob": "Moran",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 50260,
    "pob": "Morata De Jalon",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50344,
    "pob": "Morata De Jiloca",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50240,
    "pob": "Mores",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50215,
    "pob": "Moros",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50194,
    "pob": "Movera",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50143,
    "pob": "Moyuela",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50440,
    "pob": "Mozota",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50450,
    "pob": "Muel",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50196,
    "pob": "Muela, La",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50219,
    "pob": "Munebrega",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50794,
    "pob": "Nonaspe",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50510,
    "pob": "Novallas",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50530,
    "pob": "Novillas",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50173,
    "pob": "Nuez De Ebro",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50341,
    "pob": "Olves",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50810,
    "pob": "Ontinar De Salz",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50480,
    "pob": "Paniza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50342,
    "pob": "Paracuellos De Jiloca",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50690,
    "pob": "Pedrola",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50193,
    "pob": "PeÑaflor De Gallego",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50161,
    "pob": "Perdiguera",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50750,
    "pob": "Pina De Ebro",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50298,
    "pob": "Pinseque",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50197,
    "pob": "Plataforma Logistica Plaza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50198,
    "pob": "Poligono Industrial Centrovia (la Muela)",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50668,
    "pob": "Pradilla De Ebro",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50137,
    "pob": "Puebla De Alborton",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50171,
    "pob": "Puebla De Alfinden, La",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50247,
    "pob": "Purroy De Jalon",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50770,
    "pob": "Quinto",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50637,
    "pob": "Remolinos",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50270,
    "pob": "Ricla",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50741,
    "pob": "Roden",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50617,
    "pob": "Sabinar, El",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50670,
    "pob": "Sadaba",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50684,
    "pob": "Salvatierra De Esca",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50820,
    "pob": "San Juan Mozarrifar",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50584,
    "pob": "San Martin De La Virgen De Moncayo",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50840,
    "pob": "San Mateo De Gallego",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50669,
    "pob": "Sancho Abarca",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50411,
    "pob": "Santa Fe",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50780,
    "pob": "Sastago",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50334,
    "pob": "Sediles",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50248,
    "pob": "Sestrica",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50227,
    "pob": "Sisamon",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50629,
    "pob": "Sobradiel",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50680,
    "pob": "Sos Del Rey Catolico",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50500,
    "pob": "Tarazona",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50660,
    "pob": "Tauste",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50293,
    "pob": "Terrer",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50325,
    "pob": "Tobed",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50311,
    "pob": "Torralba De Ribota",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50139,
    "pob": "Torrecilla De Valmadrid",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50512,
    "pob": "Torrellas",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50693,
    "pob": "Torres De Berrellen",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50217,
    "pob": "Torrijo De La CaÑada",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50514,
    "pob": "Tortoles",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50154,
    "pob": "Tosos",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50583,
    "pob": "Trasmoz",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50678,
    "pob": "Uncastillo",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50020,
    "pob": "Urbanizacion \"el Zorongo\", Nucleo",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50180,
    "pob": "Utebo",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50372,
    "pob": "Val De San Martin",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50371,
    "pob": "Valdehorna",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50138,
    "pob": "Valmadrid",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50760,
    "pob": "Velilla De Ebro",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50343,
    "pob": "Velilla De Jiloca",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50580,
    "pob": "Vera De Moncayo",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50490,
    "pob": "Villadoz",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50174,
    "pob": "Villafranca De Ebro",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50333,
    "pob": "Villalba De Perejiles",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50216,
    "pob": "Villalengua",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50162,
    "pob": "Villamayor De Gallego",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50370,
    "pob": "Villanueva De Jiloca",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50153,
    "pob": "Villanueva Del Huerva",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50156,
    "pob": "Villar De Los Navarros",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50310,
    "pob": "Villarroya De La Sierra",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50482,
    "pob": "Vistabella",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50249,
    "pob": "Viver De La Sierra",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50784,
    "pob": "Zaida, La",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50000,
    "pob": "Zaragoza (ver Callejero)",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50800,
    "pob": "Zuera",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 1071,
    "pob": "Vitoria-gasteiz",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1080,
    "pob": "Vitoria-gasteiz",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1008,
    "pob": "Vitoria-gasteiz",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1015,
    "pob": "Vitoria-gasteiz",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1001,
    "pob": "Vitoria-gasteiz",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1006,
    "pob": "Vitoria-gasteiz",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1002,
    "pob": "Vitoria-gasteiz",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1012,
    "pob": "Vitoria-gasteiz",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1005,
    "pob": "Vitoria-gasteiz",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1003,
    "pob": "Vitoria-gasteiz",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1004,
    "pob": "Vitoria-gasteiz",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 1009,
    "pob": "Vitoria-gasteiz",
    "code_prov": 1,
    "prov": "Araba/Álava"
  },
  {
    "cp": 2070,
    "pob": "Albacete",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2071,
    "pob": "Albacete",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2080,
    "pob": "Albacete",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2006,
    "pob": "Albacete",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2004,
    "pob": "Albacete",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2003,
    "pob": "Albacete",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2002,
    "pob": "Albacete",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2005,
    "pob": "Albacete",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2001,
    "pob": "Albacete",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2008,
    "pob": "Albacete",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 2007,
    "pob": "Albacete",
    "code_prov": 2,
    "prov": "Albacete"
  },
  {
    "cp": 3070,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3071,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3080,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3004,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3010,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3014,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3002,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3012,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3003,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3006,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3011,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3008,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3005,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3016,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3013,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3015,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3009,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3007,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3001,
    "pob": "Alacant-alicante",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3800,
    "pob": "Alcoi-alcoy",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3802,
    "pob": "Alcoi-alcoy",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3804,
    "pob": "Alcoi-alcoy",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3801,
    "pob": "Alcoi-alcoy",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3803,
    "pob": "Alcoi-alcoy",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3200,
    "pob": "Elx-elche",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3205,
    "pob": "Elx-elche",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3208,
    "pob": "Elx-elche",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3206,
    "pob": "Elx-elche",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3202,
    "pob": "Elx-elche",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3207,
    "pob": "Elx-elche",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3201,
    "pob": "Elx-elche",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3204,
    "pob": "Elx-elche",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3293,
    "pob": "Elx-elche",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3290,
    "pob": "Elx-elche",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3508,
    "pob": "Benidorm",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3500,
    "pob": "Benidorm",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3501,
    "pob": "Benidorm",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3180,
    "pob": "Torrevieja",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3183,
    "pob": "Torrevieja",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3184,
    "pob": "Torrevieja",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3188,
    "pob": "Torrevieja",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3185,
    "pob": "Torrevieja",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3181,
    "pob": "Torrevieja",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3186,
    "pob": "Torrevieja",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 3182,
    "pob": "Torrevieja",
    "code_prov": 3,
    "prov": "Alicante/Alacant"
  },
  {
    "cp": 4070,
    "pob": "Almería",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4071,
    "pob": "Almería",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4080,
    "pob": "Almería",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4008,
    "pob": "Almería",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4002,
    "pob": "Almería",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4004,
    "pob": "Almería",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4007,
    "pob": "Almería",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4009,
    "pob": "Almería",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4005,
    "pob": "Almería",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4006,
    "pob": "Almería",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4003,
    "pob": "Almería",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 4001,
    "pob": "Almería",
    "code_prov": 4,
    "prov": "Almería"
  },
  {
    "cp": 5070,
    "pob": "Ávila",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5071,
    "pob": "Ávila",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5080,
    "pob": "Ávila",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5002,
    "pob": "Ávila",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5001,
    "pob": "Ávila",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5003,
    "pob": "Ávila",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5005,
    "pob": "Ávila",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 5004,
    "pob": "Ávila",
    "code_prov": 5,
    "prov": "Ávila"
  },
  {
    "cp": 6070,
    "pob": "Badajoz",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6071,
    "pob": "Badajoz",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6080,
    "pob": "Badajoz",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6010,
    "pob": "Badajoz",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6001,
    "pob": "Badajoz",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6011,
    "pob": "Badajoz",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6007,
    "pob": "Badajoz",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6005,
    "pob": "Badajoz",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6002,
    "pob": "Badajoz",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6004,
    "pob": "Badajoz",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6008,
    "pob": "Badajoz",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6009,
    "pob": "Badajoz",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6003,
    "pob": "Badajoz",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 6006,
    "pob": "Badajoz",
    "code_prov": 6,
    "prov": "Badajoz"
  },
  {
    "cp": 7070,
    "pob": "Palma De Mallorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7071,
    "pob": "Palma De Mallorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7080,
    "pob": "Palma De Mallorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7004,
    "pob": "Palma De Mallorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7010,
    "pob": "Palma De Mallorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7006,
    "pob": "Palma De Mallorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7011,
    "pob": "Palma De Mallorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7015,
    "pob": "Palma De Mallorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7013,
    "pob": "Palma De Mallorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7014,
    "pob": "Palma De Mallorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7008,
    "pob": "Palma De Mallorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7007,
    "pob": "Palma De Mallorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7005,
    "pob": "Palma De Mallorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7003,
    "pob": "Palma De Mallorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7611,
    "pob": "Palma De Mallorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7012,
    "pob": "Palma De Mallorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7002,
    "pob": "Palma De Mallorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7001,
    "pob": "Palma De Mallorca",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7701,
    "pob": "Maó",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7703,
    "pob": "Maó",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7714,
    "pob": "Maó",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 7702,
    "pob": "Maó",
    "code_prov": 7,
    "prov": "Illes Balears"
  },
  {
    "cp": 8070,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8071,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8080,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8020,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8040,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8034,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8003,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8030,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8001,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8017,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8024,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8012,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8031,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8027,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8038,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8028,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8039,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8021,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8035,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8002,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8023,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8041,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8019,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8032,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8033,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8042,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8005,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8018,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8022,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8004,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8029,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8025,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8014,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8016,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8015,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8006,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8010,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8013,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8037,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8026,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8011,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8007,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8009,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8036,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8008,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8075,
    "pob": "Barcelona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8910,
    "pob": "Badalona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8917,
    "pob": "Badalona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8915,
    "pob": "Badalona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8913,
    "pob": "Badalona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8912,
    "pob": "Badalona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8914,
    "pob": "Badalona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8918,
    "pob": "Badalona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8911,
    "pob": "Badalona",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8900,
    "pob": "Hospitalet De Llobregat,l'",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8905,
    "pob": "Hospitalet De Llobregat,l'",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8908,
    "pob": "Hospitalet De Llobregat,l'",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8906,
    "pob": "Hospitalet De Llobregat,l'",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8901,
    "pob": "Hospitalet De Llobregat,l'",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8904,
    "pob": "Hospitalet De Llobregat,l'",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8902,
    "pob": "Hospitalet De Llobregat,l'",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8907,
    "pob": "Hospitalet De Llobregat,l'",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8903,
    "pob": "Hospitalet De Llobregat,l'",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8300,
    "pob": "Mataró",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8304,
    "pob": "Mataró",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8302,
    "pob": "Mataró",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8303,
    "pob": "Mataró",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8301,
    "pob": "Mataró",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8200,
    "pob": "Sabadell",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8207,
    "pob": "Sabadell",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8206,
    "pob": "Sabadell",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8203,
    "pob": "Sabadell",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8201,
    "pob": "Sabadell",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8208,
    "pob": "Sabadell",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8204,
    "pob": "Sabadell",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8202,
    "pob": "Sabadell",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8205,
    "pob": "Sabadell",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8805,
    "pob": "Sabadell",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8920,
    "pob": "Santa Coloma De Gramanet",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8924,
    "pob": "Santa Coloma De Gramanet",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8921,
    "pob": "Santa Coloma De Gramanet",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8923,
    "pob": "Santa Coloma De Gramanet",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8922,
    "pob": "Santa Coloma De Gramanet",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8220,
    "pob": "Terrassa",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8225,
    "pob": "Terrassa",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8224,
    "pob": "Terrassa",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8223,
    "pob": "Terrassa",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8221,
    "pob": "Terrassa",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8226,
    "pob": "Terrassa",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8222,
    "pob": "Terrassa",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8248,
    "pob": "Manresa",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8240,
    "pob": "Manresa",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8242,
    "pob": "Manresa",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8243,
    "pob": "Manresa",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8171,
    "pob": "Sant Cugat Del Vallés",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8190,
    "pob": "Sant Cugat Del Vallés",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8172,
    "pob": "Sant Cugat Del Vallés",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8173,
    "pob": "Sant Cugat Del Vallés",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8198,
    "pob": "Sant Cugat Del Vallés",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8174,
    "pob": "Sant Cugat Del Vallés",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8400,
    "pob": "Granollers",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8402,
    "pob": "Granollers",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8401,
    "pob": "Granollers",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8403,
    "pob": "Granollers",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 8404,
    "pob": "Granollers",
    "code_prov": 8,
    "prov": "Barcelona"
  },
  {
    "cp": 9070,
    "pob": "Burgos",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9071,
    "pob": "Burgos",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9080,
    "pob": "Burgos",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9005,
    "pob": "Burgos",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9002,
    "pob": "Burgos",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9001,
    "pob": "Burgos",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9006,
    "pob": "Burgos",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9007,
    "pob": "Burgos",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9003,
    "pob": "Burgos",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 9004,
    "pob": "Burgos",
    "code_prov": 9,
    "prov": "Burgos"
  },
  {
    "cp": 10070,
    "pob": "Cáceres",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10071,
    "pob": "Cáceres",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10080,
    "pob": "Cáceres",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10004,
    "pob": "Cáceres",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10001,
    "pob": "Cáceres",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10003,
    "pob": "Cáceres",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10005,
    "pob": "Cáceres",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 10002,
    "pob": "Cáceres",
    "code_prov": 10,
    "prov": "Cáceres"
  },
  {
    "cp": 11070,
    "pob": "Cádiz",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11071,
    "pob": "Cádiz",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11080,
    "pob": "Cádiz",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11002,
    "pob": "Cádiz",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11007,
    "pob": "Cádiz",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11006,
    "pob": "Cádiz",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11011,
    "pob": "Cádiz",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11003,
    "pob": "Cádiz",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11010,
    "pob": "Cádiz",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11009,
    "pob": "Cádiz",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11004,
    "pob": "Cádiz",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11012,
    "pob": "Cádiz",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11001,
    "pob": "Cádiz",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11008,
    "pob": "Cádiz",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11005,
    "pob": "Cádiz",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11270,
    "pob": "Algeciras",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11271,
    "pob": "Algeciras",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11280,
    "pob": "Algeciras",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11205,
    "pob": "Algeciras",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11206,
    "pob": "Algeciras",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11201,
    "pob": "Algeciras",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11202,
    "pob": "Algeciras",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11204,
    "pob": "Algeciras",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11203,
    "pob": "Algeciras",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11207,
    "pob": "Algeciras",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11470,
    "pob": "Jerez De La Frontera",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11471,
    "pob": "Jerez De La Frontera",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11480,
    "pob": "Jerez De La Frontera",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11401,
    "pob": "Jerez De La Frontera",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11403,
    "pob": "Jerez De La Frontera",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11406,
    "pob": "Jerez De La Frontera",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11407,
    "pob": "Jerez De La Frontera",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11404,
    "pob": "Jerez De La Frontera",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 11402,
    "pob": "Jerez De La Frontera",
    "code_prov": 11,
    "prov": "Cádiz"
  },
  {
    "cp": 12070,
    "pob": "Castelló-castellón De La Plana",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12071,
    "pob": "Castelló-castellón De La Plana",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12080,
    "pob": "Castelló-castellón De La Plana",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12006,
    "pob": "Castelló-castellón De La Plana",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12005,
    "pob": "Castelló-castellón De La Plana",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12004,
    "pob": "Castelló-castellón De La Plana",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12001,
    "pob": "Castelló-castellón De La Plana",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12003,
    "pob": "Castelló-castellón De La Plana",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 12002,
    "pob": "Castelló-castellón De La Plana",
    "code_prov": 12,
    "prov": "Castellón/Castelló"
  },
  {
    "cp": 13070,
    "pob": "Ciudad Real",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13071,
    "pob": "Ciudad Real",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13080,
    "pob": "Ciudad Real",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13002,
    "pob": "Ciudad Real",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13005,
    "pob": "Ciudad Real",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13004,
    "pob": "Ciudad Real",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13003,
    "pob": "Ciudad Real",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 13001,
    "pob": "Ciudad Real",
    "code_prov": 13,
    "prov": "Ciudad Real"
  },
  {
    "cp": 14070,
    "pob": "Córdoba",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14071,
    "pob": "Córdoba",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14080,
    "pob": "Córdoba",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14003,
    "pob": "Córdoba",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14006,
    "pob": "Córdoba",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14002,
    "pob": "Córdoba",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14004,
    "pob": "Córdoba",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14001,
    "pob": "Córdoba",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14009,
    "pob": "Córdoba",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14013,
    "pob": "Córdoba",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14007,
    "pob": "Córdoba",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 14008,
    "pob": "Córdoba",
    "code_prov": 14,
    "prov": "Córdoba"
  },
  {
    "cp": 15070,
    "pob": "A Coruña",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15071,
    "pob": "A Coruña",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15080,
    "pob": "A Coruña",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15002,
    "pob": "A Coruña",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15006,
    "pob": "A Coruña",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15003,
    "pob": "A Coruña",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15001,
    "pob": "A Coruña",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15007,
    "pob": "A Coruña",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15011,
    "pob": "A Coruña",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15005,
    "pob": "A Coruña",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15004,
    "pob": "A Coruña",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15470,
    "pob": "Ferrol",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15471,
    "pob": "Ferrol",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15482,
    "pob": "Ferrol",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15404,
    "pob": "Ferrol",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15403,
    "pob": "Ferrol",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15401,
    "pob": "Ferrol",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15406,
    "pob": "Ferrol",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15402,
    "pob": "Ferrol",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15490,
    "pob": "Ferrol",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15770,
    "pob": "Santiago De Compostela",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15771,
    "pob": "Santiago De Compostela",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15780,
    "pob": "Santiago De Compostela",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15781,
    "pob": "Santiago De Compostela",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15782,
    "pob": "Santiago De Compostela",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15702,
    "pob": "Santiago De Compostela",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15704,
    "pob": "Santiago De Compostela",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15706,
    "pob": "Santiago De Compostela",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15705,
    "pob": "Santiago De Compostela",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15707,
    "pob": "Santiago De Compostela",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15701,
    "pob": "Santiago De Compostela",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 15703,
    "pob": "Santiago De Compostela",
    "code_prov": 15,
    "prov": "A Coruña"
  },
  {
    "cp": 16070,
    "pob": "Cuenca",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16071,
    "pob": "Cuenca",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16080,
    "pob": "Cuenca",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16002,
    "pob": "Cuenca",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16001,
    "pob": "Cuenca",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16004,
    "pob": "Cuenca",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 16003,
    "pob": "Cuenca",
    "code_prov": 16,
    "prov": "Cuenca"
  },
  {
    "cp": 17070,
    "pob": "Girona",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17071,
    "pob": "Girona",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17080,
    "pob": "Girona",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17005,
    "pob": "Girona",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17007,
    "pob": "Girona",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17004,
    "pob": "Girona",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17006,
    "pob": "Girona",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17003,
    "pob": "Girona",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17001,
    "pob": "Girona",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 17002,
    "pob": "Girona",
    "code_prov": 17,
    "prov": "Girona"
  },
  {
    "cp": 18070,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18071,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18080,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18010,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18012,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18006,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18005,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18008,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18009,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18015,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18004,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18001,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18014,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18007,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18002,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18013,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18003,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18011,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 18016,
    "pob": "Granada",
    "code_prov": 18,
    "prov": "Granada"
  },
  {
    "cp": 19070,
    "pob": "Guadalajara",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19071,
    "pob": "Guadalajara",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19080,
    "pob": "Guadalajara",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19005,
    "pob": "Guadalajara",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19002,
    "pob": "Guadalajara",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19003,
    "pob": "Guadalajara",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19004,
    "pob": "Guadalajara",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 19001,
    "pob": "Guadalajara",
    "code_prov": 19,
    "prov": "Guadalajara"
  },
  {
    "cp": 20070,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20071,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20080,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20003,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20014,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20002,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20009,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20012,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20013,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20016,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20017,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20015,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20005,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20006,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20011,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20010,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20004,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20001,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20007,
    "pob": "Donostia - San Sebastian",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20300,
    "pob": "Irún",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20303,
    "pob": "Irún",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20305,
    "pob": "Irún",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20301,
    "pob": "Irún",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 20302,
    "pob": "Irún",
    "code_prov": 20,
    "prov": "Gipuzkoa"
  },
  {
    "cp": 21070,
    "pob": "Huelva",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21071,
    "pob": "Huelva",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21080,
    "pob": "Huelva",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21006,
    "pob": "Huelva",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21003,
    "pob": "Huelva",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21007,
    "pob": "Huelva",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21004,
    "pob": "Huelva",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21002,
    "pob": "Huelva",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21005,
    "pob": "Huelva",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 21001,
    "pob": "Huelva",
    "code_prov": 21,
    "prov": "Huelva"
  },
  {
    "cp": 22070,
    "pob": "Huesca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22071,
    "pob": "Huesca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22080,
    "pob": "Huesca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22002,
    "pob": "Huesca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22006,
    "pob": "Huesca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22004,
    "pob": "Huesca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22001,
    "pob": "Huesca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22005,
    "pob": "Huesca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 22003,
    "pob": "Huesca",
    "code_prov": 22,
    "prov": "Huesca"
  },
  {
    "cp": 23070,
    "pob": "Jaén",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23071,
    "pob": "Jaén",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23080,
    "pob": "Jaén",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23006,
    "pob": "Jaén",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23002,
    "pob": "Jaén",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23004,
    "pob": "Jaén",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23001,
    "pob": "Jaén",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23009,
    "pob": "Jaén",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23003,
    "pob": "Jaén",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23005,
    "pob": "Jaén",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23007,
    "pob": "Jaén",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 23008,
    "pob": "Jaén",
    "code_prov": 23,
    "prov": "Jaén"
  },
  {
    "cp": 24070,
    "pob": "León",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24071,
    "pob": "León",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24080,
    "pob": "León",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24010,
    "pob": "León",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24008,
    "pob": "León",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24002,
    "pob": "León",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24005,
    "pob": "León",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24007,
    "pob": "León",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24001,
    "pob": "León",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24004,
    "pob": "León",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24006,
    "pob": "León",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24003,
    "pob": "León",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24404,
    "pob": "Ponferrada",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24401,
    "pob": "Ponferrada",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24403,
    "pob": "Ponferrada",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 24402,
    "pob": "Ponferrada",
    "code_prov": 24,
    "prov": "León"
  },
  {
    "cp": 25070,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25071,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25080,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25003,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25001,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25199,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25002,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25191,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25007,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25005,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25195,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25197,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25198,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25008,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25006,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25190,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25004,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25196,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25194,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25192,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 25193,
    "pob": "Lleida",
    "code_prov": 25,
    "prov": "Lleida"
  },
  {
    "cp": 26070,
    "pob": "Logroño",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26071,
    "pob": "Logroño",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26080,
    "pob": "Logroño",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26007,
    "pob": "Logroño",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26002,
    "pob": "Logroño",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26006,
    "pob": "Logroño",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26004,
    "pob": "Logroño",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26003,
    "pob": "Logroño",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26001,
    "pob": "Logroño",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26009,
    "pob": "Logroño",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26005,
    "pob": "Logroño",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 26008,
    "pob": "Logroño",
    "code_prov": 26,
    "prov": "La Rioja"
  },
  {
    "cp": 27070,
    "pob": "Lugo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27071,
    "pob": "Lugo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27080,
    "pob": "Lugo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27004,
    "pob": "Lugo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27002,
    "pob": "Lugo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27003,
    "pob": "Lugo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 27001,
    "pob": "Lugo",
    "code_prov": 27,
    "prov": "Lugo"
  },
  {
    "cp": 28070,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28071,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28083,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28080,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28087,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28085,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28086,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28082,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28023,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28031,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28042,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28021,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28032,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28013,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28012,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28039,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28043,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28037,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28002,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28034,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28033,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28015,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28036,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28047,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28044,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28050,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28025,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28022,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28007,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28019,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28005,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28014,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28027,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28008,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28045,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28016,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28011,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28051,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28017,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28024,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28029,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28054,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28049,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28041,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28038,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28035,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28009,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28003,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28028,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28006,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28018,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28040,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28046,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28053,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28026,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28010,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28052,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28030,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28004,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28020,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28001,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28055,
    "pob": "Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28800,
    "pob": "Alcalá De Henares",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28803,
    "pob": "Alcalá De Henares",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28806,
    "pob": "Alcalá De Henares",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28802,
    "pob": "Alcalá De Henares",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28804,
    "pob": "Alcalá De Henares",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28801,
    "pob": "Alcalá De Henares",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28807,
    "pob": "Alcalá De Henares",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28870,
    "pob": "Alcalá De Henares",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28920,
    "pob": "Alcorcón",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28922,
    "pob": "Alcorcón",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28925,
    "pob": "Alcorcón",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28923,
    "pob": "Alcorcón",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28921,
    "pob": "Alcorcón",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28924,
    "pob": "Alcorcón",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28900,
    "pob": "Getafe",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28905,
    "pob": "Getafe",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28903,
    "pob": "Getafe",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28901,
    "pob": "Getafe",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28907,
    "pob": "Getafe",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28906,
    "pob": "Getafe",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28904,
    "pob": "Getafe",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28909,
    "pob": "Getafe",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28902,
    "pob": "Getafe",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28910,
    "pob": "Leganés",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28918,
    "pob": "Leganés",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28911,
    "pob": "Leganés",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28914,
    "pob": "Leganés",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28915,
    "pob": "Leganés",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28916,
    "pob": "Leganés",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28912,
    "pob": "Leganés",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28913,
    "pob": "Leganés",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28919,
    "pob": "Leganés",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28930,
    "pob": "Móstoles",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28935,
    "pob": "Móstoles",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28931,
    "pob": "Móstoles",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28932,
    "pob": "Móstoles",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28933,
    "pob": "Móstoles",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28936,
    "pob": "Móstoles",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28934,
    "pob": "Móstoles",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28937,
    "pob": "Móstoles",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28223,
    "pob": "Pozuelo De Alarcón",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28224,
    "pob": "Pozuelo De Alarcón",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28940,
    "pob": "Fuenlabrada",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28942,
    "pob": "Fuenlabrada",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28946,
    "pob": "Fuenlabrada",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28944,
    "pob": "Fuenlabrada",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28947,
    "pob": "Fuenlabrada",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28941,
    "pob": "Fuenlabrada",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28945,
    "pob": "Fuenlabrada",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28943,
    "pob": "Fuenlabrada",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28100,
    "pob": "Alcobendas Y La Moraleja",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28230,
    "pob": "Las Rozas De Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28232,
    "pob": "Las Rozas De Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28231,
    "pob": "Las Rozas De Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28290,
    "pob": "Las Rozas De Madrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28820,
    "pob": "Coslada",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28821,
    "pob": "Coslada",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28823,
    "pob": "Coslada",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28822,
    "pob": "Coslada",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28700,
    "pob": "San Sebastián De Los Reyes",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28703,
    "pob": "San Sebastián De Los Reyes",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28702,
    "pob": "San Sebastián De Los Reyes",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28701,
    "pob": "San Sebastián De Los Reyes",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28220,
    "pob": "Majadahonda",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28221,
    "pob": "Majadahonda",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28222,
    "pob": "Majadahonda",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28529,
    "pob": "Rivas-vaciamadrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28521,
    "pob": "Rivas-vaciamadrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28522,
    "pob": "Rivas-vaciamadrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28523,
    "pob": "Rivas-vaciamadrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28524,
    "pob": "Rivas-vaciamadrid",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28980,
    "pob": "Parla",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28984,
    "pob": "Parla",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28982,
    "pob": "Parla",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28983,
    "pob": "Parla",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28981,
    "pob": "Parla",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28340,
    "pob": "Valdemoro",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28343,
    "pob": "Valdemoro",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28341,
    "pob": "Valdemoro",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 28342,
    "pob": "Valdemoro",
    "code_prov": 28,
    "prov": "Madrid"
  },
  {
    "cp": 29070,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29071,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29080,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29004,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29014,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29015,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29018,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29006,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29013,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29007,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29010,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29002,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29003,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29017,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29009,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29012,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29005,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29016,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29011,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29008,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29001,
    "pob": "Málaga",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29600,
    "pob": "Marbella",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29670,
    "pob": "Marbella",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29602,
    "pob": "Marbella",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29604,
    "pob": "Marbella",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29603,
    "pob": "Marbella",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29660,
    "pob": "Marbella",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29601,
    "pob": "Marbella",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 29651,
    "pob": "Mijas",
    "code_prov": 29,
    "prov": "Málaga"
  },
  {
    "cp": 30070,
    "pob": "Murcia",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30071,
    "pob": "Murcia",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30080,
    "pob": "Murcia",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30007,
    "pob": "Murcia",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30009,
    "pob": "Murcia",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30012,
    "pob": "Murcia",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30008,
    "pob": "Murcia",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30011,
    "pob": "Murcia",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30006,
    "pob": "Murcia",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30005,
    "pob": "Murcia",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30002,
    "pob": "Murcia",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30001,
    "pob": "Murcia",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30010,
    "pob": "Murcia",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30004,
    "pob": "Murcia",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30003,
    "pob": "Murcia",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30200,
    "pob": "Cartagena",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30205,
    "pob": "Cartagena",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30201,
    "pob": "Cartagena",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30203,
    "pob": "Cartagena",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 30204,
    "pob": "Cartagena",
    "code_prov": 30,
    "prov": "Murcia"
  },
  {
    "cp": 31070,
    "pob": "Pamplona-iruña",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31071,
    "pob": "Pamplona-iruña",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31080,
    "pob": "Pamplona-iruña",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31014,
    "pob": "Pamplona-iruña",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31007,
    "pob": "Pamplona-iruña",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31008,
    "pob": "Pamplona-iruña",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31001,
    "pob": "Pamplona-iruña",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31004,
    "pob": "Pamplona-iruña",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31015,
    "pob": "Pamplona-iruña",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31003,
    "pob": "Pamplona-iruña",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31002,
    "pob": "Pamplona-iruña",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31013,
    "pob": "Pamplona-iruña",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31006,
    "pob": "Pamplona-iruña",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31012,
    "pob": "Pamplona-iruña",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31011,
    "pob": "Pamplona-iruña",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31005,
    "pob": "Pamplona-iruña",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31010,
    "pob": "Pamplona-iruña",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 31009,
    "pob": "Pamplona-iruña",
    "code_prov": 31,
    "prov": "Navarra"
  },
  {
    "cp": 32070,
    "pob": "Ourense",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32071,
    "pob": "Ourense",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32080,
    "pob": "Ourense",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32005,
    "pob": "Ourense",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 32003,
    "pob": "Ourense",
    "code_prov": 32,
    "prov": "Ourense"
  },
  {
    "cp": 33070,
    "pob": "Oviedo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33071,
    "pob": "Oviedo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33080,
    "pob": "Oviedo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33013,
    "pob": "Oviedo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33008,
    "pob": "Oviedo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33012,
    "pob": "Oviedo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33003,
    "pob": "Oviedo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33009,
    "pob": "Oviedo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33001,
    "pob": "Oviedo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33007,
    "pob": "Oviedo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33005,
    "pob": "Oviedo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33002,
    "pob": "Oviedo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33004,
    "pob": "Oviedo",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33200,
    "pob": "Gijón",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33203,
    "pob": "Gijón",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33201,
    "pob": "Gijón",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33205,
    "pob": "Gijón",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33211,
    "pob": "Gijón",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33207,
    "pob": "Gijón",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33208,
    "pob": "Gijón",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33202,
    "pob": "Gijón",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33206,
    "pob": "Gijón",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33213,
    "pob": "Gijón",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33212,
    "pob": "Gijón",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33204,
    "pob": "Gijón",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33210,
    "pob": "Gijón",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33209,
    "pob": "Gijón",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33290,
    "pob": "Gijón",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 33400,
    "pob": "Avilés",
    "code_prov": 33,
    "prov": "Asturias"
  },
  {
    "cp": 34070,
    "pob": "Palencia",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34071,
    "pob": "Palencia",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34080,
    "pob": "Palencia",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34004,
    "pob": "Palencia",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34003,
    "pob": "Palencia",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34001,
    "pob": "Palencia",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34005,
    "pob": "Palencia",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 34002,
    "pob": "Palencia",
    "code_prov": 34,
    "prov": "Palencia"
  },
  {
    "cp": 35070,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35071,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35080,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35014,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35016,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35007,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35001,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35019,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35009,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35010,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35008,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35004,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35011,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35012,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35002,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35005,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35013,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35003,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 35006,
    "pob": "Palmas De Gran Canaria,las",
    "code_prov": 35,
    "prov": "Las Palmas"
  },
  {
    "cp": 36070,
    "pob": "Pontevedra",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36071,
    "pob": "Pontevedra",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36080,
    "pob": "Pontevedra",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36003,
    "pob": "Pontevedra",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36004,
    "pob": "Pontevedra",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36005,
    "pob": "Pontevedra",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36002,
    "pob": "Pontevedra",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36001,
    "pob": "Pontevedra",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36162,
    "pob": "Pontevedra",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36160,
    "pob": "Pontevedra",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36161,
    "pob": "Pontevedra",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36200,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36210,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36330,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36213,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36208,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36312,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36202,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36206,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36215,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36317,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36318,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36205,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36315,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36214,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36211,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36201,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36207,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36204,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36212,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36203,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36216,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36209,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36314,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36392,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36313,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36310,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36331,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 36339,
    "pob": "Vigo",
    "code_prov": 36,
    "prov": "Pontevedra"
  },
  {
    "cp": 37070,
    "pob": "Salamanca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37071,
    "pob": "Salamanca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37080,
    "pob": "Salamanca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37002,
    "pob": "Salamanca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37008,
    "pob": "Salamanca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37004,
    "pob": "Salamanca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37006,
    "pob": "Salamanca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37003,
    "pob": "Salamanca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37007,
    "pob": "Salamanca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37005,
    "pob": "Salamanca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37001,
    "pob": "Salamanca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 37009,
    "pob": "Salamanca",
    "code_prov": 37,
    "prov": "Salamanca"
  },
  {
    "cp": 38070,
    "pob": "Santa Cruz De Tenerife",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38071,
    "pob": "Santa Cruz De Tenerife",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38080,
    "pob": "Santa Cruz De Tenerife",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38010,
    "pob": "Santa Cruz De Tenerife",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38009,
    "pob": "Santa Cruz De Tenerife",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38006,
    "pob": "Santa Cruz De Tenerife",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38008,
    "pob": "Santa Cruz De Tenerife",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38004,
    "pob": "Santa Cruz De Tenerife",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38002,
    "pob": "Santa Cruz De Tenerife",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38003,
    "pob": "Santa Cruz De Tenerife",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38005,
    "pob": "Santa Cruz De Tenerife",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38007,
    "pob": "Santa Cruz De Tenerife",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38001,
    "pob": "Santa Cruz De Tenerife",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38200,
    "pob": "San Cristobal De La Laguna",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38205,
    "pob": "San Cristobal De La Laguna",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38208,
    "pob": "San Cristobal De La Laguna",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38206,
    "pob": "San Cristobal De La Laguna",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38201,
    "pob": "San Cristobal De La Laguna",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38204,
    "pob": "San Cristobal De La Laguna",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38203,
    "pob": "San Cristobal De La Laguna",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38202,
    "pob": "San Cristobal De La Laguna",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 38207,
    "pob": "San Cristobal De La Laguna",
    "code_prov": 38,
    "prov": "Santa Cruz de Tenerife"
  },
  {
    "cp": 39070,
    "pob": "Santander",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39071,
    "pob": "Santander",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39080,
    "pob": "Santander",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39011,
    "pob": "Santander",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39001,
    "pob": "Santander",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39004,
    "pob": "Santander",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39003,
    "pob": "Santander",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39012,
    "pob": "Santander",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39010,
    "pob": "Santander",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39008,
    "pob": "Santander",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39005,
    "pob": "Santander",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39009,
    "pob": "Santander",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39002,
    "pob": "Santander",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39006,
    "pob": "Santander",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 39007,
    "pob": "Santander",
    "code_prov": 39,
    "prov": "Cantabria"
  },
  {
    "cp": 40070,
    "pob": "Segovia",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40071,
    "pob": "Segovia",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40080,
    "pob": "Segovia",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40004,
    "pob": "Segovia",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40003,
    "pob": "Segovia",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40005,
    "pob": "Segovia",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40001,
    "pob": "Segovia",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40002,
    "pob": "Segovia",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 40006,
    "pob": "Segovia",
    "code_prov": 40,
    "prov": "Segovia"
  },
  {
    "cp": 41070,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41071,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41080,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41002,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41004,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41017,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41020,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41009,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41011,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41019,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41015,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41003,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41007,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41006,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41008,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41001,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41013,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41014,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41005,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41016,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41010,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41092,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41018,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41012,
    "pob": "Sevilla",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41700,
    "pob": "Dos Hermanas",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41701,
    "pob": "Dos Hermanas",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41702,
    "pob": "Dos Hermanas",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 41704,
    "pob": "Dos Hermanas",
    "code_prov": 41,
    "prov": "Sevilla"
  },
  {
    "cp": 42070,
    "pob": "Soria",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42071,
    "pob": "Soria",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42080,
    "pob": "Soria",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42005,
    "pob": "Soria",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42002,
    "pob": "Soria",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42004,
    "pob": "Soria",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42003,
    "pob": "Soria",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 42001,
    "pob": "Soria",
    "code_prov": 42,
    "prov": "Soria"
  },
  {
    "cp": 43070,
    "pob": "Tarragona",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43071,
    "pob": "Tarragona",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43080,
    "pob": "Tarragona",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43007,
    "pob": "Tarragona",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43003,
    "pob": "Tarragona",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43008,
    "pob": "Tarragona",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43004,
    "pob": "Tarragona",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43005,
    "pob": "Tarragona",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43006,
    "pob": "Tarragona",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43002,
    "pob": "Tarragona",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43001,
    "pob": "Tarragona",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43200,
    "pob": "Reus",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43205,
    "pob": "Reus",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43201,
    "pob": "Reus",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43204,
    "pob": "Reus",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43202,
    "pob": "Reus",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 43203,
    "pob": "Reus",
    "code_prov": 43,
    "prov": "Tarragona"
  },
  {
    "cp": 44070,
    "pob": "Teruel",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44071,
    "pob": "Teruel",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44080,
    "pob": "Teruel",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44003,
    "pob": "Teruel",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44001,
    "pob": "Teruel",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 44002,
    "pob": "Teruel",
    "code_prov": 44,
    "prov": "Teruel"
  },
  {
    "cp": 45070,
    "pob": "Toledo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45071,
    "pob": "Toledo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45080,
    "pob": "Toledo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45001,
    "pob": "Toledo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45005,
    "pob": "Toledo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45002,
    "pob": "Toledo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45003,
    "pob": "Toledo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45004,
    "pob": "Toledo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45006,
    "pob": "Toledo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45007,
    "pob": "Toledo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 45008,
    "pob": "Toledo",
    "code_prov": 45,
    "prov": "Toledo"
  },
  {
    "cp": 46070,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46071,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46080,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46002,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46001,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46011,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46008,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46010,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46021,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46006,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46019,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46012,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46025,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46009,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46013,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46014,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46022,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46018,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46026,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46007,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46017,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46015,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46016,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46023,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46003,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46024,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46020,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46004,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46005,
    "pob": "Valencia",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46700,
    "pob": "Gandía",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46701,
    "pob": "Gandía",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46702,
    "pob": "Gandía",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 46703,
    "pob": "Gandía",
    "code_prov": 46,
    "prov": "Valencia/València"
  },
  {
    "cp": 47070,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47071,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47080,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47012,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47009,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47013,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47011,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47008,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47010,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47004,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47005,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47001,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47003,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47006,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47007,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47017,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47002,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47014,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47016,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47015,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 47197,
    "pob": "Valladolid",
    "code_prov": 47,
    "prov": "Valladolid"
  },
  {
    "cp": 48070,
    "pob": "Bilbao",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48071,
    "pob": "Bilbao",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48080,
    "pob": "Bilbao",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48011,
    "pob": "Bilbao",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48013,
    "pob": "Bilbao",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48007,
    "pob": "Bilbao",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48006,
    "pob": "Bilbao",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48001,
    "pob": "Bilbao",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48014,
    "pob": "Bilbao",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48015,
    "pob": "Bilbao",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48008,
    "pob": "Bilbao",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48012,
    "pob": "Bilbao",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48002,
    "pob": "Bilbao",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48004,
    "pob": "Bilbao",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48010,
    "pob": "Bilbao",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48005,
    "pob": "Bilbao",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48009,
    "pob": "Bilbao",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48900,
    "pob": "Barakaldo",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48901,
    "pob": "Barakaldo",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48998,
    "pob": "Getxo (inc. Las Arenas Y Algorta)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48990,
    "pob": "Getxo (inc. Las Arenas Y Algorta)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48993,
    "pob": "Getxo (inc. Las Arenas Y Algorta)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48992,
    "pob": "Getxo (inc. Las Arenas Y Algorta)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48991,
    "pob": "Getxo (inc. Las Arenas Y Algorta)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 48930,
    "pob": "Getxo (inc. Las Arenas Y Algorta)",
    "code_prov": 48,
    "prov": "Bizkaia"
  },
  {
    "cp": 49070,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49071,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49080,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49023,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49026,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49028,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49029,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49014,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49022,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49006,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49024,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49021,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49002,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49003,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49013,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49010,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49001,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49018,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49008,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49012,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49007,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49025,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49019,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49030,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49020,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49031,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49004,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49017,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49032,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49016,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49011,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49009,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49005,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 49015,
    "pob": "Zamora",
    "code_prov": 49,
    "prov": "Zamora"
  },
  {
    "cp": 50070,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50071,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50080,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50022,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50003,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50002,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50012,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50015,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50016,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50019,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50006,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50007,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50011,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50018,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50014,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50004,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50017,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50010,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50009,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50008,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50005,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50021,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50013,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 50001,
    "pob": "Zaragoza",
    "code_prov": 50,
    "prov": "Zaragoza"
  },
  {
    "cp": 51070,
    "pob": "Ceuta",
    "code_prov": 51,
    "prov": "Ceuta"
  },
  {
    "cp": 51071,
    "pob": "Ceuta",
    "code_prov": 51,
    "prov": "Ceuta"
  },
  {
    "cp": 51080,
    "pob": "Ceuta",
    "code_prov": 51,
    "prov": "Ceuta"
  },
  {
    "cp": 51001,
    "pob": "Ceuta",
    "code_prov": 51,
    "prov": "Ceuta"
  },
  {
    "cp": 51002,
    "pob": "Ceuta",
    "code_prov": 51,
    "prov": "Ceuta"
  },
  {
    "cp": 51003,
    "pob": "Ceuta",
    "code_prov": 51,
    "prov": "Ceuta"
  },
  {
    "cp": 51005,
    "pob": "Ceuta",
    "code_prov": 51,
    "prov": "Ceuta"
  },
  {
    "cp": 51004,
    "pob": "Ceuta",
    "code_prov": 51,
    "prov": "Ceuta"
  },
  {
    "cp": 52070,
    "pob": "Melilla",
    "code_prov": 52,
    "prov": "Melilla"
  },
  {
    "cp": 52071,
    "pob": "Melilla",
    "code_prov": 52,
    "prov": "Melilla"
  },
  {
    "cp": 52080,
    "pob": "Melilla",
    "code_prov": 52,
    "prov": "Melilla"
  },
  {
    "cp": 52002,
    "pob": "Melilla",
    "code_prov": 52,
    "prov": "Melilla"
  },
  {
    "cp": 52001,
    "pob": "Melilla",
    "code_prov": 52,
    "prov": "Melilla"
  },
  {
    "cp": 52003,
    "pob": "Melilla",
    "code_prov": 52,
    "prov": "Melilla"
  },
  {
    "cp": 52005,
    "pob": "Melilla",
    "code_prov": 52,
    "prov": "Melilla"
  },
  {
    "cp": 52004,
    "pob": "Melilla",
    "code_prov": 52,
    "prov": "Melilla"
  },
  {
    "cp": 52006,
    "pob": "Melilla",
    "code_prov": 52,
    "prov": "Melilla"
  }
];