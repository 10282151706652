import * as React from 'react';
import { FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, MenuItem, Radio, RadioGroup, Select, Switch, TextField, Typography } from '@mui/material';
import { inclination, structure } from '../utils/products.catalog';

export default function StructureData({
    valueForm,
    onValueForm,
    errorForm,
    onErrorForm,
    showErrorForm
}) {
    const handleStructure = (e) => {
        if (e.target.value !== '') {
            onErrorForm({
                ...errorForm,
                structure: null,
            });
        }

        onValueForm({
            ...valueForm,
            structure: e.target.value,
            inclination: '',
        });
    };

    const handleInclination = (e) => {
        onValueForm({
            ...valueForm,
            inclination: e.target.value,
        });
    };

    const handleIncludePergola = e => {
        onValueForm({
            ...valueForm,
            includePergola: e.target.checked,
            pergola: 1,
        });
    };

    const handlePergola = e => {
        if (e.target.value >= 1) {
            onValueForm({
                ...valueForm,
                pergola: e.target.value,
            });
        }
    };

    return (
        <React.Fragment>
            <Typography variant='h5'>Estructura</Typography>
            <FormControl error={showErrorForm && errorForm.structure !== null}>
                <RadioGroup
                    row
                    aria-labelledby="structure-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={valueForm.structure}
                    onChange={handleStructure}
                >
                    {structure.map(item => {
                        return (
                            <FormControlLabel
                                key={item.value}
                                value={item.value}
                                checked={valueForm.structure === item.value}
                                control={<Radio />}
                                label={item.name}
                            />
                        );
                    })}
                </RadioGroup>
                {showErrorForm && errorForm.structure !== null && (
                    <FormHelperText>{errorForm.structure}</FormHelperText>
                )}
            </FormControl>
            {valueForm.structure === 'elevada' && (
                <FormControl>
                    <InputLabel id="inclination-select-required-label">Inclinaci&oacute;n</InputLabel>
                    <Select
                        labelId="inclination-select-required-label"
                        id="demo-simple-select-required"
                        value={valueForm.inclination}
                        onChange={handleInclination}
                        label="Inclinación *"
                    >
                        {inclination.map(item => {
                            return (<MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>);
                        })}
                    </Select>
                </FormControl>
            )}
            <FormGroup>
                <FormControlLabel control={<Switch
                    checked={valueForm.includePergola}
                    onChange={handleIncludePergola}
                />} label="Pérgola" />
            </FormGroup>
            {valueForm.includePergola && (
                <TextField
                    fullWidth
                    label='Tamaño Pérgola (mts.)'
                    type='number'
                    inputProps={{
                        autoComplete: 'off',
                        min: 1,
                    }}
                    value={valueForm.pergola}
                    onChange={handlePergola}
                />
            )}
        </React.Fragment>
    );
}