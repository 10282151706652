import React from "react";
import { Document, Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import pTwoUp from '../media/pag2-up.png';
import pTwoDown from '../media/pag2-down.png';
import { format } from "date-fns";

const PageTwoPdfQuote = ({ styles, dateContrat }) => {
    return (
        <Page
            size="A4"
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                backgroundColor: "white"
            }}
        >
            <Image
                src={pTwoUp}
            />
            <View
                style={{
                    display: "flex",
                    alignItems: 'center',
                    backgroundColor: '#005da4',
                    paddingTop: '0.75vh',
                    paddingBottom: '1.3vh',
                }}
            >
                <Text style={{...styles.fontSubtitle, color: 'white' }}>FECHA: {format(dateContrat, 'd/M/yyyy')}</Text>
            </View>
            <Image
                src={pTwoDown}
            />
        </Page>
    );
}

export default PageTwoPdfQuote;