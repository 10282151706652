import React from "react";
import FormHome from "./components/FormHome";

function App() {
  return (
      <FormHome />
  );
}

export default App;
