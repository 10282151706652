import { cableadoCatalog, costAllows, discount, displacement, displacementTypes, inclination, maintenance, maintenanceTypes, meterCatalog, montaje, pergolaCatalog, sellerCatalog, squareCatalog, structure, workforceDay } from "./products.catalog";

const PERCENT_DAY_SUN = 0.6;
const PERCENT_DAY_NIGHT = 0.4;
const IMP_SOLAR_PANEL = 1.35;
const CONST_TREE = 12.12;
const CONST_CO = 1.8;
const CONST_TV = 92;

const IVA = 0.21;
export const PRICE_KW_INJECT_RED = 0.05;

export const getProducctionPico = valueForm => {
    return getProduccionTotalInstallAuto(valueForm) * 1.4;
};

export const getProduccionTotalInstallAuto = valueForm => {
    return valueForm.countPanels * valueForm.panel.power;
};

export const getExcedenteProduccion = valueForm => {
    return getProducctionPico(valueForm) - valueForm.consum;
};

export const getDevolucionInyectRed = valueForm => {
    return (getProducctionPico(valueForm) - valueForm.consum) * PRICE_KW_INJECT_RED;
};

export const getGastoMoneyConsumDia = valueForm => {
    return valueForm.consumDayTime * valueForm.priceDayTime;
};

export const getGastoMoneyConsumNoche = valueForm => {
    return valueForm.consumNightTime * valueForm.priceNightTime;
};

export const getAhorroMoney = valueForm => {
    return getGastoMoneyConsumDia(valueForm) + (getGastoMoneyConsumNoche(valueForm) - getDevolucionInyectRed(valueForm));
};

export const getCostInversors = valueForm => {
    return valueForm.inversor.price * valueForm.countInversors;
};

export const getCostPanels = valueForm => {
    return valueForm.panel.price * valueForm.countPanels;
};

export const getCostBatteries = valueForm => {
    return valueForm.includeBattery ? valueForm.countBatteries * valueForm.battery.price : 0;
};

export const getCostStructure = valueForm => {
    return valueForm.countPanels
        * structure.find(ele => valueForm.structure === ele.value).price
        * (valueForm.inclination !== '' ? inclination.find(ele => valueForm.inclination === ele.value).percent : 1);
};

export const getCountDaysWorks = valueForm => {
    return (valueForm.countPanels % workforceDay.countPanels) > 0 ?
        Math.trunc(valueForm.countPanels / workforceDay.countPanels) + 1
        : valueForm.countPanels / workforceDay.countPanels;
};

export const getCostMount = valueForm => {
    if (valueForm.includeMount) {
        return montaje.find(ele => valueForm.mount === ele.value).price * getCountDaysWorks(valueForm);
    } else {
        return 0;
    }
};

export const getCostPergola = valueForm => {
    if (valueForm.includePergola) {
        return pergolaCatalog.price * valueForm.pergola;
    } else {
        return 0;
    }
};

export const getCostMaterials = valueForm => {
    return (valueForm.meter ? meterCatalog.price : 0)
        + (valueForm.cable * cableadoCatalog.price)
        + (valueForm.includeSquare ? valueForm.square.price : 0)
        + (valueForm.includeBatometro ? valueForm.batometro.price : 0);
};

export const getCostDisplacement = valueForm => {
    return displacementTypes.find(ele => valueForm.displacement === ele.value).price;
};

export const getCostMaintenance = valueForm => {
    return valueForm.includeMaintenance ? maintenanceTypes.find(ele => valueForm.typeMaintenance === ele.value).price : 0;
};

export const getCostWorkForce = valueForm => {
    return getCountDaysWorks(valueForm) * workforceDay.countWorker * workforceDay.priceWorker;
};
export const getCostAmortizeTools  = () => {
    return workforceDay.amortizarTools;
};

export const getCostInstallWork = valueForm => {
    return getCostInversors(valueForm)
        + getCostPanels(valueForm)
        + getCostBatteries(valueForm)
        + getCostStructure(valueForm)
        + getCostMount(valueForm)
        + getCostPergola(valueForm)
        + getCostMaterials(valueForm)
        + getCostDisplacement(valueForm)
        + getCostMaintenance(valueForm)
        + getCostWorkForce(valueForm)
        + getCostAmortizeTools();
};

export const getCostPermits = valueForm => {
    if (valueForm.processAllow) {
        return getCostInstallWork(valueForm) * costAllows.percent;
    } else {
        return 0;
    }
};

export const getCostHalfIVAAndDiscount = valueForm => {
    return (getCostInstallWork(valueForm) + getCostPermits(valueForm)) * sellerCatalog.find(ele => valueForm.seller === ele.value).percent;
};

export const getCostWDiscount = valueForm => {
    return getCostHalfIVAAndDiscount(valueForm) * valueForm.discount.percent;
};

export const getCostIVA = valueForm => {
    return getCostWDiscount(valueForm) * IVA;
};

export const getCostWIVA = valueForm => {
    return getCostWDiscount(valueForm) * (1 + IVA);
};

export const getSubvencion = valueForm => {};
export const getCostWSubvencion = valueForm => {};

export const roundTwoDecimal = numb => {
    return Math.round((numb)*100)/100;
};