import * as React from 'react';
import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';

export default function ProcessData({
    valueForm,
    onValueForm,
    errorForm,
    onErrorForm,
    showErrorForm
}) {
    const handleProcessAllow = e => {
        onValueForm({
            ...valueForm,
            processAllow: e.target.checked
        });
    };

    const handleProcessSubvencion = e => {
        onValueForm({
            ...valueForm,
            processSubvencion: e.target.checked
        });
    };

    return (
        <React.Fragment>
            <Typography variant='h5'>Tr&aacute;mites</Typography>
            <FormGroup>
                <FormControlLabel control={<Switch
                    checked={valueForm.processAllow}
                    onChange={handleProcessAllow}
                />} label="Tramitar Permisos" />
            </FormGroup>
            <FormGroup>
                <FormControlLabel control={<Switch
                    checked={valueForm.processSubvencion}
                    onChange={handleProcessSubvencion}
                />} label="Tramitar Subvención" />
            </FormGroup>
        </React.Fragment>
    );
}