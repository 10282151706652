export const characterControlCups = [
  {
    "value": 0,
    "letter": "T"
  },
  {
    "value": 1,
    "letter": "R"
  },
  {
    "value": 2,
    "letter": "W"
  },
  {
    "value": 3,
    "letter": "A"
  },
  {
    "value": 4,
    "letter": "G"
  },
  {
    "value": 5,
    "letter": "M"
  },
  {
    "value": 6,
    "letter": "Y"
  },
  {
    "value": 7,
    "letter": "F"
  },
  {
    "value": 8,
    "letter": "P"
  },
  {
    "value": 9,
    "letter": "D"
  },
  {
    "value": 10,
    "letter": "X"
  },
  {
    "value": 11,
    "letter": "B"
  },
  {
    "value": 12,
    "letter": "N"
  },
  {
    "value": 13,
    "letter": "J"
  },
  {
    "value": 14,
    "letter": "Z"
  },
  {
    "value": 15,
    "letter": "S"
  },
  {
    "value": 16,
    "letter": "Q"
  },
  {
    "value": 17,
    "letter": "V"
  },
  {
    "value": 18,
    "letter": "H"
  },
  {
    "value": 19,
    "letter": "L"
  },
  {
    "value": 20,
    "letter": "C"
  },
  {
    "value": 21,
    "letter": "K"
  },
  {
    "value": 22,
    "letter": "E"
  },
];