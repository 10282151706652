import React from "react";
import { Document, Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import nowFontBold from '../fonts/Now-Bold.woff';
import nowFontLight from '../fonts/Now-Light.woff';
import nowFontRegular from '../fonts/Now-Regular.woff';

import pOneUp from '../media/pag1-up.png';
import pOneDown from '../media/pag1-down.png';
import pTwoUp from '../media/pag2-up.png';
import pTwoDown from '../media/pag2-down.png';
import pThreeUp from '../media/pag3-up.png';
import pThreeMid from '../media/pag3-mid.png';
import pThreeDown from '../media/pag3-down.png';
import pFourFull from '../media/pag4-full.PNG';
import PageOnePdfQuote from "./PageOnePdfQuote";
import PageTwoPdfQuote from "./PageTwoPdfQuote";
import PageThreePdfQuote from "./PageThreePdfQuote";
import PageFourPdfQuote from "./PageFourPdfQuote";
import PageTenPdfQuote from "./PageTenPdfQuote";
import PageSevenPdfQuote from "./PageSevenPdfQuote";
import PageEightEPdfQuote from "./PageEightEPdfQuote";
import PageNinePdfQuote from "./PageNinePdfQuote";
import PageFivePdfQuote from "./PageFivePdfQuote";
import PageSixPdfQuote from "./PageSixPdfQuote";

Font.register({ family: 'Now Bold', src: nowFontBold });
Font.register({ family: 'Now Light', src: nowFontLight });
Font.register({ family: 'Now Regular', src: nowFontRegular });

const styles = StyleSheet.create({
    fontTitle: {
        fontFamily: 'Now Bold',
        fontSize: '18pt'
    },
    fontSubtitle: {
        fontFamily: 'Now Light',
        fontSize: '14pt'
    },
    fontBody: {
        fontFamily: 'Now Regular',
        fontSize: '12pt'
    },
    fontMiniBody: {
        fontFamily: 'Now Regular',
        fontSize: '9pt'
    },
    primaryColor: {
        color: '#005da4',
    },
    secondaryColor: {
        color: '#bfe35a',
    },
    boxValuePrimary: {
        border: '2pt',
        borderColor: '#005da4',
        borderRadius: 0.9,
        paddingTop: '5pt',
        paddingBottom: '3pt',
        paddingHorizontal: '5pt',
        textAlign: 'center',
    },
    boxValueSecondary: {
        border: '2pt',
        borderColor: '#bfe35a',
        borderRadius: 0.9,
        padding: '5pt',
        textAlign: 'center',
    },
    boxValueEspPrimary: {
        textAlign: 'center',
        marginHorizontal: '2%',
        border: 1,
        borderColor: '#005da4',
        borderRadius: '25%',
        paddingTop: 3,
        paddingBottom: 0,
        marginTop: -3,
    },
    boxValueEspSecondary: {
        textAlign: 'center',
        marginHorizontal: '2%',
        border: 1,
        borderColor: '#bfe35a',
        borderRadius: '25%',
        paddingTop: 3,
        paddingBottom: 0,
        marginTop: -3,
    },
    boxValueQuote: {
        borderBottom: 1,
        borderBottomStyle: 'dashed',
        borderBottomColor: '#005da4',
        paddingLeft: 5,
    },
});

const ViewPdfQuote = ({ valueForm, dateContrat }) => {
    return (
        <Document>
            <PageOnePdfQuote styles={styles} valueForm={valueForm} dateContrat={dateContrat} />
            <PageTwoPdfQuote styles={styles} dateContrat={dateContrat} />
            <PageThreePdfQuote styles={styles} valueForm={valueForm} />
            <PageFourPdfQuote styles={styles} valueForm={null} />
            <PageFivePdfQuote styles={styles} valueForm={valueForm} dateContrat={dateContrat} />
            <PageSixPdfQuote styles={styles} valueForm={valueForm} dateContrat={dateContrat} />
            <PageSevenPdfQuote styles={styles} valueForm={valueForm} />
            <PageEightEPdfQuote styles={styles} valueForm={valueForm} />
            <PageNinePdfQuote styles={styles} valueForm={valueForm} dateContrat={dateContrat} />
            <PageTenPdfQuote styles={styles} valueForm={null} />
        </Document>
    );
};

export default ViewPdfQuote;
