export const distributionCode = [
  {
    "type": 1,
    "typeTag": "Luz",
    "name": "Iberdrola Distribución",
    "code": "0021"
  },
  {
    "type": 1,
    "typeTag": "Luz",
    "name": "Unión Fenosa Distribución",
    "code": "0022"
  },
  {
    "type": 1,
    "typeTag": "Luz",
    "name": "Endesa Distribución",
    "code": "0023"
  },
  {
    "type": 1,
    "typeTag": "Luz",
    "name": "Endesa Distribución",
    "code": "0024"
  },
  {
    "type": 1,
    "typeTag": "Luz",
    "name": "Endesa Distribución",
    "code": "0029"
  },
  {
    "type": 1,
    "typeTag": "Luz",
    "name": "Endesa Distribución",
    "code": "0031"
  },
  {
    "type": 1,
    "typeTag": "Luz",
    "name": "E-Redes",
    "code": "0026"
  },
  {
    "type": 1,
    "typeTag": "Luz",
    "name": "Viesgo",
    "code": "0027"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nortegas",
    "code": "0201"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nortegas",
    "code": "0203"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nortegas",
    "code": "0210"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nortegas",
    "code": "0211"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nortegas",
    "code": "0212"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nortegas",
    "code": "0213"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nortegas",
    "code": "0214"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nortegas",
    "code": "0219"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nortegas",
    "code": "0229"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Redexis Gas",
    "code": "0204"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Redexis Gas",
    "code": "0205"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Redexis Gas",
    "code": "0206"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Redexis Gas",
    "code": "0208"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Redexis Gas",
    "code": "0209"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Redexis Gas",
    "code": "0225"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Gas Extremadura",
    "code": "0207"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nedgia",
    "code": "0217"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nedgia",
    "code": "0218"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nedgia",
    "code": "0220"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nedgia",
    "code": "0221"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nedgia",
    "code": "0222"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nedgia",
    "code": "0223"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nedgia",
    "code": "0224"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nedgia",
    "code": "0226"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nedgia",
    "code": "0227"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Nedgia",
    "code": "0230"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Madrileña Red de Gas",
    "code": "0228"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Madrileña Red de Gas",
    "code": "0234"
  },
  {
    "type": 2,
    "typeTag": "Gas",
    "name": "Madrileña Red de Gas",
    "code": "0236"
  },
];