import React from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import imgRight from '../media/pag5-right.png';
import { getAhorroMoney, getDevolucionInyectRed, getGastoMoneyConsumDia, getGastoMoneyConsumNoche, getProduccionTotalInstallAuto, getProducctionPico, PRICE_KW_INJECT_RED, roundTwoDecimal } from '../utils/process.quote';

const PageFivePdfQuote = ({ styles, valueForm, dateContrat }) => {
    return (
        <Page
            size="A4"
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                backgroundColor: "white",
            }}
        >
            <Text
                style={{
                    ...styles.fontBody,
                    transform: "rotate(-90deg)",
                    position: 'absolute',
                    backgroundColor: '#bfe35a',
                    color: '#005da4',
                    marginTop: 200,
                    marginLeft: -60,
                    paddingVertical: 2,

                    width: 210,
                    textAlign: 'center',
                }}
            >CONSUMO</Text>
            <Text
                style={{
                    ...styles.fontBody,
                    transform: "rotate(-90deg)",
                    position: 'absolute',
                    backgroundColor: '#bfe35a',
                    color: '#005da4',
                    marginTop: 450,
                    marginLeft: -60,
                    paddingVertical: 2,
                    width: 210,
                    textAlign: 'center',
                }}
            >INSTALACI&Oacute;N RECOMENDADA</Text>
            <Text
                style={{
                    ...styles.fontBody,
                    transform: "rotate(-90deg)",
                    position: 'absolute',
                    backgroundColor: '#bfe35a',
                    color: '#005da4',
                    marginTop: 700,
                    marginLeft: -60,
                    paddingVertical: 2,
                    width: 210,
                    textAlign: 'center',
                }}
            >AHORRO</Text>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                <View
                    style={{
                        ...styles.fontBody,
                        display: 'flex',
                        flexDirection: 'column',
                        width: '70%',
                        marginLeft: '11%',
                    }}
                >
                    <View>
                        <View>
                            <Text
                                style={{
                                    ...styles.fontTitle,
                                    ...styles.secondaryColor,
                                    marginTop: '5vh',
                                    marginBottom: '3vh',
                                    fontSize: '20pt'
                                }}
                            >SU INSTALACI&Oacute;N</Text>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginTop: '5vh',
                                    marginBottom: '0.5vh',
                                }}
                            >
                                <Text style={{ width: '35%', textAlign: 'left' }}>Consumo Anual:</Text>
                                <Text
                                    style={{
                                        ...styles.boxValueEspPrimary,
                                        width: '20%',
                                    }}
                                >{valueForm.consum}</Text>
                                <Text style={{ width: '35%', textAlign: 'left' }}>Kw</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <Image
                    src={imgRight}
                    style={{
                        width: '20%',
                        height: 'auto',
                    }}
                />
            </View>
            <View
                style={{
                    ...styles.fontBody,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '70%',
                    marginLeft: '11%',
                }}
            >
                <View>
                    <View>
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginVertical: '0.5vh',
                            }}
                        >
                            <Text
                                style={{
                                    width: '35%',
                                    textAlign: 'left',
                                }}
                            >Su precio actual KwH:</Text>
                            <Text
                                style={{
                                    ...styles.boxValueEspPrimary,
                                    width: '20%',
                                }}
                            >{valueForm.currentPriceKw}</Text>
                            <Text style={{ width: '35%', textAlign: 'left' }}>&euro;/KwH</Text>
                        </View>
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginVertical: '0.5vh',
                            }}
                        >
                            <Text
                                style={{
                                    width: '35%',
                                    textAlign: 'left',
                                }}
                            >Coste anual:</Text>
                            <Text
                                style={{
                                    ...styles.boxValueEspSecondary,
                                    width: '20%',
                                }}
                            >{roundTwoDecimal(valueForm.currentPriceKw * valueForm.consum)}</Text>
                            <Text style={{ width: '35%', textAlign: 'left' }}>&euro; + fijo + impuestos</Text>
                        </View>
                    </View>
                    <View></View>
                </View>
            </View>
            <View
                style={{
                    ...styles.fontBody,
                    marginLeft: '15%',
                    marginTop: '2vh',
                    paddingLeft: 2,
                    borderLeft: 4,
                    borderLeftColor: styles.secondaryColor.color,
                }}
            >
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginVertical: '0.5vh',
                    }}
                >
                    <Text
                        style={{
                            width: '12%',
                            textAlign: 'left',
                        }}
                    >Diurno</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                        }}
                    >{valueForm.consumDayTime}</Text>
                    <Text
                        style={{
                            width: '6%',
                            textAlign: 'left',
                        }}
                    >Kw x </Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                        }}
                    >{valueForm.priceDayTime}</Text>
                    <Text
                        style={{
                            width: '12%',
                            textAlign: 'left',
                        }}
                    >&euro;/KwH = </Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                        }}
                    >{roundTwoDecimal(valueForm.consumDayTime * valueForm.priceDayTime)}</Text>
                    <Text
                        style={{
                            textAlign: 'left',
                        }}
                    >&euro;</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginVertical: '0.5vh',
                    }}
                >
                    <Text
                        style={{
                            width: '12%',
                            textAlign: 'left',
                        }}
                    >Nocturno</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                        }}
                    >{valueForm.consumNightTime}</Text>
                    <Text
                        style={{
                            width: '6%',
                            textAlign: 'left',
                        }}
                    >Kw x </Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                        }}
                    >{valueForm.priceNightTime}</Text>
                    <Text
                        style={{
                            width: '12%',
                            textAlign: 'left',
                        }}
                    >&euro;/KwH = </Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                        }}
                    >{roundTwoDecimal(valueForm.consumNightTime * valueForm.priceNightTime)}</Text>
                    <Text
                        style={{
                            textAlign: 'left',
                        }}
                    >&euro;</Text>
                </View>
            </View>
            <View
                style={{
                    ...styles.fontBody,
                    marginLeft: '11%',
                    marginTop: '12vh',
                }}
            >
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginVertical: '0.5vh',
                    }}
                >
                    <Text style={{ textAlign: 'left', fontSize: '7pt', marginLeft: '69.5%' }}>GENERACI&Oacute;N PLACAS</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginVertical: '0.5vh',
                    }}
                >
                    <Text style={{ textAlign: 'left', width: '27%' }}>Producci&oacute;n estimada</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                        }}
                    >{getProduccionTotalInstallAuto(valueForm)}</Text>
                    <Text style={{ textAlign: 'left', fontSize: '7pt', marginTop: 5 }}>Kw x 1,4 productividad aprox. = </Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                        }}
                    >{getProducctionPico(valueForm)}</Text>
                    <Text style={{ textAlign: 'left' }}>Kw</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginVertical: '0.5vh',
                    }}
                >
                    <Text style={{ textAlign: 'left', width: '27%' }}>KwH estimados anuales</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                        }}
                    >{roundTwoDecimal(getProduccionTotalInstallAuto(valueForm) / 12)}</Text>
                    <Text style={{ textAlign: 'center', fontSize: '7pt', marginTop: 5, width: '3%' }}> / </Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                        }}
                    >{valueForm.panel.power}</Text>
                    <Text style={{ textAlign: 'left', fontSize: '7pt', marginTop: 5 }}>potencia por placa =</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '5%',
                        }}
                    >{roundTwoDecimal((getProduccionTotalInstallAuto(valueForm) / 12) / valueForm.panel.power)}</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginVertical: '0.5vh',
                    }}
                >
                    <Text style={{ textAlign: 'left', fontSize: '7pt', marginLeft: '10%' }}>Nº PLACAS</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginVertical: '0.5vh',
                    }}
                >
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                            marginLeft: '5.2%',
                        }}
                    >{valueForm.countPanels}</Text>
                    <Text style={{ textAlign: 'center', fontSize: '7pt', marginTop: 5, width: '2%' }}>x</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                        }}
                    >{valueForm.panel.power}</Text>
                    <Text style={{ textAlign: 'left', fontSize: '7pt', marginTop: 5 }}>Kw =</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspSecondary,
                            width: '12%',
                        }}
                    >{roundTwoDecimal(getProduccionTotalInstallAuto(valueForm))}</Text>
                    <Text style={{ textAlign: 'left', fontSize: '7pt', marginTop: 5 }}>Kw - 20% =</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '5%',
                        }}
                    >{roundTwoDecimal(getProduccionTotalInstallAuto(valueForm) * 0.8)}</Text>
                    <Text style={{ textAlign: 'left' }}>Kw</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginVertical: '0.5vh',
                    }}
                >
                    <Text style={{ textAlign: 'left', fontSize: '7pt', marginLeft: '76%' }}>NECESITA INVERSOR</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginVertical: '0.5vh',
                    }}
                >
                    <Text style={{ textAlign: 'left', fontSize: '7pt', marginLeft: '14.5%' }}>Nº BATER&Iacute;AS</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginVertical: '0.5vh',
                    }}
                >
                    <Text style={{ textAlign: 'left', width: '12.5%' }}>Bater&iacute;as:</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '5%',
                        }}
                    >{valueForm.includeBattery ? valueForm.countBatteries : ''}</Text>
                    <Text style={{ textAlign: 'center', fontSize: '7pt', marginTop: 5, width: '2%' }}>x</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                        }}
                    >{valueForm.includeBattery ? valueForm.battery.power : ''}</Text>
                    <Text style={{ textAlign: 'left', fontSize: '7pt', marginTop: 5 }}>Kw =</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspSecondary,
                            width: '12%',
                        }}
                    >{valueForm.includeBattery ? roundTwoDecimal(valueForm.countBatteries * valueForm.battery.power) : ''}</Text>
                    <Text style={{ textAlign: 'left' }}>Kw total acumulables</Text>
                </View>
            </View>
            <View
                style={{
                    ...styles.fontBody,
                    marginLeft: '11%',
                    marginTop: '9vh',
                }}
            >
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginVertical: '0.5vh',
                    }}
                >
                    <Text style={{ textAlign: 'left', width: '26%' }}>AHORRO DIURNO:</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspSecondary,
                            width: '12%',
                        }}
                    >{roundTwoDecimal(getGastoMoneyConsumDia(valueForm))}</Text>
                    <Text style={{ textAlign: 'left' }}>&euro; </Text>
                    <Text style={{ textAlign: 'left', fontSize: '7pt', marginTop: 3 }}> Ahorro 100% gracias a inyector a red en meses de alta generaci&oacute;n. </Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginVertical: '0.5vh',
                    }}
                >
                    <Text style={{ textAlign: 'left', width: '26%' }}>AHORRO VERTIDO RED:</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                        }}
                    >{getProducctionPico(valueForm) - valueForm.consum}</Text>
                    <Text style={{ textAlign: 'left', fontSize: '7pt', marginTop: 5 }}>Kw x</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                        }}
                    >{PRICE_KW_INJECT_RED}</Text>
                    <Text style={{ textAlign: 'left', fontSize: '7pt', marginTop: 5 }}>&euro;/KwH =</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspSecondary,
                            width: '12%',
                        }}
                    >{getDevolucionInyectRed(valueForm)}</Text>
                    <Text style={{ textAlign: 'left' }}>&euro;</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginVertical: '0.5vh',
                    }}
                >
                    <Text style={{ textAlign: 'left', fontSize: '7pt', marginLeft: '33%' }}>DIURNO</Text>
                    <Text style={{ textAlign: 'left', fontSize: '7pt', marginLeft: '14.5%' }}>NOCTURNO</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginVertical: '0.5vh',
                    }}
                >
                    <Text style={{ textAlign: 'left', width: '26%' }}>AHORRO TOTAL:</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                        }}
                    >{roundTwoDecimal(getGastoMoneyConsumDia(valueForm))}</Text>
                    <Text style={{ textAlign: 'left', fontSize: '7pt', marginTop: 5 }}>&euro; +</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                        }}
                    >{roundTwoDecimal(getGastoMoneyConsumNoche(valueForm) - getDevolucionInyectRed(valueForm))}</Text>
                    <Text style={{ textAlign: 'left', fontSize: '7pt', marginTop: 5 }}>&euro; =</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspSecondary,
                            width: '12%',
                        }}
                    >{getAhorroMoney(valueForm)}</Text>
                    <Text style={{ textAlign: 'left' }}>&euro;</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginVertical: '0.5vh',
                    }}
                >
                    <Text style={{ textAlign: 'right', marginLeft: '30%' }}>Ahorro</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                        }}
                    >{roundTwoDecimal(getAhorroMoney(valueForm)*100 / valueForm.consum)}</Text>
                    <Text style={{ textAlign: 'left' }}>% del coste consumo anual actual.</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginVertical: '0.5vh',
                    }}
                >
                    <Text style={{ textAlign: 'left', width: '26%' }}>Ahorro medio anual:</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspPrimary,
                            width: '12%',
                        }}
                    >{getAhorroMoney(valueForm)}</Text>
                    <Text style={{ textAlign: 'left', fontSize: '7pt', marginTop: 5 }}>&euro;/12 meses =</Text>
                    <Text
                        style={{
                            ...styles.boxValueEspSecondary,
                            width: '12%',
                        }}
                    >{roundTwoDecimal(getAhorroMoney(valueForm)/12)}</Text>
                    <Text style={{ textAlign: 'left' }}>&euro; mensuales</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginVertical: '0.5vh',
                    }}
                >
                    <View
                        style={{
                            marginTop: 7,
                        }}
                    >
                        <Text style={{ textAlign: 'left', fontSize: '7pt' }}>GARANT&Iacute;A PLACAS: 20 a&ntilde;os</Text>
                        <Text style={{ textAlign: 'left', fontSize: '7pt' }}>GARANT&Iacute;A INVERSOR: 10 a&ntilde;os</Text>
                        <Text style={{ textAlign: 'left', fontSize: '7pt' }}>GARANT&Iacute;A BATER&Iacute;AS: 5 a&ntilde;os</Text>
                    </View>
                    <View
                        style={{
                            marginLeft: '2%',
                            borderLeft: 4,
                            borderLeftColor: styles.secondaryColor.color,
                            paddingLeft: 3,
                        }}
                    >
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginVertical: '0.5vh',
                            }}
                        >
                            <Text style={{ textAlign: 'left', fontSize: '7pt', marginLeft: '33%' }}>AHORRO TOTAL</Text>
                        </View>
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginVertical: '0.5vh',
                            }}
                        >
                            <Text style={{ textAlign: 'left' }}>VIDA &Uacute;TIL 20 a&ntilde;os x</Text>
                            <Text
                                style={{
                                    ...styles.boxValueEspPrimary,
                                    width: '20%',
                                }}
                            >{getAhorroMoney(valueForm)}</Text>
                            <Text style={{ textAlign: 'left', fontSize: '7pt', marginTop: 5 }}>=</Text>
                            <Text
                                style={{
                                    ...styles.boxValueEspSecondary,
                                    width: '20%',
                                }}
                            >{getAhorroMoney(valueForm) * 20}</Text>
                            <Text style={{ textAlign: 'left' }}>&euro;</Text>
                        </View>
                    </View>
                </View>
            </View>
        </Page>
    );
}

export default PageFivePdfQuote;