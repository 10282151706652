import React from "react";
import { Document, Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";

import pOneUp from '../media/pag1-up.png';
import pOneDown from '../media/pag1-down.png';
import { format } from "date-fns";

const PageOnePdfQuote = ({ styles, valueForm, dateContrat }) => {
    return (
        <Page
            size="A4"
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                backgroundColor: "white"
            }}
        >
            <Image
                src={pOneUp}
            />
            <View>
                <Text style={{
                    ...styles.fontSubtitle,
                    ...styles.secondaryColor,
                    marginLeft: '52%',
                    marginTop: '2.5vh',
                }}>N&#176; PRESUPUESTO: {`${format(dateContrat, 'yyMM')}13${valueForm.noPpto}`}</Text>
            </View>
            <View style={{
                ...styles.fontSubtitle,
                ...styles.primaryColor,
                marginLeft: '15%',
                marginTop: '2vh',
            }}>
                <Text style={{ marginBottom: '1.2vh' }}>NOMBRE COMPLETO: {`${valueForm.nameClient} ${valueForm.surname} ${valueForm.secondname}`}</Text>
                <Text style={{ marginBottom: '1.2vh' }}>DIRECCIÓN: {`${valueForm.zipCode} - ${valueForm.place} - ${valueForm.address}`}</Text>
                <Text style={{ marginBottom: '2vh' }}>TELÉFONO: {`${valueForm.fixPhone} - ${valueForm.phone}`}</Text>
            </View>
            <Image
                src={pOneDown}
            />
        </Page>
    );
};

export default PageOnePdfQuote;