import * as React from 'react';
import { Autocomplete, Box, FormControlLabel, FormGroup, Switch, TextField, Typography } from '@mui/material';
import { batometroCatalog, squareCatalog } from '../utils/products.catalog';

export default function MaterialData({
    valueForm,
    onValueForm,
    errorForm,
    onErrorForm,
    showErrorForm
}) {
    const handleMeter = e => {
        onValueForm({
            ...valueForm,
            meter: e.target.checked
        });
    };

    const handleCable = e => {
        if (e.target.value >= 0) {
            onValueForm({
                ...valueForm,
                cable: e.target.value
            });
        }
    };

    const handleIncludeSquare = e => {
        onValueForm({
            ...valueForm,
            includeSquare: e.target.checked,
            square: null,
        });
    };

    const handleSquare = (e, value) => {
        onValueForm({
            ...valueForm,
            square: value,
        });
    };

    const handleIncludeBatometro = e => {
        onValueForm({
            ...valueForm,
            includeBatometro: e.target.checked,
            batometro: null,
        });
    };

    const handleBatometro = (e, value) => {
        onValueForm({
            ...valueForm,
            batometro: value,
        });
    };

    return (
        <React.Fragment>
            <Typography variant='h5'>Materiales</Typography>
            <FormGroup>
                <FormControlLabel control={<Switch
                    checked={valueForm.meter}
                    onChange={handleMeter}
                />} label="Meter" />
            </FormGroup>
            <TextField
                fullWidth
                required
                label='Cableado (mts.)'
                type='number'
                inputProps={{
                    autoComplete: 'off',
                    min: 0,
                }}
                value={valueForm.cable}
                onChange={handleCable}
            />
            <FormGroup>
                <FormControlLabel control={<Switch
                    checked={valueForm.includeSquare}
                    onChange={handleIncludeSquare}
                />} label="Cuadro" />
            </FormGroup>
            {valueForm.includeSquare && (
                <Autocomplete
                    id="select-square-install"
                    value={valueForm.square}
                    onChange={handleSquare}
                    options={squareCatalog}
                    autoHighlight
                    clearText='Limpiar selecci&oacute;n'
                    closeText='Cerrar'
                    getOptionLabel={(option) => `${option.name}`}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.name}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Tipo de Cuadro"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off',
                            }}
                        />
                    )}
                />
            )}
            <FormGroup>
                <FormControlLabel control={<Switch
                    checked={valueForm.includeBatometro}
                    onChange={handleIncludeBatometro}
                />} label="Batómetro" />
            </FormGroup>
            {valueForm.includeBatometro && (
                <Autocomplete
                    id="select-batometro-install"
                    value={valueForm.batometro}
                    onChange={handleBatometro}
                    options={batometroCatalog}
                    autoHighlight
                    clearText='Limpiar selecci&oacute;n'
                    closeText='Cerrar'
                    getOptionLabel={(option) => `${option.name}`}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.name}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Tipo de Batómetro"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off',
                            }}
                        />
                    )}
                />
            )}
        </React.Fragment>
    );
}