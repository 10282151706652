import * as React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { displacementTypes, maintenanceTypes } from '../utils/products.catalog';
import { PDFViewer } from '@react-pdf/renderer';
import ViewPdfQuote from '../viewPdf/viewPdfQuote';
import GeneralData from './GeneralData';
import ClientData from './ClientData';
import InstallData from './InstallData';
import MaterialData from './MaterialData';
import MountData from './MountData';
import StructureData from './StructureData';
import ProcessData from './ProcessData';
import DisplacementData from './DisplacementData';
import MaintenanceData from './MaintenanceData';

const iniValueForm = {
    noPpto: '',
    seller: '',
    nameSeller: '',
    nameClient: '',
    surname: '',
    secondname: '',
    indentity: '',
    fixPhone: '',
    phone: '',
    email: '',
    zipCode: '',
    place: '',
    address: '',
    cups: '',
    validCups: null,
    power: '',
    consum: '',
    currentPriceKw: '',
    consumDayTime: '',
    consumNightTime: 0,
    priceDayTime: '',
    priceNightTime: '',
    discount: null,
    countInversors: 1,
    typeInversor: '',
    inversor: null,
    countPanels: 1,
    panel: null,
    includeBattery: false,
    countBatteries: 1,
    battery: null,
    meter: false,
    cable: 0,
    includeSquare: false,
    square: null,
    includeBatometro: false,
    batometro: null,
    includeMount: false,
    mount: '',
    structure: '',
    inclination: '',
    includePergola: false,
    pergola: 1,
    displacement: displacementTypes[0].value,
    includeMaintenance: false,
    typeMaintenance: maintenanceTypes[0].value,
    processAllow: false,
    processSubvencion: false,
};

const iniErrorForm = {
    noPpto: 'Campo incompleto',
    seller: 'Campo obligatorio',
    nameSeller: 'Campo obligatorio',
    nameClient: 'Campo obligatorio',
    surname: 'Campo obligatorio',
    secondname: 'Campo obligatorio',
    indentity: 'Campo obligatorio',
    fixPhone: 'Campo obligatorio',
    phone: 'Campo obligatorio',
    email: null,
    zipCode: 'Campo obligatorio',
    address: 'Campo obligatorio',
    cups: 'Campo obligatorio',
    power: 'Campo obligatorio',
    consum: 'Campo obligatorio',
    currentPriceKw: 'Campo obligatorio',
    consumDayTime: 'Campo obligatorio',
    priceDayTime: 'Campo obligatorio',
    priceNightTime: 'Campo obligatorio',
    structure: 'Selección obligatoria',
};

export default function FormHome() {
    const [valueForm, setValueForm] = React.useState(iniValueForm);
    const [errorForm, setErrorForm] = React.useState(iniErrorForm);
    const [showErrorForm, setShowErrorForm] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const dateContrat = new Date();

    const handleSubmit = () => {
        // setLoading(true);
        if (
            errorForm.noPpto !== null
            || errorForm.seller !== null
            || (valueForm.seller === 'colaborador' && errorForm.nameSeller !== null)
            || errorForm.nameClient !== null
            || errorForm.surname !== null
            || errorForm.secondname !== null
            || errorForm.indentity !== null
            || errorForm.fixPhone !== null
            || errorForm.phone !== null
            || errorForm.email !== null
            || errorForm.zipCode !== null
            || errorForm.address !== null
            || errorForm.cups !== null
            || errorForm.power !== null
            || errorForm.consum !== null
            || errorForm.currentPriceKw !== null
            || errorForm.consumDayTime !== null
            || errorForm.priceDayTime !== null
            || errorForm.priceNightTime !== null
            || errorForm.structure !== null
        ) {
            setShowErrorForm(true);
        } else {
            setLoading(true);
        }
        // alert(JSON.stringify(valueForm));
    };

    const roundTwoDecimal = numb => {
        return Math.round((numb)*100)/100;
    };

    return (
        <Box pt={5} pb={10}>
            <Stack spacing={2}>
                <GeneralData
                    valueForm={valueForm}
                    onValueForm={setValueForm}
                    errorForm={errorForm}
                    onErrorForm={setErrorForm}
                    showErrorForm={showErrorForm}
                    dateContrat={dateContrat}
                />
                <ClientData
                    valueForm={valueForm}
                    onValueForm={setValueForm}
                    errorForm={errorForm}
                    onErrorForm={setErrorForm}
                    showErrorForm={showErrorForm}
                />
                <InstallData
                    valueForm={valueForm}
                    onValueForm={setValueForm}
                    errorForm={errorForm}
                    onErrorForm={setErrorForm}
                    showErrorForm={showErrorForm}
                />
                <MaterialData
                    valueForm={valueForm}
                    onValueForm={setValueForm}
                    errorForm={errorForm}
                    onErrorForm={setErrorForm}
                    showErrorForm={showErrorForm}
                />
                <MountData
                    valueForm={valueForm}
                    onValueForm={setValueForm}
                    errorForm={errorForm}
                    onErrorForm={setErrorForm}
                    showErrorForm={showErrorForm}
                />
                <StructureData
                    valueForm={valueForm}
                    onValueForm={setValueForm}
                    errorForm={errorForm}
                    onErrorForm={setErrorForm}
                    showErrorForm={showErrorForm}
                />
                <DisplacementData
                    valueForm={valueForm}
                    onValueForm={setValueForm}
                    errorForm={errorForm}
                    onErrorForm={setErrorForm}
                    showErrorForm={showErrorForm}
                />
                <MaintenanceData
                    valueForm={valueForm}
                    onValueForm={setValueForm}
                    errorForm={errorForm}
                    onErrorForm={setErrorForm}
                    showErrorForm={showErrorForm}
                />
                <ProcessData
                    valueForm={valueForm}
                    onValueForm={setValueForm}
                    errorForm={errorForm}
                    onErrorForm={setErrorForm}
                    showErrorForm={showErrorForm}
                />
                <Button variant='contained' onClick={handleSubmit}>Generar</Button>
                {loading && (
                    <React.Fragment>
                        {/* <Stack>
                            <Typography variant='h6'>Datos P&aacute;gina 1</Typography>
                            <Typography variant='subtitle1'>N&#176; PRESUPUESTO: {`${format(dateContrat, 'yyMM')}13${valueForm.noPpto}`}</Typography>
                            <Typography variant='subtitle1'>NOMBRE COMPLETO: {`${valueForm.nameClient} ${valueForm.surname} ${valueForm.secondname}`}</Typography>
                            <Typography variant='subtitle1'>DIRECCIÓN: {`${valueForm.zipCode} - ${valueForm.place} - ${valueForm.address}`}</Typography>
                            <Typography variant='subtitle1'>TELÉFONO: {`${valueForm.fixPhone} - ${valueForm.phone}`}</Typography>
                            <Typography variant='h6'>Datos P&aacute;gina 2</Typography>
                            <Typography variant='subtitle1'>FECHA: {format(dateContrat, 'd/M/yyyy')}</Typography>
                            <Typography variant='h6'>Datos P&aacute;gina 3</Typography>
                            <Typography variant='subtitle1'>P1: {valueForm.consumDayTime} Kw</Typography>
                            <Typography variant='subtitle1'>P2: {valueForm.consumNightTime} Kw</Typography>
                            <Typography variant='subtitle1'>Consumo Anual: {valueForm.consum} Kw</Typography>
                            <Typography variant='h6'>Datos P&aacute;gina 5</Typography>
                            <Typography variant='h5'>CONSUMO</Typography>
                            <Typography variant='subtitle1'>Consumo Anual: {valueForm.consum} Kw</Typography>
                            <Typography variant='subtitle1'>Precio Actual KwH: {valueForm.currentPriceKw} &euro;/KwH</Typography>
                            <Typography variant='subtitle1'>Coste Anual: {valueForm.consum * valueForm.currentPriceKw} &euro;/KwH + fijo + impuestos</Typography>
                            <Typography variant='subtitle1'>Diurno: {valueForm.consumDayTime} Kw * {valueForm.priceDayTime} &euro;/KwH = {roundTwoDecimal(valueForm.consumDayTime * valueForm.priceDayTime)} &euro;</Typography>
                            <Typography variant='subtitle1'>Nocturno: {valueForm.consumNightTime} Kw * {valueForm.priceNightTime} &euro;/KwH = {roundTwoDecimal(valueForm.consumNightTime * valueForm.priceNightTime)} &euro;</Typography>
                            <Typography variant='h5'>INSTALACI&Oacute;N RECOMENDADA</Typography>
                            <Typography variant='subtitle1'>Producci&oacute;n estimada: {getProduccionTotalInstallAuto(valueForm)} Kw x 1,4 productividad aprox = {getProducctionPico(valueForm)} Kw</Typography>
                            <Typography variant='subtitle1'>Kw estimados anuales: {getProduccionTotalInstallAuto(valueForm) / 12} / {valueForm.panel.power} potencia por placa = {roundTwoDecimal((getProduccionTotalInstallAuto(valueForm) / 12) / valueForm.panel.power)}</Typography>
                            <Typography variant='subtitle1'>No. Placas: {valueForm.countPanels} x {valueForm.panel.power} Kw - 20% = {getProduccionTotalInstallAuto(valueForm) * 0.8} Kw</Typography>
                            <Typography variant='subtitle1'>Bater&iacute;as: No. {valueForm.includeBattery ? valueForm.countBatteries : ''} x {valueForm.includeBattery ? valueForm.battery.power : ''} Kw = {valueForm.includeBattery ? valueForm.countBatteries * valueForm.battery.power : ''} Kw Total acumulables</Typography>
                            <Typography variant='h5'>AHORRO</Typography>
                            <Typography variant='subtitle1'>Ahorro diurno: {roundTwoDecimal(getGastoMoneyConsumDia(valueForm))} &euro; Ahorra 100% gracias a inyectar a red en meses de alta generaci&oacute;n.</Typography>
                            <Typography variant='subtitle1'>Ahorro Vertido RED: {getProducctionPico(valueForm) - valueForm.consum} Kw x {PRICE_KW_INJECT_RED} &euro;/KwH = {getDevolucionInyectRed(valueForm)} &euro;</Typography>
                            <Typography variant='subtitle1'>Ahorro Total: Diurno {roundTwoDecimal(getGastoMoneyConsumDia(valueForm))} &euro; + Nocturno {roundTwoDecimal(getGastoMoneyConsumNoche(valueForm) - getDevolucionInyectRed(valueForm))} &euro; = {getAhorroMoney(valueForm)} &euro;</Typography>
                            <Typography variant='subtitle1'>Ahorro {roundTwoDecimal(getAhorroMoney(valueForm)*100 / valueForm.consum)} % del coste consumo anual actual</Typography>
                            <Typography variant='subtitle1'>Ahorro medio anual {getAhorroMoney(valueForm)} &euro;/12 meses = {roundTwoDecimal(getAhorroMoney(valueForm)/12)} &euro; mensuales</Typography>
                            <Typography variant='subtitle1'>Vida &uacute;til 20 a&ntilde;os x Ahorro Total {getAhorroMoney(valueForm)} = {getAhorroMoney(valueForm) * 20} &euro;</Typography>
                            <Typography variant='h6'>Datos P&aacute;gina 6</Typography>
                            <Typography variant='subtitle1'>Precio estimado: {roundTwoDecimal(getCostWIVA(valueForm))} &euro; I.V.A. Inclu&iacute;do</Typography>
                            <Typography variant='subtitle1'>Precio estimado: {roundTwoDecimal(getCostWIVA(valueForm))} &euro; I.V.A. Inclu&iacute;do</Typography>
                            <Typography variant='h6'>Datos P&aacute;gina 7</Typography>
                            <Typography variant='subtitle1'>Vatios Pico: {getProducctionPico(valueForm)} KwH</Typography>
                            <Typography variant='subtitle1'>No. Paneles: {valueForm.countPanels}</Typography>
                            <Typography variant='subtitle1'>No. Inversores: {valueForm.countInversors}</Typography>
                            <Typography variant='subtitle1'>No. Bater&iacute;as: {valueForm.includeBattery ? valueForm.countBatteries : ''}</Typography>
                            <Typography variant='subtitle1'>Ahorro: {roundTwoDecimal(getAhorroMoney(valueForm))}</Typography>
                            <Typography variant='subtitle1'>Ahorro: {roundTwoDecimal(getAhorroMoney(valueForm) * 25)}</Typography>
                            <Typography variant='subtitle1'>Autoconsumo: {getProduccionTotalInstallAuto(valueForm)}</Typography>
                            <Typography variant='subtitle1'>Excedentes: {getExcedenteProduccion(valueForm)}</Typography>
                            <Typography variant='subtitle1'>Excedentes: {getExcedenteProduccion(valueForm)}</Typography>
                        </Stack> */}
                        <PDFViewer style={{ width: '100%', height: '90vh' }}>
                            <ViewPdfQuote valueForm={valueForm} dateContrat={dateContrat} />
                        </PDFViewer>
                    </React.Fragment>
                )}
            </Stack>
        </Box>
    );
}