import React from "react";
import { Document, Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import pThreeUp from '../media/pag3-up.png';
import pThreeMid from '../media/pag3-mid.png';
import pThreeDown from '../media/pag3-down.png';

const PageThreePdfQuote = ({ styles, valueForm }) => {
    return (
        <Page
            size="A4"
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                backgroundColor: "white"
            }}
        >
            <Image
                src={pThreeUp}
            />
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                <Image
                    src={pThreeMid}
                    style={{
                        width: '62%'
                    }}
                />
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        ...styles.fontBody,
                        ...styles.primaryColor,
                        marginLeft: '7.5%',
                    }}
                >
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '7vh',
                        }}
                    >
                        <Text style={{ padding: '5pt' }}>P1 </Text>
                        <Text style={{
                            ...styles.boxValueSecondary,
                        }}>{valueForm.consumDayTime} Kw</Text>
                    </View>
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginVertical: '2vh'
                        }}
                    >
                        <Text style={{ padding: '5pt' }}>P2 </Text>
                        <Text style={{
                            ...styles.boxValueSecondary,
                        }}
                        >{valueForm.consumNightTime} Kw</Text>
                    </View>
                    <Text style={{ ...styles.fontSubtitle, }}>Consumo Anual</Text>
                    <Text style={{
                        ...styles.boxValueSecondary,
                    }}>{valueForm.consum} Kw</Text>
                </View>
            </View>
            <Image
                src={pThreeDown}
            />
        </Page>
    );
}

export default PageThreePdfQuote;