import React from "react";
import { Document, Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import { getAhorroMoney, getExcedenteProduccion, getProduccionTotalInstallAuto, getProducctionPico } from '../utils/process.quote';
import pSevenA from '../media/pag7-1.png';
import pSevenB from '../media/pag7-2.png';
import pSevenC from '../media/pag7-3.png';
import pSevenD from '../media/pag7-4.png';

const PageSevenPdfQuote = ({ styles, valueForm }) => {
    return (
        <Page
            size="A4"
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                backgroundColor: "white"
            }}
        >
            <Image
                src={pSevenA}
            />
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginHorizontal: '10%'
                }}
            >
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%'
                }}>{getProducctionPico(valueForm)} Kw</Text>
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%',
                    marginLeft: '1%',
                }}>{valueForm.countPanels}</Text>
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%',
                    marginLeft: '1%',
                }}>{valueForm.countInversors}</Text>
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%'
                }}>{valueForm.includeBattery ? valueForm.countBatteries : '-'}</Text>
            </View>
            <Image
                src={pSevenB}
            />
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginHorizontal: '10%'
                }}
            >
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%'
                }}>{Math.round((getAhorroMoney(valueForm) + Number.EPSILON)*100)/100} &euro;</Text>
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%',
                    marginLeft: '1.5%',
                }}>{Math.round(((getAhorroMoney(valueForm) * 25) + Number.EPSILON)*100)/100} &euro;</Text>
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%',
                    marginLeft: '3%',
                }}>{getProduccionTotalInstallAuto(valueForm)} Kw</Text>
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%'
                }}>{getExcedenteProduccion(valueForm)} Kw</Text>
            </View>
            <Image
                src={pSevenC}
            />
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginHorizontal: '10%'
                }}
            >
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%'
                }}> </Text>
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%',
                    marginLeft: '1%',
                }}>{valueForm.includeMount && valueForm.mount === 'grua' ? 'Si' : 'No'}</Text>
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%',
                    marginLeft: '3%',
                }}> </Text>
                <Text style={{
                    ...styles.boxValuePrimary,
                    ...styles.fontBody,
                    width: '18%'
                }}>{valueForm.processAllow ? 'Si' : 'No'}</Text>
            </View>
            <Image
                src={pSevenD}
            />
        </Page>
    );
}

export default PageSevenPdfQuote;